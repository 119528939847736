export const RATIOS = {
  // Mobile ratios
  ratio_4x5: 'aspect-4/5',
  ratio_5x4: 'aspect-5/4',
  ratio_1x1: 'aspect-square',
  ratio_9x16: 'aspect-9/16',
  ratio_3x2: 'aspect-3/2',
  // Desktop ratios
  ratio_16x9: 'lg:aspect-video',
  ratio_2x1: 'lg:aspect-2/1',
  ratio_3x1: 'lg:aspect-3/1',
  ratio_4x1: 'lg:aspect-4/1',
  ratio_3x4: 'lg:aspect-3/4',
  DEFAULT: 'aspect-square',
  NONE: ''
} as const;

type ObjectValues<T> = T[keyof T];
export type RatioType = ObjectValues<typeof RATIOS>;

export const MIN_HEIGHT_RATIOS = {
  // Mobile ratios
  ratio_4x5: 'min-h-4/5',
  ratio_5x4: 'min-h-5/4',
  ratio_1x1: 'min-h-square',
  ratio_9x16: 'min-h-9/16',
  ratio_3x2: 'min-h-3/2',
  // Desktop ratios
  ratio_16x9: 'md:min-h-video',
  ratio_2x1: 'md:min-h-2/1',
  ratio_3x1: 'md:min-h-3/1',
  ratio_4x1: 'md:min-h-4/1',
  ratio_3x4: 'md:min-h-3/4',
} as const;

export type MinHeightRatioType = ObjectValues<typeof MIN_HEIGHT_RATIOS>;
