import { ratioWxHCalculation } from '../../ratio/ratio-height-calculator';

export function imageStoryblokSrcGenerator(
  url: string,
  ratio: string,
  breakpoint: number,
  itemsPerRow: number,
): string {
  const pixelWidth = Math.round(breakpoint / itemsPerRow);
  const WxH = ratioWxHCalculation(pixelWidth, ratio);
  return `${url}/m/${WxH}`;
}
