'use client';

import { ContentGuidedBuyingFlowInterface, ProductInterface } from '@qlevr/shared/interfaces';
import { getCombinationBySizeAndOrColor } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { useSearchParams } from 'next/navigation';
import ProductStatus from '../product-status/product-status';
import InventoryStatus from './inventory-status';
import { ProductBundlePromotion } from './product-bundle-promotion';

/* eslint-disable-next-line */
export interface ProductAddToCartProps {
  product: ProductInterface;
  inventoryThreshold: number;
  guidedBuyingFlow: ContentGuidedBuyingFlowInterface | null;
}

export function ProductAddToCart({ product, inventoryThreshold, guidedBuyingFlow }: ProductAddToCartProps) {
  const searchParams = useSearchParams();
  const size = searchParams.get('size');
  const color = searchParams.get('color');

  if (!product.variants || isEmpty(product.variants)) {
    return null;
  }

  let combination = getCombinationBySizeAndOrColor(product.combinations, size, color);
  const hasNoOptionsOrJustOneOption =
    !product.options?.length || (product.options.length === 1 && product.options[0]?.values.length === 1);

  if (hasNoOptionsOrJustOneOption) {
    combination = product.combinations?.[0] || null;
  }

  return (
    <div className="mt-2 flex flex-col gap-2">
      <ProductStatus product={product} combination={combination} guidedBuyingFlow={guidedBuyingFlow} />
      <InventoryStatus combination={combination} inventoryThreshold={inventoryThreshold} />
      <ProductBundlePromotion product={product} />
    </div>
  );
}

export default ProductAddToCart;
