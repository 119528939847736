import { BUTTON_SIZE, ButtonSizeType } from '@qlevr/shared/constants';

const mapLinkSizeToLinkSizeType = new Map<string, ButtonSizeType>([
  ["small", BUTTON_SIZE.small],
  ["base", BUTTON_SIZE.base],
]);

export function linkSizeMapper(size?: string): ButtonSizeType {
  if (!size) {
    return BUTTON_SIZE.base;
  }

  const mapper = mapLinkSizeToLinkSizeType.get(size);
  if (!mapper) {
    return BUTTON_SIZE.base;
  }

  return mapper;
}
