import { BREAKPOINTS } from '@qlevr/shared/constants';
import { ColumnConfigInterface, ImageInterface } from '@qlevr/shared/interfaces';
import { imageShopifySrcGenerator, imageShopifySrcSetGenerator, imageStoryblokSrcGenerator, imageStoryblokSrcSetGenerator } from '@qlevr/shared/utilities';

export type ImageRendererInterface = {
  isActive(source: ImageInterface['source']): boolean;
  srcGenerator: (asset: string, ratio: string, columnConfigMobile: ColumnConfigInterface) => string;
  srcSetGenerator: (asset: string, ratio: string, columnConfigMobile: ColumnConfigInterface) => string;
};

export class ImageMapperStrategyResolver {
  private strategies = [new ShopifyImageMapperStrategy(), new StoryblokImageMapperStrategy()];

  public getImageStrategy(image: ImageInterface): ImageRendererInterface {
    for (const strategy of this.strategies) {
      if (strategy.isActive(image.source)) {
        return strategy;
      }
    }

    return new StoryblokImageMapperStrategy()
  }
}

class ShopifyImageMapperStrategy implements ImageRendererInterface {
  srcGenerator(asset: string, ratio: string, columnConfig: ColumnConfigInterface) {
    return imageShopifySrcGenerator(asset, ratio, BREAKPOINTS.lg, columnConfig.md as number);
  }
  srcSetGenerator(asset: string, ratio: string, columnConfig: ColumnConfigInterface) {
    return imageShopifySrcSetGenerator(asset, ratio, columnConfig).join(', ');
  }
  isActive(source: ImageInterface['source']): boolean {
    if (source !== 'shopify') {
      return false;
    }

    return true;
  }
}

class StoryblokImageMapperStrategy implements ImageRendererInterface {
  srcGenerator(asset: string, ratio: string, columnConfig: ColumnConfigInterface) {
    return imageStoryblokSrcGenerator(asset, ratio, BREAKPOINTS.lg, columnConfig.md as number)
  }
  srcSetGenerator(asset: string, ratio: string, columnConfig: ColumnConfigInterface) {
    return imageStoryblokSrcSetGenerator(asset, ratio, columnConfig).join(', ');
  }
  isActive(source: ImageInterface['source']): boolean {
    if (source !== 'storyblok') {
      return false;
    }

    return true;
  }
}
