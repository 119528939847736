import { MetaobjectFieldShopify } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';

export function getMetafieldReferenceImageField(key: string, fields: MetaobjectFieldShopify[]): string | null {
  if (!fields || isEmpty(fields)) {
    return null;
  }

  const field = fields.find((item) => item.key === key);
  if (!field || !field.reference || !('image' in field.reference) || field.reference.image?.url === undefined) {
    return null;
  }

  return field.reference.image.url;
}
