import { ICONS } from '@qlevr/shared/constants';
import { ProductCombinationInterface } from '@qlevr/shared/interfaces';
import { inventoryIsLow } from '@qlevr/shared/utilities';
import { useTranslations } from 'next-intl';
import IconRenderer from '../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface InventoryStatusProps {
  combination: ProductCombinationInterface | null;
  inventoryThreshold: number;
}

export function InventoryStatus({ combination, inventoryThreshold }: InventoryStatusProps) {
  const t = useTranslations();

  const inventoryQuantity = combination?.inventoryQuantity;
  if (!inventoryIsLow(combination, inventoryThreshold)) {
    return null;
  }

  return (
    <span className="text-center text-red-600 text-xs flex justify-center items-center gap-1">
      {t('pdp.fewItemsLeft', {inventoryQuantity})}
      <IconRenderer icon={ICONS.alertCircle} className="h-3" />
    </span>
  );
}

export default InventoryStatus;
