'use client';

import { useBundleAndSave, useCart } from '@qlevr/shared/providers';
import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface BundleAndSaveCartFlowControllerProps {}

export function BundleAndSaveCartFlowController(props: BundleAndSaveCartFlowControllerProps) {
  const { isOpen: isBundleOpen } = useBundleAndSave();
  const { open: openCart } = useCart();
  const [ flow, setFlow ] = useState(false);

  useEffect(() => {
    if(isBundleOpen) {
      // start the flow
      setFlow(true);
    }
  }, [isBundleOpen]);

  useEffect(() => {
    // When the bundle gets closed, open the cart.
    if (!isBundleOpen && flow) {
      openCart();
      // close the flow
      setFlow(false);
    }
  }, [isBundleOpen, openCart, flow]);

  return null; // This component does not render anything itself.
}

export default BundleAndSaveCartFlowController;
