import { BuyingFlowProductInterface, ContentGuidedBuyingFlowInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { BuyingFlowProductStoryblok, GuidedBuyingFlowStoryblok, ShopifyProductStoryblok } from '@qlevr/shared/schema';
import { getMetaProductById } from '../../products/products-shopify';
import { sectionMapper } from '../../section/section';
import { mapBuyingFlowProduct } from './buying-flow-product';

export function mapGuidedBuyingFlow(
  content: GuidedBuyingFlowStoryblok,
  meta: MetaInterface,
): ContentGuidedBuyingFlowInterface | null {
  const product = content?.['product'].items[0] as ShopifyProductStoryblok;

  if (!product) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    products: buyingFlowProductsMapper(content.products, meta),
    product: getMetaProductById(product.id, meta),
    section: sectionMapper(content.section?.[0]),
  };
}

export function buyingFlowProductsMapper(
  products: BuyingFlowProductStoryblok[],
  meta: MetaInterface,
): BuyingFlowProductInterface[] {
  if (!products || products.length === 0) return [];

  return products.reduce((acc, product) => {
    const mappedProduct = mapBuyingFlowProduct(product, meta);

    if (mappedProduct) {
      acc.push(mappedProduct);
    }

    return acc;
  }, [] as BuyingFlowProductInterface[]);
}
