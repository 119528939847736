export const formModalTheme = {
  content: {
    base: 'relative h-full w-full flex justify-center items-center',
    inner: 'w-full max-w-96 relative bg-slate-100 rounded-lg shadow dark:bg-gray-700 flex flex-col',
  },
  body: {
    base: '',
  },
  header: {
    base: 'flex items-start justify-between pt-3 pb-1 px-2',
    title: 'w-full text-center text-lg font-medium text-black dark:text-white',
    close: {
      base: 'ml-0',
      icon: 'h-6 w-6',
    },
  },
  footer: {
    base: 'empty:hidden',
  },
};
