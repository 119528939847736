import { PageTypeType } from '@qlevr/shared/constants';
import { pagePathMapper } from '@qlevr/shared/mappers';
import { notFound } from 'next/navigation';

export const fetchPagePaths = async (pageType: PageTypeType, locale: string, preview: boolean, cv: string) => {
  const urlParams = new URLSearchParams({
    token: process.env['NEXT_PUBLIC_STORYBLOK_API_TOKEN'] as string,
    version: preview ? 'draft' : 'published',
    starts_with: `${process.env['NEXT_PUBLIC_BRAND_NAME']}${pageType}`,
    is_startpage: '0',
    cv,
  }).toString();

  const url = `https://api.storyblok.com/v2/cdn/stories?${urlParams}`;
  const res = await fetch(url);

  const isError = res.status !== 200;
  if (isError) {
    console.error('Page paths not found', url);
    return notFound();
  }

  const pages = await res.json();

  if (!pages) {
    throw new Error(`Page paths could not be fetched`);
  }

  return pagePathMapper(pages.stories, locale);
};
