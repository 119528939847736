import { InstagramInterface, LinkedMetaDataInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash/isEmpty';
import { instagramFeedService } from './instagram';

export async function getMetaInstagram(
  linkedData: LinkedMetaDataInterface | undefined,
): Promise<InstagramInterface | null> {
  if (!linkedData?.instagram) {
    return null;
  }

  const instagram = await instagramFeedService();
  if (!instagram || isEmpty(instagram.items)) {
    return null;
  }

  return instagram;
}
