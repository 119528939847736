import { LOCALES_COUNTRIES } from '@qlevr/shared/constants';
import { PathInterface } from '@qlevr/shared/interfaces';
import { addMissingLocalRegionUrls, getProductSlug } from '@qlevr/shared/mappers';
import { getLanguageCodeShopify, getLocaleFromCountryLocale, reorderLocalesUrls } from '@qlevr/shared/utilities';
import { ShopifyService } from '../shopify/shopify-service';

export async function fetchLocalisedProductSlugs(
  currentLocale: string,
  productId?: string,
): Promise<PathInterface[] | null> {
  if (!productId) {
    return null;
  }

  // The current product is already know. No need to fetch it again.
  const locales = LOCALES_COUNTRIES.filter((locale) => locale !== currentLocale);

  // Fetch the product path for each locale
  const paths = await locales.reduce(async (accPromise, locale) => {
    const acc = await accPromise; // Resolve the accumulator promise from the previous iteration

    const response = await ShopifyService({}).getProductPath({
      language: getLanguageCodeShopify(getLocaleFromCountryLocale(locale)),
      id: productId,
    });

    // Only add to the accumulator if the response is valid
    if (response?.product?.handle) {
      acc.push({
        locale,
        slug: getProductSlug(response.product.handle),
      });
    }

    return acc; // Pass the accumulated array to the next iteration
  }, Promise.resolve<PathInterface[]>([])); // Initial accumulator is a resolved promise with an empty array

  // If a "base" language-region pair exists (like `nl-nl`), and its alternate region (like `nl-be`) does not exist, add it.
  const withMissingLocalRegions = addMissingLocalRegionUrls(paths);

  // Ensure that the order of the locales is ocording to the LOCALES_COUNTRIES constant order
  const sorted = reorderLocalesUrls(withMissingLocalRegions);

  return sorted;
}
