import { ShopifyService } from './shopify-service';

export async function getCustomerMetafields(email: string, password: string) {
  try {
    const response = await ShopifyService({}).getCustomerAccessToken({ email, password });
    const accessToken = response?.customerAccessTokenCreate?.customerAccessToken?.accessToken;

    if (!accessToken) return null;

    const cartId = await ShopifyService({}).getCustomerMetafield({ customerAccessToken: accessToken, key: 'cart_id' });

    return cartId;
  } catch (error) {
    console.error('Error fetching customer metafields:', error);
  }
}
