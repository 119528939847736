'use client';
import { BUTTON_SIZE, ICONS, LINK_STYLES } from '@qlevr/shared/constants';
import {
  ContentGuidedBuyingFlowInterface,
  ImageInterface,
  ProductInterface,
  VariantWithQuantity,
} from '@qlevr/shared/interfaces';
import { GuidedBuyingFlowContext, useGuidedBuyingFlowBundler } from '@qlevr/shared/providers';
import { getProductVariantByOptionName, getSizeColorVariantFromUrl } from '@qlevr/shared/utilities';
import { Modal } from 'flowbite-react';
import { useTranslations } from 'next-intl';
import { useParams, useSearchParams } from 'next/navigation';
import { useContext } from 'react';
import IconRenderer from '../../icons/icon-renderer.component';
import ProductBulkAdd from '../../product-status/product-bulk-add';
import Section from '../../section/section';
import BuyingFlowProduct from './bundle-products/buying-flow-product-card';
import GuidedBuyingFlowCarousel from './guided-buying-flow-carousel';
import { mergeAllVariants, mergeVariantsWithQuantity } from './guided-buying-flow-filtered-variants';
import { guidedBuyingFlowModalTheme } from './guided-buying-flow-modal-theme';
import { calculateTotalPrice } from './guided-buying-flow-price';
import GuidedBuyingFlowMainProductCard from './main-product/guided-buying-flow-main-product-card';

export interface ContentGuidedBuyingFlowProps extends ContentGuidedBuyingFlowInterface {}

export function ContentGuidedBuyingFlow({ product, products, section }: ContentGuidedBuyingFlowProps) {
  const t = useTranslations();

  const searchParams = useSearchParams();
  const params = useParams<{ variant: string }>();
  const variantInfo = getSizeColorVariantFromUrl(params);
  const selectedVariant = getProductVariantByOptionName(
    product?.variants ?? [],
    searchParams,
    variantInfo?.size,
    variantInfo?.color,
  );

  const { flowProducts } = useContext(GuidedBuyingFlowContext);
  const guidedBuyingFlowModal = useGuidedBuyingFlowBundler();

  if (!product) {
    return null;
  }

  const filteredProducts = [product, ...products.map((product) => product.product)].filter(
    (product) => product !== null,
  ) as ProductInterface[];

  const images = [
    selectedVariant?.featuredImage,
    ...products.map((product) => product.product?.images),
  ].flat() as ImageInterface[];

  const { totalPrice, discountedPrice } = calculateTotalPrice(filteredProducts, flowProducts);
  const showDiscountedPrice = discountedPrice < totalPrice;

  // Create the VariantWithQuantity array
  const rawVariantWithQuantity = flowProducts.flatMap((flowProduct) =>
    flowProduct.bundledVariants
      .map((variant) => {
        if (variant.quantity === 0) return undefined;
        return {
          variantId: variant.id,
          quantity: variant.quantity || 1,
        };
      })
      .filter((variant) => variant !== undefined),
  ) as VariantWithQuantity[];

  // Merge the variants with quantity
  const variantWithQuantity = mergeVariantsWithQuantity(rawVariantWithQuantity).filter(
    (variant) => variant.variantId !== selectedVariant?.id,
  );

  // Create the variants array
  const allVariants = mergeAllVariants(filteredProducts, flowProducts).filter(
    (variant) => variant.id !== selectedVariant?.id,
  );

  return (
    <Modal
      dismissible
      size={'6xl'}
      show={guidedBuyingFlowModal.isOpen}
      theme={guidedBuyingFlowModalTheme}
      onClose={() => guidedBuyingFlowModal.close()}
      className="guided-buying-modal relative bg-black/50 backdrop-blur-sm"
    >
      <Modal.Body>
        <Section {...section} className="overflow-visible !p-0">
          <div className="relative space-y-6">
            <div className={`grid grid-cols-1 gap-0 transition-all duration-500 lg:grid-cols-12`}>
              <div className="relative col-span-1 hidden aspect-square lg:sticky lg:top-0 lg:col-span-7 lg:block lg:aspect-auto lg:h-fit">
                {images && images.length > 0 && <GuidedBuyingFlowCarousel images={images} />}
                <button
                  onClick={guidedBuyingFlowModal.close}
                  className="absolute left-5 top-5 z-10 inline-flex items-center justify-start gap-1 rounded-full bg-white/50 py-2 pl-3 pr-4 shadow-md"
                >
                  <IconRenderer icon={ICONS.accordionArrow} className="h-5 w-5 rotate-180 stroke-2 text-black" />
                  <div className="text-sm font-bold">Back</div>
                </button>
              </div>
              <div className="col-span-1 lg:col-span-5">
                <div className="sticky top-0 z-10 space-x-1 bg-white px-10 pb-2 pt-6">
                  <span className="flex items-center gap-2">
                    <IconRenderer
                      icon={ICONS.arrowLeft}
                      className="h-6 w-6 cursor-pointer text-black lg:hidden"
                      onClick={guidedBuyingFlowModal.close}
                    />{' '}
                    {t('guidedBuyingFlow.totalAmount')}
                    <span className="font-bold">
                      {showDiscountedPrice ? (
                        <>
                          <span className="text-slate-400 line-through">
                            {product.priceRange?.minVariantPrice.symbol}
                            {totalPrice}
                          </span>{' '}
                          {product.priceRange?.minVariantPrice.symbol} {discountedPrice}
                        </>
                      ) : (
                        `${product.priceRange?.minVariantPrice.symbol}${totalPrice}`
                      )}
                    </span>
                  </span>
                </div>
                <div className="bg-lavender-50 flex flex-col justify-start px-5 py-6">
                  <GuidedBuyingFlowMainProductCard product={product} />
                  {products.map((product, index) => (
                    <BuyingFlowProduct
                      key={index}
                      product={product.product}
                      text={product.text}
                      title={product.title}
                      section={section}
                    />
                  ))}
                  <div className="cursor-pointer pt-6 pb-24">
                    <ProductBulkAdd
                      cta={{
                        linkStyle: LINK_STYLES.primary,
                        size: BUTTON_SIZE.small,
                        className: 'text-white',
                      }}
                      variantWithQuantity={variantWithQuantity}
                      variants={allVariants}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </Modal.Body>
    </Modal>
  );
}

export default ContentGuidedBuyingFlow;
