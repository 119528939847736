export const menuDrawerModalTheme = {
  content: {
    base: 'relative h-dvh w-full modal-drawer',
    inner: 'relative bg-white shadow dark:bg-gray-700 flex flex-col h-full',
  },
  body: {
    base: 'flex-1 overflow-auto pb-6',
  },
  header: {
    base: 'absolute w-fit top-2 z-10',
    title: 'text-left text-base font-bold text-black dark:text-white empty:hidden',
    close: {
      base: 'bg-white shadow ml-auto pt-3 pr-3 pb-3 pl-4 inline-flex items-center rounded-2xl rounded-s-none bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900',
      icon: 'h-5 w-5',
    },
  },
};
