import { PageTypeType } from '@qlevr/shared/constants';
import { MetaInterface, QuizResultPageInterface } from '@qlevr/shared/interfaces';
import { quizResultPageMapper } from '@qlevr/shared/mappers';
import { QuizStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';
import { FetchStoryblokStoryService } from '../storyblok/fetch-storyblok-story';

export class FetchQuizResultBySlug extends FetchStoryblokStoryService<QuizResultPageInterface, StoryblokStory<QuizStoryblok>> {
  constructor(pageType: PageTypeType, slug: string, locale: string, preview: boolean, cv: string) {
    super({
      path: `${pageType}${slug}`,
      locale,
      preview,
      cv,
    });
  }

  mapper(data: StoryblokStory<QuizStoryblok>, meta: MetaInterface) {
    return quizResultPageMapper(data, meta);
  }
}
