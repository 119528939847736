import { ImageTextOverlayHeroInterface, MetaInterface, ProductInterface } from '@qlevr/shared/interfaces';
import { HeroImageTextOverlayStoryblok } from '@qlevr/shared/schema';
import { contentAlignmentMapper } from '../content-alignment/content-alignment';
import { imageStoryblokMapper } from '../image/image-storyblok';
import { linksMapper } from '../link/link';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { minHeightRatioMapper } from '../ratio/min-height-ratio';
import { getMetaProductById } from '../products/products-shopify';

export function mapImageTextOverlayHero(
  title: string,
  content: HeroImageTextOverlayStoryblok,
  meta: MetaInterface,
): ImageTextOverlayHeroInterface | null {
  const productId = content['product']?.items?.[0]?.id;

  let product = {} as ProductInterface | null;
  if (productId) {
    product = getMetaProductById(productId, meta);
  }

  const image = imageStoryblokMapper(content.image?.[0], { xs: 1 }, false);

  if (!image) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    ratio: `${minHeightRatioMapper(content.image?.[0].ratio)} ${minHeightRatioMapper(content.image?.[0].ratioLg)}`,
    alignment: contentAlignmentMapper(content.alignment?.[0]),
    cta: linksMapper(content.cta),
    image: image,
    overlay: content.overlay ?? false,
    rating: content.rating ?? false,
    section: sectionMapper({ ...content.section?.[0], spacing: 'none' }),
    text: mapRichText(content.text) ?? null,
    title: content.title ?? title,
    reviews: product?.review ?? null,
  };
}
