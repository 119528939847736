'use client';

import { ICONS } from '@qlevr/shared/constants';
import { GlobalInterface } from '@qlevr/shared/interfaces';
import { Accordion, AccordionContent, AccordionPanel, AccordionTitle, Flowbite } from 'flowbite-react';
import isEmpty from 'lodash.isempty';
import { useState } from 'react';
import { accordionTheme } from '../content-blocks/accordion/accordion-theme';
import IconRenderer from '../icons/icon-renderer.component';
import LinkRenderer from '../link/link-renderer';

export interface FooterMenuProps {
  globals: GlobalInterface;
}

export function FooterMenu(props: FooterMenuProps) {
  const [accordionStates, setAccordionStates] = useState(props?.globals?.footer?.items?.map(() => true));
  const handleClick = (index: number) => {
    setAccordionStates((prev) => prev?.map((state, i) => (i === index ? !state : true)));
  };

  if (!props.globals?.footer?.items || isEmpty(props.globals?.footer?.items)) {
    return null;
  }
  return (
    <Flowbite theme={{ theme: accordionTheme }}>
      <Accordion flush className="lg:hidden border-b border-black" collapseAll>
        {props.globals.footer.items.map((item, index) => (
          <AccordionPanel key={index}>
            <div className="last:border-b-0 border-b border-teal-800">
              <div className="flex justify-between my-4" onClick={() => handleClick(index)}>
                <AccordionTitle>
                  <div className="flex justify-between items-center w-full">
                    <div className="text-left">{item.title}</div>
                    <div className="w-6 shrink-0 transform text-black cursor-pointer">
                      {accordionStates?.[index] ? (
                        <IconRenderer icon={ICONS.chevronDown} />
                      ) : (
                        <IconRenderer icon={ICONS.chevronUp} />
                      )}
                    </div>
                  </div>
                </AccordionTitle>
              </div>

              <AccordionContent>
                {item.links?.map((cta, key) => (
                  <p key={key} className="mb-4 font-normal">
                    <LinkRenderer cta={cta} />
                  </p>
                ))}
              </AccordionContent>
            </div>
          </AccordionPanel>
        ))}
      </Accordion>
    </Flowbite>
  );
}

export default FooterMenu;
