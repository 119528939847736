/**
 * Removes the language parameter and the brand name specified in the environment variable `NEXT_PUBLIC_BRAND_NAME`
 * from the beginning of a given link. The brand name is expected to be "aeromoov" based
 * on the current setting. This function specifically targets links that start with a language
 * parameter (e.g., "en" or "nl-be") followed by the brand name "aeromoov". If the input is falsy,
 * an empty string is returned.
 *
 * Note: This removal is case-sensitive and expects the language code and brand name to follow
 * immediately after the domain or initial slash in the URL.
 *
 * @param {string} link - The link from which the language parameter and brand name "aeromoov" should be removed.
 * @returns {string} The link with the language parameter and brand name "aeromoov" removed if they were present at the beginning,
 *                   otherwise the original link or an empty string for falsy input.
 *
 * @example
 * 'de/aeromoov/page/instant-travel-cot-x' => instant-travel-cot-x
 * 'de-de/aeromoov/blog/instant-travel-cot-x' => blog/instant-travel-cot-x
 * 'nl-be/aeromoov/blog/some-url' => blog/some-url
 * 'en/aeromoov/some-dir/some-url' => some-dir/some-url
 */
export function removeBrandNameFromLink(link: string): string {
  if (!link) {
    console.error('link is missing in removeBrandNameFromLink()');
    return link;
  }

  // Pattern matches the following parts:
  // 1. Locale (e.g., 'de', 'de-de', 'nl-be', 'en', etc.) at the start of the URL.
  // 2. Brand name (e.g., 'aeromoov') immediately following the locale.
  // 3. Optionally matches '/page/' if it immediately follows the brand name.
  // Each part is followed by a forward slash which is included in the match.
  // The 'i' flag makes the match case-insensitive.

  const brandName = process.env['NEXT_PUBLIC_BRAND_NAME']; // Example: 'aeromoov'

  if (!brandName) {
    console.error('Brand name environment variable (NEXT_PUBLIC_BRAND_NAME) is not set.');
    return link; // Optionally handle this case as you see fit
  }

    // Dynamically create the pattern string to make the locale part optional.
    // The locale can be entirely absent, so its part in the regex is followed by a '?', making it optional.
    // The regex also accounts for URLs directly starting with the brand name.
    const pattern = new RegExp(`^/?(?:[a-z]{2}(?:-[a-z]{2})?/)?${brandName}(?:/page)?/`, 'i');

  // Use the replace method with the pattern to remove the matched parts from the URL.
  return link.replace(pattern, '');
}
