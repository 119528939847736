import { CollectionCardInterface, LinkedMetaDataInterface, ProductInterface, ProductReviewInterface } from '@qlevr/shared/interfaces';
import { getProductReviews } from './reviews/product-reviews';
import isEmpty from 'lodash.isempty';

export async function getMetaReview(
  linkedData: LinkedMetaDataInterface | undefined,
  products: ProductInterface[] | null,
  locale: string,
): Promise<ProductInterface[] | null> {
  if (!linkedData?.productReviewIds || isEmpty(linkedData.productReviewIds)) {
    return null;
  }

  const productWithReviewIds = Array.from(linkedData.productReviewIds).reduce((acc, productId) => {
    const product = products?.find((product) => product.id === productId);
    if(!product?.reviewId) {
      return acc
    }

    acc.push(product);
    return acc
  }, [] as ProductInterface[]);

  if (isEmpty(productWithReviewIds)) {
    return null;
  }

  for(let product of productWithReviewIds) {    
    const review = await getProductReviews(product.reviewId as string, locale);  
    product.review = review;
  }

  return productWithReviewIds;
}
