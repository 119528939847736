import { CartFreeShippingInterface } from '@qlevr/shared/interfaces';
import { getFreeShippingThreshold } from '@qlevr/shared/mappers';
import { ShopifyService } from './shopify-service';

export const fetchFreeShipping = async (): Promise<CartFreeShippingInterface> => {
  const res = await ShopifyService({}).getFreeShippingTreshold();

  return {
    threshold: getFreeShippingThreshold(res.metaobject),
  };
};
