/**
 * Calculates the discounted price of a product.
 * @param originalPrice - The original price of the product.
 * @param discountPercentage - The discount percentage to apply.
 * @returns The discounted price of the product.
 */
export function calculateDiscountPrice(originalPrice: number | undefined, discountPercentage: number): number | null {
  if (!originalPrice) {
    return null;
  }

  if (discountPercentage < 0 || discountPercentage > 100) {
    throw new Error('Invalid discount percentage. It must be between 0 and 100.');
  }

  const discountAmount = (originalPrice * discountPercentage) / 100;
  const discountedPrice = originalPrice - discountAmount;

  return parseFloat(discountedPrice.toFixed(2)); // Ensuring the price is returned with two decimal places
}
