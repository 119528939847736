import { ProductVariantSizeColorInterface } from '@qlevr/shared/interfaces';
import { getSizeColorVariantFromUrl } from './get-size-color-variant-from-url';

export function getCombinedSizeColorVariantFromUrl(url: { variant: string }, name: string, value: string): ProductVariantSizeColorInterface | null {
  const urlParam = getSizeColorVariantFromUrl(url);

  if(!urlParam) {
    if((name === 'size' || name === 'color') && value) {
      return { size: undefined, color: undefined, [name.toLowerCase()]: value };
    }

    return null;
  }

  if(name === 'size' && value) {
    urlParam.size = value;
  }

  if(name === 'color' && value) {
    urlParam.color = value;
  }

  return urlParam;
}
