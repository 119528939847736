'use client';

import { GlobalInterface, LinkInterface } from '@qlevr/shared/interfaces';
import LinkRenderer from '../link/link-renderer';
import { BUTTON_SIZE, ICONS, LINK_STYLES } from '@qlevr/shared/constants';
import { useState } from 'react';
import LoginForm from './login-form';
import RegisterForm from './register-form';

interface AuthFormContainerProps {
  globals: GlobalInterface | null;
}

export default function AuthFormContainer({ globals }: AuthFormContainerProps) {
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const handleAccountClick = () => {
    setShowLogin(true);
    setShowRegister(false);
  };

  const cta: LinkInterface = {
    as: 'button',
    label: '',
    size: BUTTON_SIZE.base,
    linkStyle: LINK_STYLES.icon,
    wrapperClassName: 'flex items-center justify-center lg:w-12 lg:h-12',
    leadingIcon: ICONS.account,
    leadingClassName: 'w-5 h-5',
    'aria-label': 'account',
    onClick: handleAccountClick,
  };

  return (
    <>
      <LinkRenderer cta={cta} />
      <LoginForm showLogin={showLogin} setShowLogin={setShowLogin} setShowRegister={setShowRegister} />
      <RegisterForm globals={globals} showRegister={showRegister} setShowLogin={setShowLogin} setShowRegister={setShowRegister} />
    </>
  );
}
