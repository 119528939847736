import { ProductReviewInterface } from '@qlevr/shared/interfaces';
import { ReviewYotpo } from '@qlevr/shared/schema';

export function productReviewMapper(review: ReviewYotpo): ProductReviewInterface {
  return {
    totalReview: review.response.bottomline['total_review'],
    averageScore: review.response.bottomline['average_score'],
    totalOrganicReviews: review.response.bottomline['total_organic_reviews'],
    organicAverageScore: review.response.bottomline['organic_average_score'],
    totalProductReviews: review.response as unknown as ProductReviewInterface['totalProductReviews'],
  };
}
