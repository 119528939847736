export function mapYoutubeVideo(url: string): string {
  // Using a regular expression to cover different YouTube URL patterns
  const youtubePatterns = [
    /youtube\.com\/watch\?v=([^&]+)/i, // Standard URL
    /youtu\.be\/([^?]+)/i, // Shortened URL
    /youtube\.com\/embed\/([^?&]+)/i, // Already an embed URL
    /m\.youtube\.com\/watch\?v=([^&]+)/i, // Mobile URL
  ];

  for (const pattern of youtubePatterns) {
    const match = url?.match(pattern);
    if (match) {
      return `https://www.youtube-nocookie.com/embed/${match[1]}`;
    }
  }

  // If no pattern matches, return the URL unchanged (or handle differently if required)
  return url;
}
