'use client';

import { ProductInterface, ProductVariantInterface } from '@qlevr/shared/interfaces';
import { useTranslations } from 'next-intl';
import { useQueryState } from 'nuqs';
import { useEffect } from 'react';
import BundleProductsOptionButton from './bundle-products-option-button';
import { getCombinationAvailableForSale, getFilteredOptionsBySizeAndOrColor } from '@qlevr/shared/utilities';

/* eslint-disable-next-line */
export interface VariantSelectorProps {
  product: ProductInterface;
  selectedVariant: ProductVariantInterface | null;
  setSelectedVariant: (variant: ProductVariantInterface | null) => void;
  setSize: (size: string) => void;
  setColor: (color: string) => void;
  size: string;
  color: string;
  setErrorMessage: (message: string) => void;
  mainVariantMatchesWithBundleVariants: boolean | undefined;
}

export function BundleProductsVariantSelector({
  product,
  selectedVariant,
  setSelectedVariant,
  setSize,
  setColor,
  size,
  color,
  setErrorMessage,
  mainVariantMatchesWithBundleVariants,
}: VariantSelectorProps) {
  const t = useTranslations();

  const [skuUrlParams] = useQueryState('sku');
  const productSkuArray = skuUrlParams?.split('.');

  // useEffect() for setting the size and color of the first available variant
  useEffect(() => {
    const variant = product.variants?.find((variant) => variant.sku && productSkuArray?.includes(variant.sku));
    if (productSkuArray && !selectedVariant && variant) {
      setSize(variant?.options.find((opt) => opt.name.toLowerCase() === 'size')?.value ?? '');
      setColor(variant?.options.find((opt) => opt.name.toLowerCase() === 'color')?.value ?? '');
    } else {
      if (product.firstAvailableVariant && !selectedVariant) {
        setSize(product.firstAvailableVariant?.options.find((opt) => opt.name.toLowerCase() === 'size')?.value ?? '');
        setColor(product.firstAvailableVariant?.options.find((opt) => opt.name.toLowerCase() === 'color')?.value ?? '');
      } else if (!product.firstAvailableVariant && !selectedVariant) {
        product.variants?.[0]?.options.forEach((option) => {
          if (option.name.toLowerCase() === 'size') {
            setSize(option.value);
          }
          if (option.name.toLowerCase() === 'color') {
            setColor(option.value);
          }
        });
      }
    }
  });

  // useEffect() for setting the selected variant based on the size and color
  useEffect(() => {
    const variant = product.variants?.find((variant) =>
      variant.options.every((option) => {
        if (option.name.toLowerCase() === 'size' && size) {
          return option.value === size;
        }
        if (option.name.toLowerCase() === 'color' && color) {
          return option.value === color;
        }

        return false;
      }),
    );

    if (variant) {
      setSelectedVariant(variant);
    }

    if (variant && !variant.availableForSale) {
      setErrorMessage(`- ${t('pdp.notAvailableForSale')}`);
    } else {
      setErrorMessage('');
    }
    // don't add unnecessary dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, size]);

  // If there are no options or just one option, return null
  const hasNoOptionsOrJustOneOption =
    !product.options?.length || (product.options.length === 1 && product.options[0]?.values.length === 1);

  if (!product || hasNoOptionsOrJustOneOption) {
    return null;
  }

  return (
    <div className="flex justify-between items-center mt-2">
      <div className="space-y-2">
        {product.options?.map((option) => {
          const variantsArray: [string, string][] = [
            ['size', ''],
            ['color', ''],
          ];
          selectedVariant?.options.forEach((option) => {
            if (option.name === 'size') {
              variantsArray[0] = [option.name, option.value];
            }
            if (option.name === 'color') {
              variantsArray[1] = [option.name, option.value];
            }
          });

          // return null if the main variant matches with the bundle variants
          if (mainVariantMatchesWithBundleVariants && option.name.toLowerCase() === 'size') {
            return null;
          }

          return (
            <div key={option.id} className="flex flex-wrap items-center justify-between gap-1">
              <div className="flex flex-wrap gap-1">
                {option.values.map((value) => {
                  const optionNameLowerCase = option.name.toLowerCase();

                  const variantOption = selectedVariant?.options.find(
                    (opt) => opt.name.toLowerCase() === optionNameLowerCase,
                  )?.value;

                  const isActive = variantOption === value;

                  if (optionNameLowerCase === 'size') {
                    variantsArray[0] = [optionNameLowerCase, value];
                  }
                  if (optionNameLowerCase === 'color') {
                    variantsArray[1] = [optionNameLowerCase, value];
                  }

                  const filtered = getFilteredOptionsBySizeAndOrColor(product.options, {
                    size: variantsArray[0][1],
                    color: variantsArray[1][1],
                  });

                  const isAvailableForSale = getCombinationAvailableForSale(product.combinations, filtered);

                  return (
                    <BundleProductsOptionButton
                      key={value}
                      product={product}
                      value={value}
                      isActive={isActive}
                      isAvailableForSale={!!isAvailableForSale}
                      setSize={setSize}
                      setColor={setColor}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BundleProductsVariantSelector;
