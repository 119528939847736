// Used for cache
// https://nextjs.org/docs/app/api-reference/functions/revalidateTag

export const CACHE_TAG = {
  cart: 'cart',
  collections: 'collections',
  products: 'products',
  productRecommendations: 'productRecommendations',
} as const;

export const CACHE_REVALIDATION_INTERVAL = 10; // seconds
export const CACHE_REVALIDATION_INTERVAL_LONG = 3600; // an hour
