import { ICONS } from '@qlevr/shared/constants';
import { AccordionInterface, SectionInterface } from '@qlevr/shared/interfaces';
import { AccordionContent, AccordionTitle } from 'flowbite-react';
import IconRenderer from '../../icons/icon-renderer.component';
import ImageRenderer from '../../image-renderer/image-renderer';
import VideoPlayer from '../../video-player/video-player';
import isEmpty from 'lodash.isempty';
import Text from '../../text/text';

export interface AccordionItemProps {
  accordion: AccordionInterface;
  section: SectionInterface;
  accordionStates: boolean[];
  handleClick: (index: number) => void;
  index: number;
}

export function AccordionItem({ accordion, handleClick, accordionStates, index, section }: AccordionItemProps) {
  return (
    <div className="border-b border-teal-800 last:border-b-0">
      <div className="my-4 flex justify-between" onClick={() => handleClick(index)}>
        <AccordionTitle>
          <div className="flex w-full justify-between">
            <div className="text-left" dangerouslySetInnerHTML={{ __html: accordion.title }} />
            <div className="h-6 w-6 shrink-0 transform cursor-pointer text-teal-500">
              {accordionStates[index] ? (
                <IconRenderer icon={ICONS.accordionClose} className="stroke-2" />
              ) : (
                <IconRenderer icon={ICONS.accordionOpen} className="stroke-2" />
              )}
            </div>
          </div>
        </AccordionTitle>
      </div>

      <AccordionContent>
        <div className="mb-8 space-y-3">
          <Text theme={section.theme} text={accordion.text} />

          {accordion.image && !isEmpty(accordion.image) && (
            <div className="flex gap-4">
              {accordion.image.map((image, key) => (
                <div key={key} className="w-full">
                  <ImageRenderer
                    loadingState="lazy"
                    image={image}
                    className="aspect-4/5 h-full w-full object-cover md:aspect-square"
                  />
                </div>
              ))}
            </div>
          )}
          {!isEmpty(accordion.video) && (
            <div className="relative flex flex-col gap-4">
              {accordion.video.map((video, key) => (
                <VideoPlayer key={key} video={video} posterLoadingState="lazy" />
              ))}
            </div>
          )}
        </div>
      </AccordionContent>
    </div>
  );
}
export default AccordionItem;
