import { ContentCardInterface, ContentColumnsContentSliderInterface } from '@qlevr/shared/interfaces';
import { ColumnsContentSliderStoryblok, ContentCardStoryblok } from '@qlevr/shared/schema';
import { contentCardStoryblokMapper } from '../content-card/content-card';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';

export function mapColumnsContentSlider(content: ColumnsContentSliderStoryblok): ContentColumnsContentSliderInterface {
  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    items: contentCardsMapper(content.items),
    section: sectionMapper(content.section?.[0]),
  };
}

export function contentCardsMapper(cards: ContentCardStoryblok[]): ContentCardInterface[] {
  return cards.reduce((acc, item) => {
    const card = contentCardStoryblokMapper(item);
    if (card) {
      acc.push(card);
    }
    return acc;
  }, [] as ContentCardInterface[]);
}
