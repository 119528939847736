import { SPACINGS, SpacingType } from '@qlevr/shared/constants';

const spacingMapping = new Map<string, SpacingType>([
  ['extra-small', SPACINGS.xs],
  ['small', SPACINGS.sm],
  ['medium', SPACINGS.md],
  ['large', SPACINGS.lg],
  ['extra-small-tb', SPACINGS.tbxs],
  ['small-tb', SPACINGS.tbsm],
  ['medium-tb', SPACINGS.tbmd],
  ['none', SPACINGS.none],
]);

export function spacingMapper(block: string): SpacingType {
  if (!block) return SPACINGS.md;

  const spacing = spacingMapping.get(block);
  if (!spacing) {
    throw new Error(`Spacing of type ${block} is missing a spacingMapper.`);
  }

  return spacing;
}
