import { CartInterface } from '@qlevr/shared/interfaces';
import { useTranslations } from 'next-intl';
import { WithFreeShippingProps } from './free-shipping-hoc';

/* eslint-disable-next-line */
export interface FreeShippingProgressProps {
  cart: CartInterface;
  locale: string;
  isFreeShipping: boolean;
  progress: number;
  amountLeft: string;
  threshold: number;
}

export function FreeShippingProgress({
  cart,
  isFreeShipping,
  progress,
  amountLeft,
  threshold,
}: FreeShippingProgressProps) {
  const t = useTranslations();
  const label = isFreeShipping ? t('freeShipping.congrats') : t('freeShipping.progress', { amountLeft: amountLeft });

  return (
    <div>
      <div className="flex justify-center text-sm text-center -mb-2">
        <span>{label}</span>
      </div>
      <div className="flex justify-end text-sm t-cart-progress-text">
        <span>€{threshold}</span>
      </div>
      <div className="rounded-full h-1 t-cart-progress-bg">
        <div className={`t-cart-progress-thumb-bg h-1 rounded-full`} style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
}

export default WithFreeShippingProps<FreeShippingProgressProps>(FreeShippingProgress);
