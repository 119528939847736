import { LOCALES, PAGE_TYPES } from '@qlevr/shared/constants';
import {
  FetchStoryblokStoryInteface,
  ProductPageInterface,
  ProductRecommendationsInterface,
  ProductReviewInterface,
  ProductStoryInterface,
} from '@qlevr/shared/interfaces';
import { ProductPageMapperProps, getProductSlug, productPageMapper } from '@qlevr/shared/mappers';
import { CountryCodeShopify, LanguageCodeShopify, ProductShopify } from '@qlevr/shared/schema';
import {
  getCountryCodeShopify,
  getCountryFromCountryLocale,
  getLanguageCodeShopify,
  getLocaleFromCountryLocale,
} from '@qlevr/shared/utilities';
import { notFound } from 'next/navigation';
import { getMeta } from '../meta';
import { getProductReviews } from '../reviews/product-reviews';
import { ShopifyService } from '../shopify/shopify-service';
import { TryFetchProductBySlug } from '../try-product-by-slug';
import { fetchLocalisedProductSlugs } from './localised-product-slugs';
import { FetchProductRecommendations } from './product-recommendations';

export class FetchProductBySlug {
  private params: FetchStoryblokStoryInteface = { path: '', locale: '', preview: false, cv: '' };
  private country: CountryCodeShopify;
  private language: LanguageCodeShopify;

  constructor(slug: string, locale: string, preview: boolean, cv: string) {
    this.params.path = slug;
    this.params.preview = preview;
    this.params.cv = cv;
    this.params.locale = locale.toLocaleLowerCase() ?? LOCALES[0];
    this.country = getCountryCodeShopify(getCountryFromCountryLocale(this.params.locale));
    this.language = getLanguageCodeShopify(getLocaleFromCountryLocale(this.params.locale));
  }

  public async request(): Promise<ProductPageInterface> {
    const shopifyResponse = await ShopifyService({ tags: [getProductSlug(this.params.path)]}).getProductByHandle({
      country: this.country,
      language: this.language,
      handle: this.params.path,
    });

    const isProductError = shopifyResponse.product === null;
    if (isProductError) {
      console.error('Product not found', this.params.path);
      return notFound();
    }

    const productId = shopifyResponse.product?.id;

    /**
     * Get the recommended products
     * Fetch product recommendations by its id
     * */
    let productRecommendations = [] as ProductRecommendationsInterface[] | null;
    try {
      const productSlug = shopifyResponse.product?.handle;
      if (productId && productSlug) {
        productRecommendations = await new FetchProductRecommendations(this.params.locale, productSlug).request(
          productId,
        );
      }
    } catch (error) {
      console.error('no product recommendations were found', error);
    }

    /**
     * Get product reviews
     * Fetch product reviews by its id
     * */
    let reviews: ProductReviewInterface | null = null;
    const productReviewId =
      shopifyResponse.product?.metafields?.find((metafield) => metafield?.key === 'magento_product_id')?.value || null;

    try {
      if (productId) {
        reviews = await getProductReviews(productReviewId as string, this.params.locale);
      }
    } catch (error) {
      console.error('no reviews were found', error);
    }

    /**
     * Get translated slugs to switch between languages
     */
    const localisation = await fetchLocalisedProductSlugs(this.params.locale, productId);

    /**
     * We need to check if there is a page in Storyblok for the current slug.
     */
    const storyblokPageRes = await new TryFetchProductBySlug(
      PAGE_TYPES.products,
      this.params.path,
      this.params.locale,
      this.params.preview,
      this.params.cv,
    ).request();

    /*
     * If the response status is not 200, there is no page in Storyblok
     * for the current slug. In this case, we build the page with the
     * data from Shopify only.
     */
    const hasNoStoryblokPage = 'notFound' in storyblokPageRes && storyblokPageRes.notFound;

    // There is no page in Storyblok for the current slug
    if (hasNoStoryblokPage) {
      const meta = await getMeta(this.params.locale, this.params.preview);
      return this.mapper({
        product: shopifyResponse.product as ProductShopify,
        meta,
        locale: getLocaleFromCountryLocale(this.params.locale),
        productRecommendations,
        page: undefined,
        reviews,
        localisation,
        productReviewId,
        fullLocale: this.params.locale,
      });
    }

    /**
     * We have an page in Storyblok for the current slug.
     * We need to get the linked data and the meta data
     */
    return this.mapper({
      product: shopifyResponse.product as ProductShopify,
      meta: (storyblokPageRes as ProductStoryInterface).meta,
      locale: getLocaleFromCountryLocale(this.params.locale),
      productRecommendations,
      page: storyblokPageRes as ProductStoryInterface,
      reviews,
      localisation,
      productReviewId,
      fullLocale: this.params.locale,
    });
  }

  private mapper(props: ProductPageMapperProps) {
    return productPageMapper(props);
  }
}
