import { ColorThemeType } from '@qlevr/shared/constants';

/* eslint-disable-next-line */
export interface TextProps {
  theme: ColorThemeType;
  text: string;
  className?: string;
}

export function Text({ className, text, theme }: TextProps) {
  return <div className={`text-editor ${className} ${theme.color.text} ${theme.editor}`} dangerouslySetInnerHTML={{ __html: text }} />;
}

export default Text;
