import { ProductCombinationInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';

export function getCombinationCurrentlyNotInStock(
  combinations: ProductCombinationInterface[] | null,
  filtered: [string, string][] | null,
): boolean {
  if (!filtered || !combinations || isEmpty(combinations)) {
    return true;
  }

  const combination = combinations.find((combination) =>
    filtered.every(([key, value]) => combination[key] === value),
  );

  if (combination === undefined) {
    return true;
  }

  return combination.currentlyNotInStock;
}
