import { FrameRangeInterface } from '@qlevr/shared/interfaces';

export const calculateProgress = (currentProgress: number, index: number, frameRanges: FrameRangeInterface): number => {
  const [startFrame, endFrame] = frameRanges[index];
  const totalFrames = endFrame - startFrame;

  // Ensure progress is within the bounds of the start and end frames
  if (currentProgress < startFrame) {
    return 0;
  } else if (currentProgress > endFrame) {
    return 100;
  } else {
    return ((currentProgress - startFrame) / totalFrames) * 100;
  }
};
