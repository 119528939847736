import { LOCALES_COUNTRIES } from '@qlevr/shared/constants';
import { PathInterface } from '@qlevr/shared/interfaces';

/**
 * Reorders the URLs in the given object based on a predefined order array.
 * Only URLs with keys that exist in the order array will be included in the reordered object.
 *
 * @param urls - The object containing the URLs to be reordered.
 * @returns The reordered object containing the URLs.
 */
export function reorderLocalesUrls(paths: PathInterface[]): PathInterface[] {
  return paths.sort((a, b) => {
    const indexA = LOCALES_COUNTRIES.indexOf(a.locale as typeof LOCALES_COUNTRIES[number]);
    const indexB = LOCALES_COUNTRIES.indexOf(b.locale as typeof LOCALES_COUNTRIES[number]);
    if (indexA === -1) return 1; // Place unknown locales at the end
    if (indexB === -1) return -1;
    return indexA - indexB;
  });
}
