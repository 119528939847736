'use client';
import { BREAKPOINTS } from '@qlevr/shared/constants';
import { ContentColumnsContentSliderInterface } from '@qlevr/shared/interfaces';
import { Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from '../container/container';
import ImageRenderer from '../image-renderer/image-renderer';
import LinkRenderer from '../link/link-renderer';
import Section from '../section/section';
import Text from '../text/text';
import Title from '../title/title';

/* eslint-disable-next-line */
export interface ContentColumnsContentSliderProps extends ContentColumnsContentSliderInterface {}

export function ContentColumnsContentSlider(props: ContentColumnsContentSliderProps) {
  return (
    <Section {...props.section}>
      <Container>
        <div className={`space-y-6 lg:space-y-10 ${props.section.theme.editor}`}>
          {props.title && <Title {...props.title} section={props.section} />}
          <Swiper
            className={`swiper-with-nav swiper-with-scrollbar ${props.section.theme.carousel}`}
            spaceBetween={24}
            slidesPerView={4}
            scrollbar={{ draggable: true, dragClass: `swiper-scrollbar-drag` }}
            navigation={true}
            modules={[Scrollbar, Navigation]}
            breakpoints={{
              [BREAKPOINTS.lg]: {
                slidesPerView: 4,
              },
              [BREAKPOINTS.sm]: {
                slidesPerView: 2,
                spaceBetween: 16,
              },
              [BREAKPOINTS.xs]: {
                slidesPerView: 1.2,
              },
            }}
          >
            {props.items.map((content, index) => (
              <SwiperSlide key={index} className="pb-4 lg:pb-6">
                <div className="rounded-lg">
                  <div className="relative">
                    <ImageRenderer
                      loadingState="lazy"
                      image={content.image}
                      className="aspect-sqaure block h-full w-full rounded-lg object-cover"
                    />
                    <ImageRenderer
                      loadingState="lazy"
                      image={content.overlaylogo}
                      className="absolute -bottom-4 right-2 block w-24"
                    />
                  </div>
                  <div className="pt-6">
                    <div className={`pb-2 font-sans text-lg font-bold ${props.section.theme.color.text}`}>
                      {content.title}
                    </div>
                    {content.text && <Text text={content.text} theme={props.section.theme} />}
                  </div>
                  {content.cta && (
                    <div className="mt-auto pt-3">
                      <LinkRenderer
                        cta={{
                          ...content.cta,
                          wrapperClassName: `${content.image ? 'after:inset-0 after:absolute' : ''} w-fit`,
                        }}
                      />
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Container>
    </Section>
  );
}

export default ContentColumnsContentSlider;
