import { ColumnConfigInterface } from '@qlevr/shared/interfaces';

export function getImageColumnConfigMobile(columnConfigForEachBreakpoint: ColumnConfigInterface): ColumnConfigInterface {
  return {
    xs: columnConfigForEachBreakpoint.xs,
    sm: columnConfigForEachBreakpoint.sm,
    md: columnConfigForEachBreakpoint.md,
  };
}

export function getImageColumnConfigDesktop(columnConfigForEachBreakpoint: ColumnConfigInterface): ColumnConfigInterface {
  return {
    lg: columnConfigForEachBreakpoint.lg,
    xl: columnConfigForEachBreakpoint.xl,
    xl2: columnConfigForEachBreakpoint.xl2,
    xl3: columnConfigForEachBreakpoint.xl3,
  };
}
