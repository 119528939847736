import { ICONS } from '@qlevr/shared/constants';
import { ProductCombinationInterface, ProductInterface } from '@qlevr/shared/interfaces';
import { useState } from 'react';
import Container from '../container/container';
import IconRenderer from '../icons/icon-renderer.component';

export interface ProductColorProps {
  product: ProductInterface;
  currentKey: string;
  setCurrentKey: (key: string) => void;
  setCurrentImage: (image: string) => void;
}

export function ProductColor({ product, currentKey, setCurrentKey, setCurrentImage }: ProductColorProps) {
  // Show more (+) product colors logic + filter combinations by color (remove duplicates)

  const uniqueColorCombinations = product?.combinations?.reduce<ProductCombinationInterface[]>(
    (unique, combination) => {
      if (!unique.some((item) => item.color === combination.color)) {
        unique.push(combination);
      }
      return unique;
    },
    [],
  );
  const combinationsCount = uniqueColorCombinations?.length ?? 0;
  let visibleCombinations = uniqueColorCombinations;

  // show more colors and slice combinations
  const [showMoreColors, setShowMoreColors] = useState(false);
  if (!showMoreColors && combinationsCount > 4) {
    visibleCombinations = product?.combinations?.slice(0, 4);
  }

  return (
    <Container className="flex flex-wrap items-center shadow-md gap-3 p-2 rounded-full w-fit">
      {visibleCombinations?.map((combination, key) => {
        const swatch = product?.colors?.find((swatch) => swatch.key === combination.color);
        const style = swatch ? { backgroundImage: `url(${swatch.value})` } : {};
        return (
          <div
            className={`w-6 h-6 rounded-full cursor-pointer bg-contain ${
              swatch?.key === currentKey ? 'border-2 border-black' : ''
            }`}
            style={style}
            key={key}
            onClick={() => {
              setCurrentKey(`${swatch?.key}`);
              setCurrentImage(combination.variantImages?.[0]?.image?.url || product?.featuredImage?.url || '');
            }}
          ></div>
        );
      })}
      {combinationsCount > 4 && !showMoreColors && (
        <div className="w-6 h-6 rounded-full cursor-pointer flex justify-center items-center" onClick={() => setShowMoreColors(true)}>
          <IconRenderer icon={ICONS.plusCircle} className="text-black bg-slate-50 p-1 rounded-full" />
        </div>
      )}
    </Container>
  );
}

export default ProductColor;
