export const ProductColorOptionName = {
  en: 'color',
  nl: 'kleur',
  fr: 'couleur',
  de: 'farbe',
  es: 'color',
  it: 'colore',
} as const;

type ObjectValues<T> = T[keyof T];
export type ProductColorOptionType = ObjectValues<typeof ProductColorOptionName>;

export const ProductSizeOptionName = {
  en: 'size',
  nl: 'maat',
  fr: 'taille',
  de: 'große',
  es: 'talla',
  it: 'misura',
} as const;

export type ProductSizeOptionType = ObjectValues<typeof ProductSizeOptionName>;
