import {
  ContentBlockInterface,
  ContentGuidedBuyingFlowInterface,
  MetaInterface,
  PathInterface,
  ProductPageInterface,
  ProductRecommendationsInterface,
  ProductReviewInterface,
  ProductStoryInterface,
} from '@qlevr/shared/interfaces';
import { ProductShopify } from '@qlevr/shared/schema';
import { mapBuyBox } from '../buy-box/buy-box';
import { mapContentProductReviews } from '../content-blocks/product-reviews';
import { productStructuredDataMapper } from '../structured-data/product-structured-data';
import { productMapper } from './products-shopify';

export interface ProductPageMapperProps {
  product: ProductShopify;
  meta: MetaInterface;
  locale: string;
  productRecommendations?: ProductRecommendationsInterface[] | null;
  page?: ProductStoryInterface;
  reviews?: ProductReviewInterface | null;
  localisation: PathInterface[] | null;
  productReviewId: string | null;
  fullLocale?: string | null;
}

export function productPageMapper({
  product: productData,
  meta,
  locale,
  productRecommendations = undefined,
  page = undefined,
  reviews = undefined,
  localisation,
  productReviewId,
  fullLocale,
}: ProductPageMapperProps): ProductPageInterface {
  let guidedBuyingFlow: ContentGuidedBuyingFlowInterface | null = null;
  const productPageHandle = productData.handle;
  const product = productMapper({
    item: productData,
    locale,
    productRecommendations,
    productPageHandle,
    reviews,
    localisation,
    fullLocale,
  });

  if (page && page.contentBlocks.map((block) => block.component).includes('guidedBuyingFlow')) {
    guidedBuyingFlow = page.contentBlocks.find(
      (block) => block.component === 'guidedBuyingFlow',
    ) as ContentGuidedBuyingFlowInterface;
  }

  const buyBox = mapBuyBox(product, guidedBuyingFlow, meta.globals);
  const productReviewBlock = mapContentProductReviews(productReviewId, reviews);
  const defaultContentBlocks: ContentBlockInterface[] = [buyBox];

  if (productReviewBlock) {
    defaultContentBlocks.push(productReviewBlock);
  }

  // If there is no page in Storyblok for the current slug, we build the page with the data from Shopify only.
  if (!page) {
    return {
      product,
      localisation,
      globals: meta.globals ?? null,
      contentBlocks: defaultContentBlocks,
      slug: product.slug,
      title: product.title,
      metatags: product.metatags,
      structuredData: productStructuredDataMapper(product, locale, meta.globals),
    };
  }

  const contentBlocks = page.contentBlocks;

  // Remove the guidedBuyingFlow from contentBlocks if it exists
  const guidedBuyingFlowIndex = contentBlocks.findIndex((block) => block.component === 'guidedBuyingFlow');
  if (guidedBuyingFlowIndex !== -1) {
    contentBlocks.splice(guidedBuyingFlowIndex, 1);
  }

  // Unshift the buyBox to the beginning of contentBlocks
  contentBlocks.unshift(buyBox);

  // Push the productReviewBlock to the end of contentBlocks if it exists
  if (productReviewBlock) {
    contentBlocks.push(productReviewBlock);
  }

  return {
    product,
    localisation,
    globals: meta.globals ?? null,
    contentBlocks,
    slug: product.slug,
    title: product.title,
    metatags: product.metatags,
    structuredData: productStructuredDataMapper(product, locale, meta.globals),
  };
}
