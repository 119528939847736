import {
  MetaInterface,
  ProductStoryInterface
} from '@qlevr/shared/interfaces';
import { ProductPageStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';
import { contentBlocksMapper } from '../content-blocks/content-blocks';

export function productStoryMapper(page: StoryblokStory<ProductPageStoryblok>, meta: MetaInterface): ProductStoryInterface {
  return {
    meta,
    contentBlocks: contentBlocksMapper(page.content.contentBlocks, meta)
  };
}
