export const productModalTheme = {
    "content": {
      "base": "relative h-full w-full product-accordion-modal",
      "inner": "relative bg-white shadow dark:bg-gray-700 flex flex-col h-full"
    },
    "body": {
      "base": "flex-1 overflow-auto p-4 pb-10",
    },
    "header": {
      "base": "flex items-start justify-between p-4",
      "title": "text-left text-base font-bold text-black dark:text-white",
    }
  }
  