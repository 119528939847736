import { PageTypeType } from '@qlevr/shared/constants';
import { MetaInterface, ProductStoryInterface } from '@qlevr/shared/interfaces';
import { productStoryMapper } from '@qlevr/shared/mappers';
import { ProductPageStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';
import { TryFetchStoryblokStoryService } from './storyblok/try-fetch-storyblok-story';

export class TryFetchProductBySlug extends TryFetchStoryblokStoryService<
  ProductStoryInterface,
  StoryblokStory<ProductPageStoryblok>
> {
  constructor(pageType: PageTypeType, slug: string, locale: string, preview: boolean, cv: string) {
    super({
      path: `${pageType}${slug}`,
      locale,
      preview,
      cv,
    });
  }

  mapper(data: StoryblokStory<ProductPageStoryblok>, meta: MetaInterface) {
    return productStoryMapper(data, meta);
  }
}
