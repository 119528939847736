import { ContentFeaturedCollectionInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { FeaturedCollectionStoryblok, ShopifyCollectionsStoryblok } from '@qlevr/shared/schema';
import { getMetaCollectionsById } from '../collection/collection-shopify';
import { contentAlignmentMapper } from '../content-alignment/content-alignment';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';

export function mapFeaturedCollectionContent(content: FeaturedCollectionStoryblok, meta: MetaInterface): ContentFeaturedCollectionInterface | null {
  const collections = content?.['collections'] as ShopifyCollectionsStoryblok;
  const collectionIds = collections.items.map((collection) => collection.id);

  if(!collections) return null;

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    alignment: contentAlignmentMapper(content.alignment?.[0]),
    title: mapTitle(content.title?.[0]) ?? null,
    text: mapRichText(content.text) ?? null,
    collections: getMetaCollectionsById(collectionIds, meta),
    section: sectionMapper(content.section?.[0]),
  };
}
