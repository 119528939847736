// Get the typeform response IDs from the cookie
// it's an array of strings with multiple response IDs
/**
 * Retrieves the value of a cookie by its name.
 * @param name - The name of the cookie.
 * @returns The value of the cookie, or null if the cookie does not exist.
 */
export function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
}
