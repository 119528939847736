import { GlobalInterface } from '@qlevr/shared/interfaces';
import LinkRenderer from '../link/link-renderer';

export interface SocialIconsProps {
  globals: GlobalInterface;
}

export function SocialIcons(props: SocialIconsProps) {
  return (
    <div className="justify-start items-center gap-4 flex">
      {props.globals?.socialLinks?.map((item, key) => (
        <LinkRenderer
          key={key}
          cta={{
            ...item,
            leadingClassName: 'w-6 h-6',
            "aria-label": item.label
          }}
        />
      ))}
    </div>
  );
}
export default SocialIcons;
