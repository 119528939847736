'use client';

import { BUTTON_SIZE, usePathname } from '@qlevr/shared/constants';
import { PathInterface } from '@qlevr/shared/interfaces';
import { getLocaleLabel } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import Link from 'next/link';
import { useParams } from 'next/navigation';

export interface InternationalisationDropdownProps {
  localisation: PathInterface[] | null;
}

export function MenuDrawerInternationalisation({ localisation }: InternationalisationDropdownProps) {
  const { locale } = useParams<{ locale: string }>();
  const pathName = usePathname();

  const localisationWithCurrentPath = [...(localisation || []), { locale, slug: pathName }]; // Add current path to the list of localisations

  if (!localisation || isEmpty(localisation)) {
    return null;
  }

  return (
    <div className="mb-4 flex flex-wrap gap-4 border-b border-solid border-slate-300 py-4">
      {localisationWithCurrentPath.map((path, key) => (
        <div key={key}>
          <Link
            href={`/${path.locale}${path.slug}`}
            className={`inline-block text-black hover:underline ${locale === path.locale ? 'underline' : ''}`}
          >
            <span className={BUTTON_SIZE.base}>{getLocaleLabel(path.locale)}</span>
          </Link>
        </div>
      ))}
    </div>
  );
}

export default MenuDrawerInternationalisation;
