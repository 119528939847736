import { ProductInterface, ProductOptionInterface } from '@qlevr/shared/interfaces';
import { useQueryState } from 'nuqs';
import ReactSelect from 'react-select';
import {
  getCombinationAvailableForSale,
  getFilteredOptionsBySizeAndOrColor,
  reverseEscapeUrl,
} from '@qlevr/shared/utilities';
import clsx from 'clsx';

interface VariantDropdownProps {
  option: ProductOptionInterface;
  product: ProductInterface;
  searchParams: URLSearchParams;
}

const controlStyles = {
  base: 'text-base group first-letter:uppercase font-bold w-full px-5 py-1 border-2 border-slate-300 rounded-full focus:outline-none bg-transparent focus:ring-0 focus:border-slate-300 hover:cursor-pointer',
  focus: '',
  nonFocus: '',
  menuStyles: 'is-active',
};
const placeholderStyles = 'text-gray-500 pl-1 py-0.5';
const selectInputStyles = 'pl-1 py-0.5';
const valueContainerStyles = 'p-1';
const singleValueStyles = 'leading-7';
const indicatorsContainerStyles = '';
const clearIndicatorStyles = 'text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800';
const indicatorSeparatorStyles = '';
const dropdownIndicatorStyles = 'p-1 hover:bg-gray-100 text-black rounded-md group-[.is-active]:rotate-180';
const menuStyles = 'p-1 mt-2 border border-gray-200 bg-white rounded-lg';
const groupHeadingStyles = 'ml-3 mt-2 mb-1 text-gray-500 text-sm';
const optionStyles = {
  base: 'hover:cursor-pointer px-3 py-2 rounded first-letter:uppercase',
  focus: 'bg-gray-100 active:bg-gray-200',
  selected: 'bg-gray-200',
  disabled: 'text-gray-500 hover:cursor-not-allowed bg-gray-50',
};
const noOptionsMessageStyles = 'text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm';

export default function VariantDropdown({ option, product, searchParams }: VariantDropdownProps) {
  const [, setSize] = useQueryState('size');
  const [, setColor] = useQueryState('color');

  const options = option.values.map((value) => ({
    value,
    label: reverseEscapeUrl(value),
  }));

  const selectedValue = options.find((o) => o.value === searchParams.get(option.name.toLowerCase()));

  return (
    <ReactSelect
      className="z-30 w-full"
      defaultValue={options[0]}
      onChange={(selectOption) => {
        if (option.name.toLowerCase() === 'size' && selectOption?.value) {
          setSize(selectOption?.value);
        }
        if (option.name.toLowerCase() === 'color' && selectOption?.value) {
          setColor(selectOption?.value);
        }
      }}
      isOptionDisabled={(selectOption) => {
        if (option.name.toLowerCase() === 'size') {
          return false;
        }

        const optionNameLowerCase = option.name.toLowerCase();

        // Base option params on current params so we can preserve any other param state in the url.
        const optionSearchParams = new URLSearchParams(searchParams.toString());

        // Update the option params using the current option to reflect how the url *would* change,
        // if the option was clicked.
        optionSearchParams.set(optionNameLowerCase, selectOption?.value);

        const filtered = getFilteredOptionsBySizeAndOrColor(product.options, {
          size: optionSearchParams.get('size') || '',
          color: optionSearchParams.get('color') || '',
        });
        const isAvailableForSale = getCombinationAvailableForSale(product.combinations, filtered);
        return !isAvailableForSale;
      }}
      classNames={{
        control: ({ isFocused, menuIsOpen }) =>
          clsx(
            isFocused ? controlStyles.focus : controlStyles.nonFocus,
            controlStyles.base,
            menuIsOpen && controlStyles.menuStyles,
          ),
        placeholder: () => placeholderStyles,
        input: () => selectInputStyles,
        valueContainer: () => valueContainerStyles,
        singleValue: () => singleValueStyles,
        indicatorsContainer: () => indicatorsContainerStyles,
        clearIndicator: () => clearIndicatorStyles,
        indicatorSeparator: () => indicatorSeparatorStyles,
        dropdownIndicator: () => dropdownIndicatorStyles,
        menu: () => menuStyles,
        groupHeading: () => groupHeadingStyles,
        option: ({ isFocused, isSelected, isDisabled }) =>
          clsx(
            isFocused && optionStyles.focus,
            isSelected && optionStyles.selected,
            optionStyles.base,
            isDisabled && optionStyles.disabled,
          ),
        noOptionsMessage: () => noOptionsMessageStyles,
      }}
      unstyled
      isSearchable={false}
      value={selectedValue}
      options={options}
    />
  );
}
