import { ImageInterface, PictoCardInterface } from '@qlevr/shared/interfaces';

export function pictoCardStoryblokMapper(
  text: string,
  description: string | null,
  image: ImageInterface | null
): PictoCardInterface | null {
  return {
    text,
    description,
    image,
  };
}
