import { PremiumProductInfoInterface } from '@qlevr/shared/interfaces';
import { Maybe, MetafieldShopify, MetaobjectShopify } from '@qlevr/shared/schema';
import { ShopifyRichTextToHtml } from '@qlevr/shared/utilities';
import { getMetafieldsByKey } from './get-metafields-by-key';

export function getPremiumProductInfoReferencesFields(
  title: string,
  references: Maybe<MetafieldShopify>[],
): PremiumProductInfoInterface[] | null {
  const reference = references.find((item) => item?.key === title);

  if (!reference || !reference?.references?.edges) {
    return null;
  }

  return reference.references.edges.reduce((acc, { node }) => {
    const isMetaObject = node.__typename === 'Metaobject';
    if (!isMetaObject) {
      return acc;
    }
    // Now you can use `metaobject` as type `MetaobjectShopify`
    const metaobject = node as MetaobjectShopify;
    const title = getMetafieldsByKey('title', metaobject.fields);

    if (!title) {
      return acc;
    }

    const content = ShopifyRichTextToHtml(getMetafieldsByKey('content', metaobject.fields) || '');
    acc.push({ title, content });
    return acc;
  }, [] as PremiumProductInfoInterface[]);
}
