import { ContentTypeFormInterface } from '@qlevr/shared/interfaces';
import { TypeFormStoryblok } from '@qlevr/shared/schema';
import { sectionMapper } from '../section/section';

export function mapTypeFormContent(content: TypeFormStoryblok): ContentTypeFormInterface | null {
  if (!content.typeformId) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    typeFormId: content.typeformId,
    section: sectionMapper(content.section?.[0]),
  };
}