'use client';

import { GlobalInterface, PathInterface } from '@qlevr/shared/interfaces';
import { Modal } from 'flowbite-react';
import MenuDrawerNavigation from './menu-drawer-navigation';
import { menuDrawerModalTheme } from './menu-drawer-theme';
import IconRenderer from '../icons/icon-renderer.component';
import { ICONS } from '@qlevr/shared/constants';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface MenuDrawerProps {
  globals: GlobalInterface;
  localisation: PathInterface[] | null;
}

export function MenuDrawer({ globals, localisation }: MenuDrawerProps) {
  const [openMenuDrawer, setOpenMenuDrawer] = useState(false);

  return (
    <>
      <button
        className="flex w-full items-center justify-between gap-3 lg:hidden"
        onClick={() => setOpenMenuDrawer(!openMenuDrawer)}
      >
        <IconRenderer id="hamburger" aria-label="hamburgerIcon" icon={ICONS.hamburger} className="h-8 w-8 text-black" />
      </button>
      <div className="empty:hidden">
        <Modal
          dismissible
          size="md"
          theme={menuDrawerModalTheme}
          show={openMenuDrawer}
          position={'top-left'}
          onClose={() => setOpenMenuDrawer(false)}
          className="modal-drawer-overlay"
        >
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <MenuDrawerNavigation globals={globals} localisation={localisation} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default MenuDrawer;
