import {
  ContentProductRecommendationInterface,
  KeyValueInterface,
  ProductRecommendationsInterface,
} from '@qlevr/shared/interfaces';
import { GetProductRecommendationsQueryShopify } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { imageShopifyMapper } from '../image/image-shopify';
import { sectionMapper } from '../section/section';
import { mapMetaFeaturedImage } from './product-featured-image';
import { productPriceMapper } from './product-price';
import { getProductSlug } from './product-slug';
import { productVariantsMapper } from './product-variant';
import { getProductTags } from './product-tags';
import { mapProductImages } from './products-shopify';

export function RecommendationsProductsMapper(
  products: GetProductRecommendationsQueryShopify['productRecommendations'][],
  locale: string,
  productPageHandle: string,
): ProductRecommendationsInterface[] | null {
  if (!products || isEmpty(products)) {
    return [];
  }

  // @todo fix the any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return products.map((item: any) => {
    const images = mapProductImages(item?.images?.edges);
    const featuredImage = mapMetaFeaturedImage(item.metafields)?.[0]?.value ?? '';
    const discountPercentage = item?.metafields?.find(
      (metafield: KeyValueInterface) => metafield?.key === 'discount_percentage',
    )?.value;
    const productMetafields = item.metafields;

    return {
      id: item.id,
      title: item.title,
      tags: getProductTags(item.tags ?? [], productPageHandle),
      slug: getProductSlug(item.handle),
      contextualImage: images?.[1],
      featuredImage: imageShopifyMapper(featuredImage) ?? images?.[0],
      priceRange: productPriceMapper(item?.priceRange),
      compareAtPriceRange: productPriceMapper(item?.compareAtPriceRange),
      variants: productVariantsMapper(
        item?.variants?.edges,
        getProductSlug(item.handle),
        locale,
        item.title,
        productMetafields,
        item.id,
        item.tags,
      ),
      discountPercentage: discountPercentage ? Number(discountPercentage) : 0,
    };
  });
}

export function mapContentProductRecommendations(
  products?: ProductRecommendationsInterface[] | null,
): ContentProductRecommendationInterface | null {
  if (!products || isEmpty(products)) {
    return null;
  }

  return {
    _uid: 'productRecommendation',
    component: 'productRecommendation',
    products,
    section: sectionMapper(undefined),
  };
}
