import { CART_QUANTITY } from '@qlevr/shared/constants';
import CartQuantityForm from './cart-quantity-form';

/* eslint-disable-next-line */
export interface CartQuantityProps {
  quantity: number;
  variantId: string;
  lineItemId: string;
}

export function CartQuantity(props: CartQuantityProps) {
  return (
    <div className="flex items-center space-x-2">
      <CartQuantityForm {...props} type={CART_QUANTITY.decrease} />
      <span className="text-sm">{props.quantity}</span>
      <CartQuantityForm {...props} type={CART_QUANTITY.increase} />
    </div>
  );
}

export default CartQuantity;
