export function formattedShippingDate(date: string | undefined): string {
  if (!date) {
    return '';
  }

  const shippingDate = new Date(date);

  if (isNaN(shippingDate.getTime())) {
    return 'Invalid date';
  }

  return shippingDate.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
}
