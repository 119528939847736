import { CompareProductInterface, ContentProductsComparisonInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { CompareProductStoryblok, ProductsComparisonStoryblok } from '@qlevr/shared/schema';
import { mapRichText } from '../../rich-text/rich-text';
import { sectionMapper } from '../../section/section';
import { mapTitle } from '../../title/title';
import { mapCompareProduct } from './compare-product';

export function mapContentProductsComparison(
  content: ProductsComparisonStoryblok,
  meta: MetaInterface,
): ContentProductsComparisonInterface | null {
  if (!content) return null;

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    text: mapRichText(content.text),
    products: productComparisonMapper(content.products, meta),
    section: sectionMapper(content.section?.[0]),
  };
}

export function productComparisonMapper(
  products: CompareProductStoryblok[],
  meta: MetaInterface,
): CompareProductInterface[] {
  if (!products || products.length === 0) return [];

  return products.reduce((acc, product) => {
    const mappedProduct = mapCompareProduct(product, meta) as CompareProductInterface;

    const newProduct = {
      ...mappedProduct,
      product: {
        ...mappedProduct?.product,
        variants: mappedProduct?.product?.variants?.sort(
          (a, b) => Number(b.defaultSelected) - Number(a.defaultSelected),
        ),
      },
    } as CompareProductInterface;

    if (newProduct) {
      acc.push(newProduct);
    }

    return acc;
  }, [] as CompareProductInterface[]);
}
