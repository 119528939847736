import { ContentBlockInterface, MappingContentBlocksFunction, MetaInterface } from '@qlevr/shared/interfaces';
import {
  AccordionContentStoryblok,
  AsMattressPremiumStoryblok,
  AsMattressStoryblok,
  BlogsStoryblok,
  CodeStoryblok,
  ColumnsContentGridStoryblok,
  ColumnsContentSliderStoryblok,
  ContentImageStoryblok,
  ContentTextImageCardStoryblok,
  ContentVideoStoryblok,
  FeaturedBlogsStoryblok,
  FeaturedCollectionCarouselStoryblok,
  FeaturedCollectionStoryblok,
  FeaturedProductsStoryblok,
  GuidedBuyingFlowStoryblok,
  ImageScrollCarouselStoryblok,
  InstagramStoryblok,
  NewsletterStoryblok,
  PageStoryblok,
  ProductBundlerStoryblok,
  ProductVariantsStoryblok,
  ProductsComparisonStoryblok,
  TeamStoryblok,
  TextImageOverlayStoryblok,
  TextImageStoryblok,
  TextPictoCardsStoryblok,
  TextPictoStoryblok,
  TextStoryblok,
  TextTextPictoStoryblok,
  TextVideoOverlayStoryblok,
  TextVideoStoryblok,
  TypeFormStoryblok,
} from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { map3dTechLayerContent } from './3d-tech-layer/3d-tech-layer';
import { mapAccordionContent } from './accordion-content';
import { mapContentBlockBlog } from './blog';
import { mapCodeContent } from './code';
import { mapColumnsContentGrid } from './columns-content-grid';
import { mapColumnsContentSlider } from './columns-content-slider';
import { mapVideoContent } from './content-video';
import { mapFeaturedBlogsContent } from './featured-blogs';
import { mapFeaturedCollectionContent } from './featured-collection';
import { mapFeaturedCollectionCarouselContent } from './featured-collection-carousel';
import { mapFeaturedProductsContent } from './featured-products';
import { mapGuidedBuyingFlow } from './guided-buying-flow/guided-buying-flow';
import { mapImageContent } from './image';
import { mapImageScrollCarouselContent } from './image-scroll-carousel';
import { mapInstagramFeed } from './instagram';
import { mapNewsletterContent } from './newsletter';
import { mapProductBundlerContent } from './product-bundler';
import { mapProductVariantsContent } from './product-variants';
import { mapContentProductsComparison } from './products-comparison/products-comparison';
import { mapTeamContent } from './team';
import { mapTextContent } from './text';
import { mapTextImageContent } from './text-image';
import { mapTextImageCardContent } from './text-image-card';
import { mapTextImageOverlayContent } from './text-image-overlay';
import { mapTextPictoContent } from './text-picto';
import { mapTextPictoCardsContent } from './text-picto-cards';
import { mapTextTextPictoContent } from './text-text-picto';
import { mapTextVideoContent } from './text-video';
import { mapTextVideoOverlayContent } from './text-video-overlay';
import { mapTypeFormContent } from './typeform';

const componentMapping = new Map<
  string,
  MappingContentBlocksFunction<unknown, MetaInterface, ContentBlockInterface | null>
>([
  ['accordionContent', (block) => mapAccordionContent(block as AccordionContentStoryblok)],
  ['blogs', (block, meta) => mapContentBlockBlog(block as BlogsStoryblok, meta)],
  ['code', (block) => mapCodeContent(block as CodeStoryblok)],
  ['columnsContentSlider', (block) => mapColumnsContentSlider(block as ColumnsContentSliderStoryblok)],
  ['columnsContentGrid', (block) => mapColumnsContentGrid(block as ColumnsContentGridStoryblok)],
  ['contentImage', (block) => mapImageContent(block as ContentImageStoryblok)],
  ['contentVideo', (block) => mapVideoContent(block as ContentVideoStoryblok)],
  ['contentTextImageCard', (block) => mapTextImageCardContent(block as ContentTextImageCardStoryblok)],
  ['featuredBlogs', (block, meta) => mapFeaturedBlogsContent(block as FeaturedBlogsStoryblok, meta)],
  ['featuredCollection', (block, meta) => mapFeaturedCollectionContent(block as FeaturedCollectionStoryblok, meta)],
  [
    'featuredCollectionCarousel',
    (block, meta) => mapFeaturedCollectionCarouselContent(block as FeaturedCollectionCarouselStoryblok, meta),
  ],
  ['featuredProducts', (block, meta) => mapFeaturedProductsContent(block as FeaturedProductsStoryblok, meta)],
  ['imageScrollCarousel', (block) => mapImageScrollCarouselContent(block as ImageScrollCarouselStoryblok)],
  ['instagram', (block, meta) => mapInstagramFeed(block as InstagramStoryblok, meta)],
  ['newsletter', (block) => mapNewsletterContent(block as NewsletterStoryblok)],
  ['productVariants', (block, meta) => mapProductVariantsContent(block as ProductVariantsStoryblok, meta)],
  ['productBundler', (block, meta) => mapProductBundlerContent(block as ProductBundlerStoryblok, meta)],
  ['guidedBuyingFlow', (block, meta) => mapGuidedBuyingFlow(block as GuidedBuyingFlowStoryblok, meta)],
  ['productsComparison', (block, meta) => mapContentProductsComparison(block as ProductsComparisonStoryblok, meta)],
  ['team', (block) => mapTeamContent(block as TeamStoryblok)],
  ['text', (block) => mapTextContent(block as TextStoryblok)],
  ['textImage', (block) => mapTextImageContent(block as TextImageStoryblok)],
  ['textImageOverlay', (block) => mapTextImageOverlayContent(block as TextImageOverlayStoryblok)],
  ['textPicto', (block) => mapTextPictoContent(block as TextPictoStoryblok)],
  ['textPictoCards', (block) => mapTextPictoCardsContent(block as TextPictoCardsStoryblok)],
  ['textTextPicto', (block) => mapTextTextPictoContent(block as TextTextPictoStoryblok)],
  ['textVideo', (block) => mapTextVideoContent(block as TextVideoStoryblok)],
  ['textVideoOverlay', (block) => mapTextVideoOverlayContent(block as TextVideoOverlayStoryblok)],
  ['typeForm', (block) => mapTypeFormContent(block as TypeFormStoryblok)],
  ['asMattress', (block) => map3dTechLayerContent(block as AsMattressStoryblok)],
  ['asMattressPremium', (block) => map3dTechLayerContent(block as AsMattressPremiumStoryblok)],
]);

export function contentBlocksMapper(
  blocks: PageStoryblok['contentBlocks'] | undefined,
  meta: MetaInterface,
): ContentBlockInterface[] {
  if (!blocks || isEmpty(blocks)) {
    return [];
  }

  return blocks.reduce((acc, block) => {
    if (!block?.component) {
      return acc;
    }

    const component = componentMapping.get(block.component);
    if (!component) {
      return acc;
    }

    const item = component(block, meta);
    if (item) {
      acc.push(item);
    }

    return acc;
  }, [] as ContentBlockInterface[]);
}
