export const productFields = `
  __typename
  id
  title
  description
  handle
  availableForSale
  vendor
  productType
  tags
  compareAtPriceRange {
    minVariantPrice {
      amount
      currencyCode
    }
    maxVariantPrice {
      amount
      currencyCode
    }
  }
  priceRange {
    minVariantPrice {
      amount
      currencyCode
    }
    maxVariantPrice {
      amount
      currencyCode
    }
  }
  images(first: 10) {
    edges {
      node {
        id
        altText
        url
      }
    }
  }
  metafields(identifiers: [{key: "featured_text", namespace: "custom"},{ key: "bundle_promotion", namespace: "custom" },{ key: "magento_product_id", namespace: "custom"},{key: "featured_image", namespace: "custom"},{key: "color_overview_image", namespace: "custom"}, { key: "product_colors", namespace: "custom" }, { key: "product_sizes", namespace: "custom" }, { key: "size_chart", namespace: "custom" }, {key: "product_specifications", namespace: "custom"},{ key: "navigation_image", namespace: "custom" },{ key: "premium", namespace: "custom" },{ key: "linked_product", namespace: "custom" },{ key: "premium_product_information", namespace: "custom" },{ key: "discount_percentage", namespace: "custom" },{ key: "usp_s", namespace: "custom" },{ key: "show_available_sizes", namespace: "custom" },{ key: "product_size_layout", namespace: "custom" },{ key: "product_color_layout", namespace: "custom" },{ key: "guided_buying_flow", namespace: "custom" }]) {
    key
    value
    reference {
      __typename
      ... on MediaImage {
        image {
          url
        }
      }
    }
    references(first: 15) {
      edges {
        node {
          __typename
          ... on Metaobject {
            handle
            type
            fields {
              key
              value
              reference {
                __typename
                ... on MediaImage {
                  image {
                    url
                  }
                }
                ... on Video {
                  sources {
                      url
                  }
                }
              }
              references(first: 15) {
                edges {
                  node {
                    __typename
                    ... on Metaobject {
                      handle
                      type
                      fields {
                        key
                        value
                        reference {
                          __typename
                          ... on MediaImage {
                            image {
                              url
                            }
                          }
                        }
                      }
                    }
                    ... on MediaImage {
                      image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  options(first: 20) {
    id
    name
    values
  }
  variants(first: 200) {
    edges {
      node {
        id
        availableForSale
        barcode
        currentlyNotInStock
        quantityAvailable
        sku
        compareAtPrice {
          amount
          currencyCode
        }
        price {
          amount
          currencyCode
        }
        selectedOptions {
          name
          value
        }
        image {
          id
          url
        }
        metafields(
          identifiers: [
            { key: "shipping_date", namespace: "custom" }
            {key: "variant_tag", namespace: "custom"}
            { key: "info_images", namespace: "custom" }
            { key: "default_selected", namespace: "custom" }
            { key: "preorder_threshold", namespace: "custom" }
            { key: "quiz_stacked_image", namespace: "custom" }
          ]
        ) {
          key
          value
          reference {
            __typename
            ... on MediaImage {
              image {
                url
              }
            }
            ... on Metaobject {
              fields {
                key
                value
              }
            }
          }
          references(first: 15) {
            edges {
              node {
                __typename
                ... on MediaImage {
                  image {
                    url
                  }
                }
                ... on Metaobject {
                  fields {
                    key
                    value
                    reference {
                      ... on MediaImage {
                        image {
                          url
                        }
                      }
                      ... on Metaobject {
                        fields {
                          key
                          value
                          reference {
                            ... on MediaImage {
                              image {
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  collections(first: 1) {
    edges {
      node {
        handle
      }
    }
  }
  seo {
    title
    description
  }
`;
