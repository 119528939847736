'use client';
import { BREAKPOINTS, LINK_STYLES } from '@qlevr/shared/constants';
import { ContentFeaturedCollectionCarouselInterface } from '@qlevr/shared/interfaces';
import { Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from '../container/container';
import ImageRenderer from '../image-renderer/image-renderer';
import LinkRenderer from '../link/link-renderer';
import Section from '../section/section';
import Text from '../text/text';
import Title from '../title/title';

export interface ContentFeaturedCollectionCarouselProps extends ContentFeaturedCollectionCarouselInterface {}

export function ContentFeaturedCollectionCarousel({
  alignment,
  collections,
  section,
  title,
  text,
}: ContentFeaturedCollectionCarouselProps) {

  return (
    <Section {...section}>
      <Container className="!px-0">
        <div className="space-y-6 pl-5 sm:px-5 lg:space-y-10">
          {!!(title || text) && (
            <div className={`flex ${alignment.horizontal}`}>
              <div className={`lg:m-auto lg:w-1/2 ${alignment.text}`}>
                {title && <Title className="text-center text-5xl font-bold" {...title} section={section} />}
                {text && <Text className="text-center" text={text} theme={section.theme} />}
              </div>
            </div>
          )}

          <Swiper
            className={`swiper-with-nav swiper-with-scrollbar ${section.theme.carousel}`}
            spaceBetween={24}
            slidesPerView={4}
            scrollbar={{ draggable: true, dragClass: `swiper-scrollbar-drag` }}
            navigation={true}
            modules={[Scrollbar, Navigation]}
            breakpoints={{
              [BREAKPOINTS.lg]: {
                slidesPerView: 4,
              },
              [BREAKPOINTS.sm]: {
                slidesPerView: 3,
                spaceBetween: 16,
              },
              [BREAKPOINTS.xs]: {
                slidesPerView: 1.5,
              },
            }}
          >
            {collections?.map((item, index) => (
              <div key={index}>
                {item.products?.map((product, key) => {
                  return (
                    <SwiperSlide
                      key={key}
                      className={`${section.theme.card.background} relative space-y-4 rounded-2xl px-4 py-6 shadow-md`}
                    >
                      <div>
                        <div className={`flex flex-col pb-2 ${section.theme.color.heading} text-center`}>
                          <LinkRenderer
                            cta={{
                              label: `${product.title}`,
                              as: 'link',
                              href: product.slug,
                              linkStyle: LINK_STYLES.textBlack,
                              className: `font-bold font-sans text-xl ${section.theme.card.body.text}`,
                              wrapperClassName: 'after:inset-0 after:absolute',
                            }}
                          />
                        </div>
                      </div>
                      {product.featuredImage && (
                        <div>
                          <ImageRenderer
                            loadingState="lazy"
                            image={product.featuredImage}
                            className="!aspect-5/4 object-contain"
                          />
                        </div>
                      )}
                    </SwiperSlide>
                  );
                })}
              </div>
            ))}
          </Swiper>
        </div>
      </Container>
    </Section>
  );
}

export default ContentFeaturedCollectionCarousel;
