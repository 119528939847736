import { RATIOS } from '@qlevr/shared/constants';
import { ColumnConfigInterface, ImageInterface } from '@qlevr/shared/interfaces';
import { AssetStoryblok, ImageStoryblok } from '@qlevr/shared/schema';
import {
  fillMissingBreakpoints
} from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';

export function imagesStoryblokMapper(
  images: ImageStoryblok[] | undefined,
  columnConfig: ColumnConfigInterface,
): ImageInterface[] {
  if (!images || isEmpty(images)) {
    return [];
  }

  return images.reduce((acc, item) => {
    const image = imageStoryblokMapper(item, columnConfig);
    if (image) {
      acc.push(image);
    }
    return acc;
  }, [] as ImageInterface[]);
}

export function imageStoryblokMapper(
  image: ImageStoryblok | undefined,
  columnConfig: ColumnConfigInterface,
  container?: boolean,
): ImageInterface | null {
  if (!image) return null;

  const ratio = getRatioWithFallback(image, true);
  const ratioLg = getRatioWithFallback(image, false);
  const asset = getImageWithFallback(image, true);
  const assetLg = getImageWithFallback(image, false);
  const columnConfigForEachBreakpoint = fillMissingBreakpoints(columnConfig);

  return {
    url: asset.filename,
    alt: asset.alt ?? '',
    ratio: ratio,
    columnConfig: columnConfigForEachBreakpoint,
    source: 'storyblok',
    container: container ?? true,
    lg: {
      url: assetLg.filename,
      ratio: ratioLg,
    },
  };
}

function getRatioWithFallback(image: ImageStoryblok, mobile: boolean): string {
  const defaultRatio = RATIOS.DEFAULT;
  const mobileRatio = image?.ratio ? image?.ratio.toString() : defaultRatio;
  const desktopRatio = image?.ratioLg ? image?.ratioLg.toString() : mobileRatio;

  return mobile ? mobileRatio : desktopRatio;
}

function getImageWithFallback(image: ImageStoryblok, mobile: boolean): AssetStoryblok {
  const mobileImage = image?.image;
  const desktopImage = image.imageLg?.filename ? image.imageLg : mobileImage;

  return mobile ? mobileImage : desktopImage;
}
