import { LOCALES } from '@qlevr/shared/constants';
import { GlobalInterface } from '@qlevr/shared/interfaces';
import { globalsMapper } from '@qlevr/shared/mappers';
import { getLocaleFromCountryLocale, hasFallbackLocale } from '@qlevr/shared/utilities';
import { notFound } from 'next/navigation';

export async function getGlobals(locale: string, preview: boolean): Promise<GlobalInterface | null> {
  const urlParams = new URLSearchParams({
    token: process.env['NEXT_PUBLIC_STORYBLOK_API_TOKEN'] as string,
    version: preview ? 'draft' : 'published',
  }).toString();

  const formattedLocal = locale.toLocaleLowerCase() || LOCALES[0];
  let res = await fetch(getUrl(formattedLocal, urlParams), { next: { tags: ['/global'] } });
  let isError = res.status !== 200;
  if (isError && !hasFallbackLocale(formattedLocal)) {
    console.error('Global not found', getUrl(formattedLocal, urlParams));
    return notFound();
  }

  // Retry with the fallback locale
  if (isError && hasFallbackLocale(formattedLocal)) {
    res = await fetch(fallBackUrl(formattedLocal, urlParams), { next: { tags: ['/global'] } });
    isError = res.status !== 200;
  }
  if (isError) {
    console.error('Page not found', fallBackUrl(formattedLocal, urlParams));
    return notFound();
  }

  const globals = await res.json();
  return globalsMapper(globals.story);
}

function getUrl(locale: string, urlParams: string): string {
  return `https://api.storyblok.com/v2/cdn/stories/${locale}/${process.env['NEXT_PUBLIC_BRAND_NAME']}/global?${urlParams}`;
}

function fallBackUrl(locale: string, urlParams: string): string {
  return `https://api.storyblok.com/v2/cdn/stories/${getLocaleFromCountryLocale(locale)}/${process.env['NEXT_PUBLIC_BRAND_NAME']}/global?${urlParams}`;
}
