import { RATIOS } from '@qlevr/shared/constants';
import { ColumnConfigInterface, ImageInterface } from '@qlevr/shared/interfaces';
import { Maybe } from '@qlevr/shared/schema';
import {
  fillMissingBreakpoints,
  imageRemoveSizeParameters
} from '@qlevr/shared/utilities';
import { getRatioWithFallback } from '../asset/asset-storyblok';

export type imageShopifyMapperProps = {
  ratio: string;
  imageLg?: string;
  ratioLg?: string;
  altText?: Maybe<string>;
  columnConfig: ColumnConfigInterface;
  container?: boolean;
};

export function imageShopifyMapper(
  image: string | undefined,
  props: imageShopifyMapperProps = {
    ratio: RATIOS.DEFAULT,
    columnConfig: { xs: 1 },
    container: true,
  },
): ImageInterface | null {
  if (!image) return null;

  const ratio = getRatioWithFallback(props.ratio);
  const ratioLg = getRatioWithFallback(props.ratio, props.ratioLg);
  const asset = imageRemoveSizeParameters(getImageWithFallback(image));
  const assetLg = imageRemoveSizeParameters(getImageWithFallback(image, props.imageLg));

  const columnConfigForEachBreakpoint = fillMissingBreakpoints(props.columnConfig);

  return {
    url: asset,
    alt: props.altText ?? '',
    ratio: ratio,
    columnConfig: columnConfigForEachBreakpoint,
    source: 'shopify',
    container: props.container ?? true,
    lg: {
      url: assetLg,
      ratio: ratioLg,
    }
  };
}

function getImageWithFallback(image: string, imageLg?: string): string {
  if (imageLg) {
    return imageLg;
  }

  return image;
}
