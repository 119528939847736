import { CURRENCY_SYMBOLS, CurrencySymbolType } from '@qlevr/shared/constants';

/**
 * Retrieves the currency symbol associated with the given currency code.
 * If no currency code is provided, the default currency code is 'EUR'.
 * If the provided currency code is not found in the symbol mapping, the default currency symbol for 'EUR' is returned.
 *
 * @param currencyCode - The currency code for which to retrieve the symbol.
 * @returns The currency symbol associated with the given currency code, or the default currency symbol for 'EUR' if not found.
 * @example getSymbolFromCurrencyCode('EUR') // '€'
 */
export function getSymbolFromCurrencyCode(currencyCode?: string | null): CurrencySymbolType | null {
  if (!currencyCode) {
    currencyCode = 'EUR';
  }

  const symbol = CURRENCY_SYMBOLS[currencyCode as keyof typeof CURRENCY_SYMBOLS];

  return symbol || CURRENCY_SYMBOLS.EUR;
}
