import { LOCALES } from '@qlevr/shared/constants';
import { FetchStoryblokStoryInteface, ProductRecommendationsInterface } from '@qlevr/shared/interfaces';
import { RecommendationsProductsMapper } from '@qlevr/shared/mappers';
import { CountryCodeShopify, GetProductRecommendationsQueryShopify, LanguageCodeShopify } from '@qlevr/shared/schema';
import {
  getCountryCodeShopify,
  getCountryFromCountryLocale,
  getLanguageCodeShopify,
  getLocaleFromCountryLocale,
} from '@qlevr/shared/utilities';
import { ShopifyService } from '../shopify/shopify-service';

export class FetchProductRecommendations {
  private params: FetchStoryblokStoryInteface = { path: '', locale: '', preview: false, cv: '' };
  private slug: string;
  private country: CountryCodeShopify;
  private language: LanguageCodeShopify;

  constructor(locale: string, slug: string) {
    this.params.locale = locale.toLocaleLowerCase() ?? LOCALES[0];
    this.slug = slug;
    this.country = getCountryCodeShopify(getCountryFromCountryLocale(this.params.locale));
    this.language = getLanguageCodeShopify(getLocaleFromCountryLocale(this.params.locale)) as LanguageCodeShopify;
  }

  public async request(id: string): Promise<ProductRecommendationsInterface[] | null> {
    const shopifyResponse = await ShopifyService({}).getProductRecommendations({
      country: this.country,
      language: this.language,
      productId: id,
    });

    const isProductError = shopifyResponse.productRecommendations === null;
    if (isProductError) {
      console.error('Product Recommendations not found', id);
    }

    return this.mapper(
      shopifyResponse.productRecommendations as unknown as GetProductRecommendationsQueryShopify['productRecommendations'][],
      this.params.locale,
    );
  }

  private mapper(products: GetProductRecommendationsQueryShopify['productRecommendations'][], locale: string) {
    return RecommendationsProductsMapper(products, locale, this.slug);
  }
}
