import {
  GlobalInterface,
  ProductCombinationInterface,
  ProductInterface,
  ProductReviewInterface,
} from '@qlevr/shared/interfaces';
import { fullPageSlug, inventoryIsLow } from '@qlevr/shared/utilities';
import { StructuredDataType, StructuredDataTypeItem } from './page-structured-data';
import isEmpty from 'lodash.isempty';
import { mapSameAs } from './organisation-structured-data';

export function productStructuredDataMapper(product: ProductInterface, locale: string, globals?: GlobalInterface | null): string {
  const structuredData: StructuredDataType = {
    '@context': 'https://schema.org',
    '@type': 'ProductGroup',
    ...(product.id ? { productGroupID: product.id } : {}),
    ...(globals?.brandName
      ? {
          brand: {
            '@type': 'Brand',
            name: globals.brandName,
            logo: `${fullPageSlug(`image/brand-logo.svg`)}`,
            ...(globals.metatags?.description ? { description: globals.metatags?.description } : {}),
          },
          url: fullPageSlug(`${locale}${product.slug}`),
          ...(isEmpty(mapSameAs(globals)) ? { sameAs: mapSameAs(globals) } : {}),
        }
      : {}),
    ...(product.featuredImage?.lg?.url ? { image: product.featuredImage.lg.url } : {}),
    url: fullPageSlug(`${locale}${product.slug}`),
    name: product.title,
    ...(product.featuredText ? { description: product.featuredText } : {}),
    ...(!isEmpty(mapProductCombinations(product.combinations, locale, globals))
      ? { hasVariant: mapProductCombinations(product.combinations, locale, globals) }
      : {}),
    ...(product.review ? mapReviews(product.review) : {}),
  };

  return JSON.stringify(structuredData);
}

function mapProductCombinations(
  combinations: ProductCombinationInterface[] | null,
  locale: string,
  globals?: GlobalInterface | null,
): StructuredDataTypeItem[] {
  if (!combinations || isEmpty(combinations)) {
    return [];
  }

  return combinations.map((item) => ({
    '@type': 'Product',
    name: item.title,
    sku: item.sku,
    gtin13: item.barcode,
    ...(item.featuredImage ? { image: item.featuredImage.lg.url } : {}),
    offers: {
      '@type': 'Offer',
      price: item.price?.amount,
      priceCurrency: item.price?.currencyCode,
      availability: mapAvailability(item, globals),
      url: fullPageSlug(`${locale}${item.slug}`),
    },
  }));
}

function mapAvailability(combination: ProductCombinationInterface, globals?: GlobalInterface | null): string {
  if (combination.preOrder) {
    return 'http://schema.org/PreOrder';
  }
  if (combination.currentlyNotInStock) {
    return 'http://schema.org/OutOfStock';
  }

  if (globals) {
    if (inventoryIsLow(combination, globals.inventoryThreshold)) {
      return 'http://schema.org/LimitedAvailability';
    }
  }

  if (!combination.availableForSale) {
    return 'http://schema.org/SoldOut';
  }

  return 'http://schema.org/InStock';
}

function mapReviews(review: ProductReviewInterface): StructuredDataTypeItem {
  return {
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: review.averageScore.toFixed(2),
      reviewCount: review.totalReview.toString(),
    },
  };
}