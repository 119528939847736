'use client';
import { AccordionInterface, SectionInterface } from '@qlevr/shared/interfaces';
import { Accordion, AccordionPanel, Flowbite } from 'flowbite-react';
import { useState } from 'react';
import AccordionItem from './accordion-item';
import { accordionTheme } from './accordion-theme';

export interface AccordionProps {
  accordions: AccordionInterface[];
  section: SectionInterface;
}

export function AccordionData(props: AccordionProps) {
  const [accordionStates, setAccordionStates] = useState(props.accordions.map(() => true));
  const handleClick = (index: number) => {
    setAccordionStates((prev) => prev.map((state, i) => (i === index ? !state : true)));
  };
  return (
    <Flowbite theme={{ theme: accordionTheme }}>
      <Accordion collapseAll>
        {props.accordions.map((accordion, index) => (
          <AccordionPanel key={index}>
            <AccordionItem
              accordion={accordion}
              accordionStates={accordionStates}
              handleClick={handleClick}
              index={index}
              section={props.section}
            />
          </AccordionPanel>
        ))}
      </Accordion>
    </Flowbite>
  );
}

export default AccordionData;
