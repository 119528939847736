import {
  ProductOptionInterface,
  ProductVariantSizeColorInterface
} from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';

export function getFilteredOptionsBySizeAndOrColor(
  options: ProductOptionInterface[] | null,
  values: ProductVariantSizeColorInterface | null,
): [string, string][] | null {
  if (!values || isEmpty(values)) {
    return null;
  }

  const entries = Object.entries(values);

  // This is the "magic" that will cross check possible variant combinations and preemptively
  // disable combinations that are not available. For example, if the color gray is only available in size medium,
  // then all other sizes should be disabled.
  // LEARN MORE => https://github.com/vercel/commerce/blob/main/components/product/variant-selector.tsx#L62
  const filtered = entries.filter(([key, value]) =>
    options?.find((option) => option.name.toLowerCase() === key && option.values.includes(value)),
  );

  // if no options is found,
  // it doesn't exist
  if (!filtered) {
    return null;
  }

  return filtered;
}
