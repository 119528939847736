import { LinkedMetaDataInterface } from '@qlevr/shared/interfaces';
import { collectionFields, productFields } from '@qlevr/shared/queries';
import { CountryCodeShopify, LanguageCodeShopify } from '@qlevr/shared/schema';
import { removeForbiddenIdChars } from '@qlevr/shared/utilities';

export function shopifyLinkedDataQueryBuilder(country: CountryCodeShopify, language: LanguageCodeShopify, data: LinkedMetaDataInterface): string {
  const collections = Array.from(data.collections)
    .map(
      (collection) =>
        `${removeForbiddenIdChars(collection)}: collection(id: "${collection}") {
          ${collectionFields}
        }`,
    )
    .join()
    .replace(/\s{2,}/g, ' ')
    .trim();

  const products = Array.from(data.products)
    .map(
      (product) =>
        `${removeForbiddenIdChars(product)}: product(id: "${product}") {
          ${productFields}
        }`,
    )
    .join()
    .replace(/\s{2,}/g, ' ')
    .trim();

  // The inContext directive is used to specify the country and language for the query
  return `query shopifyLinkedData @inContext(country: ${country}, language: ${language}) {${collections} ${products}}`;
}
