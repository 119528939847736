import { ICONS } from '@qlevr/shared/constants';
import { useCart } from '@qlevr/shared/providers';
import IconRenderer from '../../../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface CartLineItemRemoveFormProps {
  lineItemId: string;
}

export function CartLineItemRemoveForm({ lineItemId }: CartLineItemRemoveFormProps) {
  const { remove } = useCart();

  function formAction() {
    remove(lineItemId);
  }

  return (
    <form className="ml-auto" action={formAction}>
      <button type="submit" className="text-gray-500 text-xl">
        <IconRenderer icon={ICONS.close} className="w-5 h-5" />
      </button>
    </form>
  );
}

export default CartLineItemRemoveForm;
