import { LinkInterface } from '@qlevr/shared/interfaces';
import BaseLinkElementRenderer from './base/base-link-element-renderer';

/* eslint-disable-next-line */
export interface TertiaryOutlineLinkProps {
  cta: LinkInterface;
}

export function TertiaryOutlineLink(props: TertiaryOutlineLinkProps) {
  return (
    <BaseLinkElementRenderer
      {...props}
      cta={{
        ...props.cta,
        wrapperClassName: `btn-tertiary-outline flex items-center justify-center rounded-full px-12 py-4 font-sans text-lg gap-3 whitespace-nowrap ${props.cta.wrapperClassName}`,
      }}
    />
  );
}

export default TertiaryOutlineLink;
