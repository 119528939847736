import { CartFreeShippingInterface, CartInterface } from '@qlevr/shared/interfaces';

export function removeOptimisticCartItem(cartData: CartInterface | undefined, lineItemId: string, freeShippingTreshold: CartFreeShippingInterface): CartInterface {
  if (!cartData?.items) {
    return cartData as CartInterface;
  }

  return {
    ...cartData,
    freeShipping: freeShippingTreshold,
    items: cartData.items.filter((item) => item.id !== lineItemId),
  };
}
