import { KeyValueInterface } from '@qlevr/shared/interfaces';
import { Maybe, MetafieldShopify } from '@qlevr/shared/schema';
import { getProductMetafieldReferencesFields } from '../metafield/get-product-metafield-references-field';

export function mapAgeGroups(metafields: Maybe<MetafieldShopify>[]): KeyValueInterface[] {
  if (!metafields) {
    return [];
  }

  return getProductMetafieldReferencesFields('size_chart', metafields);
}
