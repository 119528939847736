'use client';
import { BUTTON_SIZE, LINK_STYLES } from '@qlevr/shared/constants';
import { ProductInterface } from '@qlevr/shared/interfaces';
import { reverseEscapeUrl } from '@qlevr/shared/utilities';
import { useState } from 'react';
import Container from '../container/container';
import LinkRenderer from '../link/link-renderer';
import ProductColor from './product-color';

export interface ProductColorsProps {
  product: ProductInterface;
  cta: string;
}

export function ProductColors({ product, cta }: ProductColorsProps) {
  // Product image by default and handled when color changed
  let hasproductColorOverviewImage = product.colorOverviewImage?.[0]?.value;

  if (!hasproductColorOverviewImage) {
    hasproductColorOverviewImage = product.featuredImage?.url;
  }

  const [currentImage, setCurrentImage] = useState(hasproductColorOverviewImage);
  const colorFeaturedImage = currentImage || product?.featuredImage?.url;
  const altText = product.colorOverviewImage?.[0]?.key || '';

  // Product color selection + url
  const [currentKey, setCurrentKey] = useState('');

  function productUrl() {
    if (currentKey) {
      return `${product.slug}?color=${currentKey}`;
    }

    return `${product.slug}`;
  }

  return (
    <Container className="mx-auto flex flex-col items-center gap-y-4">
      <img src={colorFeaturedImage} alt={altText} className="aspect-3/2 object-contain" />
      <ProductColor
        product={product}
        currentKey={currentKey}
        setCurrentKey={setCurrentKey}
        setCurrentImage={setCurrentImage}
      />
      <div className="text-center capitalize">{currentKey && <p>{`${reverseEscapeUrl(currentKey)}`}</p>}</div>
      <LinkRenderer
        cta={{
          as: 'link',
          href: productUrl(),
          linkStyle: LINK_STYLES.primary,
          size: BUTTON_SIZE.base,
          label: cta,
        }}
      ></LinkRenderer>
    </Container>
  );
}

export default ProductColors;
