import { GlobalInterface } from '@qlevr/shared/interfaces';
import { PageStoryblok } from '@qlevr/shared/schema';
import { fullPageSlug, getFullUrlFromStoryblokRelativeSlug, getLocaleFromSlug } from '@qlevr/shared/utilities';
import { StoryblokStory } from 'storyblok-generate-ts';
import { mapSameAs } from './organisation-structured-data';
import isEmpty from 'lodash.isempty';

export type StructuredDataTypeItem = {
  [x: string]: string | string[] | StructuredDataTypeSubItem | StructuredDataTypeSubItem[] | any;
};

export type StructuredDataTypeSubItem = {
  [x: string]: string | string[] | any;
};

export type StructuredDataType = {
  [x: string]: string | string[] | StructuredDataTypeItem | StructuredDataTypeItem[];
};

export function pageStructuredDataMapper(
  page: StoryblokStory<PageStoryblok>,
  globals?: GlobalInterface | null,
): string {
  const structuredData: StructuredDataType = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    ...(globals?.brandName
      ? {
          '@author': globals.brandName,
          publisher: {
            '@type': 'Organization',
            name: globals.brandName,
            logo: {
              '@type': 'ImageObject',
              url: `${fullPageSlug(`image/brand-logo.svg`)}`,
            },
            ...(isEmpty(mapSameAs(globals)) ? { sameAs: mapSameAs(globals) } : {}),
          },
        }
      : {}),
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': getFullUrlFromStoryblokRelativeSlug(page.full_slug),
    },
    headline: page.name,
    url: getFullUrlFromStoryblokRelativeSlug(page.full_slug),
    inLanguage: getLocaleFromSlug(page.full_slug),
    name: page.name,
    dateModified: page.published_at,
    ...(page.content.metatags?.[0]?.description ? { description: page.content.metatags[0].description } : {}),
    ...(page.first_published_at ? { datePublished: page.first_published_at } : {}),
  };

  return JSON.stringify(structuredData);
}
