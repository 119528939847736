import { ICONS } from '@qlevr/shared/constants';
import { ProductCombinationInterface, ProductOptionInterface } from '@qlevr/shared/interfaces';
import { getCombinationBySizeAndOrColor, reverseEscapeUrl } from '@qlevr/shared/utilities';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import IconRenderer from '../icons/icon-renderer.component';

export interface ActiveOptionTextProps {
  option: ProductOptionInterface;
  combinations: ProductCombinationInterface[] | null;
  index?: number;
}

export function ActiveOptionText({ option, index, combinations }: ActiveOptionTextProps) {
  const t = useTranslations();
  const searchParams = useSearchParams();

  const size = searchParams.get('size');
  const color = searchParams.get('color');

  const combination = getCombinationBySizeAndOrColor(combinations, size, color);
  const optionNameLowerCase = option.name.toLowerCase();

  function getOptionName(optionName: string) {
    if (optionName === 'size' && size) {
      const sizeOption = combination?.options?.find((value) => value.name.toLowerCase() === 'size');
      if (sizeOption) {
        return sizeOption.label;
      }
      return reverseEscapeUrl(size);
    }

    if (optionName === 'color' && color) {
      const colorOption = combination?.options?.find((value) => value.name.toLowerCase() === 'color');
      if (colorOption) {
        return colorOption.label;
      }
      return reverseEscapeUrl(color);
    }
  }

  return (
    <div className="flex flex-wrap text-base font-light tracking-wide">
      <span className="ml-1 capitalize">{t(`pdp.variants.${optionNameLowerCase}`)}</span>
      <span className="ml-1 font-medium">{getOptionName(optionNameLowerCase)}</span>
      {getOptionName(optionNameLowerCase) && !combination && index === 1 && (
        <span className="ml-1 flex items-center text-xs font-normal text-red-600">
          {t('pdp.outOfStockLabel')} <IconRenderer icon={ICONS.alert} className="ml-1 h-3" />
        </span>
      )}
    </div>
  );
}

export default ActiveOptionText;
