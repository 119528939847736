import { ProductInterface } from '@qlevr/shared/interfaces';
import { getProductSlug } from '@qlevr/shared/mappers';
import { useParams, useRouter } from 'next/navigation';
import { useState } from 'react';

export interface ProductPremiumToggleButtonProps {
  product: ProductInterface;
}

export default function ToggleButton({ product }: ProductPremiumToggleButtonProps) {
  const router = useRouter();
  const { locale } = useParams<{ locale: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(product.isPremium);

  if (!product.linkedProductToRedirect || !product.linkedProductToRedirect.reference) {
    return null;
  }

  const redirectProductToUrl = `/${locale}/${getProductSlug(product.linkedProductToRedirect.reference.handle)}`;

  const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    setIsLoading(true);

    if ((product.isPremium && !e.target.checked) || (!product.isPremium && e.target.checked)) {
      router.push(redirectProductToUrl, { scroll: false });
    }
  };

  return (
    <label className="inline-flex cursor-pointer items-center">
      <input
        type="checkbox"
        value=""
        className="peer sr-only"
        checked={!!checked}
        onChange={handleToggle}
        disabled={isLoading}
      />
      <div
        className={`${checked ? 't-toggle-enabled-bg' : 't-toggle-disabled-bg'} peer relative h-8 w-16 rounded-full bg-gray-200 after:absolute after:left-1 after:top-1 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:after:translate-x-8 peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-8`}
      ></div>
    </label>
  );
}
