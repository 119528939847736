import { KeyValueInterface, ProductInterface } from '@qlevr/shared/interfaces';

export interface OptionButtonProps {
  product: ProductInterface | null;
  value: string;
  isActive: boolean;
  isAvailableForSale: boolean;
  currentlyNotInStock?: boolean;
  tag?: KeyValueInterface[] | null;
  setSize: (size: string) => void;
  setColor: (color: string) => void;
}

export function BuyingFlowProductOptionButton({
  product,
  value,
  isActive,
  isAvailableForSale,
  currentlyNotInStock,
  tag,
  setSize,
  setColor,
}: OptionButtonProps) {

  // Helpers to determine if the option is a color or size
  const productColor = product?.colors?.find((color) => color.key === value);
  const productSize = product?.sizes?.find((size) => size.key === value);
  const slashCss =
    'flex items-center justify-center px-2 py-1 relative before:absolute before:inset-x-0 before:h-1 before:z-10 before:-rotate-45 before:bg-slate-500 before:transition-transform opacity-80 pointer-events-none';
  let className = 'group relative flex items-end justify-center min-w-12 min-h-12 lg:min-w-8 lg:min-h-8 transition duration-300 ease-in-out';
  let imageClassName = '';

  // isActive class application logic
  if (isActive) {
    className += ' is-active';
  }

  // slashCss class for out-of-stock products, applied only when it's a color option
  if (!isAvailableForSale) {
    className += ` ${slashCss}`;
    // Apply opacity to the image if it's a color option
    imageClassName = 'opacity-50';
  }

  // Modified handleClick function to allow clicking regardless of availability
  const handleClick = () => {
    if (productSize) {
      setSize(productSize.key);
    }
    if (productColor) {
      setColor(productColor.key);
    }
  };

  // Simplified getOption function
  const getOption = () => productColor || productSize;

  return (
    <button
      onClick={handleClick}
      className={`${className}`}
      aria-disabled={!isAvailableForSale}
      disabled={!isAvailableForSale}
      title={`${value}${!isAvailableForSale ? ' (Out of Stock)' : ''}`}
    >
      <img
        className={`absolute top-0 mx-auto w-12 h-12 lg:w-8 lg:h-8 rounded-full ${imageClassName} ${isActive ? 'group-[.is-active]:border-2 group-[.is-active]:border-black group-[.is-active]:shadow-md' : ''}`}
        src={getOption()?.value ?? ''}
        alt=""
      />
    </button>
  );
}

export default BuyingFlowProductOptionButton;
