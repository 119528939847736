import { useCart } from '../../cart-provider';

export function useLogin() {
  const cartContext = useCart();

  const loginFormSubmit = async (
    email: string,
    password: string,
    onSuccess: () => void,
    onError: (error: string) => void,
    locale: string,
  ) => {
    try {
      const customerData = {
        email,
      };

      try {
        const loggedIn = await cartContext.login(email, password);

        if (!loggedIn) {
          onError('Invalid credentials');

          throw new Error('Invalid credentials');
        }

        const response = await fetch(`/${locale}/api/multipass`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ customerData, locale }),
        });

        onSuccess();

        const { url } = await response.json();
        window.open(url, '_blank');
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      onError(`Something went wrong ${error}`);
    }
  };

  return { loginFormSubmit };
}

export default useLogin;
