import { MetaInterface, ProductInterface, TextImageHeroInterface } from '@qlevr/shared/interfaces';
import { HeroTextImageStoryblok } from '@qlevr/shared/schema';
import { contentAlignmentMapper } from '../content-alignment/content-alignment';
import { imageFrameTypeMapper } from '../image/image-frame-type';
import { imageStoryblokMapper } from '../image/image-storyblok';
import { textImageLayoutMapper } from '../layout/layout';
import { linksMapper } from '../link/link';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { getMetaProductById } from '../products/products-shopify';

export function mapTextImageHero(title: string, content: HeroTextImageStoryblok, meta: MetaInterface): TextImageHeroInterface | null {
  const productId = content['product']?.items?.[0]?.id;
  
  const image = imageStoryblokMapper(content.image?.[0], {
    xs: 1,
    lg: 2,
  });

  let product = {} as ProductInterface | null;
  if(productId) {
    product = getMetaProductById(productId, meta);
  }

  if(!image) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    alignment: contentAlignmentMapper(content.alignment?.[0]),
    cta: linksMapper(content.cta),
    layout: textImageLayoutMapper(content.layout),
    image,
    rating: content.rating ?? false,
    imageFrame: imageFrameTypeMapper(content.imageFrame as string),
    section: sectionMapper(content.section?.[0]),
    text: mapRichText(content.text) ?? null,
    title: content.title ?? title,
    reviews: product?.review ?? null,
  };
}
