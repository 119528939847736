'use client';

import { ICONS } from '@qlevr/shared/constants';
import { ProductInterface, ProductVariantInterface } from '@qlevr/shared/interfaces';
import { GuidedBuyingFlowContext } from '@qlevr/shared/providers';
import {
  calculateDiscountPrice,
  getCombinationAvailableForSale,
  getFilteredOptionsBySizeAndOrColor,
  reverseEscapeUrl,
} from '@qlevr/shared/utilities';
import { useTranslations } from 'next-intl';
import { useQueryState } from 'nuqs';
import { useContext, useEffect, useState } from 'react';
import IconRenderer from '../../../icons/icon-renderer.component';
import ImageRenderer from '../../../image-renderer/image-renderer';
import BuyingFlowProductOptionButton from './buying-flow-product-option-button';

export interface BuyingFlowVariantSelectorProps {
  uniqueKey: string;
  product: ProductInterface;
  selectedVariant: ProductVariantInterface | null;
  setSelectedVariant: (variant: ProductVariantInterface | null) => void;
  setSize: (size: string) => void;
  setColor: (color: string) => void;
  size: string;
  color: string;
  setErrorMessage: (message: string) => void;
  errorMessage: string;
  onRemove: () => void; // Add the onRemove prop
}

export function BuyingFlowProductVariantSelector({
  uniqueKey,
  product,
  selectedVariant,
  setSelectedVariant,
  setSize,
  setColor,
  size,
  color,
  setErrorMessage,
  errorMessage,
  onRemove, // Destructure the onRemove prop
}: BuyingFlowVariantSelectorProps) {
  const t = useTranslations();
  const { flowProducts, updateVariantQuantity, removeVariantId, addVariantId } = useContext(GuidedBuyingFlowContext);
  const [quantity, setQuantity] = useState(1);
  const [sizeOfMainProduct] = useQueryState('size');
  const reverseEscapeSize = reverseEscapeUrl(size);
  const reverseEscapeColor = reverseEscapeUrl(color);
  let quantitySelectorButtonCss = '';

  if (quantity === 0) {
    quantitySelectorButtonCss = 'bg-white text-night-800 border-azure-500';
  } else {
    quantitySelectorButtonCss = 'bg-green-500 text-white border-green-500';
  }

  // Check if product has size and color options
  const hasSizeOption = product.variants?.some((variant) =>
    variant.options.some((option) => option.name.toLowerCase() === 'size'),
  );
  const hasColorOption = product.variants?.some((variant) =>
    variant.options.some((option) => option.name.toLowerCase() === 'color'),
  );

  // Initialize quantity from flowProducts array
  useEffect(() => {
    const productData = flowProducts.find((item) => item.mainProductId === product.id);
    const variantData = productData?.bundledVariants.find((variant) => variant.key === uniqueKey);
    if (variantData) {
      setQuantity(variantData.quantity);
    }
  }, [flowProducts, product.id, uniqueKey]);

  // useEffect() for setting the size and color of the first available variant
  useEffect(() => {
    if (sizeOfMainProduct && !selectedVariant) {
      if (product.options?.find((option) => option.name.toLowerCase() === 'size')?.values.includes(sizeOfMainProduct)) {
        setSize(sizeOfMainProduct);
      } else {
        setSize(product.firstAvailableVariant?.options.find((opt) => opt.name.toLowerCase() === 'size')?.value ?? '');
      }
      setColor(product.firstAvailableVariant?.options.find((opt) => opt.name.toLowerCase() === 'color')?.value ?? '');
    } else {
      if (product.firstAvailableVariant && !selectedVariant) {
        setSize(product.firstAvailableVariant?.options.find((opt) => opt.name.toLowerCase() === 'size')?.value ?? '');
        setColor(product.firstAvailableVariant?.options.find((opt) => opt.name.toLowerCase() === 'color')?.value ?? '');
      } else if (!product.firstAvailableVariant && !selectedVariant) {
        product.variants?.[0]?.options.forEach((option) => {
          if (option.name.toLowerCase() === 'size') {
            setSize(option.value);
          }
          if (option.name.toLowerCase() === 'color') {
            setColor(option.value);
          }
        });
      }
    }
  }, []);

  // useEffect() for setting the selected variant based on the size and color
  useEffect(() => {
    const variant = product.variants?.find((variant) =>
      variant.options.every((option) => {
        if (option.name.toLowerCase() === 'size' && size) {
          return option.value === size;
        }
        if (option.name.toLowerCase() === 'color' && color) {
          return option.value === color;
        }

        return false;
      }),
    );

    if (variant) {
      setSelectedVariant(variant);
    }

    if (variant && !variant.availableForSale) {
      setErrorMessage(`- ${t('pdp.notAvailableForSale')}`);
    } else {
      setErrorMessage('');
    }
    // don't add unnecessary dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, size]);

  const handleIncreaseQuantity = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    if (selectedVariant && product.id) {
      updateVariantQuantity(uniqueKey, product.id, selectedVariant.id, newQuantity);
      // Special case for adding back the first variant
      if (newQuantity === 1 && uniqueKey === `${product.id}-0`) {
        addVariantId(uniqueKey, product.id, selectedVariant.id);
      }
    }
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 0) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      if (selectedVariant && product.id) {
        updateVariantQuantity(uniqueKey, product.id, selectedVariant.id, newQuantity);
        // Special case for removing the first variant
        if (newQuantity === 0 && uniqueKey !== `${product.id}-0`) {
          removeVariantId(uniqueKey, product.id, selectedVariant.id);
          onRemove(); // Call the onRemove prop
        }
      }
    }
  };

  const hasNoOptionsOrJustOneOption =
    !product.options?.length || (product.options.length === 1 && product.options[0]?.values.length === 1);

  if (!product || hasNoOptionsOrJustOneOption || (quantity === 0 && uniqueKey !== `${product.id}-0`)) {
    return null;
  }

  return (
    <div className={`flex items-center gap-2 rounded-lg bg-white p-2 shadow ${uniqueKey}`}>
      <div className="hidden lg:block">
        {selectedVariant?.featuredImage && (
          <ImageRenderer image={selectedVariant?.featuredImage} className="mr-4 h-20 w-full max-w-20 rounded-lg" />
        )}
      </div>
      <div className="flex w-full flex-col space-y-1">
        <div className="font-bold text-slate-800">{product.title}</div>
        <div className="mb-1 flex flex-col text-xs font-normal capitalize">
          {hasSizeOption && size && (
            <span className="flex gap-1">
              {t('pdp.variants.size')}: {reverseEscapeSize}
              <span className="text-red-500">{errorMessage}</span>
            </span>
          )}
          {hasColorOption && color && (
            <span className="flex gap-1">
              {t('pdp.variants.color')}: {reverseEscapeColor}
              <span className="text-red-500">{errorMessage}</span>
            </span>
          )}
        </div>
        <div className="flex flex-wrap justify-between">
          <div className="flex gap-2 font-serif text-base">
            <span className={`${product.discountPercentage ? 'text-slate-400 line-through' : ''}`}>
              {selectedVariant?.price?.amount && (
                <span>
                  {selectedVariant.price.symbol}
                  {selectedVariant.price.amount}
                </span>
              )}
            </span>
            {product.discountPercentage && selectedVariant?.price?.amount && (
              <span>
                {selectedVariant.price.symbol}
                {calculateDiscountPrice(selectedVariant.price.amount, product.discountPercentage ?? 0)}
              </span>
            )}
          </div>
          <div
            className={`flex w-full max-w-28 items-center justify-between rounded-full border-2 px-4 py-1 ${quantitySelectorButtonCss}`}
          >
            <span className="cursor-pointer" onClick={handleDecreaseQuantity}>
              <IconRenderer
                icon={ICONS.decreaseQuantity}
                className={`h-4 w-4 ${quantity === 0 ? 'text-black' : 'text-white'}`}
              />
            </span>
            <span className={`${quantity === 0 ? 'text-black' : 'text-white'}`}>{quantity}</span>
            <span className="cursor-pointer" onClick={handleIncreaseQuantity}>
              <IconRenderer
                icon={ICONS.increaseQuantity}
                className={`h-4 w-4 ${quantity === 0 ? 'text-black' : 'text-white'}`}
              />
            </span>
          </div>
        </div>

        <div className="flex flex-col">
          {product.options?.map((option, index) => {
            const variantsArray: [string, string][] = [
              ['size', ''],
              ['color', ''],
            ];
            selectedVariant?.options.forEach((option) => {
              if (option.name === 'size') {
                variantsArray[0] = [option.name, option.value];
              }
              if (option.name === 'color') {
                variantsArray[1] = [option.name, option.value];
              }
            });

            return (
              <div key={index} className="mt-1 flex flex-wrap gap-2 lg:m-0 lg:gap-1">
                {option.values.map((value) => {
                  const optionNameLowerCase = option.name.toLowerCase();

                  if (optionNameLowerCase === 'size') {
                    return null;
                  }

                  const variantOption = selectedVariant?.options.find(
                    (opt) => opt.name.toLowerCase() === optionNameLowerCase,
                  )?.value;

                  const isActive = variantOption === value;

                  if (optionNameLowerCase === 'size') {
                    variantsArray[0] = [optionNameLowerCase, value];
                  }
                  if (optionNameLowerCase === 'color') {
                    variantsArray[1] = [optionNameLowerCase, value];
                  }

                  const filtered = getFilteredOptionsBySizeAndOrColor(product.options, {
                    size: variantsArray[0][1],
                    color: variantsArray[1][1],
                  });

                  const isAvailableForSale = getCombinationAvailableForSale(product.combinations, filtered);

                  return (
                    <BuyingFlowProductOptionButton
                      key={value}
                      product={product}
                      value={value}
                      isActive={isActive}
                      isAvailableForSale={!!isAvailableForSale}
                      setSize={setSize}
                      setColor={setColor}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BuyingFlowProductVariantSelector;
