export function getYotpoKeyByLocale(locale: string): string {
  switch (locale) {
    case 'DE':
      return process.env['NEXT_PUBLIC_YOTPO_REVIEWS_ACCESS_TOKEN_DE']!;
    case 'NL':
      return process.env['NEXT_PUBLIC_YOTPO_REVIEWS_ACCESS_TOKEN_NL']!;
    case 'FR':
      return process.env['NEXT_PUBLIC_YOTPO_REVIEWS_ACCESS_TOKEN_FR']!;
    case 'EN':
      return process.env['NEXT_PUBLIC_YOTPO_REVIEWS_ACCESS_TOKEN_EN']!;
    default:
      return process.env['NEXT_PUBLIC_YOTPO_REVIEWS_ACCESS_TOKEN_EN']!;
  }
}
