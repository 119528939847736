import { GlobalInterface, NavigationItemInterface, PathInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import Container from '../container/container';
import SocialIcons from '../social-icons/social-icons';
import MenuDrawerInternationalisation from './menu-drawer-internationalisation';
import MenuNavigationLinks from './menu-drawer-links';
import MenuDrawerNavigationItem from './menu-drawer-navigation-item';

export interface NavigationProps {
  className?: string;
  globals: GlobalInterface;
  localisation: PathInterface[] | null;
}

export function MenuDrawerNavigation({ className, globals, localisation }: NavigationProps) {
  const { navigation }: { navigation: NavigationItemInterface[] } = globals.header;
  const firstProductIndex = navigation.findIndex((item) => !isEmpty(item.products));

  if (!navigation || isEmpty(navigation)) {
    return null;
  }

  return (
    <div className={className}>
      <div className="flex flex-col gap-1">
        {navigation.map((item, index) => (
          <MenuDrawerNavigationItem key={index} {...item} isFirstProduct={index === firstProductIndex} />
        ))}
      </div>
      <Container>
        <MenuNavigationLinks navigation={navigation} />
        <MenuDrawerInternationalisation localisation={localisation} />
        <SocialIcons globals={globals} />
      </Container>
    </div>
  );
}

export default MenuDrawerNavigation;
