import { ProductOptionInterface } from '@qlevr/shared/interfaces';
import { ProductOptionShopify } from '@qlevr/shared/schema';
import { escapeUrl, getDefaultLocaleProductOptionName } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';

export function productOptionMapper(options: ProductOptionShopify[]): ProductOptionInterface[] | null {
  if (!options || isEmpty(options)) {
    return null;
  }

  return options.map(option => {    
    return {
      id: option.id,
      name: getDefaultLocaleProductOptionName(escapeUrl(option.name).toLowerCase()),
      values: mapOptionValues(option.values),
    };
  });
}

export function mapOptionValues(values: string[]): string[] {
  return values.map(value => escapeUrl(value));
}