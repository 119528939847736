import { PAGE_TYPES } from '@qlevr/shared/constants';
import {
  CollectionPageInterface,
  CollectionStoryInterface,
  FetchStoryblokStoryInteface,
  MetaInterface,
  PathInterface,
} from '@qlevr/shared/interfaces';
import { collectionPageMapper } from '@qlevr/shared/mappers';
import { CollectionShopify, CountryCodeShopify, LanguageCodeShopify } from '@qlevr/shared/schema';
import {
  getCountryCodeShopify,
  getCountryFromCountryLocale,
  getLanguageCodeShopify,
  getLocaleFromCountryLocale,
} from '@qlevr/shared/utilities';
import { notFound } from 'next/navigation';
import { getMeta } from '../meta';
import { ShopifyService } from '../shopify/shopify-service';
import { TryFetchCollectionBySlug } from '../try-collection-by-slug';
import { fetchLocalisedCollectionSlugs } from './localised-collection-slugs';

export class FetchCollectionBySlug {
  private params: FetchStoryblokStoryInteface = { path: '', locale: '', preview: false, cv: '' };
  private country: CountryCodeShopify;
  private language: LanguageCodeShopify;

  constructor(slug: string, locale: string, preview: boolean, cv: string) {
    this.params.path = slug;
    this.params.preview = preview;
    this.params.locale = locale;
    this.params.cv = cv;
    this.country = getCountryCodeShopify(getCountryFromCountryLocale(this.params.locale));
    this.language = getLanguageCodeShopify(getLocaleFromCountryLocale(this.params.locale));
  }

  public async request(): Promise<CollectionPageInterface> {
    const shopifyResponse = await ShopifyService({ tags: [this.params.path]}).getCollectionByHandle({
      country: this.country,
      language: this.language,
      handle: this.params.path,
    });

    const isCollectionError = shopifyResponse.collection === null;
    if (isCollectionError) {
      console.error('Collection not found', this.params.path);
      return notFound();
    }

    const collectionId = shopifyResponse.collection?.id;

    /**
     * Get translated slugs to switch between languages
     */
    const localisation = await fetchLocalisedCollectionSlugs(this.params.locale, collectionId);

    /**
     * We need to check if there is a page in Storyblok for the current slug.
     */
    const storyblokPageRes = await new TryFetchCollectionBySlug(
      PAGE_TYPES.collections,
      this.params.path,
      this.params.locale,
      this.params.preview,
      this.params.cv,
    ).request();

    /*
     * If the response is notFound, there is no page in Storyblok
     * for the current slug. In this case, we build the page with the
     * data from Shopify only.
     */
    const hasNoStoryblokPage = 'notFound' in storyblokPageRes && storyblokPageRes.notFound;

    if (hasNoStoryblokPage) {
      const meta = await getMeta(this.params.locale, this.params.preview);
      return this.mapper(shopifyResponse.collection as CollectionShopify, meta, this.params.locale, localisation);
    }

    /**
     * We have a page in Storyblok for the current slug.
     * We need to get the linked data and the meta data
     */
    return this.mapper(
      shopifyResponse.collection as CollectionShopify,
      (storyblokPageRes as CollectionStoryInterface).meta,
      this.params.locale,
      localisation,
      storyblokPageRes as CollectionStoryInterface,
    );
  }

  private mapper(
    collection: CollectionShopify,
    meta: MetaInterface,
    locale: string,
    localisation: PathInterface[] | null,
    data?: CollectionStoryInterface,
  ) {
    return collectionPageMapper(collection, meta, locale, localisation, data);
  }
}
