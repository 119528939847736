import { CategoryInterface, LinkedMetaDataInterface, MetaInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import { FetchCategories } from './category';

export async function getMetaCategories(
  locale: string,
  preview: boolean,
  meta: MetaInterface,
): Promise<CategoryInterface[] | null> {
  const category = await new FetchCategories(locale, preview).request(meta);

  if (!category || isEmpty(category)) {
    return null;
  }

  return category;
}