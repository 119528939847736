import { LinkedMetaDataInterface, MetaInterface, MetaRelsInterface } from '@qlevr/shared/interfaces';
import { getGlobalLinkedData, mapEmptyCartProduct, mapNavigationProduct } from '@qlevr/shared/mappers';
import { getGlobals } from './globals';
import { getMetaBlog } from './meta-blog';
import { getMetaCategories } from './meta-categories';
import { getMetaCollectionShopifyData } from './meta-collection';
import { getMetaCollectionProductReview } from './meta-collection-product-review';
import { getMetaInstagram } from './meta-instagram';
import { getMetaProductShopifyData } from './meta-product';
import { getMetaReview as getMetaProductReview, getMetaReview } from './meta-review';

export async function getMeta(
  locale: string,
  preview: boolean,
  rels?: MetaRelsInterface,
  linkedData?: LinkedMetaDataInterface,
  filter?: string,
): Promise<MetaInterface> {
  /** Create temporarily meta object, so the different getMeta{PageType} can use it to find related_relations in the rels */
  const meta = {
    rels,
  };

  const globals = await getGlobals(locale, preview);
  getGlobalLinkedData(globals, linkedData);

  // Initiate all fetch operations in parallel
  const [blog, products, collections, instagram, categories] = await Promise.all([
    getMetaBlog(locale, preview, linkedData, meta, filter),
    getMetaProductShopifyData(linkedData, locale),
    getMetaCollectionShopifyData(linkedData, locale),
    getMetaInstagram(linkedData),
    getMetaCategories(locale, preview, meta),
    ]);
    
  await getMetaProductReview(linkedData, products, locale);
  await getMetaReview(linkedData, products, locale);
  mapNavigationProduct(globals, products);
  mapEmptyCartProduct(globals, products);

  return {
    globals,
    blog,
    products,
    collections: await getMetaCollectionProductReview(linkedData, collections, locale),
    rels,
    instagram,
    categories,
  };
}
