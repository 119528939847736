import { CarouselInterface, ContentImageScrollCarouselInterface } from '@qlevr/shared/interfaces';
import { CarouselStoryblok, ImageScrollCarouselStoryblok } from '@qlevr/shared/schema';
import { carouselStoryblokMapper } from '../carousel-text/carousel-text';
import { assetStoryblokMapper } from '../asset/asset-storyblok';
import { sectionMapper } from '../section/section';

export function mapImageScrollCarouselContent(
  content: ImageScrollCarouselStoryblok
): ContentImageScrollCarouselInterface {
  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    text: content.text,
    description: content.description ?? null,
    images: carouselMapper(content.carousel ?? []),
    section: sectionMapper(content.section?.[0]),
  };
}

function carouselMapper(carousels: CarouselStoryblok[]): CarouselInterface[] {
  return carousels.flatMap((carousel) => {
    const carouselContent = carouselStoryblokMapper(carousel.text ?? null, carousel.caption ?? null, assetStoryblokMapper(carousel.image));
    return carouselContent ? [carouselContent as unknown as CarouselInterface] : [];
  });
}
