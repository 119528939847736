import { useTranslations } from 'next-intl';
import IconRenderer from '../icons/icon-renderer.component';
import { ICONS } from '@qlevr/shared/constants';

interface ProductShippingDateProps {
  className?: string;
  iconClassName?: string;
  shippingDate: string;
}

export default function ProductShippingDate({ shippingDate, className, iconClassName }: ProductShippingDateProps) {
  const t = useTranslations();
  return (
    <div className={className}>
      <span>{t('pdp.shippingDate', { shippingDate })}</span>
      <IconRenderer icon={ICONS.shippingPackage} className={iconClassName ? iconClassName : 'h-3'} />
    </div>
  );
}
