import { ContentGuidedBuyingFlowInterface, ProductCombinationInterface, ProductInterface } from '@qlevr/shared/interfaces';
import ProductBuy from './product-buy';
import ProductOutOfStock from './product-out-of-stock';

/* eslint-disable-next-line */
export interface ProductStatusProps {
  product: ProductInterface;
  combination: ProductCombinationInterface | null;
  guidedBuyingFlow: ContentGuidedBuyingFlowInterface | null;
}

export function ProductStatus({ product, combination, guidedBuyingFlow }: ProductStatusProps) {
  if (!combination || (!combination.availableForSale && combination.inventoryQuantity <= 0)) {
    return <ProductOutOfStock combination={combination} />;
  }

  return (
    <ProductBuy
      combination={combination}
      showStickyAddToCart={true}
      product={product}
      variantId={combination?.id}
      isProductPage={true}
      guidedBuyingFlow={guidedBuyingFlow}
    />
  );
}

export default ProductStatus;
