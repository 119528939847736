import { CartLineItemInterface } from '@qlevr/shared/interfaces';

interface isLineItemUniqueProps {
  lineItems: CartLineItemInterface[] | undefined;
  productId: string;
}

export function isLineItemUnique({ lineItems, productId }: isLineItemUniqueProps) {
  if (!lineItems) {
    return false;
  }
  // Check if the line item only exists once in the cart
  const lineItemOccurrences = lineItems.filter((item) => item.productId === productId).length;
  if (lineItemOccurrences === 1) {
    return true;
  }

  return false;
}
