const light = {
  background: 't-light-bg',
  badge: 't-light-badge',
  progressBar: {
    background: 't-light-progress-bar-bg',
    progress: 't-light-progress-bar-progress',
  },
  card: {
    background: 't-light-card-bg',
    body: {
      background: 't-light-card-body-bg',
      text: 't-light-card-body-text',
    },
  },
  carousel: 'carousel-light',
  color: {
    heading: 't-light-color-heading',
    text: 't-light-color-text',
  },
  deliveryNote: {
    background: 't-light-delivery-note-bg',
    iconColor: 't-light-delivery-note-icon-color',
  },
  editor: 'editor',
  input: {
    placeholderColor: 't-light-input-placeholder',
    border: 't-light-input-border-color',
  },
  rating: 't-light-rating',
  variantHighlight: 't-light-variant-highlight',
};

const cold = {
  background: 't-cold-bg',
  badge: 't-cold-badge',
  progressBar: {
    background: 't-cold-progress-bar-bg',
    progress: 't-cold-progress-bar-progress',
  },
  card: {
    background: 't-cold-card-bg',
    body: {
      background: 't-cold-card-body-bg',
      text: 't-cold-card-body-text',
    },
  },
  carousel: 'carousel-cold',
  color: {
    heading: 't-cold-color-heading',
    text: 't-cold-color-text',
  },
  deliveryNote: {
    background: 't-cold-delivery-note-bg',
    text: 't-cold-delivery-note-text',
    iconColor: 't-cold-delivery-note-icon-color',
  },
  editor: 'editor editor-dark',
  input: {
    placeholderColor: 't-cold-input-placeholder',
    border: 't-cold-input-border-color',
  },
  rating: 't-cold-rating',
  variantHighlight: 't-cold-variant-highlight',
};

const warm = {
  background: 't-warm-bg',
  badge: 't-warm-badge',
  progressBar: {
    background: 't-warm-progress-bar-bg',
    progress: 't-warm-progress-bar-progress',
  },
  card: {
    background: 't-warm-card-bg',
    body: {
      background: 't-warm-card-body-bg',
      text: 't-warm-card-body-text',
    },
  },
  carousel: 'carousel-warm',
  color: {
    heading: 't-warm-color-heading',
    text: 't-warm-color-text',
  },
  deliveryNote: {
    background: 't-warm-delivery-note-bg',
    text: 't-warm-delivery-note-text',
    iconColor: 't-warm-delivery-note-icon-color',
  },
  editor: 'editor',
  input: {
    placeholderColor: 't-warm-input-placeholder',
    border: 't-warm-input-border-color',
  },
  rating: 't-warm-rating',
  variantHighlight: 't-warm-variant-highlight',
};

const dark = {
  background: 't-dark-bg',
  badge: 't-dark-badge',
  progressBar: {
    background: 't-dark-progress-bar-bg',
    progress: 't-dark-progress-bar-progress',
  },
  card: {
    background: 't-dark-card-bg',
    body: {
      background: 't-dark-card-body-bg',
      text: 't-dark-card-body-text',
    },
  },
  carousel: 'carousel-dark',
  color: {
    heading: 't-dark-color-heading',
    text: 't-dark-color-text',
  },
  deliveryNote: {
    background: 't-dark-delivery-note-bg',
    text: 't-dark-delivery-note-text',
    iconColor: 't-dark-delivery-note-icon-color',
  },
  editor: 'editor editor-dark',
  input: {
    placeholderColor: 't-dark-input-placeholder',
    border: 't-dark-input-border-color',
  },
  rating: 't-dark-rating',
  variantHighlight: 't-dark-variant-highlight',
};

export const COLOR_THEMES = {
  light: light,
  slate: cold,
  cold: cold,
  orange: warm,
  warm: warm,
  slatedark: dark,
  dark: dark,
} as const;

type ObjectValues<T> = T[keyof T];
export type ColorThemeType = ObjectValues<typeof COLOR_THEMES>;
