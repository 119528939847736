import { SitemapInterface } from '@qlevr/shared/interfaces';
import { GetCollectionPathsQueryShopify } from '@qlevr/shared/schema';
import { imageShopifyMapper } from '../image/image-shopify';
import { RATIOS } from '@qlevr/shared/constants';

export function collectionSitemapMapper(
  collections: GetCollectionPathsQueryShopify['collections'],
  locale: string,
): SitemapInterface[] {
  return collections.edges.reduce<SitemapInterface[]>((acc, collection) => {
    const { node } = collection;

    const image = imageShopifyMapper(node.image?.url, { ratio: RATIOS.DEFAULT, columnConfig: { xs: 1 } });
    const brand = node.metafields.find((metafield) => metafield?.key === 'brand')?.value;
    const hideInSitemap = node.metafields.find((metafield) => metafield?.key === 'hide_in_sitemap')?.value === 'true';

    if (brand === process.env['NEXT_PUBLIC_BRAND_NAME'] && !hideInSitemap) {
      acc.push({
        slug: node.handle,
        locale,
        updatedAt: node.updatedAt,
        ...(image?.lg.url ? { image: image.lg.url } : {}),
      });
    }

    return acc;
  }, []);
}
