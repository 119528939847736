import { RATIOS, RatioType } from '@qlevr/shared/constants';
import { isRatioType } from '@qlevr/shared/utilities';

const ratioMapping = new Map<string, RatioType>([
  ['ratio_4x5', RATIOS.ratio_4x5],
  ['ratio_5x4', RATIOS.ratio_5x4],
  ['ratio_1x1', RATIOS.ratio_1x1],
  ['ratio_9x16', RATIOS.ratio_9x16],
  ['ratio_3x2', RATIOS.ratio_3x2],
  ['ratio_16x9', RATIOS.ratio_16x9],
  ['ratio_2x1', RATIOS.ratio_2x1],
  ['ratio_3x1', RATIOS.ratio_3x1],
  ['ratio_4x1', RATIOS.ratio_4x1],
  ['ratio_3x4', RATIOS.ratio_3x4],
]);

export function ratioMapper(block: string | number | RatioType | undefined): RatioType | null {
  if (!block) return null;

  if(isRatioType(block)) {
    return block;
  }

  const ratio = ratioMapping.get(block.toString());
  if (!ratio) {
    throw new Error(`Ratio of type ${block} is missing a ratioMapper.`);
  }

  return ratio;
}
