import { ContentTextImageInterface } from '@qlevr/shared/interfaces';
import { TextImageStoryblok } from '@qlevr/shared/schema';
import { imageStoryblokMapper } from '../image/image-storyblok';
import { imageFrameTypeMapper } from '../image/image-frame-type';
import { textImageLayoutMapper } from '../layout/layout';
import { linksMapper } from '../link/link';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';

export function mapTextImageContent(content: TextImageStoryblok): ContentTextImageInterface | null {
  const image = imageStoryblokMapper(content.image?.[0], {
    xs: 1,
    lg: 2,
  });

  if(!image) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    text: mapRichText(content.text),
    rating: content.rating ?? false,
    container: content.container ?? false,
    layout: textImageLayoutMapper(content.layout),
    image,
    imageFrame: imageFrameTypeMapper(content.imageFrame),
    cta: linksMapper(content.cta),
    section: sectionMapper(content.section?.[0]),
  };
}
