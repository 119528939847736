import { ICONS } from '@qlevr/shared/constants';
import { ProductInterface } from '@qlevr/shared/interfaces';
import IconRenderer from '../icons/icon-renderer.component';

interface ProductBundlePromotionProps {
  product?: ProductInterface;
}

export function ProductBundlePromotion({ product }: ProductBundlePromotionProps) {
  if (!product?.bundlePromotion) {
    return null;
  }

  return (
    <div className="flex justify-center items-center text-orange-500 text-xs gap-1">
      {product.bundlePromotion} <IconRenderer icon={ICONS.shippingPackage} className="w-3 h-3" />
    </div>
  );
}
