import { MetaobjectFieldShopify } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';

export function getMetafieldsByKey(key: string, fields: MetaobjectFieldShopify[]): string | null {
  if (!fields || isEmpty(fields)) {
    return null;
  }

  const field = fields.find((item) => item.key === key);

  if (!field?.value) {
    return null;
  }

  return field.value ?? null;
}
