import { MetaobjectFieldShopify } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';

export function getMetafieldReferenceVideoField(key: string, fields: MetaobjectFieldShopify[]): string | null {
  if (!fields || isEmpty(fields)) {
    return null;
  }
  const field = fields.find((item) => item.key === key);

  if (!field?.reference) {
    return null;
  }

  if (!('sources' in field.reference) || field.reference.sources?.[0]?.url === undefined) {
    return null;
  }

  return field.reference.sources?.[0]?.url;
}
