'use client';
import { ContentCodeInterface } from '@qlevr/shared/interfaces';
import parse from 'node-html-parser';
import { useEffect, useRef } from 'react';
import Container from '../container/container';
import Section from '../section/section';

export interface ContentCodeProps extends ContentCodeInterface {}

export function ContentCode(props: ContentCodeProps) {
  const BlockContainer = props.container ? Container : 'div';
  const theme = props.section.theme;
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const root = parse(props.code);
    const scriptTags = root.querySelectorAll('script').map((script) => {
      const attributes = script.attributes;
      const innerContent = script.innerHTML;
      return { attributes, innerContent };
    });

    // Remove script tags from the original HTML
    root.querySelectorAll('script').forEach((script) => script.remove());

    const remainingHtml = root.toString();
    const currentDiv = divRef.current;

    if (currentDiv) {
      currentDiv.innerHTML = remainingHtml;
    }

    scriptTags.forEach(({ attributes, innerContent }) => {
      const script = document.createElement('script');
      Object.entries(attributes).forEach(([key, value]) => {
        script.setAttribute(key, value);
      });

      if (innerContent) {
        script.innerHTML = innerContent;
      }

      currentDiv?.appendChild(script);
    });

    // Clean up script when component unmounts or before re-render
    return () => {
      if (currentDiv) {
        currentDiv.innerHTML = '';
      }
    };
  }, [props.code]);

  return (
    <Section {...props.section}>
      <BlockContainer>
        <div ref={divRef} className={`text-editor ${theme.color.text} ${theme.editor}`} />
      </BlockContainer>
    </Section>
  );
}

export default ContentCode;
