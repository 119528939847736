import { ProductInterface } from '@qlevr/shared/interfaces';
import { ProductBundlerContext } from '@qlevr/shared/providers';
import { reverseEscapeUrl } from '@qlevr/shared/utilities';
import { useContext } from 'react';

export interface OptionButtonProps {
  product: ProductInterface | null;
  value: string;
  isActive: boolean;
  isAvailableForSale: boolean;
}

export function ProductBundlerOptionButton({ product, value, isActive, isAvailableForSale }: OptionButtonProps) {
  const productBundlerContext = useContext(ProductBundlerContext);

  // Helpers to determine if the option is a color or size
  const productColor = product?.colors?.find((color) => color.key === value);
  const productSize = product?.sizes?.find((size) => size.key === value);
  let className = 'group relative flex items-end justify-center min-w-8 min-h-8 transition duration-300 ease-in-out';
  let imageClassName = '';

  // isActive class application logic
  if (isActive) {
    className += ' is-active';
  }

  // slashCss class for out-of-stock products, applied only when it's a color option
  if (!isAvailableForSale) {
    imageClassName = 'opacity-50';
  }

  // Modified handleClick function to allow clicking regardless of availability
  const handleClick = () => {
    if (product) {
      productBundlerContext.selectedProductVariantOptions(product, productSize?.key, productColor?.key);
    }
  };

  const getOption = () => productColor || productSize;

  return (
    <button
      onClick={handleClick}
      className={`${className}`}
      aria-disabled={!isAvailableForSale}
      disabled={!isAvailableForSale}
      title={`${value}${!isAvailableForSale ? ' (Out of Stock)' : ''}`}
    >
      {getOption()?.value ? (
        <img
          className={`absolute top-0 mx-auto h-10 w-10 rounded-full lg:h-8 lg:w-8 ${imageClassName} ${isActive ? 'group-[.is-active]:border-2 group-[.is-active]:border-black group-[.is-active]:shadow-md' : ''}`}
          src={getOption()?.value ?? ''}
          alt={getOption()?.key}
        />
      ) : (
        <div
          className={`rounded-full border-2 border-slate-200 px-5 py-2 text-slate-600 ${imageClassName} ${isActive ? 'group-[.is-active]:border-blue group-[.is-active]:border-azure-500 group-[.is-active]:bg-azure-100 group-[.is-active]:text-night-800 group-[.is-active]:shadow-md' : ''}`}
        >
          {reverseEscapeUrl(getOption()?.key ?? null)}
        </div>
      )}
    </button>
  );
}

export default ProductBundlerOptionButton;
