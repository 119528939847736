import { Maybe, MetafieldShopify } from '@qlevr/shared/schema';

export function getMetafieldByKey(key: string, metafield: Maybe<MetafieldShopify>): string | null {
  if (!metafield) {
    return null;
  }

  if (metafield.key !== key) {
    return null;
  }

  return metafield.value ?? null;
}
