import { collectionSitemapMapper } from '@qlevr/shared/mappers';
import { getLanguageCodeShopify, getLocaleFromCountryLocale } from '@qlevr/shared/utilities';
import { notFound } from 'next/navigation';
import { ShopifyService } from './shopify-service';

export const fetchCollectionSitemap = async (locale: string) => {
  const res = await ShopifyService({ }).getCollectionPaths({
    first: 100,
    language: getLanguageCodeShopify(getLocaleFromCountryLocale(locale)),
  });

  const isError = res.collections === null;
  if (isError) {
    console.error('Collection sitemap not found');
    return notFound();
  }

  return collectionSitemapMapper(res.collections, locale);
};
