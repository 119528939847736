'use client';

import React, { createContext, useState } from 'react';

interface NewsletterSubscribeProviderProps {
  children: React.ReactNode;
}

interface NewsletterSubscribeContextType {
  isOpen: boolean;
  toggle: () => void;
  open: () => void;
  close: () => void;
}

export const NewsletterSubscribeContext = createContext<NewsletterSubscribeContextType>({
  isOpen: false,
  toggle: () => {},
  open: () => {},
  close: () => {},
});

export const NewsletterSubscribeProvider: React.FC<NewsletterSubscribeProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((prevSate) => !prevSate);
  };
  const open = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
  };

  return <NewsletterSubscribeContext.Provider value={{ isOpen, toggle, open, close }}>{children}</NewsletterSubscribeContext.Provider>;
};
