import { ColorThemeType } from '@qlevr/shared/constants';
import { NavigationItemInterface, TextImageCardInterface } from '@qlevr/shared/interfaces';
import { MenuStoryblok, ShopifyProductStoryblok, TextImageCardStoryblok } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { mapTextImageCard } from '../card/text-image-card-mapper';
import { linkMapper } from '../link/link';
import { themeMapper } from '../theme/theme';

/**
 *
 * IMPORTANT
 * Part of the data will be mapped once we have received additional data from Shopify.
 * @see mapNavigationProduct()
 */
export function mapNavigationItem(navigation?: MenuStoryblok[]): NavigationItemInterface[] {
  if (!navigation) {
    return [];
  }

  return (
    navigation?.reduce((acc, item) => {
      const link = linkMapper(item?.link?.[0]);
      const theme = themeMapper(item.theme?.toString())

      if (link) {
        acc.push({
          link,
          featuredProductId: mapFeaturedProductId(item?.['featuredProduct'].items?.[0]),
          featuredProductVariantKey: mapFeaturedVariantKey(item.featuredProductVariantKey),
          featuredCard: mapFeaturedCard(item.textImageCard?.[0], theme),
          featuredProductCtaLabel: item.featuredProductCtaLabel ?? '',
          products: null,
          productIds: mapProductIds(item?.['products'].items),
          theme,
        });
      }

      return acc;
    }, [] as NavigationItemInterface[]) ?? []
  );
}

export function mapProductIds(products: ShopifyProductStoryblok[]): string[] {
  if (!products || isEmpty(products)) {
    return [];
  }

  return products.flatMap((product: ShopifyProductStoryblok) => product.id) ?? []
}

export function mapFeaturedCard(textImageCard: TextImageCardStoryblok | undefined, theme: ColorThemeType): TextImageCardInterface | null {
  if(!textImageCard) {
    return null
  }

  return mapTextImageCard(textImageCard, theme);
}

export function mapFeaturedProductId(product: ShopifyProductStoryblok): string | null {
  if (!product?.id) {
    return null;
  }

  return product.id
}

export function mapFeaturedVariantKey(variantKey?: string): string | null {
  if (!variantKey || isEmpty(variantKey)) {
    return null;
  }

  return variantKey;
}
