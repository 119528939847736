import { NavigationItemInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import MenuProducts from '../menu/menu-product';
import MenuDrawerFeaturedCard from './menu-drawer-featured-card';
import Container from '../container/container';

export interface NavigationItemProps extends NavigationItemInterface {
  isFirstProduct?: boolean;
}

export function MenuDrawerNavigationItem({ featuredCard, products, theme, isFirstProduct }: NavigationItemProps) {
  const hasMenu = featuredCard || (!isEmpty(products) && isFirstProduct);

  return (
    <div className="empty:hidden">
      {hasMenu && (
        <div className="flex flex-col gap-3 pt-5">
          {featuredCard && (
            <Container>
              <MenuDrawerFeaturedCard {...featuredCard} theme={theme} />
            </Container>
          )}
          {isFirstProduct && products && (
            <MenuProducts products={products} hasFeaturedCard={!!featuredCard} theme={theme} />
          )}
        </div>
      )}
    </div>
  );
}

export default MenuDrawerNavigationItem;
