import { ContentInstagramInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { InstagramStoryblok } from '@qlevr/shared/schema';
import { sectionMapper } from '../section/section';

export function mapInstagramFeed(content: InstagramStoryblok, meta: MetaInterface): ContentInstagramInterface | null {
  if (!meta.instagram?.items) {
    return null;
  }

  return {
    _uid: 'instagram',
    component: 'instagram',
    title: meta.instagram.title ?? null,
    description: meta.instagram.description ?? null,
    items: meta.instagram.items,
    section: sectionMapper(content.section?.[0]),
  };
}
