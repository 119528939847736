import { CartLineItemInterface } from '@qlevr/shared/interfaces';
import CartLineItem from './cart-line-item';
import isEmpty from 'lodash.isempty';

/* eslint-disable-next-line */
export interface CartLineItemsProps {
  lineItems?: CartLineItemInterface[];
}

export function CartLineItems({ lineItems }: CartLineItemsProps) {
  if (!lineItems || isEmpty(lineItems)) {
    return null;
  }

  return (
    <>
      {lineItems.map((lineItem, key) => (
        <CartLineItem key={key} {...lineItem} lineItems={lineItems} />
      ))}
    </>
  );
}

export default CartLineItems;
