/**
 * Retrieves the discount value from a product tag.
 * @param tag - The product tag to extract the discount from.
 * @returns The discount value if found, otherwise null.
 */
// discount:instant-travel-cot:10 => 10

export function getProductDiscountFromTags(tag: string): number | null {
  const parts = tag.split(':');
  if (parts[0] !== 'discount') {
    return null;
  }

  const discount = parseInt(parts[2], 10);
  if (isNaN(discount)) {
    return null;
  }
  
  return discount;  
}
