import { CategoryInterface } from '@qlevr/shared/interfaces';
import { FetchStoryblokStoriesService } from './storyblok/fetch-storyblok-stories';
import { StoryblokStory } from 'storyblok-generate-ts';
import { CategoryStoryblok } from '@qlevr/shared/schema';
import { PAGE_TYPES } from '@qlevr/shared/constants';
import { categoriesMapper } from '@qlevr/shared/mappers';

export class FetchCategories extends FetchStoryblokStoriesService<
  CategoryInterface,
  StoryblokStory<CategoryStoryblok>
> {
  constructor(locale: string, preview: boolean) {
    super({
      pageType: PAGE_TYPES.category,
      locale,
      preview,
    });
  }

  protected override mapper(data: StoryblokStory<CategoryStoryblok>[]): CategoryInterface[] {
    return categoriesMapper(data);
  }
}
