/* eslint-disable @typescript-eslint/no-explicit-any */
import { RATIOS } from '@qlevr/shared/constants';
import {
  CartAutomaticDiscountInterface,
  CartCostInterface,
  CartFreeShippingInterface,
  CartInterface,
  CartLineItemInterface,
  CartUpsellProducts,
  EmptyCartInterface,
  KeyValueInterface,
} from '@qlevr/shared/interfaces';
import {
  CartAutomaticDiscountAllocationShopify,
  EmptyCartStoryblok,
  GetCartQueryShopify,
  ShopifyProductStoryblok,
} from '@qlevr/shared/schema';
import { calculateCartSavings, formattedShippingDate } from '@qlevr/shared/utilities';
import { mapFeaturedCard } from '../global/navigation';
import { imageShopifyMapper } from '../image/image-shopify';
import { getFreeShippingThreshold } from '../metafield/get-free-shipping-threshold-metafield-by-region';
import { getProductSlug } from '../products/product-slug';
import { productVariantOptionMapper } from '../products/product-variant';
import { productVariantPriceMapper } from '../products/product-variant-price';
import { mapRichText } from '../rich-text/rich-text';
import { themeMapper } from '../theme/theme';
import { cartAnalyticsMapper } from './cart-analytics';
import { getMetafieldByKey } from '../metafield/get-metafield-by-key';

export function cartMapper(
  cart: GetCartQueryShopify['cart'] | undefined,
  cartUpsellProducts: CartUpsellProducts[] | null,
  metaobjectFreeShipping?: GetCartQueryShopify['metaobject'] | undefined,
): CartInterface {
  if (!cart) {
    throw new Error('No cart to map');
  }

  const items = cart?.lines?.edges.reduce(mapCartItem, [] as CartLineItemInterface[]);
  const cartPendingStatus = items.length > 0 ? false : true;

  return {
    id: cart.id,
    attributes: cart.attributes.map((attr) => ({ ...attr, value: attr.value || '' })) || [],
    checkoutUrl: cart.checkoutUrl,
    cost: mapCartCost(cart),
    totalQuantity: cart.totalQuantity,
    items: items,
    savings: calculateCartSavings(items, cart.cost.subtotalAmount.amount),
    freeShipping: mapFreeShipping(cart, metaobjectFreeShipping),
    upsellProducts: cartUpsellProducts,
    analytics: cartAnalyticsMapper(cart, items),
    pending: cartPendingStatus,
  };
}

function getAttributeByKey(attributes: KeyValueInterface[] | undefined, key: string): string | null {
  const attribute = attributes?.find((attr) => attr.key === key);
  return attribute ? attribute.value : null;
}

// @todo fix typing
export function mapCartItem(acc: CartLineItemInterface[], cartItem: any): CartLineItemInterface[] {
  if (!cartItem) {
    return acc;
  }

  const shippingDate = formattedShippingDate(getAttributeByKey(cartItem.node.attributes, '_shippingDate') ?? undefined);

  const item: CartLineItemInterface = {
    id: cartItem.node.id,
    sku: cartItem.node.merchandise?.sku,
    slug: getProductSlug(cartItem.node.merchandise.product.handle),
    title: cartItem.node.merchandise?.product?.title,
    featuredImage: imageShopifyMapper(cartItem.node.merchandise?.image.url, {
      ratio: RATIOS.DEFAULT,
      altText: cartItem.node.merchandise?.image.altText,
      columnConfig: { xs: 8, lg: 10 },
    }),
    price: productVariantPriceMapper(cartItem.node.cost.totalAmount),
    compareAtPrice: productVariantPriceMapper(cartItem.node.cost.amountPerQuantity),
    variantCompareAtAmountPerQuantity: productVariantPriceMapper(cartItem.node.cost.compareAtAmountPerQuantity),
    currencyCode: cartItem.node.cost.totalAmount.currencyCode,
    quantity: cartItem.node.quantity,
    variantId: cartItem.node.merchandise?.id,
    productId: cartItem.node.merchandise?.product?.id,
    productBundlePromotion:
      getMetafieldByKey('bundle_promotion', cartItem.node.merchandise?.product?.metafields?.[0]) ?? null,
    options: productVariantOptionMapper(cartItem.node.merchandise.selectedOptions),
    discounts: getCartAutomaticDiscountTitle(cartItem.node.discountAllocations),
    shippingDate: shippingDate,
    productTags: cartItem.node.merchandise?.product?.tags,
    vendor: cartItem.node.merchandise?.product?.vendor || null,
    productType: cartItem.node.merchandise?.product?.productType || null,
  };

  acc.push(item);
  return acc;
}

export function mapCartCost(cart: GetCartQueryShopify['cart']): CartCostInterface {
  if (!cart?.cost) {
    return {
      subtotalAmount: null,
      totalAmount: null,
      totalTaxAmount: null,
      currencyCode: cart?.cost?.subtotalAmount?.currencyCode || 'EUR',
    };
  }

  return {
    subtotalAmount: productVariantPriceMapper(cart.cost.subtotalAmount),
    totalAmount: productVariantPriceMapper(cart.cost.totalAmount),
    currencyCode: cart.cost.totalAmount.currencyCode,
    totalTaxAmount: cart.cost.totalTaxAmount ? productVariantPriceMapper(cart.cost.totalTaxAmount) : null,
  };
}

function mapFreeShipping(
  cart: GetCartQueryShopify['cart'],
  metaobjectFreeShipping: GetCartQueryShopify['metaobject'] | null,
): CartFreeShippingInterface | null {
  const freeShippingThreshold = getFreeShippingThreshold(metaobjectFreeShipping);

  if (!freeShippingThreshold) {
    return null;
  }

  if (!cart) {
    return {
      threshold: freeShippingThreshold,
    };
  }

  return {
    threshold: freeShippingThreshold,
  };
}

export function mapEmptyCart(content: EmptyCartStoryblok | undefined): EmptyCartInterface | null {
  if (!content) {
    return null;
  }

  const theme = themeMapper(content.theme.toString());

  return {
    title: content.cartEmptyTitle || null,
    text: mapRichText(content.cartEmptyText),
    emptyCartProduct: mapFeaturedCard(content.cartEmptyProduct?.[0], theme),
    emptyCartProductId: (content['product'].items?.[0] as ShopifyProductStoryblok)?.id ?? null,
    theme,
  };
}

export function getCartAutomaticDiscountTitle(
  discounts: CartAutomaticDiscountAllocationShopify[] | undefined,
): CartAutomaticDiscountInterface[] | null {
  if (!discounts) {
    return null;
  }

  return discounts.map((discount) => {
    return {
      title: discount.title,
    };
  });
}

export function mapCartShippingRate(shippingRate: string | undefined): number | null {
  if (!shippingRate) {
    return null;
  }

  return shippingRate ? parseFloat(shippingRate) : null;
}
