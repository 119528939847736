'use client';

import { ColorThemeType } from '@qlevr/shared/constants';
import { GlobalInterface } from '@qlevr/shared/interfaces';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Text from '../text/text';
import Theme from '../theme/theme';

/* eslint-disable-next-line */
export interface BannerProps {
  globals: GlobalInterface | null;
}

export function Banner({ globals }: BannerProps) {
  if (!globals?.banner) {
    return null;
  }

  const swiperThemeMapping = new Map<string, string>([
    ['t-light-bg', 'carousel-light'],
    ['t-warm-bg', 'carousel-light'],
    ['t-cold-bg', 'carousel-cold'],
    ['t-dark-bg', 'carousel-dark'],
  ]);

  return (
    <Theme theme={globals.banner.theme}>
      <div className="px-4 text-center">
        <Swiper
          className={`${swiperThemeMapping.get(globals.banner.theme.background)} swiper-banner-carousel`}
          slidesPerView={1}
          navigation={true}
          modules={[Navigation]}
          loop={false}
        >
          {globals.banner.items.map((item, key) => (
            <SwiperSlide key={key}>
              <Text text={item} theme={globals.banner?.theme as ColorThemeType} className="py-1 text-sm" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Theme>
  );
}

export default Banner;
