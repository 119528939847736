import { ContentTextPictoInterface, ImageInterface } from '@qlevr/shared/interfaces';
import { ImageStoryblok, TextPictoStoryblok } from '@qlevr/shared/schema';
import { assetStoryblokMapper } from '../asset/asset-storyblok';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { pictoSizeMapper } from '../picto-card/picto-size';

export function mapTextPictoContent(content: TextPictoStoryblok): ContentTextPictoInterface | null {
  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: content.title,
    text: mapRichText(content.text),
    images: mapImages(content.images ?? []),
    section: sectionMapper(content.section?.[0]),
    size:  pictoSizeMapper(content.pictoSize ?? ''),
  };
}

function mapImages(images: ImageStoryblok[]): ImageInterface[] {
  const items = [] as ImageInterface[];

  images.reduce((acc, item) => {
    const image = assetStoryblokMapper(item.image);
    if (image) {
      acc.push(image);
    }
    return acc;
  }, items);

  return items;
}
