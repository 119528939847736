import { ImageInterface } from '../image/image';
import { ProductInterface } from '../product/product';
import { KeyValueInterface } from '../product/product-metafields';

type Scalars = {
  Int: { input: number; output: number };
  String: { input: string; output: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  JSON: { input: any; output: any };
};

type FilterValueShopify = {
  __typename?: 'FilterValue';
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  input: Scalars['JSON']['output'];
  label: Scalars['String']['output'];
};

type FilterShopify = {
  __typename?: 'Filter';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  type: FilterTypeShopify;
  values: Array<FilterValueShopify>;
};

enum FilterTypeShopify {
  /** A boolean value. */
  BooleanShopify = 'BOOLEAN',
  /** A list of selectable values. */
  ListShopify = 'LIST',
  /** A range of prices. */
  PriceRangeShopify = 'PRICE_RANGE',
}

export interface CollectionCardInterface {
  id: string;
  slug: string;
  title: string;
  text: string | null;
  image: ImageInterface | null;
  products: ProductInterface[];
  shopifyFilters: FilterShopify[];
  filters: KeyValueInterface[];
  description: string | null;
}
