import { ProductInterface, ProductOptionInterface, SectionInterface } from '@qlevr/shared/interfaces';
import { getCombinationStatus, getFilteredOptionsBySizeAndOrColor } from '@qlevr/shared/utilities';
import OptionButton from './option-button';
import OptionPill from './option-pill';
import { isPillLayout } from './variant-selector';

interface VariantImageProps {
  option: ProductOptionInterface;
  product: ProductInterface;
  searchParams: URLSearchParams;
  section: SectionInterface;
}

export default function VariantImage({ option, product, searchParams, section }: VariantImageProps) {
  return (
    <>
      {option.values.map((value) => {
        const optionNameLowerCase = option.name.toLowerCase();

        const optionSearchParams = new URLSearchParams(searchParams.toString());

        optionSearchParams.set(optionNameLowerCase, value);

        const filtered = getFilteredOptionsBySizeAndOrColor(product.options, {
          size: optionSearchParams.get('size') || '',
          color: optionSearchParams.get('color') || '',
        });

        const { isAvailableForSale, isCurrentlyNotInStock, tag, isPreOrder } = getCombinationStatus({ combinations: product.combinations, filtered });
  
        // The option is active if it's in the url params.
        const isActive = searchParams.get(optionNameLowerCase) === value;

        if (isPillLayout(option, product)) {
          return (
            <OptionPill
              key={value}
              product={product}
              value={value}
              isActive={isActive}
              isAvailableForSale={!!isAvailableForSale}
              currentlyNotInStock={!!isCurrentlyNotInStock}
              tag={tag}
              section={section}
            />
          );
        }
        return (
          <OptionButton
            key={value}
            product={product}
            value={value}
            isActive={isActive}
            isAvailableForSale={!!isAvailableForSale}
            currentlyNotInStock={!!isCurrentlyNotInStock}
            isPreOrder={!!isPreOrder}
            tag={tag}
          />
        );
      })}
    </>
  );
}
