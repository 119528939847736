import { CollectionCardInterface, LinkedMetaDataInterface } from '@qlevr/shared/interfaces';
import { metaCollectionsMapper, shopifyLinkedDataQueryBuilder } from '@qlevr/shared/mappers';
import {
  getCountryCodeShopify,
  getCountryFromCountryLocale,
  getLanguageCodeShopify,
  getLocaleFromCountryLocale,
} from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { shopifyClient } from './shopify/shopify-service';

export async function getMetaCollectionShopifyData(
  linkedData: LinkedMetaDataInterface | undefined,
  locale: string,
): Promise<CollectionCardInterface[] | null> {
  if (!linkedData?.collections || isEmpty(linkedData.collections)) {
    return null;
  }

  const country = getCountryCodeShopify(getCountryFromCountryLocale(locale));
  const language = getLanguageCodeShopify(getLocaleFromCountryLocale(locale));

  const query = shopifyLinkedDataQueryBuilder(country, language, linkedData);
  const response = await shopifyClient({}).request(query);

  if (!response) {
    return null;
  }

  return metaCollectionsMapper(response as any, linkedData);
}
