'use client';

import { BUTTON_SIZE, ICONS, LINK_STYLES } from '@qlevr/shared/constants';
import { GlobalInterface, LinkInterface } from '@qlevr/shared/interfaces';
import AuthFormContainer from '../customer/auth-form-container';
import LinkRenderer from '../link/link-renderer';

/* eslint-disable-next-line */
export interface NavigationAccountProps {
  globals: GlobalInterface | null;
}

export function NavigationAccount(props: NavigationAccountProps) {
  // const { isLoggedIn } = useCart();
  // const url = `${process.env['NEXT_PUBLIC_SHOPIFY_QLEVR_ACCOUNT_URL']}`;
  const isLoggedIn = true;
  const url = `${process.env['NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCOUNT_URL']}`;

  const cta: LinkInterface = {
    as: 'a',
    href: url,
    linkType: 'url',
    label: '',
    size: BUTTON_SIZE.base,
    linkStyle: LINK_STYLES.icon,
    wrapperClassName: 'flex items-center justify-center lg:w-12 lg:h-12',
    leadingIcon: isLoggedIn ? ICONS.account : ICONS.account,
    leadingClassName: 'w-5 h-5',
    'aria-label': 'account',
  };

  if (isLoggedIn) {
    return <LinkRenderer cta={cta} />;
  }

  return <AuthFormContainer globals={props.globals} />;
}

export default NavigationAccount;