export const ICONS = {
  account: 'account',
  accountLoggedIn: 'account-logged-in',
  accordionArrow: 'accordion-arrow',
  accordionClose: 'accordion-close',
  accordionOpen: 'accordion-open',
  alert: 'alert',
  alertCircle: 'alert-circle',
  arrowLeft: 'arrow-left',
  arrowRight: 'arrow-right',
  applePay: 'apple-pay',
  bancontact: 'bancontact',
  cart: 'cart',
  chevronRight: 'chevron-right',
  chevronUp: 'chevron-up',
  chevronDown: 'chevron-down',
  checkmark: 'checkmark',
  close: 'close',
  discount: 'discount',
  facebook: 'facebook',
  filter: 'filter',
  googlePay: 'google-pay',
  hamburger: 'hamburger',
  ideal: 'ideal',
  increaseQuantity: 'increase-quantity',
  decreaseQuantity: 'decrease-quantity',
  instagram: 'instagram',
  imagePlaceholder: 'image-placeholder',
  klarna: 'klarna',
  lock: 'lock',
  mail: 'mail',
  mastercard: 'mastercard',
  minusCircle: 'minus-circle',
  mute: 'mute',
  paypal: 'paypal',
  pause: 'pause',
  play: 'play',
  stretchedPlay: 'stretched-play',
  stretchedPause: 'stretched-pause',
  sepaBankTransfer: 'sepa-bank-transfer',
  plus: 'plus',
  plusCircle: 'plus-circle',
  searchBar: 'search-bar',
  searchClose: 'search-close',
  shippingPackage: 'shipping-package',
  spinner: 'spinner',
  star: 'star',
  unmute: 'unmute',
  visa: 'visa',
} as const;

type ObjectValues<T> = T[keyof T];
export type IconType = ObjectValues<typeof ICONS>;
