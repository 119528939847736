import { BADGE, Link } from '@qlevr/shared/constants';
import { CartLineItemInterface } from '@qlevr/shared/interfaces';
import ImageRenderer from '../../image-renderer/image-renderer';
import ProductShippingDate from '../../product-status/product-shipping-date';
import CartAutomaticDiscount from './cart-automatic-discount';
import CartLineItemOptions from './cart-line-item-options';
import CartLineItemPrice from './cart-line-item-price';
import CartQuantity from './quantity/cart-quantity';
import CartLineItemRemoveForm from './remove/cart-remove-form';
import { isLineItemUnique } from './check-unique-line-item';
import Badge from '../../badge/badge';
import { isTagPresentInOtherLineItems } from './is-tag-present-in-other-line-items';

export interface CartLineItemProps extends CartLineItemInterface {
  lineItems?: CartLineItemInterface[];
}

export function CartLineItem({
  featuredImage,
  id,
  options,
  price,
  compareAtPrice,
  variantCompareAtAmountPerQuantity,
  quantity,
  slug,
  title,
  variantId,
  productId,
  discounts,
  shippingDate,
  productBundlePromotion,
  lineItems = [],
  productTags,
}: CartLineItemProps) {
  const hasTagWithPrefix = productTags?.some((tag) => tag.startsWith('tag-'));
  const tagExistsInOtherItems = productTags?.some(
    (tag) => tag.startsWith('tag-') && isTagPresentInOtherLineItems(tag, lineItems, id),
  );
  const showBundlePromotion =
    isLineItemUnique({ lineItems, productId }) &&
    !!productBundlePromotion &&
    quantity === 1 &&
    hasTagWithPrefix &&
    !tagExistsInOtherItems;

  return (
    <div className="py-2 first:pt-0 last:pb-0">
      <div className="space-y-3">
        <div className="flex justify-between space-x-4">
          <ImageRenderer loadingState="lazy" className="h-20 w-20 rounded-lg" image={featuredImage} />
          <div className="flex-grow space-y-2">
            <div className="relative space-y-1">
              <Link className="after:absolute after:inset-0" href={slug}>
                {title}
              </Link>
              <CartLineItemOptions items={options} />
              <CartAutomaticDiscount discounts={discounts} />
            </div>
            <CartQuantity lineItemId={id} quantity={quantity} variantId={variantId} />
          </div>
          <div className="flex flex-col items-stretch justify-between">
            <CartLineItemRemoveForm lineItemId={id} />
            <CartLineItemPrice
              price={price}
              compareAtPrice={compareAtPrice}
              quantity={quantity}
              variantCompareAtAmountPerQuantity={variantCompareAtAmountPerQuantity}
            />
          </div>
        </div>
        {shippingDate && (
          <ProductShippingDate
            shippingDate={shippingDate}
            className="flex items-center justify-center gap-1 text-center text-sm text-green-500"
          />
        )}
      </div>
      {showBundlePromotion && (
        <Badge
          tags={[productBundlePromotion]}
          theme={BADGE.orange}
          className="mx-auto mt-2 px-3 py-2 text-sm font-semibold"
        />
      )}
    </div>
  );
}

export default CartLineItem;
