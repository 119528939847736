import { MetaInterface, PageInterface } from '@qlevr/shared/interfaces';
import { PageStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';
import { contentBlocksMapper } from '../content-blocks/content-blocks';
import { heroBlocksMapper } from '../hero-blocks/hero-blocks';
import { metatagsMapper } from '../metatags/metatags';
import { pageStructuredDataMapper } from '../structured-data/page-structured-data';
import { mapLocalisedPagePaths } from '../localisation/localised-page-paths';

export function pageMapper(page: StoryblokStory<PageStoryblok>, meta: MetaInterface): PageInterface {
  const hero = heroBlocksMapper(page.name, page.content.hero, meta);
  const localisation = mapLocalisedPagePaths(page.alternates);

  return {
    localisation,
    globals: meta.globals ?? null,
    contentBlocks: contentBlocksMapper(page.content.contentBlocks, meta),
    hero,
    metatags: metatagsMapper(
      page.name,
      page.content['metatags'],
      meta?.globals?.metatags,
      localisation,
      page.full_slug,
    ),
    structuredData: pageStructuredDataMapper(page, meta.globals),
  };
}
