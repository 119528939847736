export const VerticalAlignmentConstant = {
  start: 'items-start',
  center: 'items-center',
  end: 'items-end',
} as const;

type ObjectValues<T> = T[keyof T];
export type VerticalAlignmentType = ObjectValues<typeof VerticalAlignmentConstant>;

export const HorizontalAlignmentConstant = {
  start: 'justify-start',
  center: 'justify-center',
  end: 'justify-end',
} as const;
 
export type HorizontalAlignmentType = ObjectValues<typeof HorizontalAlignmentConstant>;

export const TextAlignmentConstant = {
  left: 'text-left justify-start',
  center: 'text-center justify-center',
} as const;
export type TextAlignmentType = ObjectValues<typeof TextAlignmentConstant>;

export const ContentAlignmentConstant = {
  left: '',
  center: 'mx-auto',
} as const;

export type ContentAlignmentType = ObjectValues<typeof ContentAlignmentConstant>;
