import { LinkInterface } from "@qlevr/shared/interfaces";
import BaseLinkElementRenderer from "./base/base-link-element-renderer";

export interface FilterLinkProps {
  cta: LinkInterface;
}

export function FilterLink(props: FilterLinkProps) {
  return (
    <BaseLinkElementRenderer 
      {...props}
      cta={{
        ...props.cta,
        wrapperClassName: `border-2 border-slate-300 rounded-full font-bold px-4 py-2 font-serif text-sm gap-2 ${props.cta.wrapperClassName}`,
      }}
    />
  )
}