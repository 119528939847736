import { ICONS, LINK_STYLES } from '@qlevr/shared/constants';
import { ProductInterface, ProductRecommendationsInterface } from '@qlevr/shared/interfaces';
import ImageRenderer from '../image-renderer/image-renderer';
import LinkRenderer from '../link/link-renderer';
import ProductBuy from '../product-status/product-buy';
import { calculateDiscountPrice } from '@qlevr/shared/utilities';

export interface ProductRecommendationCardProps {
  product: ProductRecommendationsInterface;
}

export function ProductRecommendationCard({ product }: ProductRecommendationCardProps) {
  const discountPercentage = product.discountPercentage ?? 0;

  return (
    <div className="grid grid-flow-col items-center justify-between gap-3 rounded-lg bg-white p-4 shadow-lg">
      <div className="flex w-full items-center space-x-4">
        {product.featuredImage && (
          <ImageRenderer loadingState="lazy" image={product.featuredImage} className="h-20 w-20" />
        )}
        <div>
          <span className="text-base font-bold">
            <LinkRenderer
              cta={{
                label: `${product.title}`,
                as: 'link',
                href: product.slug,
                linkStyle: LINK_STYLES.textBlack,
                className: `text-base font-bold pb-1`,
              }}
            />
          </span>
          <div className="flex gap-1">
            {discountPercentage > 0 && (
              <span className="t-cart-upsell-card-sale-price line-through">
                {product.priceRange?.minVariantPrice.symbol}
                {product.priceRange?.minVariantPrice.amount}
              </span>
            )}
            <span className="t-cart-upsell-card-price font-bold">
              {product.priceRange?.minVariantPrice.symbol}
              {calculateDiscountPrice(product.priceRange?.minVariantPrice.amount, discountPercentage)}
            </span>
          </div>
        </div>
      </div>
      {product.variants?.[0].id && (
        <ProductBuy
          cta={{
            linkStyle: LINK_STYLES.icon,
            leadingIcon: ICONS.plus,
            leadingClassName: 'w-6 h-6',
            className: '',
            wrapperClassName: 'btn-primary p-2 rounded-full',
          }}
          product={product as ProductInterface}
          variantId={product.variants[0].id}
          isBundleFlowProduct={true}
        />
      )}
    </div>
  );
}

export default ProductRecommendationCard;
