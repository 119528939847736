import { PathInterface } from '@qlevr/shared/interfaces';
import { AlternatesStoryblok } from '@qlevr/shared/schema';
import { getLocaleFromSlug, getLocaleRegionParamByLocale, removeBrandNameFromLink, reorderLocalesUrls } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { addMissingLocalRegionUrls } from './add-missing-local-region-urls';

export function mapLocalisedPagePaths(alternates?: AlternatesStoryblok[]): PathInterface[] | null {
  if (!alternates || isEmpty(alternates)) {
    return null;
  }

  const publishedAlternates = alternates.filter((alternate) => alternate.published);

  if (isEmpty(publishedAlternates)) {
    return null;
  }

  const paths = publishedAlternates.map((alternate) => {
    // 'fr/aeromoov/' => 'fr'
    const locale = getLocaleFromSlug(alternate.full_slug);

    // 'fr' => 'fr-fr'
    // 'nl-be' => 'nl-be'
    const localeRegion = getLocaleRegionParamByLocale(locale);

    // (homepage) fr/aeromoov/ => /
    // (blogpost) fr/aeromoov/blog/10-tips => /blog/10-tips
    const slugWithoutStoryblokFolderPrefix = removeBrandNameFromLink(alternate.full_slug);

    return {
      locale: localeRegion,
      slug: `/${slugWithoutStoryblokFolderPrefix}`,
    }
  });

  // If a "base" language-region pair exists (like `nl-nl`), and its alternate region (like `nl-be`) does not exist, add it.
  const withMissingLocalRegions = addMissingLocalRegionUrls(paths);

  // Ensure that the order of the locales is ocording to the LOCALES_COUNTRIES constant order
  const sorted = reorderLocalesUrls(withMissingLocalRegions);

  return sorted;
}
