import { RATIOS, RatioType } from '@qlevr/shared/constants';
import { ColumnConfigInterface, ImageInterface } from '@qlevr/shared/interfaces';
import { AssetStoryblok } from '@qlevr/shared/schema';
import {
  fillMissingBreakpoints
} from '@qlevr/shared/utilities';
import { ratioMapper } from '../ratio/ratio';

export type assetStoryblokMapperProps = {
  ratio: string | RatioType,
  imageLg?: AssetStoryblok,
  ratioLg?: string | RatioType,
  columnConfig: ColumnConfigInterface,
  container: boolean,
}

export function assetStoryblokMapper(
  image: AssetStoryblok | undefined,
  props: assetStoryblokMapperProps = {
    ratio: RATIOS.DEFAULT,
    columnConfig: {},
    container: true,
  },
): ImageInterface | null {
  if (!image) return null;

  const ratio = ratioMapper(getRatioWithFallback(props.ratio)) as RatioType;
  const ratioLg = ratioMapper(getRatioWithFallback(props.ratio, props.ratioLg)) as RatioType;
  const asset = getImageWithFallback(image);
  const assetLg = getImageWithFallback(image, props.imageLg);
  const columnConfigForEachBreakpoint = fillMissingBreakpoints(props.columnConfig);

  return {
    url: asset.filename,
    alt: asset.alt ?? '',
    ratio: ratio,
    columnConfig: columnConfigForEachBreakpoint,
    source: 'storyblok',
    container: props.container ?? true,
    lg: {
      url: assetLg.filename,
      ratio: ratioLg,
    }
  };
}

export function getRatioWithFallback(ratio: string | RatioType, ratioLg?: string | RatioType): string | RatioType {
  if(ratioLg) {
    return ratioLg;
  }

  return ratio;
}

function getImageWithFallback(image: AssetStoryblok, imageLg?: AssetStoryblok): AssetStoryblok {
  if(imageLg) {
    return imageLg;
  }

  return image;
}
