import { RATIOS } from '@qlevr/shared/constants';
import { CartUpsellProducts } from '@qlevr/shared/interfaces';
import {
  GetCartQueryShopify,
  GetCartUpsellProductsQueryShopify,
  ImageEdgeShopify,
  Maybe,
  MetafieldShopify,
  ProductShopify,
  ProductVariantEdgeShopify,
} from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { imageShopifyMapper } from '../image/image-shopify';
import { mapMetaFeaturedImage } from '../products/product-featured-image';
import { productPriceMapper } from '../products/product-price';
import { productRelatedVariantIdMapper } from '../products/product-related-variant';
import { getProductSlug } from '../products/product-slug';
import { mapProductImages } from '../products/products-shopify';
import { productVariantsMapper } from '../products/product-variant';

export function cartUpsellProductsMapper(
  cart: GetCartQueryShopify['cart'] | undefined,
  products: { [id: string]: GetCartUpsellProductsQueryShopify['productRecommendations'] },
  locale: string,
): CartUpsellProducts[] | null {
  const cartItems = cart?.lines?.edges;
  if (!products || isEmpty(products) || !cartItems || isEmpty(cartItems)) {
    return null;
  }

  const cartProductIds = new Set(cartItems.map((item) => item.node.merchandise.product.id));

  const uniqueProductIds = new Set();
  return Object.values(products).reduce((acc, val) => {
    if (!val || isEmpty(val)) {
      return acc;
    }

    const filteredCartUpsellProducts = val
      .filter((item) => {
        // Filter out products that have already been processed or are in the cart
        return !uniqueProductIds.has(item.id) && !cartProductIds.has(item.id);
      })
      .map((item) => {
        uniqueProductIds.add(item.id); // Add the product ID to the Set

        const images = mapProductImages(item?.images?.edges as ImageEdgeShopify[]);
        const featuredImage = mapMetaFeaturedImage(item.metafields as Maybe<MetafieldShopify>[])?.[0]?.value ?? '';
        const productMetafields = item.metafields as ProductShopify['metafields'];

        const variants = productVariantsMapper(
          item?.variants.edges as ProductVariantEdgeShopify[],
          getProductSlug(item.handle),
          locale,
          item.title,
          productMetafields,
          item.id,
          item.tags,
        );
        const discountPercentage = item?.metafields?.find(
          (metafield) => metafield?.key === 'discount_percentage',
        )?.value;

        return {
          id: item.id,
          tags: item.tags,
          title: item.title,
          slug: getProductSlug(item.handle),
          contextualImage: images?.[1],
          featuredImage:
            imageShopifyMapper(featuredImage, {
              ratio: RATIOS.ratio_1x1,
              altText: item.title,
              columnConfig: { xs: 8, lg: 10 },
            }) ?? images?.[0],
          priceRange: productPriceMapper(item?.priceRange),
          compareAtPriceRange: productPriceMapper(item?.compareAtPriceRange),
          variantId: productRelatedVariantIdMapper(item?.variants?.edges),
          variant: variants?.[0] ?? null,
          discountPercentage: discountPercentage ? Number(discountPercentage) : 0,
        };
      });

    if (filteredCartUpsellProducts) {
      acc.push(...filteredCartUpsellProducts);
    }

    return acc;
  }, [] as CartUpsellProducts[]);
}
