import { ProductVariantInterface, ProductVariantOptionInterface } from '@qlevr/shared/interfaces';
import { useTranslations } from 'next-intl';

interface ProductCardVariantsProps {
  sortedVariants: ProductVariantInterface[] | undefined;
}

export default function ProductCardVariants({ sortedVariants }: ProductCardVariantsProps) {
  const t = useTranslations();

  const sortedSizeOptions = sortedVariants
    ?.map((variant) => {
      const sizeOptions = variant.options.filter((option) => option.name.toLocaleLowerCase() === 'size');
      return sizeOptions;
    })
    .flat();

  // the following code is to remove duplicate size options as the same size can be available in multiple variants
  const sizeOptions = sortedSizeOptions?.reduce((unique: ProductVariantOptionInterface[], option) => {
    return unique.some((u) => u.value === option.value) ? unique : [...unique, option];
  }, []);

  let sizeListElements: JSX.Element[] | null = null;
  if (sizeOptions && sizeOptions.length <= 2) {
    sizeListElements = sizeOptions?.map((option, index) => (
      <li key={option.value} className="flex gap-1">
        <span className={`${index === 0 && 'text-night-900 font-bold'} index-${index}`}>{option.label}</span>
        {index + 1 !== sizeOptions?.length && <span>•</span>}
      </li>
    ));
  } else {
    const sizeOption = sizeOptions?.[0];

    sizeListElements = sizeOption
      ? [
          <li key={sizeOption.value} className="flex gap-1">
            <span className="text-night-900 font-bold">{sizeOption.label}</span>
            <span>•</span>
            <span className="">{t('productCard.availableSizesLabel', { count: sizeOptions?.length })}</span>
          </li>,
        ]
      : null;
  }

  return <ul className="flex flex-wrap gap-1 text-sm text-slate-800 empty:hidden">{sizeListElements}</ul>;
}
