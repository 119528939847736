
export function ratioWxHCalculation(width: number, ratio: string): string | null {
  const calculators: Record<string, number> = {
    'ratio_4x5': (width / 4) * 5,
    'ratio_5x4': (width / 5) * 4,
    'ratio_1x1': width / 1,
    'ratio_9x16': (width / 9) * 16,
    'ratio_3x2': (width / 3) * 2,
    'ratio_16x9': (width / 16) * 9,
    'ratio_2x1': (width / 2) * 1,
    'ratio_3x1': (width / 3) * 1,
    'ratio_4x1': (width / 4) * 1,
    'ratio_3x4': (width / 3) * 4,
    'aspect-square': (width / 1) * 1,
  };

  if (calculators[ratio] === undefined) {
    console.error(`Ratio ${ratio} is not found in ratioWxHCalculation()`)
    return null;
  }

  const height = Math.round(calculators[ratio]);
  return `${width}x${height}`;
}
