'use client';

import {
  BuyingFlowProductInterface,
  ImageInterface,
  ProductVariantInterface,
  SectionInterface,
} from '@qlevr/shared/interfaces';
import { GuidedBuyingFlowContext } from '@qlevr/shared/providers';
import { useTranslations } from 'next-intl';
import { useContext, useEffect, useRef, useState } from 'react';
import ImageRenderer from '../../../image-renderer/image-renderer';
import StarRating from '../../../rating/star-rating';
import Text from '../../../text/text';
import Title from '../../../title/title';
import { calculateTotalPrice } from '../guided-buying-flow-price';
import BuyingFlowProductVariantSelector from './buying-flow-product-variant-selector';

export interface BuyingFlowProductProps extends BuyingFlowProductInterface {
  section: SectionInterface;
}

interface SelectorsStateInterface {
  [key: number]: {
    selectedVariant: ProductVariantInterface | null;
    size: string;
    color: string;
  };
}

export function BuyingFlowProduct({ product, text, title, section }: BuyingFlowProductProps) {
  const t = useTranslations();

  const { flowProducts, addVariantId, removeVariantId, replaceVariantId } = useContext(GuidedBuyingFlowContext);
  const selectedVariantOfMainProduct = useContext(GuidedBuyingFlowContext).variant;
  const [maxLimitReachedForAddingVariants, setMaxLimitReachedForAddingVariants] = useState(true);

  const [selectedVariant] = useState<ProductVariantInterface | null>(null);
  const [, setActiveImage] = useState<ImageInterface | null>(product?.featuredImage ?? null);
  const [errorMessage, setErrorMessage] = useState('');
  const [, setSize] = useState('');
  const [, setColor] = useState('');
  const [isAdded] = useState(true);
  const previousVariantId = useRef<string | null>(null);
  const [selectorsCount, setSelectorsCount] = useState(1); // State for number of selectors

  const [selectorsState, setSelectorsState] = useState<SelectorsStateInterface>({});

  const hasSizeOption = product?.variants?.some((variant) =>
    variant.options.some((option) => option.name.toLowerCase() === 'size'),
  );
  const hasColorOption = product?.variants?.some((variant) =>
    variant.options.some((option) => option.name.toLowerCase() === 'color'),
  );

  useEffect(() => {
    if (selectedVariantOfMainProduct?.options) {
      selectedVariantOfMainProduct.options.forEach((option) => {
        if (option.name.toLowerCase() === 'size' && hasSizeOption) {
          const matchingOption = product?.options?.find(
            (p) => p.name.toLowerCase() === 'size' && p.values.includes(option.value),
          );
          if (matchingOption) {
            setSize(option.value);
          }
        }
        if (option.name.toLowerCase() === 'color' && hasColorOption) {
          const matchingOption = product?.options?.find(
            (p) => p.name.toLowerCase() === 'color' && p.values.includes(option.value),
          );
          if (matchingOption) {
            setColor(option.value);
          }
        }
      });
    }
  }, [hasSizeOption, hasColorOption, selectedVariantOfMainProduct, product?.options]);

  useEffect(() => {
    if (selectedVariant) {
      setActiveImage(selectedVariant.images?.[0]?.image || product?.featuredImage || null);

      if (selectedVariant.id !== previousVariantId.current) {
        if (isAdded && selectedVariant.availableForSale && product?.id) {
          addVariantId(`main-${product.id}`, product?.id, selectedVariant.id);
        }
        if (previousVariantId.current && product?.id) {
          removeVariantId(`main-${product.id}`, product.id, previousVariantId.current);
        }
        previousVariantId.current = selectedVariant.id;
      }
    } else {
      setActiveImage(product?.featuredImage || null);
    }
  }, [addVariantId, isAdded, product?.featuredImage, product?.id, removeVariantId, selectedVariant]);

  useEffect(() => {
    const uniqueColors = new Set<string>();
    product?.variants?.forEach((variant) => {
      if (variant.availableForSale) {
        variant.options.forEach((option) => {
          if (option.name.toLowerCase() === 'color') {
            uniqueColors.add(option.value);
          }
        });
      }
    });

    if (selectorsCount < uniqueColors.size && maxLimitReachedForAddingVariants) {
      setMaxLimitReachedForAddingVariants(false);
    }
  }, [selectorsCount, product?.variants, maxLimitReachedForAddingVariants]);

  if (!product) {
    return null;
  }

  const handleAddBuyingFlowProductVariantSelector = () => {
    const uniqueColors = new Set<string>();
    product?.variants?.forEach((variant) => {
      if (variant.availableForSale) {
        variant.options.forEach((option) => {
          if (option.name.toLowerCase() === 'color') {
            uniqueColors.add(option.value);
          }
        });
      }
    });

    if (selectorsCount < uniqueColors.size) {
      setSelectorsCount((prevCount) => {
        const newCount = prevCount + 1;
        if (newCount >= uniqueColors.size) {
          setMaxLimitReachedForAddingVariants(true);
        }
        return newCount;
      });
    } else {
      setMaxLimitReachedForAddingVariants(true);
    }
  };

  const handleSelectorChange = (index: number, variant: ProductVariantInterface | null) => {
    setSelectorsState((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        selectedVariant: variant,
      },
    }));

    if (variant && product?.id) {
      const uniqueKey = `${product.id}-${index}`;
      if (selectorsState[index]?.selectedVariant) {
        replaceVariantId(uniqueKey, product.id, selectorsState[index]?.selectedVariant?.id ?? '', variant.id);
      } else {
        addVariantId(uniqueKey, product.id, variant.id);
      }
    }
  };

  const handleRemoveSelector = (index: number) => {
    setSelectorsState((prevState) => {
      const newState = { ...prevState };
      delete newState[index];
      const updatedState: SelectorsStateInterface = {};
      Object.keys(newState).forEach((key, i) => {
        updatedState[i] = newState[Number(key)]; // reindex the keys by converting the key to a number
      });
      return updatedState;
    });

    setSelectorsCount((prevCount) => prevCount - 1);
  };

  const { totalPrice, discountedPrice } = calculateTotalPrice([product], flowProducts);
  const showDiscountedPrice = discountedPrice < totalPrice;

  return (
    <div className="product-card py-4 lg:py-10">
      <div key={product.id} className="flex flex-col space-y-4">
        <div className="flex flex-col space-x-2">
          {title && <Title {...title} section={section} className={`${section.theme.color.heading} !text-2xl`} />}
          {text && <Text text={text} theme={section.theme} className={`${section.theme.color.text}`} />}
        </div>
        <div className="lg:hidden">
          {[...Array(selectorsCount)].map((_, index) => (
            <div key={index}>
              {index < 1 && (
                <div>
                  <ImageRenderer
                    image={selectorsState[index]?.selectedVariant?.images?.[0]?.image || product?.featuredImage}
                    className="rounded-lg"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between border-b border-t border-slate-300 pb-2 pt-2">
          <StarRating className="text-kiss-500" section={section} reviews={product.review?.totalReview} />
          <div className="flex gap-2 font-serif text-base">
            <span className={`${showDiscountedPrice ? 'text-slate-400 line-through' : ''}`}>
              {showDiscountedPrice && (
                <span>
                  {product.priceRange?.minVariantPrice.symbol}
                  {totalPrice}
                </span>
              )}
            </span>
            {Number(discountedPrice) > 0 && (
              <span>
                {product?.priceRange?.minVariantPrice?.symbol}
                {discountedPrice}
              </span>
            )}
          </div>
        </div>
        {[...Array(selectorsCount)].map((_, index) => (
          <div key={index}>
            <BuyingFlowProductVariantSelector
              uniqueKey={`${product.id}-${index}`}
              product={product}
              selectedVariant={selectorsState[index]?.selectedVariant || null}
              setSelectedVariant={(variant) => handleSelectorChange(index, variant)}
              setSize={(size) =>
                setSelectorsState((prevState) => ({
                  ...prevState,
                  [index]: {
                    ...prevState[index],
                    size,
                  },
                }))
              }
              setColor={(color) =>
                setSelectorsState((prevState) => ({
                  ...prevState,
                  [index]: {
                    ...prevState[index],
                    color,
                  },
                }))
              }
              size={selectorsState[index]?.size || ''}
              color={selectorsState[index]?.color || ''}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
              onRemove={() => handleRemoveSelector(index)} // Pass the remove handler
            />
          </div>
        ))}
        <button
          className={`text-kiss-600 text-center underline ${maxLimitReachedForAddingVariants ? 'hidden cursor-not-allowed opacity-50' : ''}`}
          onClick={handleAddBuyingFlowProductVariantSelector}
        >
          {t('guidedBuyingFlow.buyAnotherSheet')}
        </button>
      </div>
    </div>
  );
}

export default BuyingFlowProduct;
