import { PriceVariantInterface } from '@qlevr/shared/interfaces';
import { priceFormat } from '@qlevr/shared/utilities';

/* eslint-disable-next-line */
export interface CartLinItemPriceProps {
  price: PriceVariantInterface | null;
  compareAtPrice?: PriceVariantInterface | null;
  quantity: number;
  variantCompareAtAmountPerQuantity?: PriceVariantInterface | null;
}

export function CartLineItemPrice({
  price,
  compareAtPrice,
  quantity,
  variantCompareAtAmountPerQuantity,
}: CartLinItemPriceProps) {
  if (!price) {
    return null;
  }

  let priceToCompare: PriceVariantInterface | null = null;
  if (compareAtPrice && compareAtPrice.amount * quantity !== price.amount) {
    priceToCompare = compareAtPrice;
  } else if (
    variantCompareAtAmountPerQuantity &&
    variantCompareAtAmountPerQuantity.amount * quantity !== price.amount
  ) {
    priceToCompare = variantCompareAtAmountPerQuantity;
  }

  const totalCompareAtPrice = priceToCompare
    ? priceFormat((Number(priceToCompare.amount) * quantity).toString())
    : null;
  const totalPrice = priceFormat(price.amount.toString());

  const numericTotalCompareAtPrice = totalCompareAtPrice ? Number(totalCompareAtPrice) : null;

  return (
    <div className="flex flex-col gap-1 items-center">
      {numericTotalCompareAtPrice && numericTotalCompareAtPrice !== price.amount && (
        <span className="text-slate-400 line-through">
          {price?.symbol}
          {totalCompareAtPrice}
        </span>
      )}

      <span className="font-medium">
        {price.symbol}
        {totalPrice}
      </span>
    </div>
  );
}

export default CartLineItemPrice;
