import { LinkedMetaDataInterface, ProductInterface } from '@qlevr/shared/interfaces';
import { metaProductsMapper, shopifyLinkedDataQueryBuilder } from '@qlevr/shared/mappers';
import { ProductShopify } from '@qlevr/shared/schema';
import {
  getCountryCodeShopify,
  getCountryFromCountryLocale,
  getLanguageCodeShopify,
  getLocaleFromCountryLocale,
} from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { shopifyClient } from './shopify/shopify-service';

export async function getMetaProductShopifyData(
  linkedData: LinkedMetaDataInterface | undefined,
  locale: string,
): Promise<ProductInterface[] | null> {
  if (!linkedData?.products || isEmpty(linkedData?.products)) {
    return null;
  }

  const country = getCountryCodeShopify(getCountryFromCountryLocale(locale));
  const language = getLanguageCodeShopify(getLocaleFromCountryLocale(locale));

  const query = shopifyLinkedDataQueryBuilder(country, language, linkedData);
  const response = await shopifyClient({}).request<{ [id: string]: ProductShopify }>(query);

  if (!response) {
    return null;
  }

  return metaProductsMapper(response, linkedData.products);
}
