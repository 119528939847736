import { LINK_STYLES, LinkStyleType } from '@qlevr/shared/constants';
import { LinkInterface, MappingFunction } from '@qlevr/shared/interfaces';
import IconLink from './icon';
import PrimaryLink from './primary';
import PrimaryOutlineLink from './primary-outline';
import SecondaryLink from './secondary';
import TertiaryLink from './tertiary';
import TertiaryOutlineLink from './tertiary-outline';
import TertiaryOutlineActiveLink from './tertiary-outline-active';
import TextBlackLink from './text-black';
import { FilterLink } from './filter';
import { FilterActiveLink } from './filter-active';

/* eslint-disable-next-line */
export interface LinkRendererProps {
  cta: LinkInterface;
  children?: React.ReactNode;
}

const componentMapping = new Map<LinkStyleType, MappingFunction<LinkRendererProps, JSX.Element>>([
  [LINK_STYLES.icon, (props) => <IconLink {...props} />],
  [LINK_STYLES.primary, (props) => <PrimaryLink {...props} />],
  [LINK_STYLES.primaryOutline, (props) => <PrimaryOutlineLink {...props} />],
  [LINK_STYLES.secondary, (props) => <SecondaryLink {...props} />],
  [LINK_STYLES.tertiary, (props) => <TertiaryLink {...props} />],
  [LINK_STYLES.tertiaryOutline, (props) => <TertiaryOutlineLink {...props} />],
  [LINK_STYLES.tertiaryOutlineActive, (props) => <TertiaryOutlineActiveLink {...props} />],
  [LINK_STYLES.textBlack, (props) => <TextBlackLink {...props} />],
  [LINK_STYLES.filter, (props) => <FilterLink {...props} />],
  [LINK_STYLES.filterActive, (props) => <FilterActiveLink {...props} />],
]);

export function LinkRenderer(props: LinkRendererProps) {
  if (!props.cta) {
    return null;
  }

  const componentControl = componentMapping.get(props.cta.linkStyle);
  if (!componentControl) {
    return null;
  }

  return componentControl(props);
}

export default LinkRenderer;
