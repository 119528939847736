import { ContentTextVideoInterface } from '@qlevr/shared/interfaces';
import { TextVideoStoryblok } from '@qlevr/shared/schema';
import { textImageLayoutMapper } from '../layout/layout';
import { linksMapper } from '../link/link';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';
import { mapVideo } from '../video/video';

export function mapTextVideoContent(content: TextVideoStoryblok): ContentTextVideoInterface | null {
  const video = mapVideo(content.video?.[0]);

  if(!video) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    text: mapRichText(content.text) ?? null,
    layout: textImageLayoutMapper(content.layout),
    container: content.container ?? false,
    video,
    rating: content.rating ?? false,
    cta: linksMapper(content.cta),
    section: sectionMapper(content.section?.[0]),
  };
}
