import { PageTypeType } from '@qlevr/shared/constants';
import { blogSitemapMapper } from '@qlevr/shared/mappers';
import { notFound } from 'next/navigation';

export const fetchBlogSitemap = async (pageType: PageTypeType, locale: string, preview: boolean, cv: string) => {
  const urlParams = new URLSearchParams({
    starts_with: `${locale}/${process.env['NEXT_PUBLIC_BRAND_NAME']}${pageType}`,
    is_startpage: '0',
    token: process.env['NEXT_PUBLIC_STORYBLOK_API_TOKEN'] as string,
    version: preview ? 'draft' : 'published',
    cv,
  }).toString();

  const url = `https://api.storyblok.com/v2/cdn/stories?${urlParams}`;
  const res = await fetch(url);

  const isError = res.status !== 200;
  if (isError) {
    console.error('Sitemap not found', url);
    return notFound();
  }

  const pages = await res.json();

  if (!pages) {
    throw new Error(`Sitemap could not be fetched`);
  }

  return blogSitemapMapper(pages.stories, locale);
};
