import { ProductInterface } from '@qlevr/shared/interfaces';
import { reverseEscapeUrl } from '@qlevr/shared/utilities';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import ImageRenderer from '../image-renderer/image-renderer';

/* eslint-disable-next-line */
export interface BundleAndSaveActiveProductProps {
  product: ProductInterface;
}

export function BundleAndSaveActiveProduct({ product }: BundleAndSaveActiveProductProps) {
  const t = useTranslations();
  const searchParams = useSearchParams();
  const color = searchParams.get('color');
  const size = searchParams.get('size');

  let displayText;
  if (color && size) {
    displayText = reverseEscapeUrl(`${size}-${color}`);
  } else if (color) {
    displayText = reverseEscapeUrl(`${color}`);
  }

  if (!product) {
    return null;
  }

  return (
    <div className="grid grid-flow-col items-center justify-items-end gap-3 bg-white px-10 py-6">
      <div className="flex w-full items-center space-x-4">
        <ImageRenderer loadingState="lazy" image={product.featuredImage} className="w-20" />
        <div>
          <span className="text-base font-bold">{product.title}</span>
          <div className="capitalize">{displayText}</div>
          <div className="flex gap-1">
            {product.compareAtPriceRange?.minVariantPrice.amount && (
              <span className="text-slate-400 line-through">
                {product.compareAtPriceRange?.minVariantPrice.symbol}
                {product.compareAtPriceRange?.minVariantPrice.amount}
              </span>
            )}
            <span className="t-cart-upsell-card-price font-bold">
              {product.priceRange?.minVariantPrice.symbol}
              {product.priceRange?.minVariantPrice.amount}
            </span>
          </div>
        </div>
      </div>
      <button className="w-fit rounded-full bg-green-500 px-4 py-2 text-sm text-white">
        {t('bundleAndSave.addedToCart')}
      </button>
    </div>
  );
}

export default BundleAndSaveActiveProduct;
