import { MetaInterface, ProductInterface, TitleHeroInterface } from '@qlevr/shared/interfaces';
import { HeroTitleStoryblok } from '@qlevr/shared/schema';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { contentAlignmentMapper } from '../content-alignment/content-alignment';
import { getMetaProductById } from '../products/products-shopify';

export function mapTitleHero(title: string, content: HeroTitleStoryblok, meta: MetaInterface): TitleHeroInterface | null {
  const productId = content['product']?.items?.[0]?.id;

  let product = {} as ProductInterface | null;
  if(productId) {
    product = getMetaProductById(productId, meta);
  }
  
  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: content.title ?? title,
    text: mapRichText(content.text),
    alignment: contentAlignmentMapper(content.alignment?.[0]),
    section: sectionMapper(content.section?.[0]),
    reviews: product?.review ?? null,
  };
}
