import { LinkInterface } from '@qlevr/shared/interfaces';
import BaseLinkElementRenderer from './base/base-link-element-renderer';

/* eslint-disable-next-line */
export interface PrimaryOutlineLinkProps {
  cta: LinkInterface;
}

export function PrimaryOutlineLink(props: PrimaryOutlineLinkProps) {
  return (
    <BaseLinkElementRenderer
      {...props}
      cta={{
        ...props.cta,
        wrapperClassName: `btn-primary-outline flex items-center justify-center rounded-full px-12 py-4 font-sans text-lg gap-3 ${props.cta.className} ${props.cta.wrapperClassName}`,
      }}
    />
  );
}

export default PrimaryOutlineLink;
