// nl -> nl-nl
// fr -> fr-fr
// nl-be -> nl-be
export function getLocaleRegionParamByLocale(locale: string): string {
  const lowercaseLocale = locale.toLowerCase();
  // Check if the locale already includes a region code (i.e., contains a hyphen)
  if (lowercaseLocale.includes('-')) {
      return lowercaseLocale; // If yes, return the locale as is
  }

  // Special case for 'en' as it typically doesn't follow the 'en-EN' format
  if (lowercaseLocale === 'en') {
      return lowercaseLocale; // Return 'en' as is
  }

  // For other cases, duplicate the locale to form a language-region code
  return `${lowercaseLocale}-${lowercaseLocale}`;
}
