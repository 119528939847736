import { BuyingFlowProductInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { BuyingFlowProductStoryblok, ShopifyProductStoryblok } from '@qlevr/shared/schema';
import { getMetaProductById } from '../../products/products-shopify';
import { mapRichText } from '../../rich-text/rich-text';
import { mapTitle } from '../../title/title';

export function mapBuyingFlowProduct(
  content: BuyingFlowProductStoryblok,
  meta: MetaInterface,
): BuyingFlowProductInterface | null {
  const buyingFlowProduct = content?.['product'].items[0] as ShopifyProductStoryblok;

  return {
    title: mapTitle(content.title?.[0]) ?? null,
    text: mapRichText(content.text) ?? null,
    product: getMetaProductById(buyingFlowProduct.id, meta),
  };
}
