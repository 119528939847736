import { PICTO_SIZES, PictoSizeType, TEXT_TEXT_PICTO_SIZES } from '@qlevr/shared/constants';

const pictoSizeMapping = new Map<string, PictoSizeType>([
  ['small', PICTO_SIZES.small],
  ['base', PICTO_SIZES.base],
  ['large', PICTO_SIZES.large],
]);
const textTextPictoSizeMapping = new Map<string, PictoSizeType>([
  ['small', TEXT_TEXT_PICTO_SIZES.small],
  ['base', TEXT_TEXT_PICTO_SIZES.base],
  ['large', TEXT_TEXT_PICTO_SIZES.large],
]);

export function pictoSizeMapper(block: string): PictoSizeType {
  if (!block) return PICTO_SIZES.base;

  const size = pictoSizeMapping.get(block);

  if (!size) {
    throw new Error(`Picto size of type ${block} is missing a pictoSizeMapper.`);
  }

  return size;
}

export function textTextPictoSizeMapper(block: string): PictoSizeType {
  if (!block) return TEXT_TEXT_PICTO_SIZES.base;

  const size = textTextPictoSizeMapping.get(block);

  if (!size) {
    throw new Error(`Picto size of type ${block} is missing a pictoSizeMapper.`);
  }

  return size;
}
