import { BREAKPOINTS } from '@qlevr/shared/constants';
import { ColumnConfigInterface } from '@qlevr/shared/interfaces';
import { getNextBreakpoint } from '../../breakpoints/get-next-breakpoint';
import { ratioWxHCalculation } from '../../ratio/ratio-height-calculator';
import { imageAddCropParameters } from './image-add-crop-parameters';

export function imageShopifySrcSetGenerator(
  url: string,
  ratio: string ,
  imageSrcSetConfig: ColumnConfigInterface,
): string[] {
  return Object.entries(imageSrcSetConfig).reduce((acc, [breakpoint, width]) => {
    const containerWidth = getNextBreakpoint(breakpoint as keyof typeof BREAKPOINTS);
    if (!containerWidth) {
      return acc;
    }

    const itemsPerRow = width;
    const pixelWidth = Math.round(containerWidth[1] / itemsPerRow);

    const WxH = ratioWxHCalculation(pixelWidth, ratio);

    if (WxH) {
      const entry = `${imageAddCropParameters(url, `${WxH}`)} ${pixelWidth}w`;
      acc.push(entry);
    }

    return acc;
  }, [] as string[]); // fix: initialize the accumulator as an empty string array
}
