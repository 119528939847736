import { PAGE_TYPES } from '@qlevr/shared/constants';

export function getCollectionSlug(handle: string): string {
  return `${PAGE_TYPES.collections}${handle}`;
}


export function getCollectionSlugFilter(handle: string, filter: string): string {
  return `${PAGE_TYPES.collections}${handle}/${filter}`;
}

