'use client';

import { ICONS } from '@qlevr/shared/constants';
import { SectionInterface } from '@qlevr/shared/interfaces';
import { useTranslations } from 'next-intl';
import IconRenderer from '../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface RatingProps {
  className?: string;
  section?: SectionInterface;
  reviews?: number;
  showText?: boolean;
}

// @todo remove default number of reviews
export function StarRating({ section, reviews = 12.256, className = '', showText = true }: RatingProps) {
  const t = useTranslations();
  return (
    <div className={`flex gap-2 ${section?.theme.rating} ${className}`}>
      <div className="flex items-center gap-0.5">
        {[1, 2, 3, 4, 5].map((index) => (
          <IconRenderer key={index} icon={ICONS.star} className={`h-5 w-5 ${section}`} />
        ))}
      </div>
      <a href="#review">
        <span className="text-base font-medium">{showText ? t('review', { reviews: reviews }) : `(${reviews})`}</span>
      </a>
    </div>
  );
}

export default StarRating;
