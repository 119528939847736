import isEmpty from 'lodash.isempty';

export function productRelatedVariantIdMapper(
  items: { node: { id: string }}[] | undefined,
): string | null {
  if (!items || isEmpty(items)) {
    return null;
  }

  return items.map((item) => item.node.id)[0] ?? null;
}
