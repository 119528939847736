import { CollectionStoryInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { CollectionPageStoryblok, ColumnStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';
import { columnsMapper } from '../columns/columns';
import { contentBlocksMapper } from '../content-blocks/content-blocks';
import { heroBlocksMapper } from '../hero-blocks/hero-blocks';

export function collectionStoryMapper(
  page: StoryblokStory<CollectionPageStoryblok>,
  meta: MetaInterface,
): CollectionStoryInterface {
  return {
    meta,
    hero: heroBlocksMapper(page.name, page?.content?.hero, meta),
    contentBlocks: contentBlocksMapper(page?.content?.contentBlocks, meta),
    columns: columnsMapper(page?.content?.columns?.[0] as ColumnStoryblok) ?? null,
    quickAdd: page?.content.quickAdd ?? false,
  };
}
