import { VideoInterface } from '@qlevr/shared/interfaces';
import { VideoStoryblok } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { assetStoryblokMapper } from '../asset/asset-storyblok';
import { ratioMapper } from '../ratio/ratio';
import { mapYoutubeVideo } from './youtube-video';

export function mapVideos(videos?: VideoStoryblok[]): VideoInterface[] {
  if (!videos || isEmpty(videos)) {
    return [];
  }

  return videos.reduce((acc, item) => {
    const video = mapVideo(item);
    if (video) {
      acc.push(video);
    }
    return acc;
  }, [] as VideoInterface[]);
}

export function mapVideo(block: VideoStoryblok): VideoInterface | null {
  if (!block || isEmpty(block.video)) {
    return null;
  }

  let mobileVideo = block.video.filename;

  // if there is no mobile video, check for shopify video link
  if(!block.video.filename && block.shopifyVideoLink?.url) {
    mobileVideo = block.shopifyVideoLink.url;
  }

  let desktopVideo = block.videoLg?.filename ? block.videoLg.filename : mobileVideo;

  // if there is no desktop video, check for shopify video link
  if (!block.videoLg?.filename && block.shopifyVideoLinkLg?.url) {
    desktopVideo = block.shopifyVideoLinkLg.url;
  }

  return {
    video: mobileVideo,
    ratio: ratioMapper(block.ratio),
    videoLg: desktopVideo,
    ratioLg: ratioMapper(block.ratioLg),
    poster: assetStoryblokMapper(block.poster),
    autoplay: block.autoplay ?? false,
    youtube: mapYoutubeVideo(block.youtube?.url),
    shopifyVideoLink: block.shopifyVideoLink?.url,
    shopifyVideoLinkLg: block.shopifyVideoLinkLg?.url,
  };
}
