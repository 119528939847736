'use client';

import { ProductInterface } from '@qlevr/shared/interfaces';
import { useTranslations } from 'next-intl';
import ToggleButton from './toggle-button';
import MoreInfoButton from './more-info-button';

export interface ProductPremiumToggleProps {
  product: ProductInterface;
}

export default function ProductPremiumToggle({ product }: ProductPremiumToggleProps) {
  const t = useTranslations();

  if (!product.linkedProductToRedirect || !product.linkedProductToRedirect.reference) {
    return null;
  }

  return (
    <div className=" space-y-3">
      <div className="flex justify-between gap-2">
        <div className="flex gap-2">
          <span className="font-light">{t('pdp.premium.label')}</span>
          <span>{t(`pdp.premium.${product.isPremium}`)}</span>
        </div>
        <MoreInfoButton product={product} />
      </div>
      <div>
        <ToggleButton product={product} />
      </div>
    </div>
  );
}
