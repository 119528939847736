import { PRODUCT_VARIANT_URL_SEPERATOR } from '@qlevr/shared/constants';
import { ProductVariantSizeColorInterface } from '@qlevr/shared/interfaces';

export function getSizeColorVariantFromUrl(url: { variant: string }): ProductVariantSizeColorInterface | null {
  if(!url.variant) {
    return null;
  }

  const variants = {} as { size: string; color: string };
  const [size, color] = url.variant.split(PRODUCT_VARIANT_URL_SEPERATOR);

  if(size) {
    variants.size = size;
  }
  if(color) {
    variants.color = color;
  }

  return variants;
}
