import { ProductCombinationInterface, ProductVariantInterface } from '@qlevr/shared/interfaces';
import { calculateVariantAvailability, escapeUrl, getDefaultLocaleProductOptionName } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';

// Schema.org is based on example from ChatGPT
// https://chat.openai.com/share/88d7fe02-d04d-4bbd-b2c4-e26bbce204fb

export function productCombinationsMapper(
  title: string,
  variants: ProductVariantInterface[],
): ProductCombinationInterface[] {
  if (!variants || isEmpty(variants)) {
    return [];
  }

  return variants.map((variant) => mapCombination(title, variant));
}

function mapCombination(title: string, variant: ProductVariantInterface): ProductCombinationInterface {
  // Adds key / value pairs for each variant (ie. "color": "Black" and "size": 'M").
  const options = variant.options.reduce(
    (accumulator, option) => ({
      ...accumulator,
      [getDefaultLocaleProductOptionName(escapeUrl(option.name).toLowerCase())]: escapeUrl(option.value),
    }),
    {},
  );

  const titleWithOptions = `${title} ${Object.values(options).length > 0 ? '- ' + Object.values(options).join(', ') : ''}`;
  const { isPreOrder, availableForSale, currentlyNotInStock } = calculateVariantAvailability(variant);

  return {
    id: variant.id,
    title: titleWithOptions,
    availableForSale: availableForSale,
    barcode: variant.barcode || null,
    inventoryQuantity: variant.inventoryQuantity,
    currentlyNotInStock: currentlyNotInStock,
    price: variant.price,
    preOrder: isPreOrder,
    shippingDate: variant.shippingDate,
    featuredImage: variant.featuredImage,
    variantImages: variant.images,
    slug: variant.slug,
    sku: variant.sku || null,
    tag: variant.tag || null,
    options: variant.options,
    ...options,
  };
}
