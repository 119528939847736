import { CollectionCardInterface, LinkedMetaDataInterface, ProductInterface, ProductReviewInterface } from '@qlevr/shared/interfaces';
import { getProductReviews } from './reviews/product-reviews';
import isEmpty from 'lodash.isempty';

// Function to fetch and update product reviews for a collection of products
export async function getMetaCollectionProductReview(
  linkedData: LinkedMetaDataInterface | undefined,
  collections: CollectionCardInterface[] | null,
  locale: string,
): Promise<CollectionCardInterface[] | null> {
  // Collect all unique review IDs
  const productReviewIds = new Set<string>();
  collections?.forEach((collection) => {
    collection.products.forEach((product) => {
      if (product.reviewId) {
        productReviewIds.add(product.reviewId);
      }
    });
  });

  // If there are no review IDs, return the collections as is
  if (isEmpty(productReviewIds)) {
    return collections;
  }

  try {
    // Fetch all reviews concurrently
    const reviewPromises = Array.from(productReviewIds).map(async (id) => {
      try {
        const response = await getProductReviews(id, locale);
        return { id, response };
      } catch (error) {
        console.error(`Failed to fetch review for product ID ${id}:`, error);
        return { id, response: null };
      }
    });

    const productReviews = await Promise.all(reviewPromises);

    // Map reviews by ID for quick access
    const reviewMap = new Map(productReviews.map(({ id, response }) => [id, response]));

    // Update collections with fetched reviews
    collections?.forEach((collection) => {
      collection.products.forEach((product) => {
        if (product.reviewId) {
          const review = reviewMap.get(product.reviewId);
          if (review) {
            product.review = review;
          }
        }
      });
    });

    return collections;
  } catch (error) {
    console.error('Unexpected error while fetching product reviews:', error);
    // Optionally handle unexpected error tracking here, e.g., send to an error tracking service
    return collections;
  }
}

