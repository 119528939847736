import { AnchorLinkInterface, GlobalInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';

export function mapSameAs(globals?: GlobalInterface | null): string[] {
  if(!globals?.socialLinks || isEmpty(globals.socialLinks)) {
    return [];
  }

  return globals.socialLinks.map((item) => (item as AnchorLinkInterface).href)
}
