import { PAGE_TYPES } from '@qlevr/shared/constants';
import { BlogCardInterface, CategoryInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { BlogStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';
import { assetStoryblokMapper } from '../asset/asset-storyblok';
import { getMetaRelationItems } from '../meta/get-meta-relation-item';

export function blogCardsMapper(blogs: StoryblokStory<BlogStoryblok>[], meta: MetaInterface): BlogCardInterface[] {
  return blogs?.map((item) => blogCardMapper(item, meta)) ?? [];
}

export function blogCardMapper(page: StoryblokStory<BlogStoryblok>, meta: MetaInterface): BlogCardInterface {
  const categories = getMetaRelationItems<CategoryInterface>(page.content.categories, meta);
  
  return {
    title: page.name,
    description: page.content?.description ?? '',
    slug: `${PAGE_TYPES.blog}${page.slug}`,
    image: assetStoryblokMapper(page.content?.['image']),
    categories,
  };
}
