import { KeyValueInterface } from '@qlevr/shared/interfaces';
import { Maybe, MetafieldShopify } from '@qlevr/shared/schema';
import { getProductMetafieldReference } from '../metafield/get-metafield-reference';

export function mapColorOverviewImage(metafields: Maybe<MetafieldShopify>[]): KeyValueInterface[] {
  if (!metafields) {
    return [];
  }

  return getProductMetafieldReference('color_overview_image', metafields);
}
