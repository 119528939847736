'use client';

import { ICONS } from '@qlevr/shared/constants';
import { ProductInterface } from '@qlevr/shared/interfaces';
import { useBundleAndSave } from '@qlevr/shared/providers';
import { Modal } from 'flowbite-react';
import isEmpty from 'lodash.isempty';
import { useTranslations } from 'next-intl';
import IconRenderer from '../icons/icon-renderer.component';
import BundleAndSaveActiveProduct from './bundle-and-save-active-product';
import { ProductRecommendationCard } from './bundle-and-save-product';
import { bundleSaveUpsellTheme } from './bundle-and-save-theme';

/* eslint-disable-next-line */
export interface BundleAndSaveUpsellProps {
  product: ProductInterface;
}

export function BundleAndSaveUpsell({ product }: BundleAndSaveUpsellProps) {
  const t = useTranslations();
  const bundleAndSaveContext = useBundleAndSave();

  if (!product.productRecommendations || isEmpty(product.productRecommendations)) return null;

  return (
    <Modal
      dismissible
      size="md"
      show={bundleAndSaveContext.isOpen}
      theme={bundleSaveUpsellTheme}
      position={'top-right'}
      onClose={() => bundleAndSaveContext.close()}
      className="bundle-modal-overlay"
    >
      <Modal.Header></Modal.Header>
      <BundleAndSaveActiveProduct product={product} />
      <div className="t-bundle-and-save-bar py-2 text-center text-lg">{t('bundleAndSave.title')}</div>
      <Modal.Body>
        {product.productRecommendations.map((item, key) => (
          <ProductRecommendationCard key={key} product={item} />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-primary flex w-full items-center justify-center gap-2 rounded-full px-3 py-4 text-center text-lg font-bold shadow-md"
          onClick={() => bundleAndSaveContext.close()}
        >
          {`${t('bundleAndSave.buttonLabel')}`}
          <IconRenderer icon={ICONS.arrowRight} className="h-4 w-4" />
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default BundleAndSaveUpsell;
