import { AnchorLinkInterface } from '@qlevr/shared/interfaces';
import IconRenderer from '../../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface AnchorProps {
  children?: React.ReactNode;
  cta: AnchorLinkInterface;
}

export function Anchor({
  children,
  cta: {
    label,
    wrapperClassName,
    className,
    leadingIcon,
    leadingClassName,
    linkStyle,
    linkType,
    size,
    trailingIcon,
    trailingClassName,
    as,
    ...anchorProps
  },
}: AnchorProps) {
  const css = `${className} ${size}`;

  return (
    <a {...anchorProps} {...(wrapperClassName ? { className: wrapperClassName } : {})}>
      {leadingIcon && (
        <span className="mr-3">
          <IconRenderer icon={leadingIcon} {...(leadingClassName ? { className: leadingClassName } : {})} />
        </span>
      )}
      <span className={css}>{children ?? label}</span>
      {trailingIcon && (
        <span className="ml-3">
          <IconRenderer icon={trailingIcon} {...(trailingClassName ? { className: trailingClassName } : {})} />
        </span>
      )}
    </a>
  );
}

export default Anchor;
