import { ButtonSizeType, IconType, LinkStyleType, LinkTypeType } from '@qlevr/shared/constants';

export type ButtonLinkInterface = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  as: 'button';
  className?: string;
  wrapperClassName?: string;
  label?: string;
  linkStyle: LinkStyleType;
  size?: ButtonSizeType;
  leadingIcon?: IconType | null;
  leadingClassName?: string | null;
  trailingIcon?: IconType | null;
  trailingClassName?: string | null;
};

export type AnchorLinkInterface = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  as: 'a';
  linkType: LinkTypeType;
  className?: string;
  wrapperClassName?: string;
  href: string;
  label: string;
  linkStyle: LinkStyleType;
  size?: ButtonSizeType;
  leadingIcon?: IconType | null;
  leadingClassName?: string | null;
  trailingIcon?: IconType | null;
  trailingClassName?: string | null;
};

export type LinkLinkInterface = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  as?: 'link';
  className?: string;
  wrapperClassName?: string;
  href: string;
  label: string;
  linkStyle: LinkStyleType;
  size?: ButtonSizeType;
  leadingIcon?: IconType | null;
  leadingClassName?: string | null;
  trailingIcon?: IconType | null;
  trailingClassName?: string | null;
};

export type LinkInterface = ButtonLinkInterface | AnchorLinkInterface | LinkLinkInterface;

const linkTypeMap = new Map<string | undefined, LinkInterface>([
  ['button', {} as ButtonLinkInterface],
  ['a', {} as AnchorLinkInterface],
  ['link', {} as LinkLinkInterface],
]);

export function getLinkType(link: LinkInterface): ButtonLinkInterface | AnchorLinkInterface | LinkLinkInterface {
  if(!link) {
    throw new Error(`Link is undefined`);
  }

  if(!link.as) {
    return link as LinkLinkInterface;
  }

  const linkType = linkTypeMap.get(link.as);
  if (!linkType) {
    throw new Error(`Invalid link type: ${link.as}`);
  }

  return linkType;
}
