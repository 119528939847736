import { LOCALES, LOCALES_DEFAULT } from '@qlevr/shared/constants';
import { LanguageCodeShopify } from '@qlevr/shared/schema';

export function getLanguageCodeShopify(language?: string | null): LanguageCodeShopify {
  if (!language) {
    language = LOCALES_DEFAULT;
  }

  const formattedLanguage = language.toLowerCase();

  const languageCodeMapping = new Map<string, LanguageCodeShopify>([
    [LOCALES[0], LanguageCodeShopify.EnShopify],
    [LOCALES[1], LanguageCodeShopify.DeShopify],
    [LOCALES[2], LanguageCodeShopify.FrShopify],
    [LOCALES[3], LanguageCodeShopify.NlShopify],
    [LOCALES[4], LanguageCodeShopify.EsShopify],
    [LOCALES[5], LanguageCodeShopify.ItShopify],
  ]);

  const languageCode = languageCodeMapping.get(formattedLanguage) || LanguageCodeShopify.EnShopify;
  if (!languageCode) {
    return LanguageCodeShopify.EnShopify;
  }

  return languageCode;
}
