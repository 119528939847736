import { BlogCardInterface, LinkedMetaDataInterface, MetaInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash/isEmpty';
import { FetchBlogs } from './blog';
import { FetchBlogsByCategory } from './blog-by-category';

export async function getMetaBlog(
  locale: string,
  preview: boolean,
  linkedData: LinkedMetaDataInterface | undefined,
  meta: MetaInterface,
  filter?: string
): Promise<BlogCardInterface[] | null> {
  if (!linkedData?.blog) {
    return null;
  }

  if (!filter) {
    const blog = await new FetchBlogs(locale, preview).request(meta);

    if (!blog || isEmpty(blog)) {
      return null;
    }

    return blog;
  }

  const blog = await new FetchBlogsByCategory(locale, preview, filter).request(meta);
  if (!blog || isEmpty(blog)) {
    return null;
  }

  return blog;
}
