import { KeyValueInterface } from '@qlevr/shared/interfaces';
import { reverseEscapeUrl } from '@qlevr/shared/utilities';
import { Modal } from 'flowbite-react';
import { useTranslations } from 'next-intl';
import { sizeChartModalTheme } from './size-modal-theme';

export interface SizeChartProps {
  isSizeChartOpen: boolean;
  setSizeChartOpen: (isOpen: boolean) => void;
  ageGroups: KeyValueInterface[] | null;
}

export function SizeChartContent({ setSizeChartOpen, ageGroups, isSizeChartOpen }: SizeChartProps) {
  const t = useTranslations();

  return (
    <Modal
      dismissible
      size="md"
      theme={sizeChartModalTheme}
      show={isSizeChartOpen}
      position={'top-right'}
      onClose={() => setSizeChartOpen(false)}
      className="size-chart-modal-overlay"
    >
      <Modal.Header>{t('sizeChart.title')}</Modal.Header>
      <Modal.Body>
        {ageGroups?.map((ageGroup, index) => (
          <div key={index} className="flex flex-col justify-between">
            <div className="t-warm-bg mx-4 rounded-full text-center font-sans text-base font-semibold">
              {reverseEscapeUrl(ageGroup.key)}
            </div>
            <img src={ageGroup.value ?? ''} alt={ageGroup.key} className="mx-auto" loading="lazy" />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => setSizeChartOpen(false)} className="mx-auto flex w-fit border-b lg:hidden">
          {t('sizeChart.close')}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default SizeChartContent;
