import { InstagramInterface, RssFeed } from '@qlevr/shared/interfaces';
import { parseStringPromise } from 'xml2js';

export async function instagramFeedService(): Promise<InstagramInterface | null> {
  try {
    const response = await fetch(process.env['NEXT_PUBLIC_INSTAGRAM_FEED'] as string);

    if (!response.ok) {
      throw new Error(`Failed to fetch the Instagram feed: ${response.statusText}`);
    }

    const xml = await response.text();
    const result: RssFeed = await parseStringPromise(xml);

    const channel = result.rss.channel[0];
    const title = channel.title[0];
    const description = channel.description[0];

    const items = channel.item.map((item) => ({
      link: item.link[0],
      url: item.enclosure[0].$.url,
    }));

    return {
      title,
      description,
      items,
    };
  } catch (error) {
    console.error('Error fetching and mapping Instagram feed:', error);
    return null;
  }
}
