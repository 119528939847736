'use client';
import { BREAKPOINTS } from '@qlevr/shared/constants';
import { ContentImageScrollCarouselInterface } from '@qlevr/shared/interfaces';
import 'swiper/css/effect-coverflow';
import { Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactComponent as Comma } from '../icons/comma.svg';
import ImageRenderer from '../image-renderer/image-renderer';
import Section from '../section/section';

/* eslint-disable-next-line */
export interface ContentImageScrollCarouselProps extends ContentImageScrollCarouselInterface {}

export function ContentImageScrollCarousel(props: ContentImageScrollCarouselProps) {
  return (
    <Section {...props.section}>
      <div className={`${props.section.theme.editor}`}>
        <div className="flex flex-col justify-center pb-6">
          {props.text && <h2 className="pb-2 text-center font-sans text-3xl font-normal">{props.text}</h2>}
          {/* @todo make reviews dynamic */}
          {/* <div className="mx-auto">
            <BrandRating section={props.section} />
          </div> */}
          {props.description && (
            <div
              className="pt-1 text-center text-lg font-normal"
              dangerouslySetInnerHTML={{ __html: props.description }}
            />
          )}
        </div>
        <Swiper
          className={`swiper-with-nav swiper-with-scrollbar image-scroll-carousel-wrapper ${props.section.theme.carousel}`}
          spaceBetween={1}
          slidesPerView={1.7}
          scrollbar={{ draggable: true, dragClass: 'swiper-scrollbar-drag' }}
          navigation={true}
          centeredSlides={true}
          roundLengths={true}
          modules={[Scrollbar, Navigation]}
          speed={500}
          breakpoints={{
            [BREAKPOINTS.xl]: {
              slidesPerView: 1.7,
            },
            [BREAKPOINTS.lg]: {
              slidesPerView: 1.6,
            },
            [BREAKPOINTS.xs]: {
              slidesPerView: 1.6,
            },
          }}
        >
          {props.images?.map((imageData, key) => (
            <SwiperSlide key={key} className="image-scroll-carousel">
              <div className="flex w-full flex-col items-center gap-6 lg:flex-row">
                {imageData.image && (
                  <div className="relative h-full w-full rounded-lg bg-transparent pt-64 sm:pt-96">
                    <ImageRenderer
                      loadingState="lazy"
                      image={imageData.image}
                      className="absolute bottom-0 left-0 right-0 top-0 block aspect-square h-full w-full rounded-lg bg-transparent object-cover"
                    />
                  </div>
                )}
                {imageData.text && (
                  <div className="slider-content relative block h-full w-full lg:hidden">
                    <div className="absolute -top-10 left-2 md:-top-14 md:left-2 lg:-left-14 lg:-top-11">
                      <Comma className="h-6 w-8 text-teal-500 md:h-12 md:w-16" />
                    </div>
                    {imageData.text && <div className="pb-3 text-xl font-normal md:text-3xl"> {imageData.text} </div>}
                    {imageData.caption && (
                      <div className="text-base font-normal text-slate-400"> - {imageData.caption} </div>
                    )}
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Section>
  );
}

export default ContentImageScrollCarousel;
