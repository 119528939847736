'use client';
import { BREAKPOINTS } from '@qlevr/shared/constants';
import { ContentProductsComparisonInterface } from '@qlevr/shared/interfaces';
import { Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from '../../container/container';
import Section from '../../section/section';
import Text from '../../text/text';
import Title from '../../title/title';
import CompareProductCard from './compare-product';

/* eslint-disable-next-line */
export interface ContentProductsComparisonProps extends ContentProductsComparisonInterface {}

export function ContentProductsComparison({ section, title, text, products }: ContentProductsComparisonProps) {
  if (!products) return null;

  return (
    <Section {...section}>
      <Container className="!px-0">
        <div className="pl-5 lg:px-5">
          <div className="space-y-6 text-center lg:space-y-10">
            {!!(title || text) && (
              <div className="pb-6">
                {title && <Title {...title} section={section} />}
                {text && <Text text={text} theme={section.theme} />}
              </div>
            )}
          </div>
          <Swiper
            className={`swiper-with-nav swiper-with-scrollbar ${section.theme.carousel}`}
            spaceBetween={24}
            scrollbar={{ draggable: true, dragClass: `swiper-scrollbar-drag` }}
            navigation={false}
            modules={[Scrollbar]}
            breakpoints={{
              [BREAKPOINTS.lg]: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
              [BREAKPOINTS.xs]: {
                slidesPerView: 1.25,
                spaceBetween: 8,
              },
            }}
          >
            {products.map((product, index) => (
              <SwiperSlide key={index}>
                <CompareProductCard product={product} section={section} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Container>
    </Section>
  );
}

export default ContentProductsComparison;
