import { ProductInterface } from '@qlevr/shared/interfaces';
import { parseInput } from './parse-input';
import { escapeUrl } from './url/escape-url';

interface GetFilteredProductsProps {
  selectedFilters: Record<string, string[]>;
  products: ProductInterface[];
  updateDefaultSelected?: boolean;
}

export function getFilteredProducts({
  selectedFilters,
  products,
  updateDefaultSelected = false,
}: GetFilteredProductsProps): ProductInterface[] {
  return products
    .map((product) => {
      let productMatches = true;
      let variantMatched = false; // Tracks if a variant matched either by metafield or option
      const selectedFiltersKeys = Object.keys(selectedFilters);
      // Iterate over each filter
      selectedFiltersKeys.forEach((filterId) => {
        const filters = selectedFilters[filterId];
        if (filters.length === 0) return;

        // Check if the product matches the filter criteria
        const matches = filters.some((filterObj) => {
          const parsedFilter = parseInput(filterObj);

          let match = true;

          if (parsedFilter.productType) {
            match = match && product.productType === parsedFilter.productType;
          }
          if (parsedFilter.productMetafield) {
            match =
              match &&
              !!product.metafields?.some(
                (metafield) =>
                  metafield.key === parsedFilter.productMetafield.key &&
                  metafield.value === parsedFilter.productMetafield.value,
              );
          }
          if (parsedFilter.variantOption && !product.splittedVariants) {
            // Here we update the variants' defaultSelected based on the variant option match
            match =
              match &&
              !!product.variants?.some((variant) => {
                const optionMatch = variant.options?.some(
                  (option) =>
                    option.name === parsedFilter.variantOption.name &&
                    escapeUrl(option.value) === escapeUrl(parsedFilter.variantOption.value),
                );

                if (optionMatch) {
                  if (updateDefaultSelected) {
                    variant.defaultSelected = true;
                  }
                  variantMatched = true; // Update variantMatched if a match is found
                } else if (updateDefaultSelected) {
                  variant.defaultSelected = false;
                }

                return optionMatch;
              });
          } else if (parsedFilter.variantOption) {
            match =
              match &&
              !!product.options?.some(
                (option) =>
                  option.name === parsedFilter.variantOption.name &&
                  option.values.includes(escapeUrl(parsedFilter.variantOption.value)),
              );
          }
          if (parsedFilter.variantMetafield) {
            // Here we update the variants' defaultSelected based on the metafield match
            match =
              match &&
              !!product.variants?.some((variant) => {
                const variantMatch = variant.metafields?.some(
                  (metafield) =>
                    metafield.key === parsedFilter.variantMetafield.key &&
                    metafield.value === parsedFilter.variantMetafield.value,
                );

                if (variantMatch) {
                  if (updateDefaultSelected) {
                    variant.defaultSelected = true;
                  }
                  variantMatched = true; // Update variantMatched if a match is found
                } else if (updateDefaultSelected) {
                  variant.defaultSelected = false;
                }

                return variantMatch;
              });
          }
          if (parsedFilter.productVendor) {
            match = match && product.vendor === parsedFilter.productVendor;
          }
          if (parsedFilter.tag) {
            match = match && !!product.productTags?.includes(parsedFilter.tag);
          }
          if (parsedFilter.available !== undefined) {
            match = match && product.availableForSale === parsedFilter.available;
          }

          return match;
        });

        productMatches = productMatches && matches;
      });

      // If no variant was explicitly matched, ensure only one is selected by default
      if (!variantMatched && product.variants) {
        product.variants?.forEach((variant) => {
          variant.defaultSelected =
            variant.metafields?.find((metafield) => metafield && metafield.key === 'default_selected')?.value ===
            'true';
        });
      }

      return productMatches ? product : null;
    })
    .filter((product): product is ProductInterface => product !== null); // Filter out any null values (products that didn't match)
}
