'use client';
import { BUTTON_SIZE, LINK_STYLES } from '@qlevr/shared/constants';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import LinkRenderer from '../link/link-renderer';
import { emailSubmit } from './email-submit';
import { validationSchema } from './validation';

interface Values {
  email: string;
}

export interface NewsletterSubscribeProps {}

export function NewsletterSubscribe(props: NewsletterSubscribeProps) {
  const [message, setMessage] = useState('');
  const t = useTranslations();
  const formConfig = {
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: Values, { resetForm }: FormikHelpers<Values>) => {
      function onSuccess() {
        setMessage(t('form.success'));
        resetForm();
      }
      function onError(error: string) {
        setMessage(error);
      }
      emailSubmit(values.email, onSuccess, onError);
    },
  };

  return (
    <div>
      <Formik {...formConfig}>
        {({ errors, touched }) => (
          <Form className="space-y-6">
            {message && (
              <p className="text-base font-light text-green-400 text-center">{message}</p>
            )}
            {errors.email && touched.email && (
              <div className="text-base font-light text-red-500 text-center">
                {t(errors.email)}
              </div>
            )}
            <label htmlFor="email" className="sr-only">
              {t('form.input.email')}
            </label>
            <Field
              id='email'
              name="email"
              placeholder={t('form.input.placeholder')}
              className="font-light w-full pl-5 lg:pl-6 pr-12 py-4 border border-slate-300 rounded-full focus:outline-none bg-transparent focus:ring-0 focus:border-slate-300"
              type="email"
            />
            <div className="grid">
              <LinkRenderer
                cta={{
                  label: `${t('form.button.label')}`,
                  as: 'button',
                  linkStyle: LINK_STYLES.primary,
                  size: BUTTON_SIZE.base,
                  className: 'w-full',
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default NewsletterSubscribe;
