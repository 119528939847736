import { ColorThemeType, LINK_STYLES } from '@qlevr/shared/constants';
import { BlogCardInterface } from '@qlevr/shared/interfaces';
import ImageRenderer from '../image-renderer/image-renderer';
import LinkRenderer from '../link/link-renderer';

/* eslint-disable-next-line */
export interface BlogCardProps extends BlogCardInterface {
  theme: ColorThemeType;
}

export function BlogCard({ image, title, slug, description, categories }: BlogCardProps) {
  return (
    <div className="relative bg-slate-100 rounded-lg h-full">
      <div>
        <ImageRenderer
          image={image}
          loadingState='lazy'
          className="aspect-5/4 rounded-t-lg bg-transparent object-cover w-full h-full block"
        />
      </div>
      <div className="p-4 rounded-b-lg text-black">
        <h4 className="text-base font-bold mb-3">
          <LinkRenderer
            cta={{
              as: 'link',
              href: slug,
              label: title,
              linkStyle: LINK_STYLES.textBlack,
              wrapperClassName: 'after:absolute after:inset-0',
            }}
          />
        </h4>
        <p className="text-sm font-normal">{description}</p>
      </div>
    </div>
  );
}

export default BlogCard;
