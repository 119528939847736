import { TITLE_SIZE, TitleSizeType } from '@qlevr/shared/constants';
import { TitleInterface } from '@qlevr/shared/interfaces';
import { TitleStoryblok } from '@qlevr/shared/schema';

export function mapTitle(block: TitleStoryblok | undefined): TitleInterface | null {
  if (!block || !block.title) {
    return null
  };

  return {
    title: block.title,
    heading: mapHeading(block.heading),
    layout: mapLayout(block.layout),
  };
}

export function mapHeading(heading: TitleStoryblok['heading']): TitleInterface['heading'] {
  if (!heading) {
    return 'h2';
  }

  const headingMapping = new Map<TitleStoryblok['heading'], TitleInterface['heading']>([
    ['heading_2', 'h2'],
    ['heading_3', 'h3'],
    ['heading_4', 'h4'],
  ]);

  const item = headingMapping.get(heading);
  if (!item) {
    throw new Error(`Heading ${heading} not found in mapHeading`);
  }

  return item;
}

export function mapLayout(layout: TitleStoryblok['layout']): TitleSizeType {
  if (!layout) {
    return TITLE_SIZE.base;
  }

  const layoutMapping = new Map<TitleStoryblok['layout'], TitleSizeType>([
    ['base', TITLE_SIZE.base],
    ['small', TITLE_SIZE.small],
    ['large', TITLE_SIZE.large],
  ]);

  const item = layoutMapping.get(layout);
  if (!item) {
    throw new Error(`Layout ${layout} not found in mapLayout`);
  }

  return item;
}
