'use client';

import { CartInterface } from '@qlevr/shared/interfaces';
import { getCountryFromCountryLocale, getFreeShippingThresholdByCountry, priceFormat } from '@qlevr/shared/utilities';
import { ComponentType } from 'react';

// Define a generic type for the new props that the HOC will add
interface WithFreeShippingProps {
  locale: string;
  cart: CartInterface;
  isFreeShipping: boolean;
  progress: number;
  amountLeft: string;
  threshold: number;
}

// Helper function to get the display name of a component
function getDisplayName(WrappedComponent: ComponentType<any>): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

// The HOC function takes a component and returns a new component with added props
// We use ComponentType to allow for both class and functional components
export function WithFreeShippingProps<T>(WrappedComponent: ComponentType<T>) {
  // The returned component now also needs to accept T props
  const WithExtraProps = (props: Omit<T, keyof WithFreeShippingProps> & Partial<WithFreeShippingProps>) => {
    const country = getCountryFromCountryLocale(props?.locale);
    if (!country) {
      return <WrappedComponent {...(props as any)} />;
    }

    const threshold = getFreeShippingThresholdByCountry(props.cart?.freeShipping?.threshold, country);
    if (!threshold) {
      return <WrappedComponent {...(props as any)} />;
    }

    const isFreeShipping = Number(props.cart?.cost.subtotalAmount?.amount) >= threshold;
    const progress = Math.min(100, (Number(props.cart?.cost.subtotalAmount?.amount) / threshold) * 100);
    const amountLeft = priceFormat((threshold - Number(props.cart?.cost.subtotalAmount?.amount)).toString());

    const newProps = {
      ...props,
      isFreeShipping,
      progress,
      amountLeft,
      threshold,
    };

    // Render the WrappedComponent with the new props
    return <WrappedComponent {...(newProps as any)} />;
  };

  WithExtraProps.displayName = `WithFreeShippingProps(${getDisplayName(WrappedComponent)})`;

  return WithExtraProps;
}
