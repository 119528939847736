import { CompareProductInterface, MetaInterface } from "@qlevr/shared/interfaces";
import { CompareProductStoryblok, ShopifyProductStoryblok } from "@qlevr/shared/schema";
import { getMetaProductById } from "../../products/products-shopify"; // Ensure this path is correct

export function mapCompareProduct(product: CompareProductStoryblok, meta: MetaInterface): CompareProductInterface | null {

  if (!product) {
    return null;
  }
  
  const comparisonproduct = product?.['product'].items[0] as ShopifyProductStoryblok;
  const productId = comparisonproduct.id;  

  return {
    _editable: product['_editable'] ?? null,
    _uid: product._uid,
    component: product.component,
    product: getMetaProductById(productId, meta)
  };
}
