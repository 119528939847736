import { BREAKPOINTS } from '@qlevr/shared/constants';
import { ImageInterface, PathInterface } from '@qlevr/shared/interfaces';
import { Metadata } from 'next/types';
import { mapAlternatesMetatag } from './alternate-metatags';

export function collectiontMetatagsMapper(
  title: string,
  description: string | null,
  image: ImageInterface | null,
  localisation: PathInterface[] | null,
  canonicalUrl: string | null,
): Metadata {
  const imageUrl = image?.url;

  return {
    title,
    description,
    openGraph: {
      ...(title ? { title } : {}),
      ...(description ? { description } : {}),
      ...(imageUrl
        ? {
            images: [
              {
                url: imageUrl,
                width: BREAKPOINTS.lg,
                height: BREAKPOINTS.lg,
              },
            ],
          }
        : {}),
    },
    alternates: { ...mapAlternatesMetatag(localisation), canonical: canonicalUrl },
  };
}
