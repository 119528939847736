import { ProductSpecificationsInterface } from '@qlevr/shared/interfaces';
import { Maybe, MetafieldShopify, MetaobjectShopify } from '@qlevr/shared/schema';
import { ShopifyRichTextToHtml } from '@qlevr/shared/utilities';
import { getMetafieldReferenceVideoField } from './get-metafield-reference-video-field';
import { getMetafieldReferencesImageField } from './get-metafield-references-image-field';
import { getMetafieldsByKey } from './get-metafields-by-key';

export function getProductSpecificationsReferencesFields(
  title: string,
  references: Maybe<MetafieldShopify>[],
): ProductSpecificationsInterface[] | null {
  const reference = references.find((item) => item?.key === title);

  if (!reference || !reference?.references?.edges) {
    return null;
  }

  return reference.references.edges.reduce((acc, { node }) => {
    const isMetaObject = node.__typename === 'Metaobject';
    if (!isMetaObject) {
      return acc;
    }
    // Now you can use `metaobject` as type `MetaobjectShopify`
    const metaobject = node as MetaobjectShopify;
    const title = getMetafieldsByKey('title', metaobject.fields);

    if (!title) {
      return acc;
    }

    const text = ShopifyRichTextToHtml(getMetafieldsByKey('text', metaobject.fields) || '');
    const type = (node as MetaobjectShopify)?.type;
    const images = getMetafieldReferencesImageField('images', (node as MetaobjectShopify).fields);
    const video = getMetafieldReferenceVideoField('video', (node as MetaobjectShopify).fields);
    acc.push({ title, type, text, images, video });
    return acc;
  }, [] as ProductSpecificationsInterface[]);
}
