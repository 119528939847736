import { MetaInterface, QuizResultPageInterface } from '@qlevr/shared/interfaces';
import { QuizStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';
import { assetStoryblokMapper } from '../asset/asset-storyblok';
import { contentBlocksMapper } from '../content-blocks/content-blocks';
import { mapLocalisedPagePaths } from '../localisation/localised-page-paths';
import { metatagsMapper } from '../metatags/metatags';

export function quizResultPageMapper(
  page: StoryblokStory<QuizStoryblok>,
  meta: MetaInterface,
): QuizResultPageInterface {
  const localisation = mapLocalisedPagePaths(page.alternates);

  return {
    localisation,
    globals: meta.globals ?? null,
    contentBlocks: contentBlocksMapper(page.content.contentBlocks, meta),
    slug: `/${page.full_slug}`,
    image: assetStoryblokMapper(page.content['image']),
    title: page.name,
    description: page.content?.description ?? null,
    metatags: metatagsMapper(
      page.name,
      page.content['metatags'],
      meta?.globals?.metatags,
      localisation,
      page.full_slug,
    ),
  };
}
