'use client';

import { ProductInterface, ProductOptionInterface, SectionInterface } from '@qlevr/shared/interfaces';
import {
  analyticsTrackEvent,
  getDefaultVariantOption,
  getProductVariantByOptionName,
  hasNoVariantOptionsOrJustOne,
  hasSizeAndOrColor,
} from '@qlevr/shared/utilities';
import { useSearchParams } from 'next/navigation';
import { useQueryState } from 'nuqs';
import { useEffect } from 'react';
import ActiveOptionText from './active-option';
import SizeChart from './size-chart-button';
import VariantDropdown from './variant-dropdown';
import VariantImage from './variant-image';
import { productAnalyticsMapper } from '@qlevr/shared/mappers';

/* eslint-disable-next-line */
export interface VariantSelectorProps {
  product: ProductInterface;
  section: SectionInterface;
}

export function isPillLayout(option: ProductOptionInterface, product: ProductInterface) {
  const optionNameLowerCase = option.name.toLowerCase();
  return (
    (optionNameLowerCase === 'size' && product.sizeOptionsLayout === 'pill') ||
    (optionNameLowerCase === 'color' && product.colorOptionsLayout === 'pill')
  );
}

export function VariantSelector({ product, section }: VariantSelectorProps) {
  const searchParams = useSearchParams();

  const [paramSize, setParamSize] = useQueryState('size');
  const [paramColor, setParamColor] = useQueryState('color');

  const activeVariant = getProductVariantByOptionName(
    product.variants ?? [],
    searchParams,
    paramSize ?? '',
    paramColor ?? '',
  );

  useEffect(() => {
    if (activeVariant?.options.length && product.variants && product.variants.length > 0) {
      analyticsTrackEvent(productAnalyticsMapper({ ...product, variants: [activeVariant] }));
    } else if (activeVariant?.options.length === 0) {
      analyticsTrackEvent(productAnalyticsMapper(product));
    }
  }, [activeVariant, product]);

  useEffect(() => {
    if (hasNoVariantOptionsOrJustOne(product)) {
      return;
    }

    if (hasSizeAndOrColor(product.options) && !paramSize && !paramColor) {
      const values = getDefaultVariantOption(product.combinations);
      if (!values) {
        return;
      }

      for (const [key, value] of Object.entries(values)) {
        if (value !== undefined) {
          if (key === 'size') {
            setParamSize(value);
          } else if (key === 'color') {
            setParamColor(value);
          }
        }
      }
    }
  }, [product, setParamSize, setParamColor, paramSize, paramColor]);

  const hasNoOptionsOrJustOneOption =
    !product.options?.length || (product.options.length === 1 && product.options[0]?.values.length === 1);

  if (hasNoOptionsOrJustOneOption || !product) {
    return null;
  }

  interface OptionLayoutProps {
    option: ProductOptionInterface;
  }

  const layoutHandlers = new Map([
    [
      'size',
      new Map([
        [
          'image',
          (option: ProductOptionInterface) => (
            <VariantImage option={option} product={product} searchParams={searchParams} section={section} />
          ),
        ],
        [
          'pill',
          (option: ProductOptionInterface) => (
            <VariantImage option={option} product={product} searchParams={searchParams} section={section} />
          ),
        ],
        [
          'dropdown',
          (option: ProductOptionInterface) => (
            <VariantDropdown option={option} product={product} searchParams={searchParams} />
          ),
        ],
      ]),
    ],
    [
      'color',
      new Map([
        [
          'image',
          (option: ProductOptionInterface) => (
            <VariantImage option={option} product={product} searchParams={searchParams} section={section} />
          ),
        ],
        [
          'pill',
          (option: ProductOptionInterface) => (
            <VariantImage option={option} product={product} searchParams={searchParams} section={section} />
          ),
        ],
        [
          'dropdown',
          (option: ProductOptionInterface) => (
            <VariantDropdown option={option} product={product} searchParams={searchParams} />
          ),
        ],
      ]),
    ],
  ]);

  function getOptionLayout({ option }: OptionLayoutProps): JSX.Element {
    const optionName = option.name.toLowerCase();
    const productLayout =
      optionName === 'size' ? product.sizeOptionsLayout : optionName === 'color' ? product.colorOptionsLayout : '';

    let handler = layoutHandlers.get(optionName)?.get('image');

    if (productLayout) {
      handler = layoutHandlers.get(optionName)?.get(productLayout);
    }

    if (handler) {
      return handler(option);
    } else {
      throw new Error(`Invalid layout type for ${optionName}: ${productLayout}`);
    }
  }

  return product.options?.map((option, index) => (
    <div key={option.id}>
      <div className="mb-3 flex flex-wrap justify-between">
        <ActiveOptionText option={option} index={index} combinations={product.combinations} />
        <SizeChart option={option.name} ageGroups={product.ageGroups} />
      </div>
      <div className={`flex flex-wrap ${isPillLayout(option, product) ? 'gap-2' : 'gap-3'} ml-1 lg:ml-0`}>
        {getOptionLayout({ option })}
      </div>
    </div>
  ));
}

export default VariantSelector;
