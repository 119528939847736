import { ProductColorOptionName, ProductSizeOptionName } from '@qlevr/shared/constants';

/**
 * Returns the default locale product option name based on the provided localized option.
 * If the localized option is not found in the reverse map, it returns the original localized option.
 * @param localizedOption - The localized product option name.
 * @returns The default locale product option name.
 * @example getDefaultLocaleProductOptionName('kleur') => 'color'
 * @example getDefaultLocaleProductOptionName('taille') => 'size'
 */

export function getDefaultLocaleProductOptionName(localizedOption: string): string {
  const combinedOptionsReverseMap = new Map<string, string>();

  Object.entries(ProductColorOptionName).forEach(([key, value]) => {
    if (key !== 'en') {
      combinedOptionsReverseMap.set(value.toLowerCase(), ProductColorOptionName.en);
    }
  });

  Object.entries(ProductSizeOptionName).forEach(([key, value]) => {
    if (key !== 'en') {
      combinedOptionsReverseMap.set(value.toLowerCase(), ProductSizeOptionName.en);
    }
  });

  const englishVersion = combinedOptionsReverseMap.get(localizedOption.toLowerCase());

  return englishVersion || localizedOption;
}
