/**
 * Splits a text to a maximum number of characters.
 * If the text length exceeds the maximum number of characters, it truncates the text and adds ellipsis.
 * @param text The text to be split.
 * @param maxChars The maximum number of characters.
 * @returns The split text.
 */
export function splitTextToMaxCharacters(text: string, maxChars: number): string {
  return text.length > maxChars ? text.slice(0, maxChars) + '...' : text;
}
