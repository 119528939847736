import { CollectionCardInterface, ElevarDataLayerEvent, Impression } from '@qlevr/shared/interfaces';
import { escapeUrl, getShopifyProductId } from '@qlevr/shared/utilities';

export function collectionAnalyticsMapper(collection: CollectionCardInterface): ElevarDataLayerEvent {
  const impressions = collection?.products.map((item, index) => {
    const color = escapeUrl(item.productCombinationColor ?? '');
    const size = escapeUrl(item.productCombinationSize ?? '');

    const activeVariant =
      item.variants?.find((variant) => {
        return (
          variant.options.find((option) => {
            return option.name === 'color' && option.value === color && variant.availableForSale;
          }) &&
          variant.options.find((option) => {
            return option.name === 'size' && option.value === size;
          })
        );
      }) ?? item.variants?.[0];

    return {
      id: getShopifyProductId(item.id ?? ''),
      name: item.title,
      brand: item.vendor !== '' ? `${item.vendor}` : item.productType ?? 'undefined',
      category: `${item.productType ?? 'undefined'}`,
      variant: activeVariant?.options.length
        ? activeVariant?.options.map((option) => option.value).join(' - ')
        : item.title,
      price: item.priceRange?.minVariantPrice?.amount.toString() ?? '0.0',
      quantity: '1',
      list: collection.slug ?? '',
      product_id: getShopifyProductId(item.id ?? ''),
      variant_id: activeVariant?.id ? getShopifyProductId(activeVariant.id) : '',
      compare_at_price: item.compareAtPriceRange?.minVariantPrice.amount.toString() ?? '0.0',
      position: index + 1,
      image: item.featuredImage?.url,
      url: activeVariant?.slug !== '' ? activeVariant?.slug : item.slug,
    };
  });

  return {
    event: 'dl_view_item_list',
    user_properties: {
      visitor_type: 'guest',
    },
    ecommerce: {
      currencyCode: 'EUR',
      impressions: impressions as Impression[],
    },
  };
}
