'use client';

import { isProduction } from '@qlevr/shared/utilities';
import { useEffect } from 'react';

export function CookieConsent() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//cdn.cookie-script.com/s/b52dbe6fedb93323cb55128e3ae9bd93.js';
    script.type = 'text/javascript';
    script.async = true;

    if (isProduction()) {
      document.body.appendChild(script);
    }

    return () => {
      if (isProduction()) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return null;
}

export default CookieConsent;
