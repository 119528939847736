'use client';
import { ContentVideoInterface } from '@qlevr/shared/interfaces';
import Section from '../section/section';
import VideoPlayer from '../video-player/video-player';
import VideoStretchedPlayPause from '../video-player/video-stretched-playpause';

/* eslint-disable-next-line */
export interface VideoProps extends ContentVideoInterface {}

export function ContentVideo(props: VideoProps) {
  return (
    <Section {...props.section}>
      <div className={`relative ${props.video.ratio} ${props.video.ratioLg}`}>
        <VideoPlayer
          video={props.video}
          overlay={props.overlay}
          scaleAnimation={props.scaleAnimation}
          playPauseComponent={VideoStretchedPlayPause}
        />
      </div>
    </Section>
  );
}

export default ContentVideo;
