import { LinkInterface } from '@qlevr/shared/interfaces';
import BaseLinkElementRenderer from './base/base-link-element-renderer';
import IconRenderer from '../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface IconLinkProps {
  cta: LinkInterface;
}

export function IconLink(props: IconLinkProps) {
  return (
    <BaseLinkElementRenderer {...props} cta={{ ...props.cta, label: '', leadingIcon: null, trailingIcon: null }}>
      <IconRenderer
        icon={props.cta.leadingIcon}
        {...(props.cta.leadingClassName ? { className: props.cta.leadingClassName } : {})}
      />
    </BaseLinkElementRenderer>
  );
}

export default IconLink;
