'use client';

import { usePathname } from 'next/navigation';
import {
  AnalyticsEventName,
  getClientBrowserParameters,
  sendShopifyAnalytics,
  ShopifyAnalyticsProduct,
  ShopifyPageViewPayload,
  ShopifySalesChannel,
  useShopifyCookies,
} from '@shopify/hydrogen-react';


type SendPageViewPayload = {
  pageType?: string;
  products?: ShopifyAnalyticsProduct[];
  collectionHandle?: string;
  searchString?: string;
  totalValue?: number;
  cartId?: string;
};

type SendAddToCartPayload = {
  cartId: string;
  products?: ShopifyAnalyticsProduct[];
  totalValue?: ShopifyPageViewPayload['totalValue'];
};

export function useShopifyAnalytics() {
  const SHOP_ID = '77417447748';
  const pathname = usePathname();
  // Send page view event
  const sendPageView = (eventName: keyof typeof AnalyticsEventName, payload?: SendPageViewPayload) => {
    return sendShopifyAnalytics({
      eventName,
      payload: {
        ...getClientBrowserParameters(),
        hasUserConsent: true,
        shopifySalesChannel: ShopifySalesChannel.headless,
        shopId: `gid://shopify/Shop/${SHOP_ID}`,
        currency: 'EUR',
        acceptedLanguage: 'EN',
        analyticsAllowed: true,
        marketingAllowed: true,
        saleOfDataAllowed: true,
        ...payload,
      },
    });
  };

  // Send add to cart event
  const sendAddToCart = ({ cartId, totalValue, products }: SendAddToCartPayload) => {
    return sendPageView(AnalyticsEventName.ADD_TO_CART, {
      cartId,
      totalValue,
      products,
    });
  };

  // Set up cookies for Shopify analytics & enable user consent
  useShopifyCookies({
    hasUserConsent: true,
  });

  return {
    sendPageView,
    sendAddToCart,
    pathname,
  };
}
