'use client';

import { BUTTON_SIZE, LINK_STYLES } from '@qlevr/shared/constants';
import { useLogin } from '@qlevr/shared/providers';
import { Modal } from 'flowbite-react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';
import { useState } from 'react';
import LinkRenderer from '../link/link-renderer';
import { validationSchema } from '../newsletter/login-form-validation';
import { formModalTheme } from './form-modal-theme';

interface Values {
  email: string;
  password: string;
}

interface LoginFormProps {
  showLogin: boolean;
  setShowLogin: (showLogin: boolean) => void;
  setShowRegister: (showRegister: boolean) => void;
}

export default function LoginForm({ showLogin, setShowLogin, setShowRegister }: LoginFormProps) {
  const t = useTranslations();
  const [message, setMessage] = useState('');
  const { loginFormSubmit } = useLogin();
  const { locale } = useParams<{ locale: string }>();

  const formConfig = {
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: Values, { resetForm }: FormikHelpers<Values>) => {
      function onSuccess() {
        setMessage(t('customer.loginForm.success'));
        resetForm();
        setShowLogin(false);
      }

      function onError(error: string) {
        setMessage(error);
      }

      loginFormSubmit(values.email, values.password, onSuccess, onError, locale);
    },
  };

  return (
    <Modal
      dismissible
      size="md"
      theme={formModalTheme}
      show={showLogin}
      position={'center'}
      onClose={() => setShowLogin(false)}
      className="bg-black/25 backdrop-blur-sm"
    >
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <Formik {...formConfig}>
          {({ errors, touched }) => (
            <Form className="relative z-10 w-full rounded-lg bg-slate-100 px-4 pb-10">
              <div className="space-y-4">
                <h2 className="px-2 text-center font-sans text-3xl font-bold text-[#13283F]">
                  {t('customer.loginForm.title')}
                </h2>
                <div className="space-y-1">
                  <div className="relative mb-4 w-full">
                    <div>
                      <label htmlFor="email" className="ml-4 font-serif text-xs font-bold capitalize">
                        {t('form.input.email')}
                      </label>
                      <Field
                        id="email"
                        name="email"
                        placeholder={t('form.input.placeholder')}
                        className="w-full rounded-full border border-slate-300 bg-transparent bg-white py-3 pl-4 pr-10 font-serif font-light placeholder:text-sm placeholder:text-slate-800 focus:border-slate-300 focus:outline-none focus:ring-0"
                        type="email"
                      />
                      <div className="mt-1 pl-4 text-sm">
                        {errors.email && touched.email && <p className="text-red-600">{t(errors.email)}</p>}
                      </div>
                    </div>
                    <div>
                      <label htmlFor="password" className="ml-4 font-serif text-xs font-bold capitalize">
                        {t('form.input.password')}
                      </label>
                      <Field
                        id="password"
                        name="password"
                        placeholder={t('form.input.password')}
                        className="w-full rounded-full border border-slate-300 bg-transparent bg-white py-3 pl-4 pr-10 font-serif font-light placeholder:text-sm placeholder:text-slate-800 focus:border-slate-300 focus:outline-none focus:ring-0"
                        type="password"
                      />

                      {errors.password && touched.password && <p className="text-red-600">{t(errors.password)}</p>}
                    </div>
                  </div>
                  <div className="py-2 text-center text-sm">{message && <p className="text-red-600">{message}</p>}</div>
                </div>
              </div>
              <div>
                <LinkRenderer
                  cta={{
                    label: t('customer.loginForm.submitButtonLabel'),
                    as: 'button',
                    linkStyle: LINK_STYLES.primary,
                    size: BUTTON_SIZE.base,
                    wrapperClassName: `w-full font-bold`,
                    type: 'submit',
                  }}
                />
              </div>
              <div className="flex justify-center gap-1 pt-4 text-center text-sm">
                <span>{t('customer.loginForm.registerText')}</span>
                <button
                  type="button"
                  className="text-azure-500 text-sm hover:underline"
                  onClick={() => {
                    setShowLogin(false);
                    setShowRegister(true);
                  }}
                >
                  {t('customer.loginForm.registerLink')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
