'use client';

import { ProductInterface, ProductVariantInterface } from '@qlevr/shared/interfaces';
import React, { createContext, useContext, useState } from 'react';
interface GuidedBuyingFlowProviderProps {
  children: React.ReactNode;
}

export interface FlowProducts {
  mainProductId: string;
  bundledVariants: {
    key: string;
    id: string;
    quantity: number;
  }[];
}

interface GuidedBuyingFlowContextType {
  changeVariant: (variant: ProductVariantInterface) => void;
  selectedProductVariantOptions: (product: ProductInterface, size?: string, color?: string) => void;
  variant: ProductVariantInterface | null;
  addVariantId: (uniqueKey: string, mainProductId: string, variantId: string) => void;
  replaceVariantId: (uniqueKey: string, mainProductId: string, oldVariantId: string, newVariantId: string) => void;
  removeVariantId: (uniqueKey: string, mainProductId: string, variantId: string) => void;
  updateVariantQuantity: (uniqueKey: string, mainProductId: string, variantId: string, quantity: number) => void;
  flowProducts: FlowProducts[];
  isOpen: boolean;
  toggle: () => void;
  open: () => void;
  close: () => void;
}

export const GuidedBuyingFlowContext = createContext<GuidedBuyingFlowContextType>({
  changeVariant: () => {},
  selectedProductVariantOptions: () => {},
  variant: null,
  addVariantId: () => {},
  replaceVariantId: () => {},
  removeVariantId: () => {},
  updateVariantQuantity: () => {},
  flowProducts: [],
  isOpen: false,
  toggle: () => {},
  open: () => {},
  close: () => {},
});

export const GuidedBuyingFlowProvider: React.FC<GuidedBuyingFlowProviderProps> = ({ children }) => {
  const [variant, setVariant] = useState<ProductVariantInterface | null>(null);
  const [, setSize] = useState<string | null>(null);
  const [, setColor] = useState<string | null>(null);
  const [flowProducts, setFlowProducts] = useState<FlowProducts[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((prevSate) => !prevSate);
  };
  const open = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
  };

  const changeVariant = (variant: ProductVariantInterface) => {
    setVariant(variant);
  };

  const selectedProductVariantOptions = (product: ProductInterface, size?: string, color?: string) => {
    if (color) {
      setColor(color);
    }
    if (size) {
      setSize(size);
    }

    const variant = product.variants?.find((variant) =>
      variant.options.every((option) => {
        if (option.name.toLowerCase() === 'size' && size) {
          return option.value === size;
        }
        if (option.name.toLowerCase() === 'color' && color) {
          return option.value === color;
        }

        return false;
      }),
    );

    setVariant(variant || null);
  };

  const addVariantId = (uniqueKey: string, mainProductId: string, variantId: string) => {
    setFlowProducts((prevFlowProducts) => {
      const existingProduct = prevFlowProducts.find((product) => product.mainProductId === mainProductId);

      if (existingProduct) {
        const existingVariant = existingProduct.bundledVariants.find((variant) => variant.key === uniqueKey);
        if (existingVariant) {
          return prevFlowProducts;
        }
        return prevFlowProducts.map((product) =>
          product.mainProductId === mainProductId
            ? {
                ...product,
                bundledVariants: [...product.bundledVariants, { id: variantId, quantity: 1, key: uniqueKey }],
              }
            : product,
        );
      }

      return [
        ...prevFlowProducts,
        { mainProductId, bundledVariants: [{ id: variantId, quantity: uniqueKey.startsWith('main-') ? 1 : 0, key: uniqueKey }] },
      ];
    });
  };

  const replaceVariantId = (uniqueKey: string, mainProductId: string, oldVariantId: string, newVariantId: string) => {
    setFlowProducts((prevFlowProducts) => {
      return prevFlowProducts.map((product) =>
        product.mainProductId === mainProductId
          ? {
              ...product,
              bundledVariants: product.bundledVariants.map((variant) =>
                variant.key === uniqueKey ? { ...variant, id: newVariantId } : variant,
              ),
            }
          : product,
      );
    });
  };

  const removeVariantId = (uniqueKey: string, mainProductId: string, variantId: string) => {
    setFlowProducts((prevFlowProducts) => {

      const updatedFlowProducts = prevFlowProducts
        .map((product) => {
          if (product.mainProductId === mainProductId) {
            const updatedVariants = product.bundledVariants.filter((variant) => variant.key !== uniqueKey);
            return { ...product, bundledVariants: updatedVariants };
          }
          return product;
        })
        .filter((product) => product.bundledVariants.length > 0);

      // Update uniqueKey
      const updatedFlowProductsWithKey = updatedFlowProducts.map((product) => {
        const updatedVariants = product.bundledVariants.map((variant, index) => {
          return { ...variant, key: `${product.mainProductId}-${index}` };
        });
        return { ...product, bundledVariants: updatedVariants };
      });

      return updatedFlowProductsWithKey;
    });
  };

  const updateVariantQuantity = (uniqueKey: string, mainProductId: string, variantId: string, quantity: number) => {
    setFlowProducts((prevFlowProducts) =>
      prevFlowProducts.map((product) =>
        product.mainProductId === mainProductId
          ? {
              ...product,
              bundledVariants: product.bundledVariants.map((variant) =>
                variant.key === uniqueKey ? { ...variant, quantity } : variant,
              ),
            }
          : product,
      ),
    );
  };

  return (
    <GuidedBuyingFlowContext.Provider
      value={{
        changeVariant,
        selectedProductVariantOptions,
        addVariantId,
        replaceVariantId,
        removeVariantId,
        updateVariantQuantity,
        variant,
        flowProducts,
        isOpen, toggle, open, close
      }}
    >
      {children}
    </GuidedBuyingFlowContext.Provider>
  );
};

export const useGuidedBuyingFlowBundler = () => {
  const context = useContext(GuidedBuyingFlowContext);
  if (context === undefined) {
    throw new Error('useGuidedBuyingFlowBundler must be used within a GuidedBuyingFlowProvider');
  }
  return context;
};
