import { LinkInterface } from '@qlevr/shared/interfaces';
import BaseLinkElementRenderer from './base/base-link-element-renderer';

/* eslint-disable-next-line */
export interface SecondaryLinkProps {
  cta: LinkInterface;
}

export function SecondaryLink(props: SecondaryLinkProps) {
  return (
    <BaseLinkElementRenderer
      {...props}
      cta={{
        ...props.cta,
        wrapperClassName: `btn-secondary flex items-center justify-center rounded-full px-12 py-4 font-sans text-lg gap-3 ${props.cta.wrapperClassName}`,
      }}
    />
  );
}

export default SecondaryLink;
