import { RichtextStoryblok } from '@qlevr/shared/schema';
import { storyblokInit } from '@storyblok/js';
import { renderRichText } from "@storyblok/js";

// This resolve the comment "Please initialize the Storyblok SDK before calling the renderRichText function"
storyblokInit({
  accessToken: process.env['NEXT_PUBLIC_STORYBLOK_API_TOKEN'],
});

export function mapRichText(text?: RichtextStoryblok): string | '' {
  if(!text) return '';

  return renderRichText(text);
}
