import { ContentBlogInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { BlogsStoryblok } from '@qlevr/shared/schema';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';

export function mapContentBlockBlog(content: BlogsStoryblok, meta: MetaInterface): ContentBlogInterface | null {
  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]) ?? null,
    blog: meta.blog ?? null,
    section: sectionMapper(content.section?.[0]),
    allCategories: meta.categories ?? null,
  };
}
