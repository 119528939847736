export const bundleSaveUpsellTheme = {
  content: {
    base: 'relative h-full w-full bundle-modal',
    inner: 'relative bg-white shadow dark:bg-gray-700 flex flex-col h-full',
  },
  body: {
    base: 'flex-1 overflow-auto bg-slate-100 py-6 px-4 lg:px-10 space-y-2',
  },
  header: {
    base: 'p-4 pb-0',
    title: 'text-left text-base font-bold text-black dark:text-white',
    close: {
      icon: 'text-black dark:text-white w-6 h-6 stroke-2',
    },
  },
  footer: {
    base: 'bg-white border-none py-6 px-4 rounded-t-lg shadow-2xl shadow-black',
  },
};
