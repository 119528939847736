import { ProductVariantInterface } from '@qlevr/shared/interfaces';

/**
 * Calculates the product's availability, considering pre-order threshold and inventory quantity.
 * Updates the product's availability and stock status based on certain conditions.
 *
 * @param variant The product variant object containing inventory and pre-order information.
 * @returns An object containing the total available quantity, pre-order status, availability for sale, and stock status.
 */
export function calculateVariantAvailability(variant: ProductVariantInterface) {
  let totalAvailableQuantity: number | null = null;
  let isPreOrder = false;
  let availableForSale = variant.availableForSale;
  let currentlyNotInStock = variant.currentlyNotInStock;

  // Calculate total available quantity considering pre-order threshold and inventory quantity
  if (variant.preOrderThreshold && variant.inventoryQuantity <= 0) {
    totalAvailableQuantity = variant.preOrderThreshold + variant.inventoryQuantity; // 50 + (-10) = 40, 50 + (-50) = 0, 50 + (-60) = -10
  }

  if (totalAvailableQuantity !== null) {
    // Determine if the product is available for pre-order
    isPreOrder =
      totalAvailableQuantity > 0 &&
      variant.inventoryQuantity <= 0 &&
      variant.currentlyNotInStock &&
      variant.availableForSale;

    if (totalAvailableQuantity <= 0 && variant.inventoryQuantity <= 0 && variant.currentlyNotInStock) {
      availableForSale = false;
      currentlyNotInStock = false;
    }
  }

  return {
    totalAvailableQuantity,
    isPreOrder,
    availableForSale,
    currentlyNotInStock,
  };
}
