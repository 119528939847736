'use client';

import { ICONS } from '@qlevr/shared/constants';
import { ImageInterface, ProductInterface } from '@qlevr/shared/interfaces';
import { GuidedBuyingFlowContext, useCart } from '@qlevr/shared/providers';
import {
  calculateDiscountPrice,
  getProductVariantByOptionName,
  getSizeColorVariantFromUrl,
  reverseEscapeUrl,
} from '@qlevr/shared/utilities';
import { useParams, useSearchParams } from 'next/navigation';
import { useContext, useEffect, useRef, useState } from 'react';
import IconRenderer from '../../../icons/icon-renderer.component';
import ImageRenderer from '../../../image-renderer/image-renderer';
import { useTranslations } from 'next-intl';

export interface GuidedBuyingFlowMainProductCardProps {
  product: ProductInterface | null;
}

export function GuidedBuyingFlowMainProductCard({ product }: GuidedBuyingFlowMainProductCardProps) {
  const t = useTranslations();
  const { addVariantId, replaceVariantId, removeVariantId, changeVariant, variant, close } =
    useContext(GuidedBuyingFlowContext);
  const [activeImage, setActiveImage] = useState<ImageInterface | null>(product?.featuredImage ?? null);
  const previousVariantId = useRef<string | null>(null);
  const cartContext = useCart();
  const searchParams = useSearchParams();
  const params = useParams<{ variant: string }>();
  const variantInfo = getSizeColorVariantFromUrl(params);
  const selectedVariant = getProductVariantByOptionName(
    product?.variants ?? [],
    searchParams,
    variantInfo?.size,
    variantInfo?.color,
  );

  // Set the first available variant as the selected variant
  useEffect(() => {
    if (selectedVariant && !variant) {
      changeVariant(selectedVariant);
    } else if (!selectedVariant && product?.firstAvailableVariant) {
      changeVariant(product.firstAvailableVariant);
    }
  }, [selectedVariant, variant, changeVariant, product]);

  useEffect(() => {
    const uniqueKey = `main-${product?.id}`;

    if (selectedVariant && product?.id) {
      setActiveImage(selectedVariant.images?.[0]?.image || product.featuredImage || null);
      if (previousVariantId.current) {
        replaceVariantId(uniqueKey, product.id, previousVariantId.current, selectedVariant.id);
      } else {
        addVariantId(uniqueKey, product.id, selectedVariant.id);
      }
      previousVariantId.current = selectedVariant.id;
    } else {
      setActiveImage(product?.featuredImage ?? null);
    }

    // Clean up previous variant ID
    return () => {
      if (previousVariantId.current && product?.id) {
        removeVariantId(uniqueKey, product.id, previousVariantId.current);
        previousVariantId.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant, product]);

  if (!product) {
    return null;
  }

  return (
    <>
      <div className="relative my-4 flex flex-col items-end justify-between space-y-2 rounded-lg border-gray-300 bg-white p-2">
        <div className="flex w-full">
          {activeImage && <ImageRenderer image={activeImage} className="mr-4 h-20 w-full max-w-20 rounded-lg" />}
          <div className="w-full">
            <div className="text-base font-bold">{product.title}</div>
            <div className="mb-1 flex flex-col text-xs font-normal leading-5">
              {selectedVariant?.options.map((option) => (
                <span key={option.name}>
                  <span className="capitalize">{option.name}:</span> {reverseEscapeUrl(option.value)}
                </span>
              ))}
            </div>
            <div className="flex items-center justify-between">
              <div className="flex gap-2 font-serif text-base">
                <span className={`${product.discountPercentage ? 'text-slate-400 line-through' : ''}`}>
                  {selectedVariant?.price?.amount && (
                    <span>
                      {selectedVariant.price?.symbol}
                      {selectedVariant.price?.amount}
                    </span>
                  )}
                </span>
                {product.discountPercentage && selectedVariant?.price?.amount && (
                  <span>
                    {selectedVariant?.price?.symbol}
                    {calculateDiscountPrice(selectedVariant?.price?.amount, product.discountPercentage ?? 0)}
                  </span>
                )}
              </div>

              <button
                className={`flex items-center gap-1 rounded-full bg-green-500 px-4 py-2 text-xs font-bold text-white`}
              >
                Added <IconRenderer icon={ICONS.checkmark} className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn-primary flex w-full items-center justify-center gap-2 rounded-full px-3 py-4 text-center text-lg font-bold shadow-md"
        onClick={() => {
          close();
          cartContext.open();
        }}
      >
        {t('guidedBuyingFlow.goToCart')}
        <IconRenderer icon={ICONS.arrowRight} className="h-4 w-4" />
      </button>
    </>
  );
}

export default GuidedBuyingFlowMainProductCard;
