import { PathInterface } from '@qlevr/shared/interfaces';
import { GlobalStoryblok, MetatagsStoryblok } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { Metadata } from 'next/types';
import { StoryblokStory } from 'storyblok-generate-ts';
import { assetStoryblokMapper } from '../asset/asset-storyblok';
import { mapAlternatesMetatag } from './alternate-metatags';
import { getFullUrlFromStoryblokRelativeSlug } from '@qlevr/shared/utilities';

export function metatagsMapper(
  title: string,
  page: MetatagsStoryblok[] | undefined,
  globalMetatags?: Metadata | null,
  localisation?: PathInterface[] | null,
  full_slug?: string | null,
): Metadata {
  const pageMetatags = page?.[0];
  if (!pageMetatags || isEmpty(globalMetatags)) {
    return {};
  }

  let canonical = full_slug && getFullUrlFromStoryblokRelativeSlug(full_slug);
  if (pageMetatags.canonicalUrl?.cached_url) {
    canonical = getFullUrlFromStoryblokRelativeSlug(pageMetatags.canonicalUrl.cached_url);
  }

  const meta: Metadata = {
    title: pageMetatags.title || title || globalMetatags?.title || null,
    description: pageMetatags.description || globalMetatags?.description || null,
    robots: pageMetatags.robots,
    openGraph: {
      ...globalMetatags.openGraph,
      title: pageMetatags.title || title || globalMetatags?.openGraph?.title,
      description: pageMetatags.description || globalMetatags?.openGraph?.description,
    },
    alternates: {
      ...mapAlternatesMetatag(localisation),
      canonical: canonical,
    },
  };

  const image = assetStoryblokMapper(pageMetatags.image);
  if (!image || !meta.openGraph) {
    return meta;
  }

  meta.openGraph.images = [
    {
      url: image.url,
    },
  ];

  return meta;
}

export function globalsMetatagsMapper(globals: StoryblokStory<GlobalStoryblok>): Metadata {
  const metatags = globals?.content?.['metatags']?.[0];

  const meta: Metadata = {
    title: metatags?.title || null,
    description: metatags?.description || null,
    robots: metatags?.robots,
    openGraph: {
      type: 'website',
      title: metatags?.title || '',
      description: metatags?.description || '',
      siteName: globals?.content?.siteName,
    },
  };

  if (!metatags) {
    return meta;
  }

  const image = assetStoryblokMapper(metatags?.image);
  if (!image || !meta.openGraph) {
    return meta;
  }

  meta.openGraph.images = [
    {
      url: image.url,
    },
  ];

  return meta;
}
