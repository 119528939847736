import { ProductSpecificationsInterface } from '@qlevr/shared/interfaces';
import { Maybe, MetafieldShopify } from '@qlevr/shared/schema';
import { getProductSpecificationsReferencesFields } from '../metafield/get-product-specifications-references-field';

export function mapProductSpecifications(metafields: Maybe<MetafieldShopify>[]): ProductSpecificationsInterface[] | null {
  if (!metafields) {
    return null;
  }

  return getProductSpecificationsReferencesFields('product_specifications', metafields);
}
