export const cartModalTheme = {
  content: {
    base: 'relative h-full w-full cart-modal',
    inner: 'relative bg-white max-w-md shadow dark:bg-gray-700 flex flex-col h-full',
  },
  body: {
    base: 'flex-1 overflow-auto',
  },
  header: {
    base: 'flex items-start justify-between p-4',
    title: 'text-center grow text-xl font-medium text-gray-900 dark:text-white',
    close: {
      base: 'ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400',
      icon: 'h-5 w-5',
    },
  },
  footer: {
    base: 't-cart-summary-bg border-none px-6 pb-6 pt-3 lg:px-10 rounded-t-lg shadow-2xl shadow-black empty:hidden',
  },
};
