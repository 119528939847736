import { ColorThemeType } from '@qlevr/shared/constants';
import { LinkImageCardInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import TitleImageCard from '../card/title-image-card/title-image-card';

/* eslint-disable-next-line */
export interface MenuProductsProps {
  products: LinkImageCardInterface[] | null;
  hasFeaturedCard?: boolean;
  theme: ColorThemeType;
}

export function MenuProducts(props: MenuProductsProps) {
  if (!props.products || isEmpty(props.products)) {
    return null;
  }

  const widthClassname = props.hasFeaturedCard ? 'lg:w-1/3' : 'w-full';

  return (
    <div className={widthClassname}>
      <div className="flex pl-5 lg:p-0 overflow-scroll lg:overflow-hidden lg:grid lg:grid-cols-3 gap-4 lg:whitespace-normal">
        {props.products.map((product, key) => (
          <TitleImageCard key={key} {...product} theme={props.theme} />
        ))}
      </div>
    </div>
  );
}

export default MenuProducts;
