import { BREAKPOINTS } from '@qlevr/shared/constants';
import { ColumnConfigInterface } from '@qlevr/shared/interfaces';

/**
 * Fills in missing breakpoints in an image source set configuration object, ensuring that every breakpoint
 * has a value. If a breakpoint is missing, it assigns the value from the last defined breakpoint before it.
 * This ensures continuity and consistency across the breakpoints. The function respects the predefined order
 * of breakpoints, filling in missing ones based on this sequence.
 *
 * @param {Partial<ColumnConfigInterface>} config - A partial configuration object that may contain some
 * of the breakpoints defined. This object is used as the base for filling in missing breakpoints.
 *
 * @returns {ColumnConfigInterface} A fully populated `ColumnConfigInterface` object with no missing breakpoints.
 * Each missing breakpoint is assigned the value of the last set breakpoint preceding it. If no breakpoints
 * were set before a missing breakpoint, the missing breakpoint remains undefined in the returned object.
 *
 * @example
 * // Define a partial configuration with some breakpoints missing
 * const partialConfig = { xs: 1, lg: 2 };
 *
 * // Fill in missing breakpoints
 * const completeConfig = fillMissingBreakpoints(partialConfig);
 *
 * // Result:
 * // {
 * //   xs: 1,
 * //   sm: 1,
 * //   md: 1,
 * //   lg: 2,
 * //   xl: 2,
 * //   xl2: 2
 * // }
 *
 * @remarks
 * - The function does not modify the input `config` object; instead, it returns a new object with the
 *   missing breakpoints filled in.
 * - The order of breakpoints is determined by a predefined array `breakpointsOrder` that specifies the
 *   sequence of breakpoints to be considered. It is important that `breakpointsOrder` is correctly
 *   defined and matches the `ColumnConfigInterface`.
 * - This function is useful for ensuring that an image source set configuration is complete and consistent
 *   before being used in applications where responsive image sources are required.
 */
export function fillMissingBreakpoints(config: Partial<ColumnConfigInterface>): ColumnConfigInterface {
  const filledConfig: ColumnConfigInterface = {};
  let lastSetValue: number | undefined = 1;

  for (const breakpoint of Object.keys(BREAKPOINTS) as (keyof typeof BREAKPOINTS)[]) {
    if (config[breakpoint] !== undefined) {
      lastSetValue = config[breakpoint];
    }
    if (lastSetValue !== undefined) {
      // @ts-ignore: The key is a valid breakpoint
      filledConfig[breakpoint] = lastSetValue;
    }
  };

  return filledConfig;
}
