'use client';

import { ProductInterface, SectionInterface } from '@qlevr/shared/interfaces';
import { getProductVariantByOptionName, getSizeColorVariantFromUrl } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { useTranslations } from 'next-intl';
import { useParams, useSearchParams } from 'next/navigation';
import ProductBuyDiscountPercentage from './product-buy-discount-percentage';

export interface ProductBuySaleBadgeProps {
  product: ProductInterface;
  section: SectionInterface;
  showPercentage?: boolean;
}

export default function ProductBuySaleBadge({ product, section, showPercentage = false }: ProductBuySaleBadgeProps) {
  const t = useTranslations();
  const searchParams = useSearchParams();
  const params = useParams<{ variant: string }>();
  const variantInfo = getSizeColorVariantFromUrl(params);

  if (isEmpty(product.variants)) return null;

  const activeVariant = getProductVariantByOptionName(
    product.variants ?? [],
    searchParams,
    variantInfo?.size,
    variantInfo?.color,
  );
  if (!activeVariant || !activeVariant.compareAtPrice) return null;

  return (
    <div className={`${section.theme.badge} w-fit rounded-full px-4 py-1 text-xs font-bold capitalize`}>
      {showPercentage ? (
        <ProductBuyDiscountPercentage
          originalPrice={activeVariant.compareAtPrice.amount}
          discountedPrice={activeVariant.price?.amount}
        />
      ) : (
        t('saleBadge')
      )}
    </div>
  );
}
