import { ColorThemeType } from '@qlevr/shared/constants';
import { ReactNode } from 'react';

/* eslint-disable-next-line */
export interface ThemeProps {
  children: ReactNode;
  className?: string;
  theme: ColorThemeType;
}

export function Theme({ children, className, theme }: ThemeProps) {
  return <div className={`${theme?.background} ${theme?.color.text} ${className}`}>{children}</div>;
}

export default Theme;
