import { CART_QUANTITY, CartQuantityType } from '@qlevr/shared/constants';
import { PayloadType } from '@qlevr/shared/interfaces';
import { useCart } from '@qlevr/shared/providers';
import { startTransition } from 'react';
import CartQuantitySubmit from './cart-quantity-submit';

/* eslint-disable-next-line */
export interface CartQuantityFormProps {
  type: CartQuantityType;
  quantity: number;
  variantId: string;
  lineItemId: string;
}

export function CartQuantityForm({ type, quantity, variantId, lineItemId }: CartQuantityFormProps) {
  const { update } = useCart();

  const payload: PayloadType = {
    lineItemId,
    variantId,
    quantity: type === CART_QUANTITY.increase ? quantity + 1 : quantity - 1,
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        startTransition(() => {
          update(payload, type);
        });
      }}
    >
      <CartQuantitySubmit quantity={quantity} type={type} />
    </form>
  );
}

export default CartQuantityForm;
