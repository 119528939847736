'use client';

import { CartInterface, GlobalInterface } from '@qlevr/shared/interfaces';
import { useCart } from '@qlevr/shared/providers';
import isEmpty from 'lodash.isempty';
import { useParams } from 'next/navigation';
import FreeShippingProgress from '../../free-shipping/free-shipping-progress';
import CartLineItems from '../cart-line-items/cart-line-items';
import EmptyCart from '../empty-cart';
import CartUpsell from '../upsell/cart-upsell';

/* eslint-disable-next-line */
export interface CartModalBodyProps {
  cart?: CartInterface;
  globals?: GlobalInterface;
}

export function CartModalBody({ globals }: CartModalBodyProps) {
  const { locale } = useParams<{ locale: string }>();
  const { cart } = useCart();

  if (!cart || isEmpty(cart.items)) {
    return <EmptyCart globals={globals} />;
  }

  return (
    <>
      <div className="space-y-6 px-5">
        <FreeShippingProgress locale={locale} cart={cart} />
        <div className="divide-y divide-slate-200 pb-10">
          <CartLineItems lineItems={cart.items} />
        </div>
      </div>
      <CartUpsell cart={cart} />
    </>
  );
}

export default CartModalBody;
