import { LinkInterface, ProductInterface } from '@qlevr/shared/interfaces';
import { reverseEscapeUrl } from '@qlevr/shared/utilities';
import { AnimatePresence, motion } from 'framer-motion';
import { useSearchParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import LinkRenderer from '../link/link-renderer';
import ProductPrice from '../product/product-price';

export interface StickyAddToCartButtonProps {
  observedElementRef: React.RefObject<HTMLElement>;
  formAction: () => void;
  label: string;
  submitCta: LinkInterface;
  product: ProductInterface;
}

export const StickyAddToCartButton = ({
  observedElementRef,
  formAction,
  label,
  submitCta,
  product,
}: StickyAddToCartButtonProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const searchParams = useSearchParams();
  const color = searchParams.get('color');
  const size = searchParams.get('size');

  // Observe the element to know when it is visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(!entry.isIntersecting && entry.boundingClientRect.y < 0),
      { threshold: 0.1 },
    );

    const observedElement = observedElementRef.current;

    if (observedElement) {
      observer.observe(observedElement);
    }

    return () => {
      if (observedElement) {
        observer.unobserve(observedElement);
      }
    };
  }, [observedElementRef]);

  // Dispaly option value => color-size or color
  let displayText;
  if (color && size) {
    displayText = reverseEscapeUrl(`${size}-${color}`);
  } else if (color) {
    displayText = reverseEscapeUrl(`${color}`);
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="product-sticky-add-to-cart fixed bottom-0 left-0 right-0 z-20 bg-white shadow-lg shadow-black"
        >
          <div className="container mx-auto flex flex-col items-center justify-between gap-2 p-5 pt-3 lg:flex-row lg:pt-5">
            <div className="flex w-full items-center justify-between lg:justify-start lg:gap-40">
              <div className="flex items-baseline gap-2 lg:block">
                <div className="font-sans text-xl lg:text-2xl">{product.title}</div>
                <div className="text-base capitalize">{displayText}</div>
              </div>
              <ProductPrice product={product} />
            </div>
            <form
              onClick={(e) => {
                e.preventDefault();
                formAction();
              }}
              className="w-full"
            >
              <LinkRenderer cta={submitCta}>{label}</LinkRenderer>
            </form>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
