import { KeyValueInterface } from '@qlevr/shared/interfaces';
import { useTranslations } from 'next-intl';

/* eslint-disable-next-line */
export interface ProductVariantBadgeProps {
  isAvailableForSale?: boolean;
  currentlyNotInStock?: boolean;
  isPreOrder?: boolean;
  tag?: KeyValueInterface[] | null;
}

export function ProductVariantBadge({
  isAvailableForSale,
  currentlyNotInStock,
  isPreOrder,
  tag,
}: ProductVariantBadgeProps) {
  const t = useTranslations();

  // Priority 1: don't continue selling when out of stock = out of stock
  if (!isAvailableForSale && !currentlyNotInStock) {
    return (
      <div className="absolute -bottom-2 z-20 text-xs font-medium">
        <div className="whitespace-nowrap rounded-sm bg-slate-100 p-1 text-slate-600 shadow-md">
          {t('pdp.outOfStockBadgeLabel')}
        </div>
      </div>
    );
  }

  // Priority 2: continue selling when out of stock = pre-order
  else if (isAvailableForSale && currentlyNotInStock && isPreOrder) {
    return (
      <div className="absolute -bottom-2 z-20 text-xs font-medium">
        <div className="t-variant-badge whitespace-nowrap rounded-sm p-1 font-medium lowercase shadow-md">
          {t('pdp.preOrderLabel')}
        </div>
      </div>
    );
  }

  // Priority 3: tag = show when available
  else if (tag && isAvailableForSale) {
    return (
      <div className="absolute -bottom-2 z-20 text-xs font-medium">
        {tag.map((item, index) => (
          <div key={index} className={`whitespace-nowrap rounded-sm p-1 shadow-md ${item.value}`}>
            {item.key}
          </div>
        ))}
      </div>
    );
  }

  // Return null if none of the conditions are met
  return null;
}

export default ProductVariantBadge;
