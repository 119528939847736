import { ContentTextImageCardInterface } from '@qlevr/shared/interfaces';
import { ContentTextImageCardStoryblok } from '@qlevr/shared/schema';
import { imageStoryblokMapper } from '../image/image-storyblok';
import { linksMapper } from '../link/link';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';

export function mapTextImageCardContent(content: ContentTextImageCardStoryblok): ContentTextImageCardInterface | null {
  const image = imageStoryblokMapper(content.image?.[0], {
    xs: 1,
    lg: 3,
  });

  if(!image) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    rating: content.rating ?? false,
    text: mapRichText(content.text),
    cta: linksMapper(content.cta),
    image,
    section: sectionMapper(content.section?.[0]),
    reviews: null,
  };
}
