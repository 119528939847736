import { Link } from '@qlevr/shared/constants';
import { LinkLinkInterface } from '@qlevr/shared/interfaces';
import IconRenderer from '../../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface InternalLinkProps {
  children?: React.ReactNode;
  cta: LinkLinkInterface;
}

export function InternalLink({
  children,
  cta: {
    label,
    className,
    wrapperClassName,
    leadingIcon,
    leadingClassName,
    linkStyle,
    size,
    trailingIcon,
    trailingClassName,
    as,
    ...anchorProps
  },
}: InternalLinkProps) {
  const css = `${className} ${size}`;

  return (
    <Link {...anchorProps} {...(wrapperClassName ? { className: wrapperClassName } : {})}>
      {leadingIcon && (
        <span>
          <IconRenderer icon={leadingIcon} {...(leadingClassName ? { className: leadingClassName } : {})} />
        </span>
      )}
      <span className={css}>{children ?? label}</span>
      {trailingIcon && (
        <span>
          <IconRenderer icon={trailingIcon} {...(trailingClassName ? { className: trailingClassName } : {})} />
        </span>
      )}
    </Link>
  );
}

export default InternalLink;
