import { useCart } from '../../cart-provider';
import { setAuthCookie } from '../cookies/cookies';

export function useRegister() {
  const cartContext = useCart();

  const registerFormSubmit = async (
    email: string,
    password: string,
    onSuccess: () => void,
    onError: (error: string) => void,
    locale: string
  ) => {
    try {
      const createCustomerResponse = await fetch(`/${locale}/api/customer/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!createCustomerResponse.ok) {
        const { errors } = await createCustomerResponse.json();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorMessage = errors.map((error: any) => error.message).join(', ');
        onError(errorMessage);
        return;
      }

      const customerData = {
        email,
      };

      try {
        const response = await fetch(`/${locale}/api/multipass`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ customerData, locale }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          onError(`Multipass error: ${errorData.message}`);
          return;
        }

        const { url } = await response.json();
        window.open(url, '_blank');
      } catch (error) {
        onError(`Multipass request failed: ${(error as Error).message}`);
        return;
      }

      try {
        cartContext.register(email, password);
        await setAuthCookie(true);
      } catch (error) {
        onError(`Cart context or auth cookie error: ${(error as Error).message}`);
        return;
      }

      onSuccess();
    } catch (error) {
      onError(`Something went wrong: ${(error as Error).message}`);
    }
  };

  return { registerFormSubmit };
}

export default useRegister;
