import { ContentTextInterface } from '@qlevr/shared/interfaces';
import { TextStoryblok } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { contentAlignmentMapper } from '../content-alignment/content-alignment';
import { linksMapper } from '../link/link';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';

export function mapTextContent(content: TextStoryblok): ContentTextInterface | null {
  if (isEmpty(content.title) && !content.text) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    text: mapRichText(content.text),
    cta: linksMapper(content.cta),
    alignment: contentAlignmentMapper(content.alignment?.[0]),
    section: sectionMapper(content.section?.[0]),
  };
}
