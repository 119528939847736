import { ButtonLinkInterface } from '@qlevr/shared/interfaces';
import IconRenderer from '../../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface ButtonProps {
  children?: React.ReactNode;
  cta: ButtonLinkInterface;
}

export function Button({
  children,
  cta: {
    label,
    wrapperClassName,
    className,
    leadingIcon,
    leadingClassName,
    linkStyle,
    size,
    trailingIcon,
    trailingClassName,
    ...buttonProps
  },
}: ButtonProps) {
  const css = `${className} ${size}`;

  return (
    <button {...buttonProps} {...(wrapperClassName ? { className: wrapperClassName } : {})}>
      {leadingIcon && (
        <span className='mr-3'>
          <IconRenderer icon={leadingIcon} {...(leadingClassName ? { className: leadingClassName } : {})} />
        </span>
      )}
      <span className={css}>{children ?? label}</span>
      {trailingIcon && (
        <span className='ml-3'>
          <IconRenderer icon={trailingIcon} {...(trailingClassName ? { className: trailingClassName } : {})} />
        </span>
      )}
    </button>
  );
}

export default Button;
