import { COUNTRIES } from '@qlevr/shared/constants';
import { CountryCodeShopify } from '@qlevr/shared/schema';

export function getCountryCodeShopify(country: string): CountryCodeShopify {
  if (!country) {
    throw new Error('Country code is required');
  }

  const formattedCountry = country.toUpperCase();

  const countryCodeMapping = new Map<string, CountryCodeShopify>([
    [COUNTRIES[0], CountryCodeShopify.NlShopify],
    [COUNTRIES[1], CountryCodeShopify.BeShopify],
    [COUNTRIES[2], CountryCodeShopify.FrShopify],
    [COUNTRIES[3], CountryCodeShopify.DeShopify],
    [COUNTRIES[4], CountryCodeShopify.EsShopify],
    [COUNTRIES[5], CountryCodeShopify.ItShopify],
    [COUNTRIES[6], CountryCodeShopify.AtShopify], // international market
  ]);

  const countryCode = countryCodeMapping.get(formattedCountry);
  if (!countryCode) {
    return CountryCodeShopify.BeShopify;
  }

  return countryCode;
}
