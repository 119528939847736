import { CartFreeShippingInterface, CartInterface, ProductVariantInterface } from '@qlevr/shared/interfaces';

export function createNewOptimisticCart(
  cartData: CartInterface | undefined,
  variant: ProductVariantInterface,
  productId: string,
  freeShippingTreshold: CartFreeShippingInterface,
  quantity = 1,
) {
  if (!cartData) {
    // Initialize a new cart if there is no existing cart data
    cartData = {
      id: crypto.randomUUID(),
      checkoutUrl: '',
      cost: {
        subtotalAmount: null,
        totalAmount: {
          amount: variant.price ? variant.price.amount * quantity : 0,
          currencyCode: variant.price ? variant.price.currencyCode : 'EUR',
          symbol: variant.price ? variant.price.symbol : '€',
        },
        totalTaxAmount: null,
        currencyCode: 'EUR',
      },
      items: [], // Start with an empty items array if no existing cart
      totalQuantity: quantity,
      savings: null,
      freeShipping: freeShippingTreshold,
      upsellProducts: null,
      analytics: null,
    };
  }

  const existingItemIndex = cartData.items.findIndex((item) => item.variantId === variant.id);
  if (existingItemIndex !== -1) {
    // If the item already exists in the cart, increase its quantity
    cartData.freeShipping = freeShippingTreshold;
    cartData.items[existingItemIndex].quantity += quantity;
  } else {
    // If the item does not exist, add it to the items array
    // Shopify adds the new item to the beginning of the items array so we do the same here to avoid seeing the item at the end of the list
    cartData.items.unshift({
      id: crypto.randomUUID(),
      sku: variant.sku,
      slug: variant.slug || '',
      title: variant.productTitle || '',
      featuredImage: variant.featuredImage || null,
      variantId: variant.id,
      productId: variant.productId || productId,
      compareAtPrice: null,
      variantCompareAtAmountPerQuantity: null,
      quantity: quantity,
      price: variant.price
        ? {
            amount: variant.price.amount * quantity,
            currencyCode: variant.price.currencyCode,
            symbol: variant.price.symbol,
          }
        : null,
      currencyCode: variant.currencyCode || null,
      options: variant.options,
      discounts: null,
      vendor: variant?.vendor || '',
      productType: variant?.productType || '',
      productBundlePromotion: variant.bundlePromotion || null,
      productTags: variant.productTags || [],
      shippingDate: null,
    });
    // cartData.cost.totalAmount?.amount += variant.price ? variant.price.amount : 0;
    cartData.totalQuantity += quantity; // Increment the total quantity for the new item added
    cartData.cost.totalAmount = {
      amount: parseFloat(
        ((cartData.cost.totalAmount?.amount ?? 0) + (variant.price ? variant.price.amount * quantity : 0)).toFixed(2),
      ),
      currencyCode: variant.price ? variant.price.currencyCode : 'EUR',
      symbol: variant.price ? variant.price.symbol : '€',
    };
    cartData.cost.subtotalAmount = {
      amount: parseFloat(
        ((cartData.cost.subtotalAmount?.amount ?? 0) + (variant.price ? variant.price.amount * quantity : 0)).toFixed(
          2,
        ),
      ),
      currencyCode: variant.price ? variant.price.currencyCode : 'EUR',
      symbol: variant.price ? variant.price.symbol : '€',
    };
    cartData.freeShipping = freeShippingTreshold;
    cartData.savings = null;
  }

  return cartData;
}
