import { ProductCombinationInterface, ProductVariantSizeColorInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';

export function getDefaultVariantOption(combinations: ProductCombinationInterface[] | null): ProductVariantSizeColorInterface | null {
  if (!combinations || isEmpty(combinations)) {
    return null;
  }

  const { size, color } = combinations.find((combination) => combination.availableForSale === true && combination.inventoryQuantity > 0) as ProductVariantSizeColorInterface || combinations[0];

  const noSizeOrColor = !size && !color;
  if (noSizeOrColor) {
    return null;
  }

  return {
    size,
    color,
  };
}
