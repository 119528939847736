import {
  GlobalInterface,
  LinkImageCardInterface,
  NavigationItemInterface,
  ProductInterface,
  ProductVariantInterface,
} from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import { mapLinkImageCardFromProduct, mapLinkImageCardFromProductVariant } from '../card/link-image-card-mapper';
import { mapTextImageCardFromProduct } from '../card/text-image-card-mapper';

/**
 * @description Check for each navigation item if the featured product has variants. When there is a featured product AND there are no products, the featured product variants will be added with data derived from Shopify.
 * @param globals
 * @param products
 */
export function mapNavigationProduct(globals: GlobalInterface | null, products: ProductInterface[] | null) {
  if (!globals?.header?.navigation || !products) {
    return;
  }

  globals.header.navigation.forEach((nav) => {
    const product = products.find((product) => product.id === nav.featuredProductId);

    featuredCard(nav, product);
    featuredVariant(nav, product);
    featuredProducts(nav, products);
  });
}

function featuredCard(nav: NavigationItemInterface, product?: ProductInterface) {
  // check if the featured card has all the required fields -> storyblok
  const isTextImageCard =
    nav.featuredCard && 'title' in nav.featuredCard && 'text' in nav.featuredCard && 'image' in nav.featuredCard;
  if (!product || isTextImageCard) {
    return;
  }

  nav.featuredCard = mapTextImageCardFromProduct(product, nav.featuredProductCtaLabel, nav.theme);
}

function featuredVariant(nav: NavigationItemInterface, product?: ProductInterface) {
  // When there is no variant key, no variants to show
  if (!nav.featuredProductVariantKey || !product?.variants || isEmpty(product.variants)) {
    return;
  }

  const variantsByFeaturedVariantKey = filterUniqueVariantsByKey(product.variants, nav.featuredProductVariantKey);
  nav.products = variantsByFeaturedVariantKey.reduce((acc: LinkImageCardInterface[], item: ProductVariantInterface) => {
    const card = mapLinkImageCardFromProductVariant(product, item, nav.featuredProductVariantKey || '');
    if (card) {
      acc.push(card);
    }

    return acc;
  }, [] as LinkImageCardInterface[]);
}

function featuredProducts(nav: NavigationItemInterface, products: ProductInterface[]) {
  if (!nav.productIds || isEmpty(nav.productIds)) {
    return;
  }

  nav.products = nav.productIds.reduce((acc: LinkImageCardInterface[], id: string) => {
    const product = products.find((product) => product.id === id);
    if (product) {
      const card = mapLinkImageCardFromProduct(product, nav.theme);
      if (card) {
        acc.push(card);
      }
    }

    return acc;
  }, [] as LinkImageCardInterface[]);
}

function filterUniqueVariantsByKey(variants: ProductVariantInterface[], key: string): ProductVariantInterface[] {
  const uniqueSizes = new Set<string>();

  return variants.filter((variant) => {
    const option = variant.options.find((option) => option['name'] === key);
    if (option && !uniqueSizes.has(option.value)) {
      uniqueSizes.add(option.value);
      return true;
    }
    return false;
  });
}
