/**
 * Add crop parameters to a Shopify image URL.
 * @documentation https://www.shopify.com/partners/blog/img-url-filter
 */
export function imageAddCropParameters(url: string, size: string): string {
  // url = https://cdn.shopify.com/s/files/1/0774/1744/7748/files/AS-AM-AP-110-060-B-02.jpg?v=1708099438
  // size = _450x450_crop_center
  // split up the url and add the size parameter before the file extension
  // result = https://cdn.shopify.com/s/files/1/0774/1744/7748/files/AS-AM-AP-110-060-B-02_450x450_crop_center.jpg?v=1708099438


  // Check if URL has query parameters
  const urlParts = url.split('?');
  const baseUrl = urlParts[0];
  const queryParams = urlParts.length > 1 ? '?' + urlParts[1] : '';

  // Split the base URL to insert the size before the file extension
  const baseParts = baseUrl.split('.');
  if (baseParts.length < 2) {
    throw new Error("Invalid URL: Missing file extension.");
  }

  // Extract the file extension and rebuild the URL with the size parameter inserted before the extension
  const extension = baseParts.pop(); // Remove and save the file extension
  const newUrl = `${baseParts.join('.')}_${size}_crop_center.${extension}${queryParams}`;

  return newUrl;
}
