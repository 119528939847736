import { CartLineItemInterface } from '@qlevr/shared/interfaces';

export const isTagPresentInOtherLineItems = (
  tag: string,
  lineItems: CartLineItemInterface[],
  currentLineItemId: string,
) => {
  return lineItems.some(
    (lineItem) => lineItem.id !== currentLineItemId && lineItem.productTags && lineItem.productTags.includes(tag),
  );
};
