export const sizeChartModalTheme = {
  content: {
    base: 'relative h-full w-full size-chart-modal',
    inner: 'relative bg-white shadow dark:bg-gray-700 flex flex-col h-full',
  },
  body: {
    base: 'overflow-auto py-6 grid grid-cols-2 gap-x-4 gap-y-10',
  },
  header: {
    base: 'flex flex-row-reverse items-start justify-between p-4',
    title: 'w-full text-center text-lg font-medium text-black dark:text-white',
    close: {
      base: 'ml-0',
      icon: 'h-6 w-6',
    },
  },
  footer: {
    base: 'border-none px-6 py-6 lg:px-10 rounded-t-lg empty:hidden',
  },
};
