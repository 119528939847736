'use client';
import { ProductInterface } from '@qlevr/shared/interfaces';
import { getFilteredProducts } from '@qlevr/shared/utilities';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface CollectionFiltersProviderProps {
  children: React.ReactNode;
  products: ProductInterface[];
}

interface CollectionFiltersContextType {
  filteredProducts: ProductInterface[];
  setFilteredProducts: React.Dispatch<React.SetStateAction<ProductInterface[]>>;
  selectedFilters: Record<string, string[]>;
  setSelectedFilters: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
}

export const CollectionFiltersContext = createContext<CollectionFiltersContextType>({
  filteredProducts: [],
  setFilteredProducts: () => {},
  selectedFilters: {},
  setSelectedFilters: () => {},
});

export const CollectionFiltersProvider: React.FC<CollectionFiltersProviderProps> = ({ children, products }) => {
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({});
  const [filteredProducts, setFilteredProducts] = useState<ProductInterface[]>(products || []);

  const filteredProductsMemo = useMemo(() => {
    if (products.length === 0) {
      return [];
    }
    const filteredProducts = getFilteredProducts({ selectedFilters, products, updateDefaultSelected: true });

    return filteredProducts;
  }, [selectedFilters, products]);

  useEffect(() => {
    setFilteredProducts(filteredProductsMemo);
  }, [filteredProductsMemo, setFilteredProducts]);

  return (
    <CollectionFiltersContext.Provider
      value={{ filteredProducts, setFilteredProducts, selectedFilters, setSelectedFilters }}
    >
      {children}
    </CollectionFiltersContext.Provider>
  );
};

export const useCollectionFilters = () => {
  const context = useContext(CollectionFiltersContext);
  if (context === undefined) {
    throw new Error('useCollectionFilters must be used within a CollectionFiltersProvider');
  }
  return context;
};
