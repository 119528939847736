import { FilterShopify, ProductFilterShopify } from '@qlevr/shared/schema';
import { useTranslations } from 'next-intl';
import FilterMarkup from './filter-markup';

interface FiltersModalBodyProps {
  shopifyFilters: FilterShopify[];
}

export type AppliedFilter = {
  filter: ProductFilterShopify;
  label: string;
};

export default function FiltersModalBody({ shopifyFilters }: FiltersModalBodyProps) {
  const t = useTranslations();

  return (
    <>
      <div className="border-b border-slate-300 pb-3">{t('collection.filters.filterBy')}</div>
      {shopifyFilters &&
        shopifyFilters.map((filter) => (
          <div className="space-y-3 border-b border-slate-300 py-3" key={filter.id}>
            <div>{filter.label}</div>
            <ul className={`space-y-3 text-sm ${filter.values.length > 5 && 'columns-2'}`} key={filter.id}>
              {filter.values?.map((option) => {
                return (
                  <li className="" key={option.id}>
                    <FilterMarkup filter={filter} option={option} />
                  </li>
                );
              })}
            </ul>
          </div>
        ))}
    </>
  );
}
