import { ICONS } from '@qlevr/shared/constants';
import IconRenderer from '../../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface CartNavigationItemProps {
  className?: string;
  quantity?: number;
}

export function CartNavigationItem({ className, quantity }: CartNavigationItemProps) {
  if (!quantity) {
    return (
      <div className="relative flex h-11 w-11 items-center justify-center text-black transition-colors dark:border-neutral-700 dark:text-white">
        <IconRenderer className={`w-5 h-5 ${className}`} icon={ICONS.cart} />
      </div>
    );
  }

  return (
    <div className="relative flex h-11 w-11 items-center justify-center text-black transition-colors dark:border-neutral-700 dark:text-white">
      <IconRenderer className={`w-5 h-5 ${className}`} icon={ICONS.cart} />
      <div className="absolute right-1 top-1 -mr-2 -mt-2 h-4 w-4 rounded bg-orange-500 text-[11px] font-medium text-white flex items-center justify-center">
        {quantity}
      </div>
    </div>
  );
}

export default CartNavigationItem;
