import { ProductVariantOptionInterface, ProductVariantSizeColorInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import { buildSizeColorUrlParam } from '../variants/color-size-variant-options/build-size-color-url-param';

export function getProductSlugWithVariantParams(slug: string, options: ProductVariantOptionInterface[]): string {
  if(!options || isEmpty(options)) {
  return slug;
  }

  const params = {} as ProductVariantSizeColorInterface;

  options.forEach((option) => {
    const optionNameLowerCase = option.name.toLowerCase();
    if(optionNameLowerCase === 'size' ) {
      params.size = option.value;
    }
    if (optionNameLowerCase === 'color') {
      params.color = option.value;
    }
  });

  return `${slug}/${buildSizeColorUrlParam(params.size, params.color)}`;
}
