import { ICONS } from '@qlevr/shared/constants';
import { CartAutomaticDiscountInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import IconRenderer from '../../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface CartAutomaticDiscountProps {
  discounts: CartAutomaticDiscountInterface[] | null;
}

export function CartAutomaticDiscount({ discounts }: CartAutomaticDiscountProps) {
  if (!discounts || isEmpty(discounts)) {
    return null;
  }

  return (
    <div>
      {discounts.map((discount, key) => (
        <div key={key} className="flex items-center gap-1 text-xs">
          <IconRenderer icon={ICONS.discount} className="w-3 h-3 text-black" />
          {discount.title}
        </div>
      ))}
    </div>
  );
}

export default CartAutomaticDiscount;
