'use client';

import { ICONS } from '@qlevr/shared/constants';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import IconRenderer from '../icons/icon-renderer.component';

export function NewsletterSubscribe({ newsletterUrl }: { newsletterUrl: string | undefined }) {
  const t = useTranslations();

  if (!newsletterUrl) {
    return null;
  }

  return (
    <Link href={newsletterUrl} target='_blank' className="relative block w-full">
      <span className="block w-full rounded-full border border-black bg-white px-6 py-3.5 text-left text-base font-light lg:bg-transparent">
        {t('pdp.emailPlaceholder')}
      </span>
      <span className="absolute right-6 top-4 cursor-pointer rounded-full bg-black text-white">
        <IconRenderer icon={ICONS.chevronRight} className="h-6" />
      </span>
    </Link>
  );
}

export default NewsletterSubscribe;
