import { CartFreeShippingInterface } from '@qlevr/shared/interfaces';
import { GetCartQueryShopify } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';

export function getFreeShippingThreshold(
  metaobjectFreeShipping: GetCartQueryShopify['metaobject'] | null,
): CartFreeShippingInterface['threshold'] {
  if (!metaobjectFreeShipping?.fields || isEmpty(metaobjectFreeShipping.fields)) {
    return null;
  }

  const threshold = metaobjectFreeShipping.fields.reduce(
    (acc, item) => {
      if (!item.value) {
        return acc;
      }

      // country_be => be
      const key = item.key.split('_')[1];
      return {
        ...acc,
        [key]: Number(item.value),
      };
    },
    {} as CartFreeShippingInterface['threshold'],
  );

  return threshold;
}
