import {
  HorizontalAlignmentConstant,
  HorizontalAlignmentType,
  TextAlignmentConstant,
  TextAlignmentType,
  VerticalAlignmentConstant,
  VerticalAlignmentType,
} from '@qlevr/shared/constants';

export function VerticalAlignmentMapper(type: string): VerticalAlignmentType {
  if (!type) return VerticalAlignmentConstant.start;

  const mapping = new Map<string, VerticalAlignmentType>([
    ['start', VerticalAlignmentConstant.start],
    ['center', VerticalAlignmentConstant.center],
    ['end', VerticalAlignmentConstant.end],
  ]);

  const verticalAlignment = mapping.get(type);
  if (!verticalAlignment) {
    throw new Error(`verticalAlignment of type ${type} is missing in verticalAlignmentMapper.`);
  }

  return verticalAlignment;
}

export function HorizontalAlignmentMapper(type: string): HorizontalAlignmentType {
  if (!type) return HorizontalAlignmentConstant.start;

  const mapping = new Map<string, HorizontalAlignmentType>([
    ['start', HorizontalAlignmentConstant.start],
    ['center', HorizontalAlignmentConstant.center],
    ['end', HorizontalAlignmentConstant.end],
  ]);

  const horizontalAlignment = mapping.get(type);
  if (!horizontalAlignment) {
    throw new Error(`horizontalAlignment of type ${type} is missing in HorizontalAlignmentMapper.`);
  }

  return horizontalAlignment;
}

export function TextAlignmentMapper(type: string): TextAlignmentType | null {
  if (!type) return null;

  const mapping = new Map<string, TextAlignmentType>([
    ['left', TextAlignmentConstant.left],
    ['center', TextAlignmentConstant.center],
  ]);

  const textAlignment = mapping.get(type);
  if (!textAlignment) {
    throw new Error(`textAlignment of type ${type} is missing in TextAlignmentMapper.`);
  }

  return textAlignment;
}