import { COLUMNS } from '@qlevr/shared/constants';

export const determineGridLayout = (productCount: number): string => {
  switch (productCount) {
    case 2:
      return COLUMNS['1/2'];
    case 3:
      return COLUMNS['1/3'];
    default:
      return COLUMNS['1/2'];
  }
};
