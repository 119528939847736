import { ContentImageInterface, ColumnConfigInterface } from '@qlevr/shared/interfaces';
import { ContentImageStoryblok } from '@qlevr/shared/schema';
import { imageStoryblokMapper } from '../image/image-storyblok';
import { sectionMapper } from '../section/section';

export function mapImageContent(content: ContentImageStoryblok): ContentImageInterface | null {
  const imageSrcsetConfig: ColumnConfigInterface = {
    xs: 1,
  };

  const container = content.container ?? false;
  const image = imageStoryblokMapper(content.image?.[0], imageSrcsetConfig, container);

  if (!image) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    container,
    image,
    overlay: content.overlay ?? false,
    section: sectionMapper(content.section?.[0]),
  };
}
