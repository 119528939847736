import { HeroBlockInterface, MappingHeroBlocksFunction, MetaInterface } from '@qlevr/shared/interfaces';
import { HeroImageTextOverlayStoryblok, HeroTextImageCardStoryblok, HeroTextImageStoryblok, HeroTitleStoryblok, HeroVideoStoryblok, PageStoryblok } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { mapImageTextOverlayHero } from './image-text-overlay';
import { mapTextImageHero } from './text-image';
import { mapTextImageCardHero } from './text-image-card';
import { mapTitleHero } from './title';
import { mapVideoHero } from './video';

const componentMapping = new Map<string, MappingHeroBlocksFunction<unknown, MetaInterface, HeroBlockInterface | null>>([
  ['heroImageTextOverlay', (title, block, meta) => mapImageTextOverlayHero(title, block as HeroImageTextOverlayStoryblok, meta)],
  ['heroTextImage', (title, block, meta) => mapTextImageHero(title, block as HeroTextImageStoryblok, meta)],
  ['heroTextImageCard', (title, block, meta) => mapTextImageCardHero(title, block as HeroTextImageCardStoryblok, meta)],
  ['heroVideo', (title, block, meta) => mapVideoHero(title, block as HeroVideoStoryblok, meta)],
  ['heroTitle', (title, block, meta) => mapTitleHero(title, block as HeroTitleStoryblok, meta)],
]);

export function heroBlocksMapper(title : string, block: PageStoryblok['Hero'] | undefined, meta: MetaInterface): HeroBlockInterface | null {
  if (!block || isEmpty(block)) {
    return null;
  }

  const hero = block[0];
  if (!hero?.component) {
    throw new Error('Hero block cannot be mapped because component is undefined.');
  }

  const component = componentMapping.get(hero.component);
  if (!component) {
    throw new Error(`Hero block of type ${hero.component} is missing a heroBlocksMapper.`);
  }

  return component(title, hero, meta);
}
