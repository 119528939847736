'use client';

import { ImageInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import 'photoswipe/style.css';
import { useState } from 'react';
import SwiperCore from 'swiper';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageRenderer from '../../../image-renderer/image-renderer';

export interface ProductCarouselProps {
  quizStackedImages?: string[] | null;
  mainProductImages?: ImageInterface[] | null;
}

export function QuizStackedImages({ quizStackedImages, mainProductImages }: ProductCarouselProps) {
  const [, setMainSwiper] = useState<SwiperCore | null>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);
  const reInitThumbnailCarousel = thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null;

  if (!quizStackedImages || isEmpty(quizStackedImages)) return null;

  return (
    <>
      <Swiper
        spaceBetween={24}
        slidesPerView={1}
        navigation={true}
        pagination={{ clickable: true }}
        thumbs={{ swiper: reInitThumbnailCarousel || undefined }}
        onSwiper={setMainSwiper}
        modules={[Navigation, Pagination, FreeMode, Thumbs]}
        className="swiper-product-desktop-carousel swiper-pagination-hidden swiper-product-carousel"
      >
        <SwiperSlide>
          {quizStackedImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="stacked-images"
              className={`absolute inset-0 h-auto w-full`}
              style={index === 0 ? {} : { zIndex: `${10 * (quizStackedImages.length - index)}` }}
            />
          ))}
        </SwiperSlide>
        {mainProductImages &&
          mainProductImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image.url} alt="main-stacked-images" className="h-auto w-full" />
            </SwiperSlide>
          ))}
      </Swiper>
      {mainProductImages && (
        <div className="mobile-thumbnails relative mx-auto hidden max-w-sm pt-5 lg:block">
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={5}
            navigation={true}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="swiper-product-carousel swiper-product-mobile-carousel !px-1 !pb-2 !pt-1"
          >
            <SwiperSlide>
              <img
                key={'quiz-stacked-image'}
                src={quizStackedImages?.[0]}
                alt="quiz-stacked-images"
                className="block aspect-square cursor-pointer rounded bg-transparent object-cover shadow-md"
              />
            </SwiperSlide>
            {mainProductImages.map((image, key) => (
              <SwiperSlide key={`thumb-${key}`}>
                {image && (
                  <ImageRenderer
                    image={image}
                    className="block aspect-square cursor-pointer rounded bg-transparent object-cover shadow-md"
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
}

export default QuizStackedImages;
