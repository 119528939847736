import { productPathsMapper } from '@qlevr/shared/mappers';
import { getLanguageCodeShopify, getLocaleFromCountryLocale } from '@qlevr/shared/utilities';
import { notFound } from 'next/navigation';
import { ShopifyService } from './shopify-service';

export const fetchProductPaths = async (locale: string) => {
  const res = await ShopifyService({ cache: 'no-store' }).getCollectionPath({
    id: process.env.NEXT_PUBLIC_ALL_PRODUCTS_COLLECTION_HANDLE!,
    language: getLanguageCodeShopify(getLocaleFromCountryLocale(locale)),
  });

  const isError = res.collection === null;
  if (isError) {
    console.error('Collection with brand products not found');
    return notFound();
  }

  return productPathsMapper(res.collection, locale);
};
