import { IMAGE_FRAME, ImageFrameType } from '@qlevr/shared/constants';

export function imageFrameTypeMapper(type: string): ImageFrameType {
  if (!type) return IMAGE_FRAME.square;

  const mapping = new Map<string, ImageFrameType>([
    ['square', IMAGE_FRAME.square],
    ['circle', IMAGE_FRAME.circle],
  ]);

  const imageType = mapping.get(type);
  if (!imageType) {
    throw new Error(`Layout of type ${type} is missing in ImageTypeMapper.`);
  }

  return imageType;
}
