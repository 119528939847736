/**
 * Extracts the locale from a country locale string.
 *
 * @param locale - The country locale string.
 * @returns The extracted locale.
 * @example nl-BE => nl
 * @example nl-NL => nl
 */
export function getLocaleFromCountryLocale(locale: string): string {
  return locale.split('-')[0];
}
