import { FooterInterface } from '@qlevr/shared/interfaces';
import { FooterStoryblok } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { mapGroupLink } from '../link/link-group';
import { sectionMapper } from '../section/section';

export function mapFooter(footer: FooterStoryblok[] | undefined): FooterInterface | null {
  if (!footer || isEmpty(footer)) {
    return null;
  }

  return {
    items: footer[0].links?.map((item) => mapGroupLink(item)) ?? null,
    section: sectionMapper(footer[0]?.section?.[0]),
  };
}
