'use client';

import React, { createContext, useContext, useState } from 'react';

interface BundleAndSaveProviderProps {
  children: React.ReactNode;
}

interface BundleAndSaveContextType {
  isOpen: boolean;
  toggle: () => void;
  open: () => void;
  close: () => void;
}

export const BundleAndSaveContext = createContext<BundleAndSaveContextType>({
  isOpen: false,
  toggle: () => {},
  open: () => {},
  close: () => {},
});

export const BundleAndSaveProvider: React.FC<BundleAndSaveProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((prevSate) => !prevSate);
  };
  const open = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
  };

  return (
    <BundleAndSaveContext.Provider value={{ isOpen, toggle, open, close }}>
      {children}
    </BundleAndSaveContext.Provider>
  );
};

export const useBundleAndSave = () => {
  const context = useContext(BundleAndSaveContext);
  if (context === undefined) {
    throw new Error('useBundleAndSave must be used within a BundleAndSaveProvider');
  }
  return context;
};
