import { ContentPictoTextCardsInterface, PictoCardInterface } from '@qlevr/shared/interfaces';
import { PictoCardStoryblok, TextPictoCardsStoryblok } from '@qlevr/shared/schema';
import { assetStoryblokMapper } from '../asset/asset-storyblok';
import { pictoCardStoryblokMapper } from '../picto-card/picto-card';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { RATIOS } from '@qlevr/shared/constants';
import { pictoSizeMapper } from '../picto-card/picto-size';

export function mapTextPictoCardsContent(content: TextPictoCardsStoryblok): ContentPictoTextCardsInterface {
  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: content.title ?? '',
    cards: pictoCardMapper(content.cards ?? []),
    section: sectionMapper(content.section?.[0]),
    size: pictoSizeMapper(content.pictoSize ?? ''),
  };
}

// @todo refactor pictoCardStoryblokMapper
function pictoCardMapper(cards: PictoCardStoryblok[]): PictoCardInterface[] {
  return cards.flatMap((item) => {
    const card = pictoCardStoryblokMapper(
      item.text,
      mapRichText(item.description),
      assetStoryblokMapper(item.image, { columnConfig: { xs: 4 }, ratio: RATIOS.DEFAULT, container: true }),
    );
    return card ? [card as PictoCardInterface] : [];
  });
}
