import { PageTypeType } from '@qlevr/shared/constants';
import { CollectionStoryInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { collectionStoryMapper } from '@qlevr/shared/mappers';
import { CollectionPageStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';
import { TryFetchStoryblokStoryService } from './storyblok/try-fetch-storyblok-story';

export class TryFetchCollectionBySlug extends TryFetchStoryblokStoryService<
  CollectionStoryInterface,
  StoryblokStory<CollectionPageStoryblok>
> {
  constructor(pageType: PageTypeType, slug: string, locale: string, preview: boolean, cv: string) {
    super({
      path: `${pageType}${slug}`,
      locale,
      preview,
      cv
    });
  }

  mapper(data: StoryblokStory<CollectionPageStoryblok>, meta: MetaInterface) {
    return collectionStoryMapper(data, meta);
  }
}
