'use client';
import { ICONS } from '@qlevr/shared/constants';
import { ProductInterface, ProductSpecificationsInterface } from '@qlevr/shared/interfaces';
import { Modal } from 'flowbite-react';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import IconRenderer from '../../icons/icon-renderer.component';
import AccordionModal from './product-accordion-modal';
import { productModalTheme } from './product-modal-theme';

export interface ProductAccordionProps {
  specifications: ProductInterface['productSpecifications'];
}

export function ProductAccordion(props: ProductAccordionProps) {
  const t = useTranslations();
  const [openModal, setOpenModal] = useState(false);
  const [selectedSpecification, setSelectedSpecification] = useState<ProductSpecificationsInterface | null>(null);

  const handleOpenModal = (spec: ProductSpecificationsInterface | null) => {
    setSelectedSpecification(spec);
    setOpenModal(true);
  };

  return (
    <div className="flex flex-col">
      {props.specifications?.map((item, key) => (
        <div key={key} className="flex justify-between items-center py-4 border-b border-teal-800">
          <button className="text-left text-base font-bold text-black w-full" onClick={() => handleOpenModal(item)}>
            {t(`pdp.specifications.${item.type}`)}
          </button>
          <IconRenderer icon={ICONS.accordionArrow} className="w-6 h-6" />
        </div>
      ))}
      {selectedSpecification && (
        <Modal
          dismissible
          size="md"
          theme={productModalTheme}
          show={openModal}
          position={'top-right'}
          onClose={() => setOpenModal(false)}
          className="product-accordion-modal-overlay"
        >
          <Modal.Header>{selectedSpecification.title && selectedSpecification.title}</Modal.Header>
          <Modal.Body>
            <AccordionModal specifications={selectedSpecification} />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default ProductAccordion;
