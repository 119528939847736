'use client';

import { ImageInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import { useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageRenderer from '../../image-renderer/image-renderer';

export interface GuidedBuyingFlowCarouselProps {
  images?: ImageInterface[] | null;
}

export function GuidedBuyingFlowCarousel({ images }: GuidedBuyingFlowCarouselProps) {
  const [mainSwiper, setMainSwiper] = useState<SwiperCore | null>(null);

  useEffect(() => {
    if (!images || isEmpty(images)) return;

    let lightbox = new PhotoSwipeLightbox({
      gallery: '#pdp-carousel',
      children: 'a',
      pswpModule: () => import('photoswipe'),
      preloaderDelay: 0,
    }) as PhotoSwipeLightbox | null;

    lightbox?.init();

    lightbox?.on('change', () => {
      const { pswp } = lightbox as PhotoSwipeLightbox;

      if (!mainSwiper || !pswp) return;

      mainSwiper?.slideTo(pswp.currIndex, 0, false);
    });

    return () => {
      lightbox?.destroy();
      lightbox = null;
    };
  }, [images, mainSwiper]);

  if (!images || isEmpty(images)) return null;

  return (
    <Swiper
      id="pdp-carousel"
      spaceBetween={24}
      slidesPerView={1}
      navigation={true}
      pagination={{ clickable: true }}
      onSwiper={setMainSwiper}
      modules={[Navigation, Pagination, FreeMode, Thumbs]}
      className="swiper-pagination-hidden swiper-product-carousel"
    >
      {images.map((image, key) => (
        <SwiperSlide key={`thumbnail-${key}`}>
          {image && (
            <a
              href={image.url}
              data-pswp-width="1024"
              data-pswp-height="1024"
              target="_blank"
              rel="noreferrer"
              className="relative w-full h-full"
            >
              <ImageRenderer
                image={image}
                className="object-contain bg-white aspect-square rounded-lg lg:rounded-none"
              />
            </a>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default GuidedBuyingFlowCarousel;
