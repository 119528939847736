export const accordionTheme = {
    accordion: {
      root: {
        base: 'divide-b divide-black dark:divide-transparent dark:border-transparent rounded-none',
        flush: {
          off: 'border-0 rounded-none',
        },
      },
      content: {
        base: 'text-sm font-light',
      },
      title: {
        arrow: {
          base: 'hidden',
        },
        base: 'flex justify-between items-center cursor-pointer w-full bg-transparent text-left',
        flush: {
          off: 'hover:bg-transparent focus:ring-0 focus:ring-transparent dark:hover:bg-transparent dark:focus:ring-transparent focus:bg-transparent',
        },
        heading: 'text-base font-bold text-left w-full',
        open: {
          off: 'bg-transparent dark:bg-transparent',
          on: 'bg-transparent dark:bg-transparent',
        },
      },
    },
  };
  