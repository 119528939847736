import { ContentVideoInterface } from '@qlevr/shared/interfaces';
import { ContentVideoStoryblok } from '@qlevr/shared/schema';
import { sectionMapper } from '../section/section';
import { mapVideo } from '../video/video';

export function mapVideoContent(content: ContentVideoStoryblok): ContentVideoInterface | null {
  const video = mapVideo(content.video?.[0]);

  if (!video) {
    return null;
  }

  const scaleAnimation = content.scaleAnimation ?? false;

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    video,
    overlay: content.overlay ?? false,
    scaleAnimation: scaleAnimation,
    section: sectionMapper(content.section?.[0]),
  };
}
