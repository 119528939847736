import { cartUpsellProducts } from '@qlevr/shared/queries';
import { CountryCodeShopify, LanguageCodeShopify } from '@qlevr/shared/schema';
import { removeForbiddenIdChars } from '@qlevr/shared/utilities';

export function shopifyCartUpsellQueryBuilder(country: CountryCodeShopify, language: LanguageCodeShopify, productIds: Set<string>): string {
  const products = Array.from(productIds)
    .map(
      (product) =>
        `${removeForbiddenIdChars(product)}: productRecommendations(productId: "${product}", intent: COMPLEMENTARY) {
          ${cartUpsellProducts}
        }`,
    )
    .join()
    .replace(/\s{2,}/g, ' ')
    .trim();

  return `query shopifyCartUpsellData @inContext(country: ${country}, language: ${language}) {${products}}`;
}