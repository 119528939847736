import {
  CollectionPageInterface,
  CollectionStoryInterface,
  ContentBlockInterface,
  MetaInterface,
  PathInterface,
} from '@qlevr/shared/interfaces';
import { CollectionShopify } from '@qlevr/shared/schema';
import { fullPageSlug } from '@qlevr/shared/utilities';
import { collectiontMetatagsMapper } from '../metatags/collection-metatags';
import { mapProductGrid } from '../product-grid/product-grid';
import { collectionAnalyticsMapper } from './collection-analytics';
import { collectionMapper } from './collection-shopify';

export function collectionPageMapper(
  collectionData: CollectionShopify,
  meta: MetaInterface,
  locale: string,
  localisation: PathInterface[] | null,
  page?: CollectionStoryInterface,
): CollectionPageInterface {
  const collection = collectionMapper(collectionData);
  const productGrid = mapProductGrid(collection, page?.quickAdd, page?.columns);
  const contentBlocks = [] as ContentBlockInterface[];
  if (productGrid) {
    contentBlocks.push(productGrid);
  }
  const canonicalUrl = locale && `${fullPageSlug(locale)}${collection.slug}`;
  // If there is no page in Storyblok for the current slug, we build the page with the data from Shopify only.
  if (!page) {
    return {
      ...collection,
      collection,
      meta,
      localisation,
      globals: meta.globals ?? null,
      contentBlocks: contentBlocks,
      quickAdd: false,
      columns: null,
      metatags: collectiontMetatagsMapper(
        collection.title,
        collection.description,
        collection.image,
        localisation,
        canonicalUrl,
      ),
      analytics: collectionAnalyticsMapper(collection),
    };
  }

  return {
    ...collection,
    collection,
    meta,
    localisation,
    globals: meta.globals ?? null,
    hero: page.hero,
    contentBlocks: [...contentBlocks, ...page.contentBlocks],
    metatags: collectiontMetatagsMapper(
      collection.title,
      collection.description,
      collection.image,
      localisation,
      canonicalUrl,
    ),
    quickAdd: page?.quickAdd ?? false,
    columns: page?.columns,
    analytics: collectionAnalyticsMapper(collection),
  };
}
