import { CarouselInterface, ImageInterface } from '@qlevr/shared/interfaces';

export function carouselStoryblokMapper(
  text: string | null,
  caption: string | null,
  image: ImageInterface | null
): CarouselInterface | null {
  return {
    text,
    caption,
    image,
  };
}
