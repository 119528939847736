import { CART_QUANTITY, CartQuantityType } from '@qlevr/shared/constants';
import { CartFreeShippingInterface, CartInterface, PayloadType } from '@qlevr/shared/interfaces';

export function updateOptimisticCart(
  cartData: CartInterface | undefined,
  payload: PayloadType,
  type: CartQuantityType,
  freeShippingTreshold: CartFreeShippingInterface | undefined,
): CartInterface {
  if (!cartData?.items) {
    return cartData as CartInterface;
  }

  const item = cartData.items.find((item) => item.id === payload.lineItemId);

  return {
    ...cartData,
    freeShipping: freeShippingTreshold as CartFreeShippingInterface | null,
    items: cartData.items.map((item) => {
      if (item.id === payload.lineItemId) {
        return {
          ...item,
          quantity: payload.quantity,
          compareAtPrice: null,
          price: {
            amount: (item.compareAtPrice?.amount ?? 0) * payload.quantity,
            currencyCode: item.price?.currencyCode || 'EUR',
            symbol: item.price?.symbol || '€',
          },
        };
      }
      return item;
    }),
    cost: {
      ...cartData.cost,
      subtotalAmount: {
        amount: parseFloat(
          (
            (cartData.cost.subtotalAmount?.amount ?? 0) +
            (type === CART_QUANTITY.increase ? item?.compareAtPrice?.amount ?? 0 : -(item?.compareAtPrice?.amount ?? 0))
          ).toFixed(2),
        ),
        currencyCode: cartData.cost.subtotalAmount?.currencyCode || 'EUR',
        symbol: cartData.cost.subtotalAmount?.symbol || '€',
      },
      totalAmount: {
        amount: parseFloat(
          (
            (cartData.cost.subtotalAmount?.amount ?? 0) +
            (type === CART_QUANTITY.increase ? item?.compareAtPrice?.amount ?? 0 : -(item?.compareAtPrice?.amount ?? 0))
          ).toFixed(2),
        ),
        currencyCode: cartData.cost.subtotalAmount?.currencyCode || 'EUR',
        symbol: cartData.cost.subtotalAmount?.symbol || '€',
      },
    },
  };
}
