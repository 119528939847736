import { ICONS } from '@qlevr/shared/constants';
import { ImageInterface, ProductInterface, ProductVariantInterface } from '@qlevr/shared/interfaces';
import { ProductBundlerContext } from '@qlevr/shared/providers';
import { calculateDiscountPrice, reverseEscapeUrl } from '@qlevr/shared/utilities';
import { useTranslations } from 'next-intl';
import { useQueryState } from 'nuqs';
import { useContext, useEffect, useRef, useState } from 'react';
import IconRenderer from '../../../icons/icon-renderer.component';
import ImageRenderer from '../../../image-renderer/image-renderer';
import BundleProductsVariantSelector from './bundle-products-variant-selector';

/* eslint-disable-next-line */
export interface ProductBundlerCardProps {
  product: ProductInterface;
}

export function ProductBundlerCard(props: ProductBundlerCardProps) {
  const { addVariantId, removeVariantId, variantIds } = useContext(ProductBundlerContext);
  const selectedVariantOfMainProduct = useContext(ProductBundlerContext).variant;

  // state for the selected variant of the product + size and color + active image
  const [activeImage, setActiveImage] = useState<ImageInterface | null>(props.product.featuredImage);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedVariant, setSelectedVariant] = useState<ProductVariantInterface | null>(null);
  const [size, setSize] = useState('');
  const [color, setColor] = useState('');
  const [isAdded, setIsAdded] = useState(true);
  const reverseEscapeSize = reverseEscapeUrl(size);
  const reverseEscapeColor = reverseEscapeUrl(color);
  const previousVariantId = useRef<string | null>(null);
  const [skuUrlParams, setSkuUrlParams] = useQueryState('sku');

  const t = useTranslations();

  // Check if product has size and color options
  const hasSizeOption = props.product.variants?.some((variant) =>
    variant.options.some((option) => option.name.toLowerCase() === 'size'),
  );
  const hasColorOption = props.product.variants?.some((variant) =>
    variant.options.some((option) => option.name.toLowerCase() === 'color'),
  );

  if (selectedVariantOfMainProduct && skuUrlParams) {
    setSkuUrlParams(null);
  }

  // Update size and color based on the selectedVariantOfMainProduct options
  useEffect(() => {
    if (selectedVariantOfMainProduct?.options && !skuUrlParams) {
      selectedVariantOfMainProduct.options.forEach((option) => {
        if (option.name.toLowerCase() === 'size' && hasSizeOption) {
          // Find the size option in props.product.options and check if it contains the value
          const matchingOption = props.product.options?.find(
            (p) => p.name.toLowerCase() === 'size' && p.values.includes(option.value),
          );
          if (matchingOption) {
            setSize(option.value);
          }
        }
        if (option.name.toLowerCase() === 'color' && hasColorOption) {
          // Find the color option in props.product.options and check if it contains the value
          const matchingOption = props.product.options?.find(
            (p) => p.name.toLowerCase() === 'color' && p.values.includes(option.value),
          );
          if (matchingOption) {
            setColor(option.value);
          }
        }
      });
    }
  }, [hasSizeOption, hasColorOption, selectedVariantOfMainProduct, props.product.options, skuUrlParams]);

  // Update active image and manage variant ID in context
  useEffect(() => {
    if (selectedVariant) {
      setActiveImage(selectedVariant.images?.[0]?.image || props.product.featuredImage);

      if (selectedVariant.id !== previousVariantId.current) {
        if (isAdded && selectedVariant.availableForSale) {
          addVariantId(selectedVariant.id);
        }
        if (previousVariantId.current) {
          removeVariantId(previousVariantId.current);
        }
        previousVariantId.current = selectedVariant.id;
      }
    } else {
      setActiveImage(props.product.featuredImage);
    }
  }, [addVariantId, isAdded, props.product.featuredImage, removeVariantId, selectedVariant, variantIds]);

  // make a check that if the varaint options of props.mainproduct and props.product matches, if yes then do not show BundleProductsVariantSelector
  const mainVariantMatchesWithBundleVariants = selectedVariantOfMainProduct?.options?.every((option) => {
    const matchingOption = props.product.options?.find((p) => p.name === option.name);
    return matchingOption?.values.includes(option.value);
  });

  const handleButtonClick = () => {
    if (selectedVariant) {
      if (isAdded) {
        removeVariantId(selectedVariant.id);
      } else {
        addVariantId(selectedVariant.id);
      }
      setIsAdded(!isAdded);
    }
  };

  const isAddedButtonCss = !isAdded
    ? 'bg-white text-night-800 border-azure-500'
    : 'bg-green-500 text-white border-green-500';

  let outOfStockButton = '';

  if (selectedVariant?.availableForSale === false || !selectedVariant?.availableForSale) {
    outOfStockButton = 'opacity-50 cursor-not-allowed';
  }

  return (
    <div className="flex flex-col items-end justify-between rounded-lg border-gray-300 p-2 bg-gray-100 space-y-2 relative">
      <div className="flex w-full">
        {activeImage && <ImageRenderer image={activeImage} className="w-full max-w-20 h-20 mr-4 rounded-lg" />}
        <div className="w-full">
          <div className="text-base font-bold">{props.product.title}</div>
          <div className="text-xs font-normal flex flex-col capitalize mb-1">
            {hasSizeOption && size && (
              <span className="flex gap-1">
                {t('pdp.variants.size')}: {reverseEscapeSize}
                <span className="text-red-500">{errorMessage}</span>
              </span>
            )}
            {hasColorOption && color && (
              <span className="flex gap-1">
                {t('pdp.variants.color')}: {reverseEscapeColor}
                <span className="text-red-500">{errorMessage}</span>
              </span>
            )}
          </div>
          <div className="flex justify-between items-center">
            <div className="text-base font-serif flex gap-2">
              <span className={`${props.product.discountPercentage ? 'line-through text-slate-400' : ''}`}>
                {selectedVariant?.price?.amount && (
                  <span>
                    {selectedVariant.price.symbol}
                    {selectedVariant.price.amount}
                  </span>
                )}
              </span>
              {props.product.discountPercentage && selectedVariant?.price?.amount && (
                <span>
                  {selectedVariant.price.symbol}
                  {calculateDiscountPrice(selectedVariant.price.amount, props.product.discountPercentage ?? 0)}
                </span>
              )}
            </div>
            {(selectedVariant?.availableForSale === true || selectedVariant?.availableForSale) && (
              <button
                className={`font-bold text-xs px-4 py-2 rounded-full flex items-center gap-1 border-2 ${isAddedButtonCss} ${outOfStockButton}`}
                onClick={handleButtonClick}
              >
                {isAdded ? (
                  <>
                    {t('productBundler.isAdded')}
                    <IconRenderer icon={ICONS.checkmark} className="w-4 h-4" />
                  </>
                ) : (
                  <>
                    {t('productBundler.toAdd')}
                    <IconRenderer icon={ICONS.plus} className="w-4 h-4" />
                  </>
                )}
              </button>
            )}
          </div>
          <BundleProductsVariantSelector
            product={props.product}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            setSize={setSize}
            setColor={setColor}
            size={size}
            color={color}
            setErrorMessage={setErrorMessage}
            mainVariantMatchesWithBundleVariants={mainVariantMatchesWithBundleVariants}
          />
        </div>
      </div>
    </div>
  );
}

export default ProductBundlerCard;
