import { ContentCardInterface, ContentColumnsContentGridInterface } from '@qlevr/shared/interfaces';
import { ColumnsContentGridStoryblok, ContentCardStoryblok } from '@qlevr/shared/schema';
import { columnsMapper } from '../columns/columns';
import { contentAlignmentMapper } from '../content-alignment/content-alignment';
import { contentCardStoryblokMapper } from '../content-card/content-card';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';

export function mapColumnsContentGrid(content: ColumnsContentGridStoryblok): ContentColumnsContentGridInterface {
  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    text: mapRichText(content.text),
    items: contentCardsMapper(content.items),
    alignment: contentAlignmentMapper(content.alignment?.[0]),
    columns: columnsMapper(content.columns?.[0]),
    section: sectionMapper(content.section?.[0]),
  };
}

export function contentCardsMapper(cards: ContentCardStoryblok[]): ContentCardInterface[] {
  return cards.reduce((acc, item) => {
    const card = contentCardStoryblokMapper(item);
    if (card) {
      acc.push(card);
    }
    return acc;
  }, [] as ContentCardInterface[]);
}
