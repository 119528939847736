import { BannerInterface } from '@qlevr/shared/interfaces';
import { BannerItemStoryblok, BannerStoryblok } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { themeMapper } from '../theme/theme';
import { mapRichText } from '../rich-text/rich-text';

export function mapBanner(banner: BannerStoryblok[] | undefined): BannerInterface | null {
  if (!banner || isEmpty(banner) || isEmpty(banner[0]?.items)) {
    return null;
  }

  const items = mapBannerItems(banner[0].items);
  if (!items || isEmpty(items)) {
    return null;
  }

  return {
    items,
    theme: themeMapper(banner?.[0]?.theme?.toString()),
  };
}

function mapBannerItems(items?: BannerItemStoryblok[]): string[] {
  if (!items || isEmpty(items)) {
    return [];
  }

  return items?.reduce((acc, item) => {
    if (item.text) {
      acc.push(mapRichText(item.text));
    }

    return acc;
  }, [] as string[]);
}
