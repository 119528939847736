/**
 * Formats the given price string into a number with two decimal places.
 *
 * @param price - The price string to be formatted.
 * @returns The formatted price as a number.
 * @example priceFormat('34.9') => 34.90
 */
export function priceFormat(price: string): string | null {
  if (!price) {
    return null;
  }

  const priceFloat: number = parseFloat(price);
  return priceFloat.toFixed(2);
}
