import { COLUMNS } from '@qlevr/shared/constants';
import { ColumnsInterface } from '@qlevr/shared/interfaces';
import { ColumnStoryblok } from '@qlevr/shared/schema';
import { columnMapper } from './column-type';

export function columnsMapper(column: ColumnStoryblok | undefined): ColumnsInterface {
  if (!column) {
    return {
      columns: COLUMNS['1/1'],
      columnsLg: COLUMNS['1/3'],
    };
  }

  return {
    columns: columnMapper(column.columns),
    columnsLg: columnMapper(column.columnsLg),
  };
}
