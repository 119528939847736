import { LOCALES } from '@qlevr/shared/constants';
import { FetchStoryblokStoryInteface, MetaInterface, RequestErrorInterface } from '@qlevr/shared/interfaces';
import { getLinkedData } from '@qlevr/shared/mappers';
import { getLocaleFromCountryLocale, hasFallbackLocale } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { getMeta } from '../meta';
import { resolveRelationsQueryParams } from '../resolve-relations';

export abstract class TryFetchStoryblokStoryService<T, S> {
  private params: FetchStoryblokStoryInteface;

  constructor(params: FetchStoryblokStoryInteface) {
    this.params = params;
    this.params.locale = this.params.locale.toLocaleLowerCase() ?? LOCALES[0];
  }

  protected abstract mapper(data: S, meta: MetaInterface): T;

  public async request(): Promise<T | RequestErrorInterface> {
    let res = await fetch(this.getURL, { next: { tags: [this.params.path] } });
    let isError = res.status !== 200;
    if (isError && !hasFallbackLocale(this.params.locale)) {
      console.error('Page not found', this.getURL);
      return { notFound: true };
    }

    // Retry with the fallback locale
    if (isError && hasFallbackLocale(this.params.locale)) {
      res = await fetch(this.fallBackUrl, { next: { tags: [this.params.path] } });
      isError = res.status !== 200;
    }
    if (isError) {
      console.error('Page not found', this.fallBackUrl);
      return { notFound: true };
    }

    const page = await res.json();

    if (!page.story.content.contentBlocks || isEmpty(page.story.content.contentBlocks)) {
      console.error('Empty content blocks', this.getURL, this.fallBackUrl);
      return { notFound: true };
    }

    const linkedData = getLinkedData(page.story.content);
    const meta = await getMeta(this.params.locale, this.params.preview, page.rels, linkedData);

    return this.mapper(page.story, meta);
  }

  private get getParams(): string {
    return new URLSearchParams({
      token: process.env['NEXT_PUBLIC_STORYBLOK_API_TOKEN'] as string,
      version: this.params.preview ? 'draft' : 'published',
      resolve_relations: resolveRelationsQueryParams,
      cv: this.params.cv,
    }).toString();
  }

  private get getURL(): string {
    return `https://api.storyblok.com/v2/cdn/stories/${this.params.locale}/${process.env['NEXT_PUBLIC_BRAND_NAME']}${this.params.path}?${this.getParams}`;
  }

  private get fallBackUrl(): string {
    return `https://api.storyblok.com/v2/cdn/stories/${getLocaleFromCountryLocale(this.params.locale)}/${process.env['NEXT_PUBLIC_BRAND_NAME']}${this.params.path}?${this.getParams}`;
  }
}
