'use client';
import { BREAKPOINTS, LINK_STYLES } from '@qlevr/shared/constants';
import { ContentInstagramInterface } from '@qlevr/shared/interfaces';
import { FreeMode, Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from '../container/container';
import LinkRenderer from '../link/link-renderer';
import Section from '../section/section';

/* eslint-disable-next-line */
export interface ContentInstagramProps extends ContentInstagramInterface {}

export function Instagram(props: ContentInstagramProps) {
  return (
    <Section {...props.section}>
      <Container>
        <div className="pb-6 text-center">
          <h2 className="mb-1 font-sans text-4xl">{props.title}</h2>
          <p>{props.description}</p>
        </div>
        <Swiper
          className={`swiper-with-nav swiper-with-scrollbar ${props.section.theme.carousel}`}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          navigation={true}
          modules={[Scrollbar, FreeMode, Navigation]}
          scrollbar={{ draggable: true, dragClass: `swiper-scrollbar-drag` }}
          breakpoints={{
            [BREAKPOINTS.lg]: {
              slidesPerView: 4,
            },
            [BREAKPOINTS.sm]: {
              slidesPerView: 3,
            },
            [BREAKPOINTS.xs]: {
              slidesPerView: 1.5,
            },
          }}
        >
          {props.items.map((item, key) => (
            <SwiperSlide key={key}>
              <div className="relative h-full">
                <img
                  src={item.url}
                  alt=""
                  className="block aspect-square h-full w-full rounded-t-lg bg-transparent object-cover"
                />
                <LinkRenderer
                  cta={{
                    as: 'link',
                    href: item.link,
                    label: '',
                    linkStyle: LINK_STYLES.textBlack,
                    target: '_blank',
                    wrapperClassName: 'after:absolute after:inset-0',
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Section>
  );
}

export default Instagram;
