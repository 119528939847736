import { LinkedMetaDataInterface } from '@qlevr/shared/interfaces';
import {
  PageStoryblok
} from '@qlevr/shared/schema';
import { getContentBlocksLinkedData } from './content-blocks-linked-data';
import { getHeroBlocksLinkedData } from './hero-blocks-linked-data';

export function getLinkedData(
  page: PageStoryblok
): LinkedMetaDataInterface {
  const meta: LinkedMetaDataInterface = {
    blog: false,
    products: new Set(),
    collections: new Set(),
    instagram: false,
    productReviewIds: new Set(),
  };

  getHeroBlocksLinkedData(page.hero, meta)
  getContentBlocksLinkedData(page.contentBlocks, meta);

  return meta;
}
