'use client';

import { ImageInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import { useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageRenderer from '../../image-renderer/image-renderer';
import VariantImageInfo from './variant-image-info';

export interface ProductCarouselProps {
  images?: ImageInterface[] | null;
  thumbnailImages: ImageInterface[] | null;
  featuredImage?: ImageInterface | null;
}

export function ProductCarousel({ images, thumbnailImages, featuredImage }: ProductCarouselProps) {
  const [mainSwiper, setMainSwiper] = useState<SwiperCore | null>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);
  const reInitThumbnailCarousel = thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null;
  useEffect(() => {
    if (!images || isEmpty(images)) return;

    let lightbox = new PhotoSwipeLightbox({
      gallery: '#pdp-carousel',
      children: 'a',
      pswpModule: () => import('photoswipe'),
      preloaderDelay: 0,
    }) as PhotoSwipeLightbox | null;

    lightbox?.init();

    lightbox?.on('change', () => {
      const { pswp } = lightbox as PhotoSwipeLightbox;

      if (!mainSwiper || !pswp) return;

      mainSwiper?.slideTo(pswp.currIndex, 0, false);
    });

    return () => {
      lightbox?.destroy();
      lightbox = null;
    };
  }, [images, mainSwiper]);

  if (!images || isEmpty(images)) {
    return (
      <div className="flex aspect-square h-full w-full animate-pulse items-center justify-center rounded-lg bg-gray-300">
        {featuredImage && (
        <ImageRenderer
          image={featuredImage}
          className="productcarousel-image aspect-square rounded-lg bg-white object-contain"
          loadingState={'eager'}
          fetchPriority={'high'}
          preload='preload'
        />
      )}
      </div>
    );
  }

  return (
    <>
      <Swiper
        id="pdp-carousel"
        spaceBetween={24}
        slidesPerView={1}
        navigation={true}
        pagination={{ clickable: true }}
        thumbs={{ swiper: reInitThumbnailCarousel || undefined }}
        onSwiper={setMainSwiper}
        modules={[Navigation, Pagination, FreeMode, Thumbs]}
        className="swiper-product-desktop-carousel swiper-pagination-hidden swiper-product-carousel"
      >
        {images.map((image, key) => (
          <SwiperSlide key={`thumbnail-${key}`}>
            {image && (
              <>
              <a
                href={image.url}
                data-pswp-width="1024"
                data-pswp-height="1024"
                target="_blank"
                rel="noreferrer"
                className="relative h-full w-full"
              >
                <ImageRenderer
                  image={image}
                  className="aspect-square rounded-lg bg-white object-contain"
                  loadingState={key === 0 ? 'eager' : 'lazy'}
                  fetchPriority={key === 0 ? 'high' : 'auto'}
                  preload={key === 0 ? 'preload' : 'none'}
                />
                {image.alt && <span className="sr-only">{image.alt}</span>}
              </a>
              <VariantImageInfo image={image} />
              </>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {thumbnailImages && (
        <div className="mobile-thumbnails relative mx-auto hidden max-w-sm pt-5 lg:block">
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={5}
            navigation={true}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="swiper-product-carousel swiper-product-mobile-carousel !px-1 !pb-2 !pt-1"
          >
            {thumbnailImages.map((image, key) => (
              <SwiperSlide key={`thumb-${key}`}>
                {image && (
                  <ImageRenderer
                    image={image}
                    className="block aspect-square cursor-pointer rounded bg-transparent object-cover shadow-md"
                    loadingState={key < 5 ? 'eager' : 'lazy'}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
}

export default ProductCarousel;
