import { LOCALISED_PAIRS } from '@qlevr/shared/constants';
import { PathInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';

/**
 * Ensures that alternate language-region URLs are added to the input object based on predefined mappings.
 * If a "base" language-region pair exists (like `nl-nl`), and its alternate region (like `nl-be`) does not exist,
 * the alternate region is added with the same path. If the alternate region already exists, it is not modified.
 * This function works even if the base language-region pair is missing but an alternate region exists.
 */
export function addMissingLocalRegionUrls(paths: PathInterface[]): PathInterface[] {
  if (!paths || isEmpty(paths)) {
    return [];
  }
  // Process each locale pair and accumulate the results
  const updatedPaths = Object.entries(LOCALISED_PAIRS).reduce((acc, [baseLocale, regionalLocale]) => {
    return addRegionalLocale(acc, baseLocale, regionalLocale);
  }, paths);

  return updatedPaths;
}

function addRegionalLocale(paths: PathInterface[], baseLanguage: string, regionalLanguage: string): PathInterface[] {
  const regionalLocaleExists = paths.some((path) => path.locale === regionalLanguage);
  if (!regionalLocaleExists) {
    const baseLanguageUrl = paths.find((path) => path.locale === baseLanguage);
    if (baseLanguageUrl) {
      // Create a new array with the new locale added, instead of modifying the existing array
      return [...paths, { locale: regionalLanguage, slug: baseLanguageUrl.slug }];
    }
  }
  return paths;
}
