import { COLOR_THEMES, ColorThemeType } from '@qlevr/shared/constants';
import { isThemeType } from '@qlevr/shared/utilities';

const themeMapping = new Map<string, ColorThemeType>([
  ['light', COLOR_THEMES.light],
  ['cold', COLOR_THEMES.cold],
  ['warm', COLOR_THEMES.warm],
  ['dark', COLOR_THEMES.dark],
]);

export function themeMapper(block?: string | ColorThemeType): ColorThemeType {
  if (!block) return COLOR_THEMES.light;

  if(isThemeType(block)) {
    return block;
  }

  const theme = themeMapping.get(block);
  if (!theme) {
    return COLOR_THEMES.light;
  }

  return theme;
}
