export const LINK_STYLES = {
  icon: 'icon',
  primary: 'primary',
  primaryOutline: 'primaryOutline',
  secondary: 'secondary',
  tertiary: 'tertiary',
  tertiaryOutline: 'tertiaryOutline',
  tertiaryOutlineActive: 'tertiaryOutlineActive',
  textBlack: 'text-black',
  yellow: 'yellow',
  filter: 'filter',
  filterActive: 'filterActive',
} as const;

type ObjectValues<T> = T[keyof T];
export type LinkStyleType = ObjectValues<typeof LINK_STYLES>;
