import { ICONS, IconType } from '@qlevr/shared/constants';
import { MappingFunction } from '@qlevr/shared/interfaces';
import { SVGProps } from 'react';
import { ReactComponent as AccordionClose } from '../icons/accordion-content/accordion-close.svg';
import { ReactComponent as AccordionOpen } from '../icons/accordion-content/accordion-open.svg';
import { ReactComponent as AccountLoggedIn } from '../icons/account-logged-in.svg';
import { ReactComponent as Account } from '../icons/account.svg';
import { ReactComponent as ArrowLeft } from '../icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg';
import { ReactComponent as Discount } from '../icons/cart/discount.svg';
import { ReactComponent as Checkmark } from '../icons/checkmark.svg';
import { ReactComponent as Hamburger } from '../icons/hamburger.svg';
import { ReactComponent as ImagePlaceholder } from '../icons/image-placeholder.svg';
import { ReactComponent as ApplePay } from '../icons/payment-methods/apple-pay.svg';
import { ReactComponent as Bancontact } from '../icons/payment-methods/bancontact.svg';
import { ReactComponent as GooglePay } from '../icons/payment-methods/google-pay.svg';
import { ReactComponent as Ideal } from '../icons/payment-methods/ideal.svg';
import { ReactComponent as Klarna } from '../icons/payment-methods/klarna.svg';
import { ReactComponent as MasterCard } from '../icons/payment-methods/mastercard.svg';
import { ReactComponent as PayPal } from '../icons/payment-methods/paypal.svg';
import { ReactComponent as SepaBankTransfer } from '../icons/payment-methods/sepa-bank-transfer.svg';
import { ReactComponent as Visa } from '../icons/payment-methods/visa.svg';
import { ReactComponent as AccordionArrow } from '../icons/product/accordion-arrow.svg';
import { ReactComponent as Close } from '../icons/product/close.svg';
import { ReactComponent as SearchBar } from '../icons/search-bar.svg';
import { ReactComponent as SearchClose } from '../icons/search-close.svg';
import { ReactComponent as ShippingPackage } from '../icons/shipping/package.svg';
import { ReactComponent as Facebook } from '../icons/social-icons/facebook.svg';
import { ReactComponent as Instagram } from '../icons/social-icons/instagram.svg';
import { ReactComponent as Mail } from '../icons/social-icons/mail.svg';
import { ReactComponent as Star } from '../icons/star.svg';
import { ReactComponent as Mute } from '../icons/video/mute.svg';
import { ReactComponent as Unmute } from '../icons/video/unmute.svg';
import { ReactComponent as ChevronDown } from './accordion-content/chevron-down.svg';
import { ReactComponent as ChevronUp } from './accordion-content/chevron-up.svg';
import { ReactComponent as AlertCircle } from './alert-cirlce.svg';
import { ReactComponent as CartIcon } from './cart.svg';
import { ReactComponent as Spinner } from './cart/spinner.svg';
import { ReactComponent as Filter } from './collections/filter.svg';
import { ReactComponent as DecreaseQuantity } from './guided-buying-flow/minus.svg';
import { ReactComponent as IncreaseQuantity } from './guided-buying-flow/plus.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as MinusCircle } from './minus-circle.svg';
import { ReactComponent as PlusCircle } from './plus-circle.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Alert } from './product/icon-alert.svg';
import { ReactComponent as ChevronRight } from './subscribe/chevron-right.svg';
import { ReactComponent as Pause } from './video/pause.svg';
import { ReactComponent as Play } from './video/play.svg';
import { ReactComponent as StretchedPause } from './video/stretched-pause.svg';
import { ReactComponent as StretchedPlay } from './video/stretched-play.svg';

/* eslint-disable-next-line */
export interface IconRendererProps extends SVGProps<SVGSVGElement> {
  icon?: IconType | undefined | null;
}

const componentMapping = new Map<IconType, MappingFunction<SVGProps<SVGSVGElement>, JSX.Element>>([
  [ICONS.accordionArrow, (props) => <AccordionArrow {...props} />],
  [ICONS.accordionClose, (props) => <AccordionClose {...props} />],
  [ICONS.accordionOpen, (props) => <AccordionOpen {...props} />],
  [ICONS.account, (props) => <Account {...props} />],
  [ICONS.accountLoggedIn, (props) => <AccountLoggedIn {...props} />],
  [ICONS.alert, (props) => <Alert {...props} />],
  [ICONS.alertCircle, (props) => <AlertCircle {...props} />],
  [ICONS.arrowLeft, (props) => <ArrowLeft {...props} />],
  [ICONS.arrowRight, (props) => <ArrowRight {...props} />],
  [ICONS.applePay, (props) => <ApplePay {...props} />],
  [ICONS.bancontact, (props) => <Bancontact {...props} />],
  [ICONS.cart, (props) => <CartIcon {...props} />],
  [ICONS.chevronRight, (props) => <ChevronRight {...props} />],
  [ICONS.chevronUp, (props) => <ChevronUp {...props} />],
  [ICONS.chevronDown, (props) => <ChevronDown {...props} />],
  [ICONS.checkmark, (props) => <Checkmark {...props} />],
  [ICONS.close, (props) => <Close {...props} />],
  [ICONS.discount, (props) => <Discount {...props} />],
  [ICONS.facebook, (props) => <Facebook {...props} />],
  [ICONS.filter, (props) => <Filter {...props} />],
  [ICONS.googlePay, (props) => <GooglePay {...props} />],
  [ICONS.hamburger, (props) => <Hamburger {...props} />],
  [ICONS.ideal, (props) => <Ideal {...props} />],
  [ICONS.increaseQuantity, (props) => <IncreaseQuantity {...props} />],
  [ICONS.decreaseQuantity, (props) => <DecreaseQuantity {...props} />],
  [ICONS.instagram, (props) => <Instagram {...props} />],
  [ICONS.imagePlaceholder, (props) => <ImagePlaceholder {...props} />],
  [ICONS.lock, (props) => <Lock {...props} />],
  [ICONS.klarna, (props) => <Klarna {...props} />],
  [ICONS.mail, (props) => <Mail {...props} />],
  [ICONS.mastercard, (props) => <MasterCard {...props} />],
  [ICONS.minusCircle, (props) => <MinusCircle {...props} />],
  [ICONS.mute, (props) => <Mute {...props} />],
  [ICONS.paypal, (props) => <PayPal {...props} />],
  [ICONS.pause, (props) => <Pause {...props} />],
  [ICONS.play, (props) => <Play {...props} />],
  [ICONS.plus, (props) => <Plus {...props} />],
  [ICONS.plusCircle, (props) => <PlusCircle {...props} />],
  [ICONS.searchBar, (props) => <SearchBar {...props} />],
  [ICONS.searchClose, (props) => <SearchClose {...props} />],
  [ICONS.shippingPackage, (props) => <ShippingPackage {...props} />],
  [ICONS.spinner, (props) => <Spinner {...props} />],
  [ICONS.sepaBankTransfer, (props) => <SepaBankTransfer {...props} />],
  [ICONS.star, (props) => <Star {...props} />],
  [ICONS.unmute, (props) => <Unmute {...props} />],
  [ICONS.visa, (props) => <Visa {...props} />],
  [ICONS.stretchedPlay, (props) => <StretchedPlay {...props} />],
  [ICONS.stretchedPause, (props) => <StretchedPause {...props} />],
]);

export function IconRenderer({ icon, ...restProps }: IconRendererProps) {
  if (!icon) {
    return null;
  }

  const componentControl = componentMapping.get(icon);
  if (!componentControl) {
    return null;
  }

  return componentControl(restProps);
}

export default IconRenderer;
