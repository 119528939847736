import {
  AccordionInterface, ContentAccordionInterface,
} from '@qlevr/shared/interfaces';
import { AccordionContentStoryblok, AccordionStoryblok } from '@qlevr/shared/schema';
import { accordionStoryblokMapper } from '../accordion/accordion';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';
import { contentAlignmentMapper } from '../content-alignment/content-alignment';

export function mapAccordionContent(content: AccordionContentStoryblok): ContentAccordionInterface {
  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    alignment: contentAlignmentMapper(content.alignment?.[0]),
    accordions: accordionMapper(content.accordion),
    section: sectionMapper(content.section?.[0]),
  };
}

export function accordionMapper(accordions: AccordionStoryblok[]): AccordionInterface[] {
  return accordions.reduce((acc, item) => {
    const accordion = accordionStoryblokMapper(item);
    if(accordion) {
      acc.push(accordion);
    }
    return acc;
  }, [] as AccordionInterface[]);
}
