import { ColorThemeType, LINK_STYLES } from '@qlevr/shared/constants';
import { TextImageCardInterface } from '@qlevr/shared/interfaces';
import ImageRenderer from '../image-renderer/image-renderer';
import LinkRenderer from '../link/link-renderer';
import Theme from '../theme/theme';

/* eslint-disable-next-line */
export interface MenuFeaturedCardProps extends TextImageCardInterface {
  theme: ColorThemeType;
}

export function MenuDrawerFeaturedCard(props: MenuFeaturedCardProps) {
  return (
    <Theme className="h-full overflow-hidden rounded-2xl shadow" theme={props.theme}>
      <div className="flex h-full flex-col gap-4 px-3 py-3 text-center">
        {props.cta && (
          <LinkRenderer
            cta={{
              as: 'link',
              href: props.cta.as === 'link' ? props.cta.href : '',
              label: props.cta.label || '',
              linkStyle: LINK_STYLES.textBlack,
              className: 'w-full h-full',
              wrapperClassName: 'w-full space-y-6',
            }}
          >
            <div className="mb-3 font-sans text-2xl">{props.title}</div>
            <ImageRenderer loadingState="lazy" className="h-full" image={props.image} />
          </LinkRenderer>
        )}
        {props.cta && <LinkRenderer cta={{ ...props.cta }} />}
      </div>
    </Theme>
  );
}

export default MenuDrawerFeaturedCard;
