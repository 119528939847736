import { ProductCombinationInterface } from '@qlevr/shared/interfaces';

export function getCombinationIsPreOrder(
  combinations: ProductCombinationInterface[] | null,
  filtered: [string, string][] | null,
): boolean {
  if (!filtered || !combinations) {
    return false;
  }

  const combination = combinations.find((combination) =>
    filtered.every(([key, value]) => combination[key] === value && combination.preOrder),
  );

  if (!combination) {
    return false;
  }

  return combination.preOrder;
}
