import { HeaderInterface } from '@qlevr/shared/interfaces';
import { HeaderStoryblok } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { mapNavigationItem } from './navigation';

export function mapHeader(header: HeaderStoryblok[] | undefined): HeaderInterface {
  if (!header || isEmpty(header)) {
    return {
      navigation: [],
    };
  }

  return {
    navigation: mapNavigationItem(header[0].navigation),
  };
}
