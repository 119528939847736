import { BUTTON_SIZE, ColorThemeType, LINK_STYLES } from '@qlevr/shared/constants';
import { LinkImageCardInterface, ProductInterface, ProductVariantInterface } from '@qlevr/shared/interfaces';
import { getProductSlugWithVariantParams, reverseEscapeUrl } from '@qlevr/shared/utilities';
import { themeMapper } from '../theme/theme';


export function mapLinkImageCardFromProduct(product?: ProductInterface, theme?: ColorThemeType): LinkImageCardInterface | null {
  if(!product) {
    return null;
  }

  return {
    image: product.navigationImage ?? product.featuredImage ?? product.images[0],
    cta: {
      as: 'link',
      linkStyle: LINK_STYLES.textBlack,
      href: product.slug,
      label: product.title,
      size: BUTTON_SIZE.small,
    },
    theme: themeMapper(theme),
  };
}

export function mapLinkImageCardFromProductVariant(product: ProductInterface, variant: ProductVariantInterface, optionKey: string): LinkImageCardInterface | null {
  if(!variant?.featuredImage) {
    return null;
  }

  const title = variant.options.find((option) => option.name === optionKey)?.value || '';

  return {
    image: variant.featuredImage,
    cta: {
      as: 'link',
      linkStyle: LINK_STYLES.textBlack,
      href: getProductSlugWithVariantParams(product.slug, variant.options),
      label: reverseEscapeUrl(title) ?? variant.title,
      size: BUTTON_SIZE.small,
    },
    theme: themeMapper(undefined),
  };
}
