'use client';
import { ContentProductGridInterface } from '@qlevr/shared/interfaces';
import { useCollectionFilters } from '@qlevr/shared/providers';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { Suspense, useEffect, useMemo } from 'react';
import Container from '../container/container';
import Section from '../section/section';
import CollectionProductFilters from './collection-product-filters';
import ProductItem from './product-item';

export interface ContentProductGridProps extends ContentProductGridInterface {}

export function ContentProductGrid(props: ContentProductGridProps) {
  const t = useTranslations();
  const { filteredProducts, setSelectedFilters } = useCollectionFilters();
  const searchParams = useSearchParams();

  const filters = useMemo(() => {
    const filters: Record<string, string[]> = {};
    if (props.collection?.shopifyFilters) {
      props.collection.shopifyFilters.forEach((filter) => {
        filter.values.forEach((value) => {
          if (searchParams.get(value.id)) {
            const array = filters[filter.id] ?? [];
            filters[filter.id] = [...array, value.input];
          }
        });
      });
    }

    return filters;
  }, [props.collection.shopifyFilters, searchParams]);

  useEffect(() => {
    setSelectedFilters(filters);
  }, [filters]);

  return (
    <Section {...props.section} className="pb-5">
      <Container>
        <Suspense fallback={<div>Loading...</div>}>
          <CollectionProductFilters collection={props.collection} />
        </Suspense>
        <div
          className={`grid gap-x-4 gap-y-8 sm:gap-x-6 sm:gap-y-10 ${props.columns?.columns} lg:${props.columns?.columnsLg}`}
        >
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product, key) => (
              <div key={key} className="col-span-1">
                <Suspense fallback={<div>Loading...</div>}>
                  <ProductItem product={product} quickAdd={props.quickAdd} />
                </Suspense>
              </div>
            ))
          ) : (
            <div className="col-span-full">{t('collection.filters.noProducts')}</div>
          )}
        </div>
      </Container>
    </Section>
  );
}

export default ContentProductGrid;
