import { getProductDiscountFromTags } from '@qlevr/shared/utilities';

/**
 * Retrieves the product tags based on the provided parameters.
 * @param tags - The array of tags associated with the product.
 * @param compareAtPrice - The compare at price of the product.
 * @param productPageHandle - The handle of the product page.
 * @returns An array of product tags.
 */

export function getProductTags(tags: string[], productPageHandle: string) {
  const resultTags: string[] = [];

  tags.forEach((tag) => {
    const discountValue = getProductDiscountFromTags(tag);
    if (discountValue !== null && tag.includes(productPageHandle)) {
      resultTags.push(`-${discountValue}%`);
    }
  });

  return resultTags;
}
