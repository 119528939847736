import { KeyValueInterface } from '@qlevr/shared/interfaces';
import { Maybe, MetafieldShopify } from '@qlevr/shared/schema';
import { getProductVariantTagMetafieldReference } from '../metafield/get-product-variant-tag';

export function mapProductVariantTag(metafields: Maybe<MetafieldShopify>[]): KeyValueInterface[] | null {
  if (!metafields) {
    return [];
  }

  return getProductVariantTagMetafieldReference('variant_tag', metafields);
}
