import { ProductVariantOptionInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';

/* eslint-disable-next-line */
export interface CartLinItemOptionsProps {
  items: ProductVariantOptionInterface[];
}

export function CartLineItemOptions({ items }: CartLinItemOptionsProps) {
  if (!items || isEmpty(items)) {
    return null;
  }

  return (
    <div>
      {items.map((item, key) => (
        <span key={key} className="block text-sm first-letter:uppercase">
          {item.label}
        </span>
      ))}
    </div>
  );
}

export default CartLineItemOptions;
