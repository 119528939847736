import { ContentTextImageOverlayInterface } from '@qlevr/shared/interfaces';
import { TextImageOverlayStoryblok } from '@qlevr/shared/schema';
import { contentAlignmentMapper } from '../content-alignment/content-alignment';
import { linksMapper } from '../link/link';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';
import { imageStoryblokMapper } from '../image/image-storyblok';

export function mapTextImageOverlayContent(content: TextImageOverlayStoryblok): ContentTextImageOverlayInterface | null {
    const image = imageStoryblokMapper(content.image?.[0], { xs: 1 }, false);

    if(!image) {
        return null;
    }

    return {
        _editable: content['_editable'] ?? null,
        _uid: content._uid,
        component: content.component,
        title: mapTitle(content.title?.[0]),
        text: mapRichText(content.text) ?? null,
        rating: content.rating ?? false,
        image,
        overlay: content.overlay ?? false,
        cta: linksMapper(content.cta),
        alignment: contentAlignmentMapper(content.alignment?.[0]),
        section: sectionMapper(content.section?.[0]),
    };
}
