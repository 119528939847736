/* eslint-disable @typescript-eslint/no-explicit-any */

import { ElevarDataLayerEvent } from '@qlevr/shared/interfaces';

export const elevarTrack = (args: ElevarDataLayerEvent) => {
  if (typeof window === 'undefined') return;
  let datalayer: any = (window as any)['ElevarDataLayer'];
  if (!datalayer) {
    datalayer = (window as any)['ElevarDataLayer'] = [];
  }
  datalayer.push({ ...args });
};
