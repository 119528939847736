import { getSizeColorVariantFromUrl } from "./color-size-variant-options/get-size-color-variant-from-url";

export function getActiveStateFromUrlParams(params: { locale: string, slug: string; variant: string }, name: string, value: string) {
  const variant = getSizeColorVariantFromUrl(params);
  if (!variant) {
    return false;
  }

  const isSize = name === 'size' && variant.size === value;
  const isColor = name === 'color' && variant.color === value;
  return isSize || isColor;

}
