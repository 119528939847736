import isEmpty from 'lodash.isempty';
import IconRenderer from '../icons/icon-renderer.component';
import { ICONS } from '@qlevr/shared/constants';
import { SectionInterface } from '@qlevr/shared/interfaces';

/* eslint-disable-next-line */
export interface ProductDeliveryNoteProps {
  notes: string[] | null;
  className?: string;
  section?: SectionInterface;
}

export function ProductDeliveryNote(props: ProductDeliveryNoteProps) {
  if (!props.notes || isEmpty(props.notes)) {
    return null;
  }

  function isLastItem(key: number) {
    return (props.notes?.length ?? 0) - 1 === key;
  }

  return (
    <div className={`flex flex-col gap-2 rounded-lg p-4 text-sm ${props.className}`}>
      {props.notes.map((item, key) => (
        <div key={key} className="flex items-center gap-2">
          <IconRenderer icon={ICONS.checkmark} className={`h-5 ${props.section?.theme.deliveryNote.iconColor}`} />
          <span className="inline-block" dangerouslySetInnerHTML={{ __html: item }} />
          {isLastItem(key) && <IconRenderer icon={ICONS.klarna} className="inline-block h-3" />}
        </div>
      ))}
    </div>
  );
}

export default ProductDeliveryNote;
