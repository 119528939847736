import { ContentCodeInterface } from '@qlevr/shared/interfaces';
import { CodeStoryblok } from '@qlevr/shared/schema';
import { sectionMapper } from '../section/section';

export function mapCodeContent(content: CodeStoryblok): ContentCodeInterface | null {
  if (!content?.['code']?.code) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    code: content['code'].code,
    container: content.container ?? false,
    section: sectionMapper(content.section?.[0]),
  };
}
