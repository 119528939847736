import { LinkInterface, ProductCombinationInterface } from '@qlevr/shared/interfaces';
import { formattedShippingDate } from '@qlevr/shared/utilities';
import { useTranslations } from 'next-intl';
import isEmpty from 'lodash.isempty';
import LinkRenderer from '../link/link-renderer';
import ProductShippingDate from './product-shipping-date';

/* eslint-disable-next-line */
export interface ProductPreOrderProps {
  combination: ProductCombinationInterface | null;
  submitCta?: LinkInterface;
}

export function ProductPreOrder({ combination, submitCta }: ProductPreOrderProps) {
  const t = useTranslations();
  const shippingDate = formattedShippingDate(combination?.shippingDate ?? '');

  return (
    <div className="text-left flex flex-col gap-1">
      <div className="font-sans text-base">{t('pdp.preOrder')}</div>
      <div className="text-sm">{t('pdp.preOrderText', { shippingDate })}</div>
      <div className="w-full">
        {!isEmpty(submitCta) && <LinkRenderer cta={submitCta}>{t('cta.preOrder')}</LinkRenderer>}
        <ProductShippingDate
          shippingDate={shippingDate}
          className="text-green-500 text-sm text-center pt-2 flex justify-center items-center gap-1"
        />
      </div>
    </div>
  );
}

export default ProductPreOrder;
