import { GlobalInterface } from '@qlevr/shared/interfaces';
import CartEmptyProduct from './cart-empty-product';

/* eslint-disable-next-line */
export interface EmptyCartProps {
  globals?: GlobalInterface;
}

export function EmptyCart({ globals }: EmptyCartProps) {
  if (!globals?.emptyCart) {
    return null;
  }

  return (
    <div className="space-y-6 text-center py-10 px-4">
      <div>
        <h2 className="text-2xl font-bold font-sans pb-4">{globals?.emptyCart?.title}</h2>
        {globals?.emptyCart?.text && <div dangerouslySetInnerHTML={{ __html: globals?.emptyCart?.text }} />}
      </div>
      <CartEmptyProduct emptyProduct={globals?.emptyCart?.emptyCartProduct} />
    </div>
  );
}

export default EmptyCart;
