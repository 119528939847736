/**
 * Replace spaces with hyphens
 * Forwards slashes with underscores
 * @param url - The URL to escape.
 * @returns The escaped URL.
 * @example escapeUrl('This is a string') // 'this-is-a-string'
 * @example escapeUrl('Group 2/3 with legs') // 'group-2_3-with-legs'
 */
export function escapeUrl(url: string | null): string {
  if (!url) {
    return '';
  }
  
  return url
    .toLowerCase() // Convert to lowercase
    .normalize('NFD') // Decompose into combining characters
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/\//g, '_') // Replace forward slashes with underscores
}

export function reverseEscapeUrl(url: string | null): string {
  if (!url) {
    return '';
  }
  // Replace underscores with forward slashes and hyphens with spaces
  return url.replace(/_/g, '/').replace(/-/g, ' ');
}
