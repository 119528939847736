import { ContentTeamInterface, PersonInterface } from '@qlevr/shared/interfaces';
import { PersonStoryblok, TeamStoryblok } from '@qlevr/shared/schema';
import { assetStoryblokMapper } from '../asset/asset-storyblok';
import { personStoryblokMapper } from '../person/person';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';

export function mapTeamContent(content: TeamStoryblok): ContentTeamInterface {
  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    text: mapRichText(content.text),
    persons: personMapper(content.person ?? []),
    section: sectionMapper(content.section?.[0]),
  };
}

// @todo refactor personStoryblokMapper
function personMapper(persons: PersonStoryblok[]): PersonInterface[] {
  return persons.flatMap((item) => {
    const person = personStoryblokMapper(item.name, item.role as string, assetStoryblokMapper(item.image));
    return person ? [person as unknown as PersonInterface] : [];
  });
}
