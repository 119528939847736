import { ProductCombinationInterface } from '@qlevr/shared/interfaces';
import { getCountryFromCountryLocale } from '../locales/get-country-country-locale';


/**
 * Determines if a product is applicable for free shipping.
 *
 * @param locale - page locale (e.g. 'en-DE' 'en-BE').
 * @param variant - The variant of the product.
 * @returns A boolean indicating if the product is applicable for free shipping.
 */
export function isApplicableForFreeShipping(
  locale: string | undefined,
  combination: ProductCombinationInterface | null,
): boolean {
  if (!locale || !combination) {
    return false;
  }

  const region = getCountryFromCountryLocale(locale.toLowerCase());
  const isGermany = region === 'de';
  if (!isGermany || !combination.availableForSale) {
    return false;
  }

  return true;
}
