import { AccordionInterface } from '@qlevr/shared/interfaces';
import { AccordionStoryblok } from '@qlevr/shared/schema';
import { imagesStoryblokMapper } from '../image/image-storyblok';
import { mapRichText } from '../rich-text/rich-text';
import { mapVideos } from '../video/video';


export function accordionStoryblokMapper(item: AccordionStoryblok): AccordionInterface | null {
  if (!item.title || !item.text) {
    return null;
  }

  return {
    title: mapRichText(item.title),
    text: mapRichText(item.text),
    video: mapVideos(item.video),
    image: imagesStoryblokMapper(item.image, {
      xs: 1,
      lg: 1.5,
    })
  };
}
