import { ProductCombinationInterface } from '@qlevr/shared/interfaces';

export function inventoryIsLow(combination: ProductCombinationInterface | null | undefined, inventoryThreshold: number) {
  if (!combination) {
    return false;
  }

  if (combination.inventoryQuantity <= 0 || combination.inventoryQuantity > inventoryThreshold) {
    return false;
  }

  return true;
}
