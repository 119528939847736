import { ContentBuyBoxInterface, ContentGuidedBuyingFlowInterface, GlobalInterface, ProductInterface } from '@qlevr/shared/interfaces';
import { sectionMapper } from '../section/section';

export function mapBuyBox(product: ProductInterface, guidedBuyingFlow: ContentGuidedBuyingFlowInterface | null, globals: GlobalInterface | null | undefined): ContentBuyBoxInterface {
  return {
    _uid: 'buyBox',
    component: 'buyBox',
    product,
    deliveryNotes: globals?.deliveryNotes ?? null,
    inventoryThreshold: globals?.inventoryThreshold ?? 0,
    freeShippingThreshold: globals?.freeShippingThreshold ?? 0,
    section: sectionMapper(undefined),
    guidedBuyingFlow: guidedBuyingFlow ?? null,
  };
}
