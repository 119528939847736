import { BUTTON_SIZE, LINK_STYLES } from '@qlevr/shared/constants';
import { CompareProductInterface, SectionInterface } from '@qlevr/shared/interfaces';
import { reverseEscapeUrl } from '@qlevr/shared/utilities';
import { useTranslations } from 'next-intl';
import ImageRenderer from '../../image-renderer/image-renderer';
import LinkRenderer from '../../link/link-renderer';

/* eslint-disable-next-line */
export interface CompareProductProps {
  product: CompareProductInterface;
  section: SectionInterface;
}

export function CompareProductCard({ product, section }: CompareProductProps) {
  const t = useTranslations();

  return (
    <div
      className={`${section.theme.card.background} ${section.theme.card.body.text} relative h-full rounded-lg shadow-md`}
    >
      <div className="absolute left-4 top-4">
        {product.product?.discountPercentage && (
          <div className={`${section.theme.badge} w-fit rounded-full px-4 py-1 text-xs font-bold`}>
            {t('bestDealBadge')}
          </div>
        )}
      </div>
      <ImageRenderer
        image={product.product?.variants?.[0].images?.[0]?.image ?? product.product?.images?.[0] ?? null}
        className="!aspect-3/2 rounded-lg"
      />
      <div className="space-y-4 p-6">
        <div className="text-center font-sans text-xl font-bold">{product.product?.variants?.[0].productTitle}</div>
        <div className="flex items-center justify-center gap-2">
          <span className="text-3xl font-bold lg:text-4xl">
            {product.product?.variants?.[0].price?.symbol}
            {product.product?.variants?.[0].price?.amount}
          </span>
          <span>/ {reverseEscapeUrl(product.product?.variants?.[0].options?.[0].value ?? null)}</span>
        </div>
        <div>
          <LinkRenderer
            cta={{
              label: `Discover`,
              as: 'link',
              wrapperClassName: 'w-full',
              href: `${product.product?.variants?.[0].slug}`,
              size: BUTTON_SIZE.base,
              linkStyle: LINK_STYLES.primary,
            }}
          />
        </div>
        {product.product?.usps && (
          <div className="circle-check-icon" dangerouslySetInnerHTML={{ __html: product.product?.usps || '' }} />
        )}
      </div>
    </div>
  );
}

export default CompareProductCard;
