export const BREAKPOINTS = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xl2: 1536,
  xl3: 1920,
} as const;

type ObjectValues<T> = T[keyof T];
export type BreakpointType = ObjectValues<typeof BREAKPOINTS>;


export const BREAKPOINTS_MOBILE = ['xs', 'sm', 'md'] as const;
export const BREAKPOINTS_DESKTOP = ['lg', 'xl', 'xl2', 'xl3'] as const;
