import { PRODUCT_VARIANT_URL_SEPERATOR } from '@qlevr/shared/constants';
import { ReadonlyURLSearchParams } from 'next/navigation';
import { buildSizeColorUrlParam } from './variants/color-size-variant-options/build-size-color-url-param';

export const getProductUrlParams = (pathname: string, params: URLSearchParams | ReadonlyURLSearchParams) => {
  const paramsString = params.toString();
  const queryString = `${paramsString.length ? '?' : ''}${paramsString}`;

  return `${pathname}${queryString}`;
};

export const getProductUrl = (path: string, urlSegment: { variant: string }, name: string, value: string) => {
  // If the variant is undefined, we have no variant selected yet, so we can just append the value to the path.
  if (urlSegment.variant === undefined) {
    if (name === 'size') {
      return `${path}/${buildSizeColorUrlParam(value)}`;
    }
    if (name === 'color') {
      return `${path}/${buildSizeColorUrlParam(undefined, value)}`;
    }
  }

  // If the variant is defined, we need to replace the current variant with the new one.
  const [size, color] = urlSegment.variant.split(PRODUCT_VARIANT_URL_SEPERATOR);
  const pathWithoutVariant = path.replace(urlSegment.variant, '');

  if (name === 'size') {
    return `${pathWithoutVariant}${buildSizeColorUrlParam(value, color)}`;
  }

  if (name === 'color') {
    // If the size is undefined, we have no size selected yet, so we can just append the value to the path.
    // important: we need to prepend the color with the PRODUCT_VARIANT_URL_SEPERATOR
    return `${pathWithoutVariant}${buildSizeColorUrlParam(size, value)}`;
  }

  // @todo: throw an error here, we should never reach this point.
  return path;
};
