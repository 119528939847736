import { BadgeType } from '@qlevr/shared/constants';
import { isEmpty } from 'lodash';

interface BadgeProps {
  className?: string;
  tags: string[] | null;
  theme: BadgeType;
}

export default function Badge({ tags, className, theme }: BadgeProps) {
  if (!tags || isEmpty(tags)) {
    return null;
  }

  return (
    <div className={`${theme} ${className} border py-1 px-4 rounded-full font-bold w-fit`}>
      {tags.map((tag, index) => (
        <span key={index}>{tag}</span>
      ))}
    </div>
  );
}
