import { ContentTextTextPictoInterface, ImageInterface } from '@qlevr/shared/interfaces';
import { ImageStoryblok, TextTextPictoStoryblok } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { imageStoryblokMapper } from '../image/image-storyblok';
import { linksMapper } from '../link/link';
import { textTextPictoSizeMapper } from '../picto-card/picto-size';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';

export function mapTextTextPictoContent(content: TextTextPictoStoryblok): ContentTextTextPictoInterface | null {
  const logos = mapLogos(content.logo);

  if (!content.text || isEmpty(logos)) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    text: content.text,
    description: mapRichText(content.description),
    cta: linksMapper(content.cta),
    logos,
    section: sectionMapper(content.section?.[0]),
    size: textTextPictoSizeMapper(content.pictoSize ?? ''),
  };
}

function mapLogos(logos: ImageStoryblok[]): ImageInterface[] {
  const items = [] as ImageInterface[];

  logos.reduce((acc, item) => {
    const columnConfig = {
      xs: 4,
      lg: 10,
    };
    const logo = imageStoryblokMapper(item, columnConfig);
    if (logo) {
      acc.push(logo);
    }
    return acc;
  }, items);

  const hasEnoughItems = items.length > 1;
  if (!hasEnoughItems) {
    items.push(...items);
  }

  return items;
}
