import { getLocaleFromSlug } from '../locales/get-locale-from-slug';
import { getLocaleRegionParamByLocale } from '../locales/get-locale-region-param-by-locale';
import { fullPageSlug } from './full-page-slug';
import { removeBrandNameFromLink } from './remove-locale-brand-from-link';

export function getFullUrlFromStoryblokRelativeSlug(relativeSlug: string): string {
   // 'fr/aeromoov/' => 'fr'
   const locale = getLocaleFromSlug(relativeSlug);

   // 'fr' => 'fr-fr'
   // 'nl-be' => 'nl-be'
   const localeRegion = getLocaleRegionParamByLocale(locale);

   // (homepage) fr/aeromoov/ => /
   // (blogpost) fr/aeromoov/blog/10-tips => /blog/10-tips
   const slugWithoutStoryblokFolderPrefix = removeBrandNameFromLink(relativeSlug);

   // en/blog/10-tips
   // nl-be/blog/10-tips
  return fullPageSlug(`${localeRegion}/${slugWithoutStoryblokFolderPrefix}`);
}
