

/**
 * Removes the size parameters from the given URL.
 * @param url - The URL with size parameters.
 * @returns The URL without size parameters.
 * @example https://example.com/image_200x200.jpg => https://example.com/image.jpg
 */
export function imageRemoveSizeParameters(url: string): string {
  const regex = /(_\d+x\d+)|(_x\d+)/;
  return url.replace(regex, '');
}
