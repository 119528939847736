import { LinkInterface } from '@qlevr/shared/interfaces';
import BaseLinkElementRenderer from './base/base-link-element-renderer';

/* eslint-disable-next-line */
export interface TextBlackLinkProps {
  cta: LinkInterface
}

export function TextBlackLink(props: TextBlackLinkProps) {
  return (
    <BaseLinkElementRenderer
      {...props}
      cta={{
        ...props.cta,
        wrapperClassName: `inline-block text-black hover:underline ${props.cta.wrapperClassName}`,
      }}
    />
  );
}

export default TextBlackLink;
