import { ICONS } from '@qlevr/shared/constants';
import { Dispatch, SetStateAction } from 'react';
import IconRenderer from '../icons/icon-renderer.component';
import { useTranslations } from 'next-intl';

interface VideoStretchedPlayPauseProps {
  isPlaying: boolean;
  setPlaying: Dispatch<SetStateAction<boolean>>;
}

export function VideoStretchedPlayPause({ isPlaying: playing, setPlaying }: VideoStretchedPlayPauseProps) {
  const t = useTranslations();
  const accessibilityLabel = playing ? t('video.pause') : t('video.play');
  const icon = playing ? ICONS.stretchedPause : ICONS.stretchedPlay;
  const absoluteClasses = 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
  const isPlayingIcon = playing ? 'opacity-0' : 'opacity-100';
  return (
    <button
      className="group absolute inset-0 flex h-full w-full items-center justify-center text-white after:absolute after:inset-0"
      onClick={() => setPlaying(!playing)}
    >
      <IconRenderer
        className={`max-w-20 text-teal-700 lg:h-24 lg:w-24 lg:max-w-24 ${isPlayingIcon} transition-opacity group-hover:opacity-100 ${absoluteClasses}`}
        icon={icon}
      />
      <span className="sr-only">{accessibilityLabel}</span>
    </button>
  );
}

export default VideoStretchedPlayPause;
