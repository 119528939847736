import { BUTTON_SIZE, LINK_STYLES } from '@qlevr/shared/constants';
import { NewsletterSubscribeInterface } from '@qlevr/shared/interfaces';
import { GlobalStoryblok } from '@qlevr/shared/schema';
import { imageStoryblokMapper } from '../image/image-storyblok';
import { mapRichText } from '../rich-text/rich-text';
import { themeMapper } from '../theme/theme';

export function mapNewsletterSubscribe(global: GlobalStoryblok): NewsletterSubscribeInterface | null {
  const image = imageStoryblokMapper(global.newsletterSubscribeImage?.[0], { xs: 1 })

  if(!global.newsletterSubscribeScript) {
    return null
  }

  return {
    image: image || null,
    script: global.newsletterSubscribeScript,
    text: mapRichText(global.newsletterSubscribeText),
    theme: themeMapper(global.newsletterSubscribeTheme.toString()),
    submit: {
      as: 'button',
      type: 'submit',
      linkStyle: LINK_STYLES.primary,
      size: BUTTON_SIZE.base,
      wrapperClassName: "w-full"
    },
    cancel: {
      as: 'button',
      type: 'button',
      linkStyle: LINK_STYLES.textBlack,
      size: BUTTON_SIZE.small,
    }
  }
}
