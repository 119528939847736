'use client';
import { LINK_STYLES } from '@qlevr/shared/constants';
import { CategoryInterface, SectionInterface } from '@qlevr/shared/interfaces';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';
import Container from '../container/container';
import LinkRenderer from '../link/link-renderer';
import Section from '../section/section';

export interface BlogFilterProps {
  section: SectionInterface;
  categories: CategoryInterface[] | null;
}

export function BlogFilter(props: BlogFilterProps) {
  const t = useTranslations();
  const params = useParams();

  if (!props.categories) return null;

  const getLinkStyle = (category?: string) => {
    const isActive = params.filter === category;
    const isShowAll = !params.filter;

    if (!isActive) {
      return LINK_STYLES.filter;
    }

    if (isShowAll) {
      return LINK_STYLES.filterActive;
    }

    return LINK_STYLES.filterActive;
  };

  const getLink = (category: string) => {
    const isActive = params.filter === category;
    if (!isActive) {
      return `/blog/category/${category}`;
    }

    return `/blog`;
  };

  const getCategoryLink = (categoryId?: string) => {
    if (!categoryId) {
      return `/blog`;
    }

    return getLink(categoryId);
  };

  return (
    <Section {...props.section}>
      <Container>
        <div className="flex w-full flex-wrap gap-2">
          <LinkRenderer
            cta={{
              label: t('blog.filter.showAll'),
              as: 'link',
              linkStyle: getLinkStyle(),
              href: getCategoryLink(),
            }}
          />
          {props.categories.map((category, key) => (
            <LinkRenderer
              key={key}
              cta={{
                label: category.name,
                as: 'link',
                linkStyle: getLinkStyle(`${category.slug}--${category.uuid}`),
                href: getCategoryLink(`${category.slug}--${category.uuid}`),
              }}
            />
          ))}
        </div>
      </Container>
    </Section>
  );
}
