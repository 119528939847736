/**
 * @example
 * 'fr/aeromoov/' => 'fr'
 * 'nl/aeromoov/' => 'nl'
 * 'fr-be/aeromoov/' => 'fr-be'
 * 'nl-be/aeromoov/' => 'nl-be'
 * 'de/aeromoov/' => 'de'
 */

export function getLocaleFromSlug(slug: string): string {
  return slug.split('/')[0];
}
