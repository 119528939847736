import { KeyValueInterface } from '@qlevr/shared/interfaces';
import { Maybe, MetafieldReferenceShopify, MetafieldShopify } from '@qlevr/shared/schema';
import { getMetafieldReferenceImage } from './get-metafield-reference-image';

export function getProductMetafieldReference(key: string, reference: Maybe<MetafieldShopify>[]): KeyValueInterface[] {
  const items: KeyValueInterface[] = [];
  const ref = reference.find((item) => item?.key === key);

  if (!reference) {
    return items;
  }
  const value = getMetafieldReferenceImage(ref?.reference as MetafieldReferenceShopify);

  if (!key || !value) {
    return [];
  }

  items.push({ key, value });

  return items;
}
