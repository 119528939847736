import { BUTTON_SIZE, ColorThemeType, LINK_STYLES } from '@qlevr/shared/constants';
import { ImageInterface, ProductInterface, TextImageCardInterface } from '@qlevr/shared/interfaces';
import { TextImageCardStoryblok } from '@qlevr/shared/schema';
import { imageStoryblokMapper } from '../image/image-storyblok';
import { linkMapper } from '../link/link';
import { mapRichText } from '../rich-text/rich-text';
import { themeMapper } from '../theme/theme';

export function mapTextImageCard(card: TextImageCardStoryblok, theme?: ColorThemeType): TextImageCardInterface {
  return {
    title: card.title ?? '',
    text: mapRichText(card.text),
    image: imageStoryblokMapper(card.image?.[0], { xs: 1 }, true) as ImageInterface,
    cta: linkMapper(card.cta?.[0]),
    theme: themeMapper(theme),
  };
}

export function mapTextImageCardFromProduct(product: ProductInterface, ctaLabel: string, theme?: ColorThemeType): TextImageCardInterface {

  return {
    title: product.title,
    text: product.featuredText ?? '',
    image: product.navigationImage ?? product.featuredImage ?? product.images[0],
    theme: themeMapper(theme),
    cta: {
      as: 'link',
      label: ctaLabel,
      linkStyle: LINK_STYLES.primary,
      size: BUTTON_SIZE.small,
      href: product.slug,
    },
  }
}
