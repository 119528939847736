import { ICONS } from '@qlevr/shared/constants';
import { ImageInterface } from '@qlevr/shared/interfaces';
import { Tooltip } from 'flowbite-react';
import { useTranslations } from 'use-intl';
import IconRenderer from '../../icons/icon-renderer.component';

interface VariantImageInfoProps {
  image: ImageInterface;
}
export function VariantImageInfo({ image }: VariantImageInfoProps) {
  const t = useTranslations();
  if (!image.content?.key) return null;

  return (
    <div className="absolute bottom-0 flex w-full items-center justify-between bg-slate-100 p-4">
      <p className="font-sans">{image.content.key}</p>
      <Tooltip className="max-w-xs bg-white text-black" content={image.content.text} arrow={false} trigger="click">
        <div className="flex items-center gap-1">
          <span className="cursor-pointer text-sm text-slate-400 underline">{t('pdp.moreInfo')}</span>
          {image.content.value ? (
            <img src={image.content.value} alt={image.content.key} className="h-4 w-4 rounded-full" />
          ) : (
            <IconRenderer icon={ICONS.alertCircle} className="h-4 w-4 cursor-pointer text-black" />
          )}
        </div>
      </Tooltip>
    </div>
  );
}
export default VariantImageInfo;
