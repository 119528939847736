import { KeyValueInterface, ProductCombinationInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';

export function getCombinationTag(
  combinations: ProductCombinationInterface[] | null,
  filtered: [string, string][] | null,
): KeyValueInterface[] | null {
  if (!filtered || isEmpty(filtered) || !combinations) {
    return null;
  }

  const combination = combinations.find((combination) =>
    filtered.every(([key, value]) => combination[key] === value && combination.tag),
  );

  if (!combination) {
    return null;
  }

  return combination.tag;
}
