'use client';

import { isProduction } from "@qlevr/shared/utilities";

export function ElevarInitialScript() {

  if(!isProduction()) {
    return null;
  }

  return (
    <script
      type="module"
      dangerouslySetInnerHTML={{
        __html: `
        window.ElevarDataLayer = window.ElevarDataLayer ?? [];
          try {
            const settings = {};
            const config = {
              ...(await import("https://shopify-gtm-suite.getelevar.com/configs/5c48c4107659b3f9c88e121a4b1b46beb9fe2bb5/config.js")).default,
              apex_domain: "aeromoov.com"
            };

            const scriptUrl = settings.proxyPath ? settings.proxyPath + config.script_src_custom_pages_proxied : config.script_src_custom_pages;
            if (scriptUrl) {
              const { handler } = await import(scriptUrl);
              await handler(config, settings);
            }
          }
          catch (error) {
            console.error('Elevar error:', error);
          }
        `,
      }}
    />
  );
}
