import { ContentTextVideoOverlayInterface } from '@qlevr/shared/interfaces';
import { TextVideoOverlayStoryblok } from '@qlevr/shared/schema';
import { contentAlignmentMapper } from '../content-alignment/content-alignment';
import { linksMapper } from '../link/link';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';
import { mapVideo } from '../video/video';

export function mapTextVideoOverlayContent(content: TextVideoOverlayStoryblok): ContentTextVideoOverlayInterface | null {
  const video = mapVideo(content.video?.[0]);

  if(!video) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    text: mapRichText(content.text) ?? null,
    video,
    overlay: content.overlay ?? false,
    cta: linksMapper(content.cta),
    alignment: contentAlignmentMapper(content.alignment?.[0]),
    section: sectionMapper(content.section?.[0]),
  };
}
