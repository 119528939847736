import { TextPartialStoryblok } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { mapRichText } from '../rich-text/rich-text';

export function mapProductDeliveryContent(productDeliveryContent?: TextPartialStoryblok[]): string[] | null {
  if(!productDeliveryContent) {
    return null
  }

  const items = productDeliveryContent?.reduce((acc, item) => {
    if (item.text) {
      acc.push(mapRichText(item.text));
    }

    return acc;
  }, [] as string[]);

  if(isEmpty(items)) {
    return null
  }

  return items
}
