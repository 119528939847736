import { PageTypeType } from '@qlevr/shared/constants';
import { BlogPageInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { blogPageMapper } from '@qlevr/shared/mappers';
import { BlogStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';
import { FetchStoryblokStoryService } from './storyblok/fetch-storyblok-story';

export class FetchBlogBySlug extends FetchStoryblokStoryService<BlogPageInterface, StoryblokStory<BlogStoryblok>> {
  constructor(pageType: PageTypeType, slug: string, locale: string, preview: boolean, cv: string) {
    super({
      path: `${pageType}${slug}`,
      locale,
      preview,
      cv
    });
  }

  mapper(data: StoryblokStory<BlogStoryblok>, meta: MetaInterface) {
    return blogPageMapper(data, meta);
  }
}
