/**
 * Extracts the Shopify product ID from a given URL.
 * @param url - The URL from which to extract the product ID.
 * @returns The extracted Shopify product ID, or null if no match is found.
 */
export function getShopifyProductId(url: string) {
  const regex = /(\d+)$/;
  const match = typeof url === 'string' ? url.match(regex) : null;
  return match ? match[0] : null;
}
