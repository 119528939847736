import { BlogPageInterface, CategoryInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { BlogStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';
import { assetStoryblokMapper } from '../asset/asset-storyblok';
import { contentBlocksMapper } from '../content-blocks/content-blocks';
import { heroBlocksMapper } from '../hero-blocks/hero-blocks';
import { mapLocalisedPagePaths } from '../localisation/localised-page-paths';
import { getMetaRelationItems } from '../meta/get-meta-relation-item';
import { metatagsMapper } from '../metatags/metatags';
import { blogStructuredDataMapper } from '../structured-data/blog-structured-data';

export function blogsMapper(blogs: StoryblokStory<BlogStoryblok>[], meta: MetaInterface): BlogPageInterface[] {
  return blogs?.map((item) => blogPageMapper(item, meta)) ?? [];
}

export function blogPageMapper(page: StoryblokStory<BlogStoryblok>, meta: MetaInterface): BlogPageInterface {
  const hero = heroBlocksMapper(page.name, page.content.hero, meta);
  const localisation = mapLocalisedPagePaths(page.alternates);
  const categories = getMetaRelationItems<CategoryInterface>(page.content.categories, meta);

  return {
    localisation,
    globals: meta.globals ?? null,
    contentBlocks: contentBlocksMapper(page.content.contentBlocks, meta),
    hero,
    slug: `/${page.full_slug}`,
    image: assetStoryblokMapper(page.content['image']),
    title: page.name,
    description: page.content?.description ?? '',
    metatags: metatagsMapper(
      page.name,
      page.content['metatags'],
      meta?.globals?.metatags,
      localisation,
      page.full_slug,
    ),
    structuredData: blogStructuredDataMapper(page, meta.globals),
    categories: categories,
  };
}
