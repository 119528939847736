import { escapeUrl } from "../../url/escape-url";

export function buildSizeColorUrlParam(size?: string, color?: string): string {
  const params = [] as string[];

  if (size) {
    params.push(`size=${escapeUrl(size)}`);
  }

  if (color) {
    params.push(`color=${escapeUrl(color)}`);
  }

  // Only prepend '?' if we have any parameters to add
  return params.length > 0 ? `?${params.join('&')}` : '';
}
