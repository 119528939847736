'use client';

import { ProductInterface } from '@qlevr/shared/interfaces';
import { ProductBundlerContext } from '@qlevr/shared/providers';
import {
  getCombinationAvailableForSale,
  getFilteredOptionsBySizeAndOrColor,
  reverseEscapeUrl,
} from '@qlevr/shared/utilities';
import { useContext, useEffect } from 'react';
import ProductBundlerOptionButton from './product-bundler-option-button';

/* eslint-disable-next-line */
export interface VariantSelectorProps {
  product: ProductInterface;
}

export function ProductBundlerVariantSelector({ product }: VariantSelectorProps) {
  // set the selectedParentVariant to the variant from the ProductBundlerContext
  const selectedParentVariant = useContext(ProductBundlerContext).variant;
  // set the productBundlerContext to the ProductBundlerContext
  const productBundlerContext = useContext(ProductBundlerContext);

  // Set the first available variant as the selected variant
  useEffect(() => {
    if (product.firstAvailableVariant && !selectedParentVariant) {
      productBundlerContext.changeVariant(product.firstAvailableVariant);
    }
  });

  // If the product has no options or just one option, return null
  const hasNoOptionsOrJustOneOption =
    !product.options?.length || (product.options.length === 1 && product.options[0]?.values.length === 1);

  if (!product || hasNoOptionsOrJustOneOption) {
    return null;
  }

  return (
    <div className="ProductOptions">
      <div className="flex flex-wrap gap-1 mb-3">
        {selectedParentVariant?.options.map((option) => (
          <span className="font-light" key={option.name}>
            {option.name}: <span className="font-bold">{reverseEscapeUrl(option.value)}</span>
          </span>
        ))}
      </div>
      {product.options?.map((option) => {
        const variantsArray: [string, string][] = [
          ['size', ''],
          ['color', ''],
        ];
        selectedParentVariant?.options.forEach((option) => {
          if (option.name === 'size') {
            variantsArray[0] = [option.name, option.value];
          }
          if (option.name === 'color') {
            variantsArray[1] = [option.name, option.value];
          }
        });

        return (
          <div key={option.id}>
            <div className="flex flex-wrap gap-2 ml-1 lg:ml-0">
              {option.values.map((value) => {
                const optionNameLowerCase = option.name.toLowerCase();

                // Get the active variant option value for the current option
                const variantOption = selectedParentVariant?.options.find(
                  (opt) => opt.name.toLowerCase() === optionNameLowerCase,
                )?.value;

                const isActive = variantOption === value;

                if (optionNameLowerCase === 'size') {
                  variantsArray[0] = [optionNameLowerCase, value];
                }
                if (optionNameLowerCase === 'color') {
                  variantsArray[1] = [optionNameLowerCase, value];
                }

                const filtered = getFilteredOptionsBySizeAndOrColor(product.options, {
                  size: variantsArray[0][1],
                  color: variantsArray[1][1],
                });

                const isAvailableForSale = getCombinationAvailableForSale(product.combinations, filtered);

                return (
                  <ProductBundlerOptionButton
                    key={value}
                    product={product}
                    value={value}
                    isActive={isActive}
                    isAvailableForSale={!!isAvailableForSale}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ProductBundlerVariantSelector;
