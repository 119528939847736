'use client';

import { useEffect } from 'react';
import { AnalyticsEventName } from '@shopify/hydrogen-react';
import { useShopifyAnalytics } from './use-shopify-analytics';
import { isProduction } from '@qlevr/shared/utilities';

export function ShopifyAnalytics() {
  const { sendPageView, pathname } = useShopifyAnalytics();
  useEffect(() => {
    if (!isProduction()) {
      return;
    }
    sendPageView(AnalyticsEventName.PAGE_VIEW);
  }, [pathname, sendPageView]);
  return null;
}

export default ShopifyAnalytics;
