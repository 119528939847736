import { BREAKPOINTS, PAGE_TYPES, RATIOS } from '@qlevr/shared/constants';
import { GlobalInterface } from '@qlevr/shared/interfaces';
import { BlogStoryblok } from '@qlevr/shared/schema';
import {
  fullPageSlug,
  getFullUrlFromStoryblokRelativeSlug,
  getLocaleFromSlug,
  imageStoryblokSrcGenerator,
} from '@qlevr/shared/utilities';
import { StoryblokStory } from 'storyblok-generate-ts';
import { mapSameAs } from './organisation-structured-data';
import { StructuredDataType } from './page-structured-data';
import isEmpty from 'lodash.isempty';

export function blogStructuredDataMapper(
  page: StoryblokStory<BlogStoryblok>,
  globals?: GlobalInterface | null,
): string {
  const structuredData: StructuredDataType = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    ...(globals?.brandName
      ? {
          author: [
            {
              '@type': 'Organization',
              name: globals.brandName,
            },
          ],
          publisher: {
            '@type': 'Organization',
            name: globals.brandName,
            logo: `${fullPageSlug(`image/brand-logo.svg`)}`,
            ...(isEmpty(mapSameAs(globals)) ? { sameAs: mapSameAs(globals) } : {}),
          },
        }
      : {}),
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': fullPageSlug(PAGE_TYPES.blog.substring(1)), // slice the leading / from /blog/ => prevent double slash ..com//blog
    },
    headline: page.name,
    url: getFullUrlFromStoryblokRelativeSlug(page.full_slug),
    inLanguage: getLocaleFromSlug(page.full_slug),
    name: page.name,
    dateModified: page.published_at,
    ...(page.content.image?.filename
      ? {
          image: {
            '@type': 'ImageObject',
            url: imageStoryblokSrcGenerator(page.content.image.filename, RATIOS.DEFAULT, BREAKPOINTS.lg, 1),
            width: BREAKPOINTS.lg.toString(), // because we are using a 1x1 image
            height: BREAKPOINTS.lg.toString(),
          },
        }
      : {}),
    ...(page.content.metatags?.[0]?.description ? { description: page.content.metatags[0].description } : {}),
    ...(page.first_published_at ? { datePublished: page.first_published_at } : {}),
  };

  return JSON.stringify(structuredData);
}
