'use client';

import { BUTTON_SIZE, ButtonSizeType, LINK_STYLES, LinkStyleType } from '@qlevr/shared/constants';
import { LinkInterface, ProductVariantInterface, VariantWithQuantity } from '@qlevr/shared/interfaces';
import { useCart, useGuidedBuyingFlowBundler } from '@qlevr/shared/providers';
import { useTranslations } from 'next-intl';
import { startTransition, useRef } from 'react';
import LinkRenderer from '../link/link-renderer';

/* eslint-disable-next-line */
export interface ProductBulkAddProps {
  variants: ProductVariantInterface[];
  variantWithQuantity: VariantWithQuantity[];
  cta?: Partial<LinkInterface>;
  linkStyle?: LinkStyleType;
  size?: ButtonSizeType;
  label?: string;
}

export function ProductBulkAdd({ variants, label = 'cta.addToCart', cta, variantWithQuantity }: ProductBulkAddProps) {
  const t = useTranslations();
  const cartContext = useCart();
  const componentRef = useRef(null);
  const { bulkAdd } = useCart();
  const guidedBuyingFlowModal = useGuidedBuyingFlowBundler();

  const submitCta: LinkInterface = {
    as: 'button',
    type: 'submit',
    disabled: !variantWithQuantity,
    ...(cta?.linkStyle ? { linkStyle: cta.linkStyle } : { linkStyle: LINK_STYLES.primary }),
    ...(cta?.wrapperClassName ? { wrapperClassName: cta.wrapperClassName } : { wrapperClassName: 'w-full' }),
    ...(cta?.className ? { className: cta.className } : { className: 'whitespace-nowrap' }),
    ...(cta?.size ? { size: cta.size } : { size: BUTTON_SIZE.base }),
    ...(cta?.leadingIcon ? { leadingIcon: cta.leadingIcon } : {}),
    ...(cta?.leadingClassName ? { leadingClassName: cta.leadingClassName } : {}),
  };

  async function formAction() {
    if (!variants || !variantWithQuantity) return;

    startTransition(() => {
      bulkAdd(variants, variantWithQuantity);
    });

    if (guidedBuyingFlowModal.isOpen) {
      guidedBuyingFlowModal.close();
    }

    cartContext.open();
  }

  return (
    <form
      ref={componentRef}
      onClick={(e) => {
        e.preventDefault();
        formAction();
      }}
      className="w-full"
    >
      <LinkRenderer cta={submitCta}>{t(label)}</LinkRenderer>
    </form>
  );
}

export default ProductBulkAdd;
