export const SPACINGS = {
  xs: 'py-8 md:py-6',
  sm: 'py-6 md:py-10', 
  md: 'py-10 md:py-20',
  lg: 'py-20 md:py-40',
  tbxs: 'py-4',
  tbsm: 'py-6', 
  tbmd: 'py-10',
  none: 'py-0',
};

type ObjectValues<T> = T[keyof T];
export type SpacingType = ObjectValues<typeof SPACINGS>;
