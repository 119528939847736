import { useQueryState } from 'nuqs';
import { KeyValueInterface, ProductInterface, SectionInterface } from '@qlevr/shared/interfaces';
import { reverseEscapeUrl } from '@qlevr/shared/utilities';

export interface OptionPillProps {
  product: ProductInterface | null;
  value: string;
  isActive: boolean;
  isAvailableForSale: boolean;
  section: SectionInterface;
  currentlyNotInStock: boolean;
  tag?: KeyValueInterface[] | null;
}

export function OptionPill({
  product,
  value,
  isActive,
  isAvailableForSale,
  section,
  currentlyNotInStock,
  tag,
}: OptionPillProps) {
  const [, setSize] = useQueryState('size');
  const [, setColor] = useQueryState('color');

  // Helpers to determine if the option is a color or size
  const productColor = product?.colors?.find((color) => color.key === value);
  const productSize = product?.sizes?.find((size) => size.key === value);
  const reverseEscapeColor = reverseEscapeUrl(productColor?.key ?? null);
  const reverseEscapeSize = reverseEscapeUrl(productSize?.key ?? null);
  const isSizeOption = !!product?.sizes?.some((size) => size.key === value);
  const slashCss = '';
  let className = 'group relative flex items-end justify-center min-w-8 min-h-8 transition duration-300 ease-in-out';
  let imageClassName = '';

  // isActive class application logic
  if (isActive) {
    className += ' is-active';
  }

  // slashCss class for out-of-stock products, applied only when it's a color option
  if (!isAvailableForSale && !isSizeOption) {
    className += ` ${slashCss}`;
    // Apply opacity to the image if it's a color option
    imageClassName = 'opacity-50';
  }

  // Modified handleClick function to allow clicking regardless of availability
  const handleClick = () => {
    if (productSize) {
      setSize(productSize.key);
    }
    if (productColor) {
      setColor(productColor.key);
    }
  };

  // Simplified getOption function
  const getOption = () => reverseEscapeColor || reverseEscapeSize;

  return (
    <button
      onClick={handleClick}
      className={`${className}`}
      aria-disabled={!isAvailableForSale}
      disabled={!isAvailableForSale && !isSizeOption} // Ensure size options that are not available for sale are disabled
      title={`${value}${!isAvailableForSale ? ' (Out of Stock)' : ''}`}
    >
      <div
        className={`border-2 capitalize font-bold border-slate-200 text-slate-600 rounded-full px-5 py-2 ${imageClassName} ${isActive ? `${section.theme.variantHighlight} group-[.is-active]:shadow-md group-[.is-active]:text-night-800` : ''}`}
      >
        {getOption()}
      </div>
    </button>
  );
}

export default OptionPill;
