import { KeyValueInterface } from '@qlevr/shared/interfaces';
import { Maybe, MetafieldShopify, MetaobjectShopify } from '@qlevr/shared/schema';
import { getMetafieldReferenceFields } from './get-metafield-reference-fields';
import { getVariantTagColor } from '@qlevr/shared/utilities';

export function getProductVariantTagMetafieldReference(
  metaKey: string,
  reference: Maybe<MetafieldShopify>[],
): KeyValueInterface[] | null {
  const items: KeyValueInterface[] = [];
  const ref = reference.find((item) => item?.key === metaKey);

  if (!reference || !ref) {
    return items;
  }

  const key = getMetafieldReferenceFields('tag', (ref.reference as MetaobjectShopify).fields);
  const value = getVariantTagColor(getMetafieldReferenceFields('color', (ref.reference as MetaobjectShopify).fields));

  if (!key || !value) {
    return [];
  }

  items.push({ key, value });

  return items;
}
