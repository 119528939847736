import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useScroll, useTransform, motion, useInView } from 'framer-motion';
import { AnimatedImagesInterface } from '@qlevr/shared/interfaces';

interface AnimatedImageCanvasProps {
  scrollHeight: number;
  numFrames: number;
  width: number;
  height: number;
  className?: string;
  sectionRef: React.RefObject<HTMLDivElement>;
  images: AnimatedImagesInterface[];
}

export default function AnimatedImageCanvas({
  scrollHeight,
  numFrames,
  width,
  height,
  className,
  sectionRef,
  images,
}: AnimatedImageCanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageElements, setImageElements] = useState<(HTMLImageElement | null)[]>(Array(numFrames).fill(null));
  const [frameIndex, setFrameIndex] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const loadedImages = useRef(new Set<number>());

  const getCurrentFrame = useCallback(
    (index: number) => {
      try {
        return images[index - 1].url;
      } catch (e) {
        console.log(e);
      }
    },
    [images],
  );

  const preloadInitialImages = useCallback(() => {
    const initialImages: (HTMLImageElement | null)[] = [];
    for (let i = 1; i <= Math.ceil(numFrames * 0.1); i++) {
      const img = new Image();
      const imgSrc = getCurrentFrame(i) as string;
      if (imgSrc) {
        img.src = imgSrc;
        img.onerror = () => console.log(`Failed to load image: ${imgSrc}`);
        initialImages.push(img);
        loadedImages.current.add(i - 1);
      }
    }
    setImageElements((prev) => initialImages.concat(prev.slice(initialImages.length)));
  }, [numFrames, getCurrentFrame]);

  const loadImage = useCallback(
    (index: number) => {
      if (!loadedImages.current.has(index)) {
        const img = new Image();
        const imgSrc = getCurrentFrame(index + 1) as string;
        if (imgSrc) {
          img.src = imgSrc;
          img.onerror = () => console.log(`Failed to load image: ${imgSrc}`);
          img.onload = () => {
            setImageElements((prev) => {
              const newImages = [...prev];
              newImages[index] = img;
              return newImages;
            });
            loadedImages.current.add(index);
          };
        }
      }
    },
    [getCurrentFrame],
  );

  const renderCanvas = useCallback(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      context.canvas.width = width;
      context.canvas.height = height;
    }
  }, [width, height]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      preloadInitialImages();
      renderCanvas();
    }
  }, [preloadInitialImages, renderCanvas]);

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    let animationFrameId: number;

    const render = () => {
      if (context && imageElements[frameIndex]) {
        context.clearRect(0, 0, width, height);
        context.drawImage(imageElements[frameIndex] as CanvasImageSource, 0, 0);
      }
      animationFrameId = requestAnimationFrame(render);
    };

    render();

    return () => cancelAnimationFrame(animationFrameId);
  }, [frameIndex]);

  const { scrollY } = useScroll();
  const frameIndexTransform = useTransform(
    scrollY,
    [
      sectionRef.current?.offsetTop ?? 0,
      (sectionRef.current?.offsetTop ?? 0) + scrollHeight - (typeof window !== 'undefined' ? window.innerHeight : 0),
    ],
    [0, numFrames - 1],
  );

  useEffect(() => {
    const unsubscribe = frameIndexTransform.on('change', (latest) => {
      if (isInView) {
        const index = Math.floor(latest);
        setFrameIndex(index);
        loadImage(index);
      }
    });
    return () => unsubscribe();
  }, [frameIndexTransform, isInView, loadImage]);

  const inView = useInView(canvasRef, { amount: 0.6 });

  useEffect(() => {
    setIsInView(inView);
    if (inView) {
      for (let i = Math.ceil(numFrames * 0.1); i < numFrames; i++) {
        loadImage(i);
      }
    }
    document.body.classList.toggle('tech-in-view', inView);
  }, [inView, loadImage]);

  return (
    <motion.canvas
      ref={canvasRef}
      className={`absolute left-1/2 top-1/2 mx-auto -mt-16 h-auto max-h-[80vh] w-auto max-w-full -translate-x-1/2 -translate-y-1/2 outline outline-4 -outline-offset-2 outline-transparent ${className}`}
    />
  );
}
