export const COLUMNS = {
  '1/1': 'grid-cols-1',
  '1/2': 'grid-cols-2',
  '1/3': 'grid-cols-3',
  '1/4': 'grid-cols-4',

  'lg:1/1': 'lg:grid-cols-1',
  'lg:1/2': 'lg:grid-cols-2',
  'lg:1/3': 'lg:grid-cols-3',
  'lg:1/4': 'lg:grid-cols-4',
};

type ObjectValues<T> = T[keyof T];
export type ColumnsType = ObjectValues<typeof COLUMNS>;
