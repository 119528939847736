'use client';
import { ICONS } from '@qlevr/shared/constants';
import { ContentNewsletterInterface } from '@qlevr/shared/interfaces';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import IconRenderer from '../icons/icon-renderer.component';
import ImageRenderer from '../image-renderer/image-renderer';
import Text from '../text/text';
import Title from '../title/title';
import NewsletterSubscribe from './newsletter-subscribe';

export interface NewsletterPopupProps extends ContentNewsletterInterface {}

export function NewsletterPopup(props: NewsletterPopupProps) {
  const t = useTranslations();
  const [openModal, setOpenModal] = useState(props.popup);

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <div>
      {openModal && (
        <div>
          <div className="absolute left-1/2 top-1/2 z-20 mx-auto flex w-full max-w-sm -translate-x-1/2 -translate-y-1/2 transform flex-col items-center overflow-hidden rounded-2xl bg-white shadow transition duration-500 ease-in-out lg:max-w-4xl lg:flex-row lg:rounded-lg">
            <div className="h-full w-full">
              <ImageRenderer image={props.image} className="h-full w-full object-cover" />
            </div>
            <div className="h-full w-full p-4 text-center lg:p-10">
              {props.text && <Text text={props.text} theme={props.section.theme} />}
              {props.title && <Title {...props.title} section={props.section} className="py-2" />}
              {props.subtext && <Text text={props.subtext} theme={props.section.theme} />}
              <IconRenderer
                icon={ICONS.close}
                className="absolute right-4 top-4 h-6 w-6 cursor-pointer text-black"
                onClick={handleCloseModal}
              />
              <NewsletterSubscribe />
              <div
                className="cursor-pointer py-6 text-center text-base font-normal underline"
                onClick={handleCloseModal}
              >
                {t('newsletter.cta.cancel')}
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-10 h-full w-full bg-stone-900 bg-opacity-50 transition-opacity"></div>
        </div>
      )}
    </div>
  );
}

export default NewsletterPopup;
