import { useCollectionFilters } from '@qlevr/shared/providers';
import { FilterShopify } from '@qlevr/shared/schema';
import { getFilteredProducts } from '@qlevr/shared/utilities';
import { useQueryState } from 'nuqs';
import { useEffect, useMemo, useState } from 'react';
import DefaultFilter from './default-filter';
import { ToggleSwitchFilter } from './toggle-switch-filter';

interface FilterMarkupProps {
  filter: FilterShopify;
  option: FilterShopify['values'][0];
}

export default function FilterMarkup({ filter, option }: FilterMarkupProps) {
  const optionId = option.id;
  const [, setparamFilter] = useQueryState(optionId);

  const { selectedFilters, filteredProducts } = useCollectionFilters();
  const checked = (selectedFilters[filter.id] || []).some((f) => f === option.input);

  const [isDisabled, setIsDisabled] = useState(false);

  const simulateFilterApplication = useMemo(() => {
    if (checked) return true;
    const simulate = () => {
      const simulatedFilters = { ...selectedFilters };
      if (!checked) {
        if (!simulatedFilters[filter.id]) {
          simulatedFilters[filter.id] = [];
        }
        simulatedFilters[filter.id] = [...simulatedFilters[filter.id], option.input];
      }

      let simulatedFilteredProducts = [...filteredProducts];
      if (simulatedFilteredProducts.length === 0) {
        return false;
      }
      simulatedFilteredProducts = getFilteredProducts({
        selectedFilters: simulatedFilters,
        products: simulatedFilteredProducts,
      });

      return simulatedFilteredProducts.length > 0;
    };

    return simulate();
  }, [selectedFilters, filteredProducts]);

  useEffect(() => {
    setIsDisabled(!simulateFilterApplication);
  }, [simulateFilterApplication]);

  function handleChange() {
    if (checked) {
      setparamFilter(null);
      return;
    }
    setparamFilter(option.input);
  }

  switch (filter.type) {
    case 'PRICE_RANGE':
      return null;
    case 'BOOLEAN':
      return (
        <ToggleSwitchFilter isDisabled={isDisabled} checked={checked} handleChange={handleChange} option={option} />
      );
    default:
      return <DefaultFilter isDisabled={isDisabled} checked={checked} handleChange={handleChange} option={option} />;
  }
}
