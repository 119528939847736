import { CollectionCardInterface, ColumnsInterface, ContentProductGridInterface } from '@qlevr/shared/interfaces';
import { ColumnStoryblok, SectionStoryblok } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { columnsMapper } from '../columns/columns';
import { sectionMapper } from '../section/section';

export function mapProductGrid(
  collection: CollectionCardInterface,
  quickAdd: boolean | undefined,
  columns?: ColumnsInterface | null,
): ContentProductGridInterface | null {
  if (!collection || isEmpty(collection.products)) {
    return null;
  }
  const section: SectionStoryblok = {
    _uid: 'section',
    theme: 'scheme1',
    spacing: 'none',
    component: 'section',
  };
  return {
    _uid: 'productGrid',
    component: 'productGrid',
    collection: collection,
    columns: columnsMapper(columns as ColumnStoryblok) ?? null,
    products: collection.products,
    quickAdd: quickAdd ?? false,
    section: sectionMapper(section),
  };
}
