import { ICONS, IconType } from '@qlevr/shared/constants';

const mapIconToIcon = new Map<string, IconType>([
  ['alert', ICONS.alert],
  ['alert_circle', ICONS.alertCircle],
  ['arrow_left', ICONS.arrowLeft],
  ['arrow_right', ICONS.arrowRight],
  ['bancontact', ICONS.bancontact],
  ['chevron_right', ICONS.chevronRight],
  ['checkmark', ICONS.checkmark],
  ['discount', ICONS.discount],
  ['facebook', ICONS.facebook],
  ['ideal', ICONS.ideal],
  ['instagram', ICONS.instagram],
  ['image_placeholder', ICONS.imagePlaceholder],
  ['klarna', ICONS.klarna],
  ['mail', ICONS.mail],
  ['mastercard', ICONS.mastercard],
  ['mute', ICONS.mute],
  ['paypal', ICONS.paypal],
  ['play', ICONS.play],
  ['shipping_package', ICONS.shippingPackage],
  ['star', ICONS.star],
  ['unmute', ICONS.unmute],
  ['visa', ICONS.visa],
]);

export function iconMapper(icon?: string): IconType | null {
  if (!icon) {
    return null;
  }

  const mapper = mapIconToIcon.get(icon);
  if (!mapper) {
    throw new Error(`Icon type "${icon}" not supported`);
  }
  return mapper;
}
