import { ProductCombinationInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';

export function getCombinationBySizeAndOrColor(
  combinations: ProductCombinationInterface[] | null,
  size: string | null,
  color: string | null,
): ProductCombinationInterface | null {
  if (!combinations || isEmpty(combinations) || (!size && !color)) {
    return null;
  }

  const combination = combinations.find(
    (combination) => (!size || combination.size === size) && (!color || combination.color === color),
  );

  return combination || null;
}
