import { LINK_TYPES } from '@qlevr/shared/constants';
import { LinkInterface } from '@qlevr/shared/interfaces';
import { LinkStoryblok } from '@qlevr/shared/schema';
import { removeBrandNameFromLink } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { iconMapper } from '../icon/icon';
import { linkSizeMapper } from './size';
import { linkStyleMapper } from './style';

const mapLinkTypeToLinkInterface = new Map<string, (link: LinkStoryblok) => LinkInterface>([
  ['asset', mapUrl],
  ['email', mapEmail],
  ['story', mapPage],
  ['url', mapUrl],
]);

export function linksMapper(links?: LinkStoryblok[]): LinkInterface[] {
  if (!links || isEmpty(links)) {
    return [];
  }

  return links.reduce((acc, link) => {
    const mappedLink = linkMapper(link);
    if (mappedLink) {
      acc.push(mappedLink);
    }
    return acc;
  }, [] as LinkInterface[]);
}

export function linkMapper(item?: LinkStoryblok): LinkInterface | null {
  if (!item) {
    return null;
  }

  const mapper = mapLinkTypeToLinkInterface.get(item?.link?.linktype ?? '');
  if (!mapper) {
    throw new Error(`Link type "${item?.link?.linktype}" not supported`);
  }
  return mapper(item);
}

function mapPage(link: LinkStoryblok): LinkInterface {
  const href = link?.link?.cached_url ?? link?.link?.['story']?.slug;
  return {
    as: 'link',
    href: `/${removeBrandNameFromLink(href)}`,
    label: link.label ?? link?.link?.['story']?.name ?? '',
    linkStyle: linkStyleMapper(link.style),
    size: linkSizeMapper(link.size),
    leadingIcon: iconMapper(link.leading),
    trailingIcon: iconMapper(link.trailing),
  };
}

function mapUrl(link: LinkStoryblok): LinkInterface {
  return {
    as: 'a',
    linkType: LINK_TYPES.url,
    href: `${link?.link?.url}`,
    label: link.label ?? '',
    target: link?.link?.['target'] ?? '_self',
    linkStyle: linkStyleMapper(link.style),
    size: linkSizeMapper(link.size),
    leadingIcon: iconMapper(link.leading),
    trailingIcon: iconMapper(link.trailing),
  };
}

function mapEmail(link: LinkStoryblok): LinkInterface {
  return {
    as: 'a',
    linkType: LINK_TYPES.email,
    href: link?.link?.email,
    label: link.label ?? link?.link?.email ?? '',
    linkStyle: linkStyleMapper(link?.link?.['story']?.style),
    size: linkSizeMapper(link.size),
    leadingIcon: iconMapper(link.leading),
    trailingIcon: iconMapper(link.trailing),
  };
}
