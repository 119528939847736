import { KeyValueInterface, ProductCombinationInterface } from '@qlevr/shared/interfaces';
import { getCombinationAvailableForSale } from './get-combination-available-for-sale';
import { getCombinationCurrentlyNotInStock } from './get-combination-currently-not-in-stock';
import { getCombinationIsPreOrder } from './get-combination-is-pre-order';
import { getCombinationTag } from './get-combination-tag';

interface GetCombinationStatusProps {
  combinations: ProductCombinationInterface[] | null;
  filtered: [string, string][] | null;
}
export function getCombinationStatus({ combinations, filtered }: GetCombinationStatusProps): {
  isAvailableForSale: boolean;
  isCurrentlyNotInStock: boolean;
  tag: KeyValueInterface[] | null;
  isPreOrder: boolean;
} {

  // Perform the checks
  const isAvailableForSale = getCombinationAvailableForSale(combinations, filtered);
  const isCurrentlyNotInStock = getCombinationCurrentlyNotInStock(combinations, filtered);
  const tag = getCombinationTag(combinations, filtered);
  const isPreOrder = getCombinationIsPreOrder(combinations, filtered);

  // Return an object with all the results
  return {
    isAvailableForSale,
    isCurrentlyNotInStock,
    tag,
    isPreOrder,
  };
}
