import { LINK_STYLES, LinkStyleType } from '@qlevr/shared/constants';

const mapLinkStyleToLinkStyle = new Map<string, LinkStyleType>([
  ["icon", LINK_STYLES.icon],
  ["primary", LINK_STYLES.primary],
  ["secondary", LINK_STYLES.secondary],
  ["tertiary", LINK_STYLES.tertiary],
  ["text_black", LINK_STYLES.textBlack],
]);

export function linkStyleMapper(style?: string): LinkStyleType {
  if (!style) {
    return LINK_STYLES.primary;
  }

  const mapper = mapLinkStyleToLinkStyle.get(style);
  if (!mapper) {
    return LINK_STYLES.primary;
  }
  return mapper;
}
