import { KeyValueInterface } from '@qlevr/shared/interfaces';
import { Maybe, MetafieldShopify } from '@qlevr/shared/schema';
import { getProductMetafieldReferencesFields } from '../metafield/get-product-metafield-references-field';


export function mapProductSizeSwatches(metafields: Maybe<MetafieldShopify>[]): KeyValueInterface[] {
  if (!metafields) {
    return [];
  }

  return getProductMetafieldReferencesFields('product_sizes', metafields);
}
