import { ProductInterface } from '@qlevr/shared/interfaces';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { useQueryState } from 'nuqs';

interface ImageToggleProps {
  product: ProductInterface[];
}

export function ImageToggle({ product }: ImageToggleProps) {
  const t = useTranslations();
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams.toString());
  const isActive = params.has('listView');
  const [, setListView] = useQueryState('listView');

  const getProductInUseStyle = (isActive: boolean) => {
    if (!isActive) return 'border-slate-300 text-slate-300';
    return 'border-black text-black';
  };

  return (
    <div className="flex lg:flex-row flex-row-reverse justify-between items-center">
      <div className="flex flex-row text-base font-normal">
        <div
          className={`border py-1 px-4 rounded-l-sm text-center cursor-pointer ${getProductInUseStyle(!isActive)}`}
          onClick={() => setListView(null)}
        >
          {t('collection.product')}
        </div>
        <div
          className={`border py-1 px-4 rounded-r-sm text-center cursor-pointer ${getProductInUseStyle(isActive)}`}
          onClick={() => setListView('contextual-image')}
        >
          {t('collection.inUse')}
        </div>
      </div>
    </div>
  );
}

export default ImageToggle;
