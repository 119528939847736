import { getLocaleFromCountryLocale } from './get-locale-country-locale';

/**
 * Extracts the locale from a country locale string.
 *
 * @param locale - The country locale string.
 * @returns boolean
 * @example nl-BE = nl => true
 * @example nl = nl => false
 */
export function hasFallbackLocale(locale: string): boolean {
  const fallbackLocale = getLocaleFromCountryLocale(locale);
  return fallbackLocale !== locale;
}
