import { ContentFeaturedBlogsInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { BlogStoryblok, FeaturedBlogsStoryblok } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { StoryblokStory } from 'storyblok-generate-ts';
import { blogCardsMapper } from '../blog/blog-card';
import { contentAlignmentMapper } from '../content-alignment/content-alignment';
import { getMetaRelationItems } from '../meta/get-meta-relation-item';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';

export function mapFeaturedBlogsContent( content: FeaturedBlogsStoryblok, meta: MetaInterface ): ContentFeaturedBlogsInterface | null {
  const blogs = getMetaRelationItems<StoryblokStory<BlogStoryblok>>(content.blogs, meta);

  if (isEmpty(blogs)) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]) ?? null,
    alignment: contentAlignmentMapper(content.alignment?.[0]),
    text: mapRichText(content.text) ?? null,
    section: sectionMapper(content.section?.[0]),
    blogs: blogCardsMapper(blogs, meta),
  };
}
