import { Modal } from 'flowbite-react';
import { ProductInterface } from '@qlevr/shared/interfaces';
import { premiumProductModalTheme } from './premium-product-modal-theme';

export interface MoreInfoContentProps {
  openModal: boolean;
  setOpenModal: (isOpen: boolean) => void;
  product: ProductInterface;
}

export function MoreInfoContent({ openModal, setOpenModal, product }: MoreInfoContentProps) {
  const title = product.premiumProductInformation?.[0].title;
  const content = product.premiumProductInformation?.[0].content;
  return (
    <Modal
      dismissible
      size="md"
      theme={premiumProductModalTheme}
      show={openModal}
      position={'top-right'}
      onClose={() => setOpenModal(false)}
      className="modal-drawer-overlay bg-black/50 backdrop-blur-sm "
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>{content && <div className="px-4 w-full" dangerouslySetInnerHTML={{ __html: content }} />}</Modal.Body>
    </Modal>
  );
}

export default MoreInfoContent;
