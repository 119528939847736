import { collectionPathsMapper } from '@qlevr/shared/mappers';
import { getLanguageCodeShopify, getLocaleFromCountryLocale } from '@qlevr/shared/utilities';
import { notFound } from 'next/navigation';
import { ShopifyService } from './shopify-service';

export const fetchCollectionPaths = async (locale: string) => {
  const res = await ShopifyService({}).getCollectionPaths({
    language: getLanguageCodeShopify(getLocaleFromCountryLocale(locale)),
    first: 100,
  });

  const isError = res.collections === null;
  if (isError) {
    console.error('Collection paths not found');
    return notFound();
  }

  return collectionPathsMapper(res.collections, locale);
};
