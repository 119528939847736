import { SectionInterface, TitleInterface } from '@qlevr/shared/interfaces';

/* eslint-disable-next-line */
export interface TitleProps extends TitleInterface {
  className?: string;
  section: SectionInterface;
}

export function Title({ className, heading, title, layout, section }: TitleProps) {
  const Element = heading;

  return (
    <div className={section.theme.editor}>
      <Element className={`font-sans ${section.theme.color.heading} ${layout} ${className}`}>{title}</Element>
    </div>
  );
}

export default Title;
