import { TextImageCardInterface } from '@qlevr/shared/interfaces';
import ImageRenderer from '../image-renderer/image-renderer';
import Theme from '../theme/theme';
import LinkRenderer from '../link/link-renderer';

/* eslint-disable-next-line */
export interface CartEmptyProductProps {
  emptyProduct: TextImageCardInterface | null;
}

export function CartEmptyProduct(props: CartEmptyProductProps) {
  if (!props.emptyProduct) {
    return null;
  }

  return (
    <Theme className="py-6 px-4 rounded-lg shadow" theme={props.emptyProduct?.theme}>
      <div className="flex flex-col items-center h-full gap-4">
        <div className="space-y-2">
          <h2 className="text-2xl font-bold font-sans">{props?.emptyProduct?.title}</h2>
          {props?.emptyProduct?.text && <div dangerouslySetInnerHTML={{ __html: props?.emptyProduct?.text }} />}
        </div>
        <div className="space-y-4">
          <ImageRenderer loadingState='lazy' image={props.emptyProduct?.image} />
          {props.emptyProduct?.cta && <LinkRenderer cta={props.emptyProduct.cta} />}
        </div>
      </div>
    </Theme>
  );
}

export default CartEmptyProduct;
