export async function emailSubmit(email: string, onSuccess: () => void, onError: (error: string) => void) {
    try {
      // @todo replace this with actual API request
      console.log(`Submitting email ${email}`)
      onSuccess();
    }
    catch(error) {
      throw new Error(`Something went wrong ${error}`)
    }
  }
  