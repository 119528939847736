import { MetaInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';

export function getMetaRelationItems<T>(ids?: (string | T)[], meta?: MetaInterface): T[] {
  if(!ids || isEmpty(ids)) return [];

  return ids.reduce((acc, id) => {
    const item = getMetaRelationItem(id, meta);
    if (item !== null) {
      acc.push(item);
    }

    return acc;
  }, [] as T[]);
}

export function getMetaRelationItem<T>(id: string | T, meta?: MetaInterface): T | null {
  if(!id) return null;

  const unresolvedRelation = typeof id === 'string';
  if (!unresolvedRelation) {
    return id as T;
  }

  const item = meta?.rels?.find((item: { uuid: string }) => item.uuid === id) as | T | undefined;
  if (!item) {
    return null;
  }

  return item;
}
