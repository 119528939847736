import { HeaderInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import LinkRenderer from '../link/link-renderer';

export interface NavigationProps extends HeaderInterface {}

export function MenuNavigationLinks({ navigation }: NavigationProps) {
  if (!navigation || isEmpty(navigation)) {
    return null;
  }

  return (
    <div className="grid grid-cols-2 gap-4 border-b border-t border-solid border-slate-300 py-4 mt-6">
      {navigation.map((item, key) => (
        <LinkRenderer
          key={key}
          cta={{
            ...item.link,
          }}
        />
      ))}
    </div>
  );
}

export default MenuNavigationLinks;
