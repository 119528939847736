import { LinkInterface } from '@qlevr/shared/interfaces';
import Anchor from './anchor';
import Button from './button';
import InternalLink from './internal-link';

/* eslint-disable-next-line */
export interface BaseLinkElementProps {
  children?: React.ReactNode;
  cta: LinkInterface;
}

export function BaseLinkElementRenderer(props: BaseLinkElementProps) {
  if (!props.cta) {
    return null;
  }
  const ctaType = props.cta.as;

  switch (ctaType) {
    case 'a':
      return <Anchor {...props} cta={props.cta} />;
    case 'button':
      return <Button {...props} cta={props.cta} />;
    case 'link':
      return <InternalLink {...props} cta={props.cta} />;
    default:
      throw new Error(`Invalid link type: ${ctaType}`);
  }
}

export default BaseLinkElementRenderer;
