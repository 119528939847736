'use client';
import { VideoInterface } from '@qlevr/shared/interfaces';
import { isInMobileBreakpointView, overlayBrightnessCss } from '@qlevr/shared/utilities';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import VideoPlayPause from './video-playpause';
import VideoPoster from './video-poster';
import VideoSound from './video-sound';

// Dynamically import ReactPlayer for file playback
const ReactPlayerFile = dynamic(() => import('react-player/file'), { ssr: false });

// Dynamically import ReactPlayer for lazy loading
const ReactPlayerLazy = dynamic(() => import('react-player/lazy'), { ssr: false });

/* eslint-disable-next-line */
export interface VideoPlayerProps {
  video: VideoInterface;
  isMuted?: boolean;
  overlay?: boolean;
  scaleAnimation?: boolean;
  playPauseComponent?: React.ElementType | null;
  posterLoadingState?: 'lazy' | 'eager';
}

export function VideoPlayer({
  video,
  isMuted = video.autoplay ? true : false,
  scaleAnimation,
  overlay,
  playPauseComponent: VideoStretchedPlayPause = null,
  posterLoadingState,
}: VideoPlayerProps) {
  const [ref, inView, entry] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const [videoLoaded, setVideoLoaded] = useState(false);
  const isMobile = isInMobileBreakpointView();
  const [scaleValue, setScaleValue] = useState(0.85);
  const [muted, setMuted] = useState(isMuted);
  const isAutoplay = video.autoplay ? inView : false;
  const [playing, setPlaying] = useState(isAutoplay);
  const hasYoutubeUrl = video.youtube;
  const hasShopifyVideoUrl = isMobile ? video.shopifyVideoLink : video.shopifyVideoLinkLg;
  const [videoReady, setVideoReady] = useState(false);

  useEffect(() => {
    if (scaleAnimation) {
      if (inView) {
        setScaleValue(1);
      } else if (entry && entry.boundingClientRect.top > 0) {
        setScaleValue(0.85);
      }
    }

    if (video.autoplay) {
      setPlaying(inView && videoReady);
    }

    if (inView && !videoLoaded) {
      setVideoLoaded(true);
    }
  }, [inView, entry, scaleAnimation, video.autoplay, videoReady]);

  if (!video) return null;

  let videoUrl = isMobile ? video.video : video.videoLg;
  if (hasYoutubeUrl) {
    videoUrl = video.youtube;
  }

  if (hasShopifyVideoUrl) {
    videoUrl = hasShopifyVideoUrl;
  }

  // Determine the type of player to use based on conditions, such as video source type
  const ReactPlayerComponent = hasYoutubeUrl || hasShopifyVideoUrl ? ReactPlayerLazy : ReactPlayerFile;

  return (
    <motion.div
      ref={ref}
      animate={scaleAnimation && { scale: scaleValue }}
      transition={{ duration: 0.5 }}
      className="h-full w-full"
    >
      <VideoPoster
        poster={video.poster}
        isPlaying={playing}
        setPlaying={setPlaying}
        className={`${overlayBrightnessCss(overlay)}`}
        loadingState={posterLoadingState}
      />
      {videoLoaded && (
        <ReactPlayerComponent
          url={videoUrl}
          width="100%"
          height="100%"
          onReady={() => {
            setVideoReady(true);
          }}
          playing={playing}
          loop={video.autoplay}
          playsinline={true}
          muted={muted}
          className={`flex min-h-full w-full items-center`}
        />
      )}
      {VideoStretchedPlayPause ? (
        <>
          <VideoStretchedPlayPause isPlaying={playing} setPlaying={setPlaying} />
          <div className="absolute bottom-4 right-4 z-10 sm:bottom-10 sm:right-20">
            <VideoSound isAutoplay={video.autoplay} isMuted={muted} setIsMuted={setMuted} isPlaying={playing} />
          </div>
        </>
      ) : (
        <div className="absolute bottom-4 right-4 z-10 sm:bottom-10 sm:right-20">
          <div className="flex space-x-2">
            <VideoPlayPause isPlaying={playing} setPlaying={setPlaying} />
            <VideoSound isAutoplay={video.autoplay} isMuted={muted} setIsMuted={setMuted} isPlaying={playing} />
          </div>
        </div>
      )}
    </motion.div>
  );
}

export default VideoPlayer;
