import { AlternateUrlInterface, PathInterface } from '@qlevr/shared/interfaces';
import { fullPageSlug } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { Metadata } from 'next/types';

export function mapAlternatesMetatag(paths?: PathInterface[] | null): Metadata['alternates'] | null {
  if (!paths || isEmpty(paths)) {
    return null;
  }

  const urls = {} as AlternateUrlInterface;
  paths.forEach((path) => {
    urls[path.locale] = `${fullPageSlug(path.locale)}${path.slug}`;
  });

  // add alternate regions when available
  return { languages: urls };
}
