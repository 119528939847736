import { RATIOS } from '@qlevr/shared/constants';
import { SitemapInterface } from '@qlevr/shared/interfaces';
import { GetCollectionPathQueryShopify } from '@qlevr/shared/schema';
import { imageShopifyMapper } from '../image/image-shopify';

export function productSitemapMapper(collection: GetCollectionPathQueryShopify['collection'], locale: string): SitemapInterface[] {
  if(!collection?.products) {
    return []
  }

  return collection.products.edges.map((product) => {
    const image = imageShopifyMapper(product.node.images.edges[0].node.url, { ratio: RATIOS.DEFAULT, columnConfig: { xs: 1 } });

    return {
      slug: product.node.handle,
      locale,
      updatedAt: product.node.updatedAt,
      ...(image?.lg.url ? { image: image.lg.url } : {}),
    };

  });
}
