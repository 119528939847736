'use client';

import { BREAKPOINTS, ICONS, LINK_STYLES } from '@qlevr/shared/constants';
import { CartInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import { useTranslations } from 'next-intl';
import { FreeMode, Navigation, Scrollbar, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductSimpleBuy from '../../product-status/product-buy-simple';
import { calculateDiscountPrice } from '@qlevr/shared/utilities';
import ImageRenderer from '../../image-renderer/image-renderer';

/* eslint-disable-next-line */
export interface CartUpsellProps {
  cart?: CartInterface;
}

// @todo - for design purpose I have used the same products that are in the cart.
export function CartUpsell({ cart }: CartUpsellProps) {
  const t = useTranslations();

  if (!cart || isEmpty(cart.upsellProducts)) {
    return null;
  }

  return (
    <div className="t-cart-upsell-bg swiper-scrollbar-orange w-full pb-4 pl-10">
      <p className="t-cart-upsell-title py-3 text-lg font-medium">{t('cart.upsell.title')}</p>
      <Swiper
        className={`swiper-scrollbar-cart-upsell carousel-warm`}
        spaceBetween={10}
        slidesPerView={1.2}
        freeMode={true}
        modules={[Scrollbar, FreeMode, Navigation, Thumbs]}
        scrollbar={{ draggable: true, dragClass: `swiper-scrollbar-drag` }}
        breakpoints={{
          [BREAKPOINTS.sm]: {
            slidesPerView: 1.5,
          },
        }}
      >
        {cart?.upsellProducts?.map((lineItem, key) => {
          const discountPercentage = lineItem.discountPercentage || 0;
          const discountedPrice =
            discountPercentage > 0
              ? calculateDiscountPrice(lineItem.priceRange?.minVariantPrice.amount, discountPercentage)
              : lineItem.priceRange?.minVariantPrice.amount;

          return (
            <SwiperSlide key={key}>
              <div className="t-cart-upsell-card-border-color grid grid-flow-col items-center justify-items-end gap-3 rounded-2xl border-2 bg-white p-2">
                <div className="flex w-full items-center space-x-4">
                  <ImageRenderer loadingState="lazy" image={lineItem.featuredImage} className="h-20 w-20" />
                  <div>
                    <span className="text-base font-bold">{lineItem.title}</span>
                    <div className="flex gap-1">
                      {discountPercentage > 0 && (
                        <span className="text-slate-400 line-through">
                          {lineItem.priceRange?.minVariantPrice.symbol}
                          {lineItem.priceRange?.minVariantPrice.amount}
                        </span>
                      )}
                      <span className="t-cart-upsell-card-price">
                        {lineItem.priceRange?.minVariantPrice.symbol}
                        {discountedPrice}
                      </span>
                    </div>
                  </div>
                </div>
                {lineItem.variantId && (
                  <ProductSimpleBuy
                    cta={{
                      linkStyle: LINK_STYLES.icon,
                      leadingIcon: ICONS.plus,
                      leadingClassName: 'w-6 h-6',
                      className: '',
                      wrapperClassName: 'btn-primary p-2 rounded-full',
                    }}
                    variant={lineItem.variant}
                    variantId={lineItem.variantId}
                  />
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default CartUpsell;
