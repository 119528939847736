import { AnimatedImageType, Content3dTechLayerInterface, TitleWithTextInterface } from '@qlevr/shared/interfaces';
import { AsMattressPremiumStoryblok, AsMattressStoryblok, TitleWithTextStoryblok } from '@qlevr/shared/schema';
import { sectionMapper } from '../../section/section';
import { titleWithTextMapper } from '../../title-with-text/title-with-text';
import { mapTitle } from '../../title/title';

export function map3dTechLayerContent(content: AsMattressStoryblok | AsMattressPremiumStoryblok): Content3dTechLayerInterface | null {
  if (!content) return null;
  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    titleWithText: titleWithTextBlockMapper(content.titleWithText ?? []) ?? null,
    section: sectionMapper(content.section?.[0]),
    type: content['type'] as AnimatedImageType,
  };
}

export function titleWithTextBlockMapper(titleWithTexts: TitleWithTextStoryblok[]): TitleWithTextInterface[] {
  return titleWithTexts.reduce((acc, item) => {
    const titleWithText = titleWithTextMapper(item);
    if (titleWithText) {
      acc.push(titleWithText);
    }
    return acc;
  }, [] as TitleWithTextInterface[]);
}
