import { PriceInterface } from '@qlevr/shared/interfaces';
import { CurrencyCodeShopify, ProductPriceRangeShopify } from '@qlevr/shared/schema';
import { getSymbolFromCurrencyCode } from '@qlevr/shared/utilities';

export function productPriceMapper(priceRange: ProductPriceRangeShopify): PriceInterface | null {

  if (!priceRange || !priceRange.minVariantPrice || !priceRange.maxVariantPrice || parseFloat(priceRange.minVariantPrice.amount) <= 0) {
    return null;
  }

  return {
    minVariantPrice: {
      amount: parseFloat(priceRange.minVariantPrice.amount),
      currencyCode: priceRange.minVariantPrice.currencyCode,
      symbol: getSymbolFromCurrencyCode(priceRange.minVariantPrice.currencyCode as CurrencyCodeShopify),
    },
    maxVariantPrice: {
      amount: parseFloat(priceRange.maxVariantPrice.amount),
      currencyCode: priceRange.maxVariantPrice.currencyCode,
      symbol: getSymbolFromCurrencyCode(priceRange.maxVariantPrice.currencyCode as CurrencyCodeShopify),
    },
  };
}
