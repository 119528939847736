import {
  CartInterface,
  CartLineItemInterface,
  ElevarDataLayerEvent,
  ElevarProduct,
  Impression,
  ProductInterface,
  VariantAnalyticsInterface,
} from '@qlevr/shared/interfaces';
import { ProductVariantShopify } from '@qlevr/shared/schema';
import { getShopifyProductId } from '@qlevr/shared/utilities';

// TODO: Michiel
export function variantAnalyticsMapper(variant: ProductVariantShopify): VariantAnalyticsInterface {
  return {
    properties: {
      ...(variant.sku ? { sku: variant.sku } : { sku: '' }),
      price: variant.price.amount,
      currency: variant.price.currencyCode,
      variant_id: variant.id,
    },
  };
}

export function productAnalyticsMapper(product: ProductInterface): ElevarDataLayerEvent {
  const activeVariant = product.variants?.[0];

  const products: ElevarProduct[] = [
    {
      id: getShopifyProductId(product.id ?? ''),
      name: `${product.title}`,
      brand: product.vendor !== '' ? `${product.vendor}` : (product.productType ?? 'undefined'),
      category: `${product.productType ?? 'undefined'}`,
      variant: activeVariant?.options.length
        ? activeVariant.options.map((option) => option.value).join(' - ')
        : (product.title ?? 'Default Title'),
      price: product.priceRange?.minVariantPrice.amount.toString() ?? '0.0',
      quantity: '0',
      position: 0,
      list: '',
      product_id: product.id ? getShopifyProductId(product.id) : '',
      variant_id: activeVariant?.id ? getShopifyProductId(activeVariant.id) : '',
      compare_at_price: product.compareAtPriceRange?.minVariantPrice.amount.toString() ?? '0.0',
      image: product.featuredImage?.url,
      url: activeVariant?.slug !== '' ? activeVariant?.slug : product.slug,
    },
  ];

  return {
    event: 'dl_view_item',
    user_properties: {
      visitor_type: 'guest',
    },
    ecommerce: {
      currencyCode: 'EUR',
      detail: {
        actionField: {
          list: '',
          action: 'detail',
        },
        products: products,
      },
    },
  };
}

export function viewCartElevar(cartData: CartInterface | undefined): ElevarDataLayerEvent {
  const impressions = cartData?.items.map((item, index) => {
    return {
      id: getShopifyProductId(item.productId) ?? '',
      name: `${item.title}`,
      brand: item.vendor !== '' ? `${item.vendor}` : (item.productType ?? 'undefined'),
      category: `${item.productType ?? 'undefined'}`,
      variant: item.options.length
        ? item.options.map((option) => option.value).join(' - ')
        : (item.title ?? 'Default Title'),
      price: item.price?.amount.toString() ?? '0.0',
      quantity: item.quantity.toString() ?? '1',
      product_id: getShopifyProductId(item.productId) ?? '',
      variant_id: getShopifyProductId(item.variantId) ?? '',
      compare_at_price: item.compareAtPrice?.amount.toString() ?? '',
      position: index + 1,
    };
  });

  return {
    event: 'dl_view_cart',
    user_properties: {
      visitor_type: 'guest',
    },
    cart_total: cartData?.cost?.totalAmount?.amount?.toString() ?? '0.0',
    ecommerce: {
      currencyCode: 'EUR',
      actionField: {
        list: 'Shopping Cart',
      },
      impressions: impressions as Impression[], // See impressions array below
    },
  };
}

export function removeCartElevar(
  cartData: CartInterface | undefined,
  item: CartLineItemInterface | undefined,
): ElevarDataLayerEvent {
  const products = item
    ? [
        {
          id: item.sku ?? getShopifyProductId(item.productId) ?? '',
          name: `${item.title}`,
          brand: item.vendor !== '' ? `${item.vendor}` : (item.productType ?? 'undefined'),
          category: `${item.productType ?? 'undefined'}`,
          variant: item.options.length
            ? item.options.map((option) => option.value).join(' - ')
            : (item.title ?? 'Default Title'),
          price: item.price?.amount.toString() ?? '0.0',
          quantity: item.quantity.toString() ?? '1',
          list: '',
          product_id: getShopifyProductId(item.productId) ?? '',
          variant_id: getShopifyProductId(item.variantId) ?? '',
          compare_at_price: item.compareAtPrice?.amount.toString() ?? '0.0',
          position: 0,
          image: item.featuredImage?.lg.url ?? item.featuredImage?.url ?? '', // If available, otherwise use an empty string
          url: (item.slug !== '' ? item.slug : 'undefined') || '', // URL for the Product Page. Only required for dl_add_to_cart.
        },
      ]
    : [];

  return {
    event: 'dl_remove_from_cart',
    user_properties: {
      visitor_type: 'guest',
    },
    cart_total: cartData?.cost?.totalAmount?.amount?.toString() ?? '0.0',
    ecommerce: {
      currencyCode: 'EUR',
      remove: {
        actionField: {
          list: 'Shopping Cart',
        },
        products: products ?? [], // See the products array below
      },
    },
  };
}

export function addToCartElevar(
  cartData: CartInterface | undefined,
  item: CartLineItemInterface | undefined,
): ElevarDataLayerEvent {
  const products = item
    ? [
        {
          id: getShopifyProductId(item.productId) ?? '',
          name: item.title !== '' ? `${item.title}` : 'undefined',
          brand: item.vendor !== '' ? `${item.vendor}` : (item.productType ?? 'undefined'),
          category: `${item.productType ?? 'undefined'}`,
          variant: item.options.length
            ? item.options.map((option) => option.value).join(' - ')
            : (item.title ?? 'Default Title'),
          price: item.price?.amount.toString() ?? '0.0',
          quantity: item.quantity.toString() ?? '1',
          list: '',
          product_id: getShopifyProductId(item.productId) ?? '',
          variant_id: getShopifyProductId(item.variantId) ?? '',
          compare_at_price: item.compareAtPrice?.amount.toString() ?? '0.0',
          position: 0,
          image: item.featuredImage?.lg.url ?? item.featuredImage?.url ?? '', // If available, otherwise use an empty string
          url: (item.slug !== '' ? item.slug : 'undefined') || '', // URL for the Product Page. Only required for dl_add_to_cart.
        },
      ]
    : [];

  return {
    event: 'dl_add_to_cart',
    user_properties: {
      visitor_type: 'guest',
    },
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        actionField: {
          list: 'Shopping Cart',
          action: 'add',
        },
        products: products,
      },
    },
  };
}

export function cartElevar(cartData: CartInterface | undefined): ElevarDataLayerEvent {
  const products: ElevarProduct[] | undefined = cartData?.items.map((item, index) => {
    return {
      id: item.sku ?? getShopifyProductId(item.productId) ?? '',
      name: `${item.title}`,
      brand: item.vendor !== '' ? `${item.vendor}` : (item.productType ?? 'undefined'),
      category: `${item.productType ?? 'undefined'}`,
      variant: item.options.length
        ? item.options.map((option) => option.value).join(' - ')
        : (item.title ?? 'Default Title'),
      price: item.price?.amount.toString() ?? '0.0',
      quantity: item.quantity.toString() ?? '1',
      list: '',
      product_id: getShopifyProductId(item.productId) ?? '',
      variant_id: getShopifyProductId(item.variantId) ?? '',
      compare_at_price: item.compareAtPrice?.amount.toString() ?? '0.0',
      position: index + 1,
      image: item.featuredImage?.lg.url ?? item.featuredImage?.url ?? '',
      url: (item.slug !== '' ? item.slug : 'undefined') || '',
    };
  });

  return {
    event: 'dl_user_data',
    cart_total: cartData?.cost?.totalAmount?.amount?.toString() ?? '0.0',
    user_properties: {
      visitor_type: 'guest',
    },
    ecommerce: {
      currencyCode: 'EUR',
      cart_contents: {
        products: products ?? [],
      },
    },
  };
}
