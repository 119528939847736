export const PAGE_TYPES = {
  blog: '/blog/',
  collections: '/collections/',
  page: '/page/',
  products: '/products/',
  quiz: '/quiz/',
  root: '/',
  category: '/category/',
} as const;

type ObjectValues<T> = T[keyof T];
export type PageTypeType = ObjectValues<typeof PAGE_TYPES>;
