'use client';

import { GlobalInterface } from '@qlevr/shared/interfaces';
import { useCart } from '@qlevr/shared/providers';
import { Modal } from 'flowbite-react';
import { useTranslations } from 'next-intl';
import CartNavigationItem from '../cart-navigation-item/cart-navigation-item';
import CartModalBody from './cart-modal-body';
import CartModalFooter from './cart-modal-footer';
import { cartModalTheme } from './cart-modal-theme';

/* eslint-disable-next-line */
export interface CartModalProps {
  globals: GlobalInterface;
  locale?: string;
}

export function CartModal({ globals, locale }: CartModalProps) {
  const t = useTranslations();
  const { cart, open, isOpen, close } = useCart();

  return (
    <>
      <button aria-label={t('cart.openCart.ariaLabel')} onClick={() => open()}>
        <CartNavigationItem quantity={cart?.totalQuantity} />
      </button>
      <Modal
        dismissible
        size="md"
        theme={cartModalTheme}
        show={isOpen}
        position={'top-right'}
        onClose={() => close()}
        className="cart-modal-overlay bg-gray-800"
      >
        <Modal.Header>{t('cart.title')}</Modal.Header>
        <Modal.Body>
          <CartModalBody cart={cart} globals={globals} />
        </Modal.Body>
        <Modal.Footer>
          <CartModalFooter locale={locale} cart={cart} shippingRate={globals.shippingRate} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CartModal;
