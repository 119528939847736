import { BUTTON_SIZE, ICONS } from '@qlevr/shared/constants';
import IconRenderer from '../../icons/icon-renderer.component';
import LinkRenderer from '../../link/link-renderer';
import { useTranslations } from 'next-intl';
import MoreInfoContent from './more-info-content';
import { useState } from 'react';
import { ProductInterface } from '@qlevr/shared/interfaces';

interface MoreInfoButtonProps {
  product: ProductInterface;
}

export default function MoreInfoButton({ product }: MoreInfoButtonProps) {
  const t = useTranslations();

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className="flex items-center gap-1 cursor-pointer relative">
        <LinkRenderer
          cta={{
            label: t('pdp.moreInfo'),
            as: 'button',
            linkStyle: 'text-black',
            size: BUTTON_SIZE.small,
            onClick: () => {
              setOpenModal(true);
            },
            className: 'font-light underline lowercase ',
            wrapperClassName: 'after:content-[""] after:absolute after:inset-0',
          }}
        ></LinkRenderer>
        <IconRenderer icon={ICONS.alertCircle} className="w-4 h-4 text-green-500" />
      </div>
      <MoreInfoContent openModal={openModal} setOpenModal={setOpenModal} product={product} />
    </>
  );
}
