import { ImageInterface, PersonInterface } from '@qlevr/shared/interfaces';

export function personStoryblokMapper(
  name: string,
  role: string | null,
  image: ImageInterface | null
): PersonInterface | null {
  return {
    name,
    role,
    image,
  };
}
