import { ProductInterface, ProductVariantInterface, VariantWithQuantity } from '@qlevr/shared/interfaces';
import { FlowProducts } from '@qlevr/shared/providers';
import { getVariantForProduct } from '@qlevr/shared/utilities';

export const mergeVariantsWithQuantity = (variants: VariantWithQuantity[]) => {
  if (!variants) return [];
  const variantMap: { [key: string]: number } = {};
  variants.forEach((variant) => {
    if (variantMap[variant.variantId] && variant.quantity) {
      variantMap[variant.variantId] += variant.quantity;
    } else {
      variantMap[variant.variantId] = variant.quantity || 0;
    }
  });
  return Object.keys(variantMap).map((variantId) => ({
    variantId,
    quantity: variantMap[variantId],
  }));
};

export const mergeAllVariants = (products: ProductInterface[], flowProducts: FlowProducts[]) => {
  const allVariantsMap: { [key: string]: ProductVariantInterface } = {};
  flowProducts.forEach((flowProduct) => {
    const mainProduct = products.find((p) => p.id === flowProduct.mainProductId);
    flowProduct.bundledVariants.forEach((variantInfo) => {
      if (variantInfo.quantity === 0) return;
      const variant = mainProduct ? getVariantForProduct(mainProduct, [variantInfo.id]) : undefined;
      if (variant) {
        allVariantsMap[variant.id] = variant;
      }
    });
  });
  return Object.values(allVariantsMap);
};
