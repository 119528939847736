import { FORM_STATUS } from '@qlevr/shared/constants';
import { getLocaleFromCountryLocale } from '@qlevr/shared/utilities';

export async function newsletterService(formData: FormData) {
  const user = process.env['NEXT_PUBLIC_DOTDIGITAL_API_USER'];
  const password = process.env['NEXT_PUBLIC_DOTDIGITAL_API_USER_PASSWORD'];
  const list = process.env['NEXT_PUBLIC_DOTDIGITAL_LIST_ID'];
  const url = `https://r1-api.dotdigital.com/v2/address-books/${list}/contacts`;
  const brandKey = `BRAND_${process.env['NEXT_PUBLIC_BRAND_ABBR']}`;
  const dataFields = [{ key: brandKey, value: process.env['NEXT_PUBLIC_BRAND_NAME']?.toUpperCase() }];

  const locale = formData.get('locale');
  if (locale) {
    dataFields.push({ key: 'LANGUAGE', value: getLocaleFromCountryLocale(locale as string) });
    dataFields.push({ key: 'COUNTRY_LANG', value: locale as string });
  }

  const payload = {
    email: formData.get('email'),
    optInType: 'Single',
    dataFields,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Basic ${Buffer.from(`${user}:${password}`).toString('base64')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    const succes = data.status === 'Subscribed' && response.status === 200;

    if (!succes) {
      console.error('something went wrong');
      return FORM_STATUS.error;
    }

    return FORM_STATUS.success;
  } catch (error) {
    console.error('newsletterService error:', error);
    return FORM_STATUS.error;
  }
}
