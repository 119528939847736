import { ImageInterface, ProductInterface } from '@qlevr/shared/interfaces';
import { ProductBundlerContext } from '@qlevr/shared/providers';
import { calculateDiscountPrice, reverseEscapeUrl } from '@qlevr/shared/utilities';
import { useContext, useEffect, useState } from 'react';
import ImageRenderer from '../../../image-renderer/image-renderer';

/* eslint-disable-next-line */
export interface ProductBundlerCardProps {
  product: ProductInterface;
}

export function ProductBundlerMainProductCard(props: ProductBundlerCardProps) {
  const { variant, addVariantId, removeVariantId } = useContext(ProductBundlerContext);
  const [activeImage, setActiveImage] = useState<ImageInterface | null>(props.product.featuredImage);

  useEffect(() => {
    if (variant) {
      addVariantId(variant.id); // Add the selected variant ID
    } else {
      setActiveImage(props.product.featuredImage);
    }

    // Clean up previous variant ID
    return () => {
      if (variant) {
        removeVariantId(variant.id); // Remove the previous variant ID
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant]);

  return (
    <div className="flex flex-col items-end justify-between rounded-lg border-gray-300 p-2 bg-gray-100 space-y-2 relative">
      <div className="flex w-full">
        {activeImage && <ImageRenderer image={activeImage} className="w-full max-w-20 h-20 mr-4 rounded-lg" />}
        <div className="w-full">
          <div className="text-base font-bold">{props.product.title}</div>
          <div className="text-xs leading-5 font-normal flex flex-col mb-1">
            {variant?.options.map((option) => (
              <span key={option.name}>
                <span className="capitalize">{option.name}:</span> {reverseEscapeUrl(option.value)}
              </span>
            ))}
          </div>
          <div className="text-base font-serif flex gap-2">
            <span className={`${props.product.discountPercentage ? 'line-through text-slate-400' : ''}`}>
              {variant?.price?.amount && (
                <span>
                  {variant.price.symbol}
                  {variant.price.amount}
                </span>
              )}
            </span>
            {props.product.discountPercentage && variant?.price?.amount && (
              <span>
                {props.product?.priceRange?.minVariantPrice.symbol}
                {calculateDiscountPrice(variant?.price?.amount, props.product.discountPercentage ?? 0)}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductBundlerMainProductCard;
