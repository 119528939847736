import { ProductInterface } from '@qlevr/shared/interfaces';
import { FlowProducts } from '@qlevr/shared/providers';
import { getDiscountedPriceForItem, getVariantForProduct } from '@qlevr/shared/utilities';

export const calculateTotalPrice = (products: ProductInterface[], flowProducts: FlowProducts[]) => {
  let totalPrice = 0;
  let discountedPrice = 0;

  // Create a map to aggregate quantities of each variant ID
  const variantQuantityMap: { [variantId: string]: number } = flowProducts.reduce(
    (acc, flowProduct) => {
      flowProduct.bundledVariants.forEach(({ id, quantity }) => {
        if (quantity === 0) return;
        acc[id] = (acc[id] || 0) + quantity;
      });
      return acc;
    },
    {} as { [variantId: string]: number },
  );

  // Calculate total and discounted prices
  for (const [variantId, quantity] of Object.entries(variantQuantityMap)) {
    const mainProduct = products.find((product) => product.variants?.some((variant) => variant.id === variantId));
    if (!mainProduct) continue;

    const variant = getVariantForProduct(mainProduct, [variantId]);
    if (variant?.price) {
      const price = variant.price.amount * quantity;
      totalPrice += price;

      const discounted = getDiscountedPriceForItem(mainProduct, variant);
      discountedPrice += discounted !== null ? discounted * quantity : price;
    }
  }

  return {
    totalPrice: totalPrice === 0 ? '0' : totalPrice.toFixed(2),
    discountedPrice: discountedPrice === 0 ? '0' : discountedPrice.toFixed(2),
  };
};
