import { BUTTON_SIZE, ICONS } from '@qlevr/shared/constants';
import { KeyValueInterface } from '@qlevr/shared/interfaces';
import { useState } from 'react';
import { useTranslations } from 'use-intl';
import IconRenderer from '../icons/icon-renderer.component';
import LinkRenderer from '../link/link-renderer';
import SizeChartContent from './size-chart';

export interface SizeChartProps {
  option: string;
  ageGroups: KeyValueInterface[] | null;
}

export function SizeChartButton({ option, ageGroups }: SizeChartProps) {
  const t = useTranslations();
  const [isSizeChartOpen, setSizeChartOpen] = useState(false);
  const optionLowerCase = option.toLowerCase();

  if (optionLowerCase !== 'size' || !ageGroups || ageGroups.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="w-full flex items-center gap-1 cursor-pointer">
        <LinkRenderer
          cta={{
            label: t('sizeChart.button'),
            as: 'button',
            linkStyle: 'text-black',
            size: BUTTON_SIZE.small,
            onClick: () => setSizeChartOpen(true),
            className: 'font-light underline'
          }}
        ></LinkRenderer>
        <IconRenderer icon={ICONS.alertCircle} className="w-4 h-4 text-teal-500" />
      </div>
      <SizeChartContent isSizeChartOpen={isSizeChartOpen} setSizeChartOpen={setSizeChartOpen} ageGroups={ageGroups} />
    </div>
  );
}

export default SizeChartButton;
