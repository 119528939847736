// Define an object with language constants as properties
export const LANGUAGES = {
  nlBe: 'nl-be',
  frBe: 'fr-be',
  frFr: 'fr-fr',
  nlNl: 'nl-nl',
  deDe: 'de-de',
  esEs: 'es-es',
  itIt: 'it-it',
  en: 'en'
} as const;

// Utility type to extract the values from the LANGUAGES object
type ObjectValues<T> = T[keyof T];

// Define a type for the localization language using the values from the LANGUAGES object
export type LanguageType = ObjectValues<typeof LANGUAGES>;
