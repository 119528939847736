import { ProductVariantInterface } from '@qlevr/shared/interfaces';
import { ReadonlyURLSearchParams } from 'next/navigation';

export function getProductVariantByOptionName( variants: ProductVariantInterface[], searchParams: ReadonlyURLSearchParams, size?: string, color?: string): ProductVariantInterface | undefined {
  return variants.find((variant) => variant.options.every((option) => {
      if (option.name.toLowerCase() === 'size' && size) {
        return option.value === size;
      }
      else if (option.name.toLowerCase() === 'color' && color) {
        return option.value === color;
      }

      // Fallback to matching against other search parameters
      return option.value === searchParams.get(option.name.toLowerCase());
    }),
  );
}
