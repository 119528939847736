'use client';

import { ProductInterface, ProductVariantInterface } from '@qlevr/shared/interfaces';
import React, { createContext, useContext, useState } from 'react';

interface ProductBundlerProviderProps {
  children: React.ReactNode;
}

interface ProductBundlerContextType {
  changeVariant: (variant: ProductVariantInterface) => void;
  selectedProductVariantOptions: (product: ProductInterface, size?: string, color?: string) => void;
  variant: ProductVariantInterface | null;
  addVariantId: (id: string) => void;
  removeVariantId: (id: string) => void;
  variantIds: string[];
}

export const ProductBundlerContext = createContext<ProductBundlerContextType>({
  changeVariant: () => {},
  selectedProductVariantOptions: () => {},
  variant: null,
  addVariantId: () => {},
  removeVariantId: () => {},
  variantIds: [],
});

export const ProductBundlerProvider: React.FC<ProductBundlerProviderProps> = ({ children }) => {
  const [variant, setVariant] = useState<ProductVariantInterface | null>(null);
  const [variantIds, setVariantIds] = useState<string[]>([]);
  const [, setSize] = useState<string | null>(null);
  const [, setColor] = useState<string | null>(null);

  const changeVariant = (variant: ProductVariantInterface) => {
    setVariant(variant);
  };

  const selectedProductVariantOptions = (product: ProductInterface, size?: string, color?: string) => {
    if (color) {
      setColor(color);
    }
    if (size) {
      setSize(size);
    }

    const variant = product.variants?.find((variant) =>
      variant.options.every((option) => {
        if (option.name.toLowerCase() === 'size' && size) {
          return option.value === size;
        } 
        if (option.name.toLowerCase() === 'color' && color) {
          return option.value === color;
        }

        return false;
      }),
    );

    setVariant(variant || null);

  };

  const addVariantId = (id: string) => {
    setVariantIds((prevIds) => [...prevIds, id]);
  };

  const removeVariantId = (id: string) => {
    setVariantIds((prevIds) => prevIds.filter((prevId) => prevId !== id));
  };

  return (
    <ProductBundlerContext.Provider value={{ changeVariant, selectedProductVariantOptions, addVariantId, removeVariantId, variantIds, variant }}>
      {children}
    </ProductBundlerContext.Provider>
  );
};

export const useProductBundler = () => {
  const context = useContext(ProductBundlerContext);
  if (context === undefined) {
    throw new Error('useProductBundler must be used within a ProductBundlerProvider');
  }
  return context;
};