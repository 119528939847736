export interface RichTextNode {
  type: string;
  children?: RichTextNode[];
  value?: string;
  bold?: boolean;
  italic?: boolean;
  url?: string;
  listType?: 'unordered' | 'ordered';
  level?: number;
}

export interface RichTextRoot {
  type: 'root';
  children: RichTextNode[];
}

export function ShopifyRichTextToHtml(jsonString: string): string {
  let parsedData: RichTextRoot;

  try {
    parsedData = JSON.parse(jsonString);
  } catch (error) {
    // console.error('Error parsing JSON string:', error);
    return 'Invalid rich text data';
  }

  const convertNodeToHtml = (node: RichTextNode | RichTextRoot): string => {
    switch (node.type) {
      case 'root':
        return node.children?.map(convertNodeToHtml).join('') || '';
      case 'paragraph': {
        // Check if the paragraph is specifically a placeholder for a space or break
        if (node.children?.length === 1 && node.children[0].value === '--') {
          return '<br>'; // Replace the paragraph with a <br> tag
        }
        return `<p>${node.children?.map(convertNodeToHtml).join('')}</p>`;
      }
      case 'text': {
        let text = node.value || '';
        if (node.bold) {
          text = `<strong>${text}</strong>`;
        }
        if (node.italic) {
          text = `<em>${text}</em>`;
        }
        return text;
      }
      case 'link': {
        const url = node.url || '#';
        const linkText = node.children?.map(convertNodeToHtml).join('') || '';
        return `<a href="${url}">${linkText}</a>`;
      }
      case 'list': {
        const tag = node.listType === 'ordered' ? 'ol' : 'ul';
        const className = node.listType === 'ordered' ? 'list-decimal' : 'list-disc';
        const itemsHtml = node.children?.map((item) => convertNodeToHtml(item)).join('') || '';
        return `<${tag} class="${className} p-5">${itemsHtml}</${tag}>`;
      }
      case 'list-item': {
        return `<li>${node.children?.map(convertNodeToHtml).join('')}</li>`;
      }
      case 'heading': {
        const level = node.level || 1;
        return `<h${level}>${node.children?.map(convertNodeToHtml).join('')}</h${level}>`;
      }
      default:
        return '';
    }
  };

  return convertNodeToHtml(parsedData);
}
