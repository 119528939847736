import { RATIOS } from '@qlevr/shared/constants';
import { KeyValueInterface, ProductVariantInterface, ProductVariantOptionInterface } from '@qlevr/shared/interfaces';
import {
  ProductShopify,
  ProductVariantEdgeShopify,
  ProductVariantShopify,
  SelectedOptionShopify,
} from '@qlevr/shared/schema';
import {
  escapeUrl,
  getDefaultLocaleProductOptionName,
  getProductSlugWithVariantParams,
  reverseEscapeUrl,
} from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { imageShopifyMapper } from '../image/image-shopify';
import { getMetafieldByKey } from '../metafield/get-metafield-by-key';
import { productVariantPriceMapper } from './product-variant-price';
import { mapProductVariantTag } from './product-variant-tag';
import { mapVariantInfoImages } from './variant-info-images/product-variant-info-images';
import { mapQuizStackedImage } from './quiz-stacked-image-by-reference';

export function productVariantsMapper(
  items: ProductVariantEdgeShopify[] | undefined,
  slug: string,
  locale: string,
  productTitle?: string,
  productMetafields?: ProductShopify['metafields'],
  productId?: string,
  productTags?: string[],
  isCollection: boolean = false,
): ProductVariantInterface[] {
  if (!items || isEmpty(items)) {
    return [];
  }

  return items.reduce((acc, item) => {
    const variant = productVariantMapper(
      item.node,
      slug,
      locale,
      productTitle,
      productMetafields,
      productId,
      productTags,
      isCollection,
    );
    if (variant) {
      acc.push(variant);
    }

    return acc;
  }, [] as ProductVariantInterface[]);
}

export function productVariantMapper(
  item: ProductVariantShopify | undefined,
  slug: string,
  locale: string,
  productTitle?: string,
  productMetafields?: ProductShopify['metafields'],
  productId?: string,
  productTags?: string[],
  isCollection: boolean = false,
): ProductVariantInterface | null {
  if (!item) {
    return null;
  }

  const defaultSelected =
    item.metafields?.find((metafield) => metafield && metafield.key === 'default_selected')?.value === 'true';
  const preorderThreshold =
    item.metafields?.find((metafield) => metafield && metafield.key === 'preorder_threshold')?.value ?? null;
  const quizStackedImage = mapQuizStackedImage(item.metafields)?.[0]?.value ?? '';
  const bundlePromotion = Array.isArray(productMetafields)
    ? (productMetafields.find((metafield) => metafield && metafield.key === 'bundle_promotion')?.value ?? null)
    : null;
  const metafields: KeyValueInterface[] | null =
    (isCollection &&
      item.metafields
        ?.map((metafield) => {
          return {
            key: metafield?.key ?? '',
            value: metafield?.value ?? '',
          };
        })
        .filter((metafield) => metafield !== null)) ||
    [];
  return {
    availableForSale: item.availableForSale ?? false,
    barcode: item.barcode ?? null,
    compareAtPrice: productVariantPriceMapper(item.compareAtPrice) ?? null,
    currencyCode: item.price.currencyCode,
    currentlyNotInStock: item.currentlyNotInStock,
    defaultSelected,
    id: item.id,
    productId: productId ?? null,
    featuredImage: imageShopifyMapper(item.image?.url, {
      ratio: RATIOS.ratio_1x1,
      altText: item.image?.altText,
      columnConfig: { xs: 1, lg: 2 },
    }),
    images: mapVariantInfoImages(item.metafields, locale),
    inventoryQuantity: item.quantityAvailable ?? 0,
    options: productVariantOptionMapper(item.selectedOptions),
    price: productVariantPriceMapper(item.price),
    productTitle: productTitle,
    preOrderThreshold: preorderThreshold ? parseInt(preorderThreshold) : null,
    shippingDate: getMetafieldByKey('shipping_date', item.metafields?.[0]),
    sku: item.sku ?? null,
    title: item.title,
    slug: getProductSlugWithVariantParams(slug, productVariantOptionMapper(item.selectedOptions)),
    tag: mapProductVariantTag(item.metafields),
    productTags: productTags ?? [],
    bundlePromotion,
    vendor: item?.product?.vendor, // @todo should be fixed
    productType: item?.product?.productType ?? null,
    quizStackedImage: quizStackedImage ?? null,
    metafields,
  };
}

export function productVariantOptionMapper(item: SelectedOptionShopify[] | undefined): ProductVariantOptionInterface[] {
  if (!item) {
    return [];
  }

  return item.reduce((acc, option) => {
    if (option.value !== 'Default Title') {
      acc.push({
        name: getDefaultLocaleProductOptionName(escapeUrl(option.name.toLowerCase())),
        value: escapeUrl(option.value),
        label: reverseEscapeUrl(option.value),
      });
    }
    return acc;
  }, [] as ProductVariantOptionInterface[]);
}
