import { ColumnConfigInterface, ContentCardInterface } from '@qlevr/shared/interfaces';
import { ContentCardStoryblok } from '@qlevr/shared/schema';
import { imageStoryblokMapper } from '../image/image-storyblok';
import { mapRichText } from '../rich-text/rich-text';
import { linkMapper } from '../link/link';

export function contentCardStoryblokMapper(card: ContentCardStoryblok): ContentCardInterface | null {
  if (!card) {
    return null;
  }

  const columnConfig: ColumnConfigInterface = {
    xs: 1,
    lg: 4,
  };

  const columnConfigOverlayLogo: ColumnConfigInterface = {
    xs: 6,
    lg: 16,
  };

  const image = imageStoryblokMapper(card.image?.[0], columnConfig);
  const overlaylogo = imageStoryblokMapper(card.overlaylogo?.[0], columnConfigOverlayLogo);

  return {
    image,
    overlaylogo,
    title: card.title ?? '',
    text: mapRichText(card.text),
    cta: linkMapper(card.cta?.[0]),
  };
}
