import { getSdk } from "@qlevr/shared/admin-types";
import { GraphQLClient } from 'graphql-request';
import { ShopifyCartServiceOptions } from "./shopify-service";

const accessToken = process.env['NEXT_PUBLIC_SHOPIFY_ADMIN_ACCESS_TOKEN'] as string;
const endpoint = process.env['NEXT_PUBLIC_SHOPIFY_ADMIN_API_ENDPOINT'] as string;

export function ShopifyAdminService({ cache, tags, revalidate }: ShopifyCartServiceOptions) {
  const client = new GraphQLClient(endpoint, {
    fetch,
    headers: {
      'X-Shopify-Access-Token': accessToken,
    },
    cache,
    next: {
      ...(tags ? { tags } : {}),
      ...(revalidate ? { revalidate } : {}),
    }
  });

  return getSdk(client);
}
