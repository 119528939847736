import { ContentProductBundlerInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { ProductBundlerStoryblok, ShopifyProductStoryblok, ShopifyProductsStoryblok } from '@qlevr/shared/schema';
import { getMetaProductById, getMetaProductsById } from '../products/products-shopify';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';

export function mapProductBundlerContent(
  content: ProductBundlerStoryblok,
  meta: MetaInterface,
): ContentProductBundlerInterface | null {
  const product = content?.['product'].items[0] as ShopifyProductStoryblok;
  const products = content?.['products'] as ShopifyProductsStoryblok;
  const productIds = products.items.map((product) => product.id);

  if (!product) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]) ?? null,
    text: mapRichText(content.text) ?? null,
    product: getMetaProductById(product.id, meta),
    products: getMetaProductsById(productIds, meta),
    deliveryNotes: meta.globals?.deliveryNotes ?? null,
    section: sectionMapper(content.section?.[0]),
  };
}
