/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { ContentProductReviewsInterface } from '@qlevr/shared/interfaces';
import { getLocaleFromCountryLocale, getYotpoKeyByLocale } from '@qlevr/shared/utilities';
import { useParams } from 'next/navigation';
import { useEffect } from 'react';
import Container from '../container/container';

export function ContentProductReviewsReviews(props: ContentProductReviewsInterface) {
  const { locale } = useParams<{ locale: string }>();
  const localUppercase = getLocaleFromCountryLocale(locale.toUpperCase());

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://staticw2.yotpo.com/${getYotpoKeyByLocale(localUppercase)}/widget.js`;

    const reviewContainer = document.getElementById('review-container');
    if (reviewContainer) {
      reviewContainer.innerHTML = '';
      reviewContainer.appendChild(script);

      script.onload = () => {
        (window as any).yotpo.initWidgets();
      };

      return () => {
        reviewContainer.innerHTML = '';

        script.onload = () => {
          (window as any).yotpo.refreshWidgets();
        };
      };
    }
  }, [localUppercase]);

  return (
    <Container>
      <div id="review-container" />
      <div
        id="review"
        className="yotpo yotpo-main-widget"
        data-product-id={props.productId}
        data-name="{{ product.title | escape }}"
        data-url="{{ shop.url }}{{ product.url }}"
        data-image-url={`{{ product.featured_image | product_img_url: "large" |replace: '?', '%3F' | replace: '&','%26'}}`}
        data-description="{{ product.description | escape }}"
      />
    </Container>
  );
}

export default ContentProductReviewsReviews;
