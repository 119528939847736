import { MetaobjectFieldShopify } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';

export function getMetafieldReferencesImageField(key: string, fields: MetaobjectFieldShopify[]): string[] | null {
  if (!fields || isEmpty(fields)) {
    return null;
  }
  const field = fields.find((item) => item.key === key);
  const references_images: string[] = [];

  if (!field?.references?.edges) {
    return null;
  }

  field.references.edges.forEach(({ node }) => {
    if (!('image' in node) || node.image?.url === undefined) {
      return null;
    }
    return references_images.push(node.image.url);
  });

  return references_images;
}
