'use client';

import { CART_QUANTITY, CartQuantityType, ICONS } from '@qlevr/shared/constants';
import { useTranslations } from 'next-intl';
import { useFormStatus } from 'react-dom';
import IconRenderer from '../../../icons/icon-renderer.component';

/* eslint-disable-next-line */
export interface CartQuantitySubmitProps {
  type: CartQuantityType;
  quantity: number;
}

export function CartQuantitySubmit({ type, quantity }: CartQuantitySubmitProps) {
  const { pending } = useFormStatus();
  const t = useTranslations();

  const isIncrease = type === CART_QUANTITY.increase;
  const icon = isIncrease ? ICONS.plusCircle : ICONS.minusCircle;
  const ariaLabel = isIncrease ? t('cart.quantity.increase.ariaLabel') : t('cart.quantity.decrease.ariaLabel');
  const textColor = isDisabled() ? 'text-slate-400' : 'text-black';
  function isDisabled() {
    if(pending) {
      return true;
    }

    return !isIncrease && quantity === 1
  }

  return (
    <button
      type="submit"
      aria-disabled={pending}
      onClick={(e: React.FormEvent<HTMLButtonElement>) => {
        if (pending) e.preventDefault();
      }}
      aria-label={ariaLabel}
      disabled={pending}
      className={`${textColor} flex items-center justify-center`}
    >
      <IconRenderer icon={icon} className="w-5 h-5" />
    </button>
  );
}

export default CartQuantitySubmit;
