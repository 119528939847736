import { MIN_HEIGHT_RATIOS, MinHeightRatioType, RatioType } from '@qlevr/shared/constants';
import { isMinHeightRatioType } from '@qlevr/shared/utilities';

const ratioMapping = new Map<string, MinHeightRatioType>([
  ['ratio_4x5', MIN_HEIGHT_RATIOS.ratio_4x5],
  ['ratio_4x5', MIN_HEIGHT_RATIOS.ratio_4x5],
  ['ratio_5x4', MIN_HEIGHT_RATIOS.ratio_5x4],
  ['ratio_1x1', MIN_HEIGHT_RATIOS.ratio_1x1],
  ['ratio_9x16', MIN_HEIGHT_RATIOS.ratio_9x16],
  ['ratio_3x2', MIN_HEIGHT_RATIOS.ratio_3x2],
  ['ratio_16x9', MIN_HEIGHT_RATIOS.ratio_16x9],
  ['ratio_2x1', MIN_HEIGHT_RATIOS.ratio_2x1],
  ['ratio_3x1', MIN_HEIGHT_RATIOS.ratio_3x1],
  ['ratio_4x1', MIN_HEIGHT_RATIOS.ratio_4x1],
]);

export function minHeightRatioMapper(block: string | number | RatioType | undefined): MinHeightRatioType | null {
  if (!block) return null;

  if(isMinHeightRatioType(block)) {
    return block;
  }


  const ratio = ratioMapping.get(block.toString());
  if (!ratio) {
    throw new Error(`Min height ratio of type ${block} is missing a minHeightRatioMapper.`);
  }

  return ratio;
}

