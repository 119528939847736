import { LinkImageCardInterface } from '@qlevr/shared/interfaces';
import ImageRenderer from '../../image-renderer/image-renderer';
import LinkRenderer from '../../link/link-renderer';
import ThemeCard from '../../theme/theme';

/* eslint-disable-next-line */
export interface TitleImageCardProps extends LinkImageCardInterface {}

export function TitleImageCard(props: TitleImageCardProps) {
  return (
    <ThemeCard className="relative rounded-lg px-4 py-3 shadow" theme={props.theme}>
      <div className="flex h-full flex-col items-center">
        <div className="mb-4 min-w-24 lg:min-w-0">
          <ImageRenderer loadingState="lazy" image={props.image} className="lg:aspect-5/4" />
        </div>
        <div className="text-center capitalize">
          <LinkRenderer cta={{ ...props.cta, wrapperClassName: 'after:inset-0 after:absolute leading-none' }} />
        </div>
      </div>
    </ThemeCard>
  );
}

export default TitleImageCard;
