import { ContentFeaturedProductsInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { FeaturedProductsStoryblok, ShopifyProductsStoryblok } from '@qlevr/shared/schema';
import { determineGridLayout } from '@qlevr/shared/utilities';
import { contentAlignmentMapper } from '../content-alignment/content-alignment';
import { getMetaProductsById } from '../products/products-shopify';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';

export function mapFeaturedProductsContent(
  content: FeaturedProductsStoryblok,
  meta: MetaInterface,
): ContentFeaturedProductsInterface {
  const products = content?.['products'] as ShopifyProductsStoryblok;
  const productIds = products.items.map((product) => product.id);
  const gridLayout = determineGridLayout(productIds.length);

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    text: mapRichText(content.text) ?? null,
    alignment: contentAlignmentMapper(content.alignment?.[0]),
    products: getMetaProductsById(productIds, meta),
    ctaLabel: content.ctaLabel ?? null,
    section: sectionMapper(content.section?.[0]),
    gridLayout,
  };
}
