'use client';
import { ContentTypeFormInterface, TypeformResponseData } from '@qlevr/shared/interfaces';
import { getCookie, setCookie } from '@qlevr/shared/utilities';
import { useEffect, useRef } from 'react';
import Section from '../section/section';

/* eslint-disable-next-line */
export interface ContentTypeFormProps extends ContentTypeFormInterface {}

// Define the onTypeformSubmit function
function onTypeformSubmit(data: TypeformResponseData) {
  // Get the existing response IDs from the cookie
  const existingResponses = getCookie('typeFormIds');
  const responseIds = existingResponses ? JSON.parse(existingResponses) : [];

  // Append the new response ID
  responseIds.push(data.responseId);

  // Save the updated array back to the cookie
  setCookie('typeFormIds', JSON.stringify(responseIds), 30); // Expires in 30 days
}

export function ContentTypeForm(props: ContentTypeFormProps) {
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const currentDiv = divRef.current;
    const script = document.createElement('script');
    script.setAttribute('src', '//embed.typeform.com/next/embed.js');
    currentDiv?.appendChild(script);
    // Attach the function to the window object
    window.onTypeformSubmit = onTypeformSubmit;

    // Cleanup function to remove the attached function
    return () => {
      delete window.onTypeformSubmit;
      if (currentDiv) {
        currentDiv.innerHTML = '';
      }
    };
  }, []);

  if (!props.typeFormId) {
    return null;
  }

  return (
    <Section {...props.section}>
      <div data-tf-live={props.typeFormId} data-tf-on-submit="onTypeformSubmit"></div>
      <div ref={divRef}></div>
    </Section>
  );
}

export default ContentTypeForm;
