import { ProductSpecificationsInterface } from '@qlevr/shared/interfaces';
export interface AccordionModalProps {
  specifications: ProductSpecificationsInterface;
}

export function AccordionModal({ specifications }: AccordionModalProps) {
  return (
    <div className='space-y-4'>
      {specifications.images?.map((image, key) => <img src={image} alt="" key={key} />)}
      {specifications.text && (
        <div
          className="text-sm font-light"
          dangerouslySetInnerHTML={{ __html: specifications.text }}
        />
      )}
    </div>
  );
}

export default AccordionModal;
