export const FORM_STATUS = {
  success: {
    status: 'success',
    message: 'form.success',
  },
  error: {
    status: 'error',
    message: 'form.error',
  },
} as const;

type ObjectValues<T> = T[keyof T];
export type FormStatusType = ObjectValues<typeof FORM_STATUS>;
