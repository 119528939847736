import { TitleWithTextInterface } from '@qlevr/shared/interfaces';
import { TitleWithTextStoryblok } from '@qlevr/shared/schema';
import { mapRichText } from '../rich-text/rich-text';

export function titleWithTextMapper(content: TitleWithTextStoryblok): TitleWithTextInterface | null {
  if (!content) {
    return null;
  }

  return {
    title: content.title,
    text: mapRichText(content.text) ,
  };
}
