import { ProductInterface } from '@qlevr/shared/interfaces';

/**
 *
 * @description Returns true if the product has no variants or just one variant option
 */
export function hasNoVariantOptionsOrJustOne(product: ProductInterface): boolean {
  return (
    !product ||
    !product.options ||
    product.options.length === 0 ||
    (product.options?.length === 1 && product.options[0]?.values.length === 1)
  );
}
