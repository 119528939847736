import { getDiscountPercentage } from '@qlevr/shared/utilities';

interface ProductBuyDiscountPercentageProps {
  originalPrice?: number;
  discountedPrice?: number;
}

export default function ProductBuyDiscountPercentage({
  originalPrice,
  discountedPrice,
}: ProductBuyDiscountPercentageProps) {
  if (!originalPrice || !discountedPrice) {
    return null;
  }

  const discountPercentage = getDiscountPercentage(originalPrice, discountedPrice);
  if (!discountPercentage) {
    return null;
  }

  return <>- {discountPercentage}%</>;
}
