import { GlobalInterface, LinkedMetaDataInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';

export function getGlobalLinkedData(globals: GlobalInterface | null, linkedData?: LinkedMetaDataInterface) {
  if (!globals || !linkedData || isEmpty(globals)) {
    return;
  }

  globals.header.navigation.forEach((nav) => {
    if (nav.featuredProductId) {
      linkedData.products.add(nav.featuredProductId);
    }

    if (nav.productIds) {
      nav.productIds.forEach((productId) => {
        linkedData.products.add(productId);
      });
    }
  });

  if(globals.emptyCart?.emptyCartProductId) {
    linkedData.products.add(globals.emptyCart.emptyCartProductId);
  }
}
