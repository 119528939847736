import { ContentNewsletterInterface } from '@qlevr/shared/interfaces';
import { NewsletterStoryblok } from '@qlevr/shared/schema';
import { imageStoryblokMapper } from '../image/image-storyblok';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';

export function mapNewsletterContent(content: NewsletterStoryblok): ContentNewsletterInterface | null {
  const image = imageStoryblokMapper(content.image?.[0], {
    xs: 1,
    lg: 3,
  });

  if(!image) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    text: mapRichText(content.text) ?? null,
    subtext: mapRichText(content.subtext) ?? null,
    image,
    popup: content.popup ?? false,
    section: sectionMapper(content.section?.[0]),
  };
}
