'use client';

import { PathInterface } from '@qlevr/shared/interfaces';
import { getLocaleLabel } from '@qlevr/shared/utilities';
import { CustomFlowbiteTheme, Dropdown, DropdownItem, Flowbite } from 'flowbite-react';
import isEmpty from 'lodash.isempty';
import Link from 'next/link';
import { useParams } from 'next/navigation';

export interface InternationalisationDropdownProps {
  localisation: PathInterface[] | null;
}

export function InternationalisationDropdown({ localisation }: InternationalisationDropdownProps) {
  const { locale } = useParams<{ locale: string }>();

  if (!localisation || isEmpty(localisation)) {
    return null;
  }

  const dropdownTheme: CustomFlowbiteTheme = {
    dropdown: {
      inlineWrapper: 'flex items-center whitespace-nowrap lg:min-w-11 lg:min-h-11',
      floating: {
        base: 'z-10 w-fit rounded divide-y divide-gray-100 shadow focus:outline-none ml-0',
      },
    },
  };

  return (
    <Flowbite theme={{ theme: dropdownTheme }}>
      <li className="flex">
        <Dropdown label={getLocaleLabel(locale)} dismissOnClick={false} inline>
          {localisation.map((path, key) => (
            <DropdownItem key={key} as={Link} href={`/${path.locale}${path.slug}`}>
              {getLocaleLabel(path.locale)}
            </DropdownItem>
          ))}
        </Dropdown>
      </li>
    </Flowbite>
  );
}

export default InternationalisationDropdown;
