import { CollectionPathInterface } from '@qlevr/shared/interfaces';
import { GetCollectionPathsQueryShopify } from '@qlevr/shared/schema';
import { escapeUrl } from '@qlevr/shared/utilities';

export function collectionPathsMapper(
  collections: GetCollectionPathsQueryShopify['collections'],
  locale: string,
): CollectionPathInterface[] {
  return collections.edges.flatMap((collection) => {
    const uniqueFilters = new Set<string>();

    // Collect unique sizes directly into the set
    collection.node.products.edges.forEach((product) => {
      product.node.options.forEach((option) => {
        if (option.name.toLowerCase() === 'size') {
          option.values.forEach((value) => {
            uniqueFilters.add(escapeUrl(value));
          });
        }
      });
    });

    // Map each unique filter to the collection path structure
    return Array.from(uniqueFilters).map((filter) => ({
      slug: collection.node.handle,
      filter,
      locale,
    }));
  });
}
