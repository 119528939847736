import { BREAKPOINTS } from '@qlevr/shared/constants';
import { ImageInterface, PathInterface } from '@qlevr/shared/interfaces';
import { SeoShopify } from '@qlevr/shared/schema';
import { Metadata } from 'next/types';
import { mapAlternatesMetatag } from './alternate-metatags';

export function productMetatagsMapper(
  seo: SeoShopify,
  image?: ImageInterface,
  localisation?: PathInterface[] | null,
  canonicalUrl?: string,
): Metadata {
  return {
    title: seo?.title || null,
    description: seo?.description || null,
    openGraph: {
      ...(seo?.title ? { title: seo.title } : {}),
      ...(seo?.description ? { description: seo.description } : {}),
      ...(image?.url
        ? {
            images: [
              {
                url: image.url,
                width: BREAKPOINTS.lg,
                height: BREAKPOINTS.lg,
              },
            ],
          }
        : {}),
    },
    alternates: { ...mapAlternatesMetatag(localisation), canonical: canonicalUrl },
  };
}
