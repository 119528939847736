'use client';

import { ProductInterface } from '@qlevr/shared/interfaces';
import { getProductVariantByOptionName, getSizeColorVariantFromUrl } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { useParams, useSearchParams } from 'next/navigation';

/* eslint-disable-next-line */
export interface ProductPriceProps {
  product: ProductInterface;
}

export function ProductPrice({ product }: ProductPriceProps) {
  const searchParams = useSearchParams();
  const params = useParams<{ variant: string }>();
  const variantInfo = getSizeColorVariantFromUrl(params);
  const activeVariant = getProductVariantByOptionName(product.variants ?? [], searchParams, variantInfo?.size, variantInfo?.color);
  
  if (isEmpty(product.variants) || !activeVariant ) {
    return null;
  }

  if (activeVariant.compareAtPrice) {
    return (
      <span className="flex items-center gap-2">
        <span className="text-base font-normal line-through text-gray-400">
          {product.compareAtPriceRange?.minVariantPrice.symbol}
          {activeVariant.compareAtPrice.amount}
        </span>
        <span className="text-lg inline-block text-green-700">
          {product.priceRange?.minVariantPrice.symbol}
          {activeVariant.price?.amount}
        </span>
      </span>
    );
  }

  return (
    <span className="text-lg font-normal">
      {product.priceRange?.minVariantPrice.symbol}
      {activeVariant.price?.amount}
    </span>
  );
}

export default ProductPrice;
