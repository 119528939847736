import { TitleLinkGroupInterface } from '@qlevr/shared/interfaces';
import { TitleLinkGroupStoryblok } from '@qlevr/shared/schema';
import { linksMapper } from './link';

export function mapGroupLink(group: TitleLinkGroupStoryblok): TitleLinkGroupInterface {
  return {
    title: group.title ?? null,
    links: linksMapper(group.links),
  };
}
