import { ProductReviewInterface } from '@qlevr/shared/interfaces';
import { productReviewMapper } from '@qlevr/shared/mappers';
import { notFound } from 'next/navigation';

export async function getProductReviews(productId: string, locale: string): Promise<ProductReviewInterface> {

  // Temporarily hardcoding the storeId to NL as requested by the client
  // https://www.notion.so/deardigital/Yotpo-star-reviews-from-dutch-YP-store-a5f9b8b5aac44185a80647a9ad3648a4?pvs=4
  // const storeId = getYotpoKeyByLocale(localUppercase) as string;
  const storeId = process.env['NEXT_PUBLIC_YOTPO_REVIEWS_ACCESS_TOKEN_NL']!;

  const res = await fetch(getUrl(storeId, productId));
  const isError = res.status !== 200;
  if (isError) {
    console.error('Review not found', getUrl(storeId, productId));
    return notFound();
  }

  const reviews = await res.json();
  return productReviewMapper(reviews);
}

function getUrl(storeId: string, productId: string): string {
  return `https://api-cdn.yotpo.com/v1/widget/${storeId}/products/${productId}/reviews.json`;
}
