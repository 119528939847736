'use client';
import { BREAKPOINTS } from '@qlevr/shared/constants';
import { ContentFeaturedBlogsInterface } from '@qlevr/shared/interfaces';
import { Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import BlogCard from '../../blog/blog-card';
import Container from '../../container/container';
import Section from '../../section/section';
import Text from '../../text/text';
import Title from '../../title/title';

/* eslint-disable-next-line */
export interface FeaturedBlogsProps extends ContentFeaturedBlogsInterface {}

export function ContentFeaturedBlogs({ alignment, section, title, text, blogs }: FeaturedBlogsProps) {
  return (
    <Section {...section}>
      <Container>
        <div className="space-y-6 lg:space-y-10">
          {!!(title || text) && (
            <div className={`flex ${alignment.horizontal}`}>
              <div className={`lg:w-1/2 ${alignment.text}`}>
                {title && <Title {...title} section={section} />}
                {text && <Text text={text} theme={section.theme} />}
              </div>
            </div>
          )}
          <Swiper
            className={`swiper-with-nav swiper-with-scrollbar ${section.theme.carousel}`}
            spaceBetween={24}
            slidesPerView={3}
            scrollbar={{ draggable: true, dragClass: `swiper-scrollbar-drag` }}
            navigation={true}
            modules={[Scrollbar, Navigation]}
            breakpoints={{
              [BREAKPOINTS.lg]: {
                slidesPerView: 3,
              },
              [BREAKPOINTS.sm]: {
                slidesPerView: 2,
                spaceBetween: 16,
              },
              [BREAKPOINTS.xs]: {
                slidesPerView: 1.5,
              },
            }}
          >
            {blogs.map((blog, index) => (
              <SwiperSlide key={index}>
                <BlogCard {...blog} theme={section.theme} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Container>
    </Section>
  );
}

export default ContentFeaturedBlogs;
