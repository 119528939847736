'use client';
import { BUTTON_SIZE, LINK_STYLES } from '@qlevr/shared/constants';
import { GlobalInterface } from '@qlevr/shared/interfaces';
import { Modal } from 'flowbite-react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';
import { useState } from 'react';
import LinkRenderer from '../link/link-renderer';
import { validationSchema } from '../newsletter/validation';
import { formModalTheme } from './form-modal-theme';
import { useRegister } from '@qlevr/shared/providers';

interface Values {
  email: string;
  password: string;
  terms: boolean;
}

interface RegisterFormProps {
  globals: GlobalInterface | null;
  showRegister: boolean;
  setShowRegister: (showRegister: boolean) => void;
  setShowLogin: (showLogin: boolean) => void;
}

export default function RegisterForm({ globals, showRegister, setShowRegister, setShowLogin }: RegisterFormProps) {
  const t = useTranslations();
  const [message, setMessage] = useState('');
  const { registerFormSubmit } = useRegister();
  const { locale } = useParams<{ locale: string }>();

  const formConfig = {
    initialValues: {
      email: '',
      password: '',
      terms: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values: Values, { resetForm }: FormikHelpers<Values>) => {
  function onSuccess() {
    setMessage(t('customer.registerForm.success'));
    resetForm();
    setShowRegister(false);  // Only close the form on success
  }
  function onError(error: string) {
    setMessage(error);
  }

  try {
    registerFormSubmit(values.email, values.password, onSuccess, onError, locale);
  } catch (e: any) {
    console.error(e);
    onError(e.toString());  // Display any caught errors
  }
},

  };

  return (
    <Modal
      dismissible
      size="md"
      theme={formModalTheme}
      show={showRegister}
      position={'center'}
      onClose={() => setShowRegister(false)}
      className="bg-black/25 backdrop-blur-sm"
    >
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <Formik {...formConfig}>
          {({ errors, touched }) => (
            <Form className="pb-10 px-4 w-full max-w-96 bg-slate-100 relative z-10 rounded-lg">
              <div className="space-y-4">
                <h2 className="text-3xl text-[#13283F] px-2 font-sans text-center font-bold">
                  {t('customer.registerForm.title')}
                </h2>
                <div className="space-y-1">
                  <div className="w-full relative mb-4">
                    <div>
                      <label htmlFor="email" className="font-bold text-xs capitalize font-serif ml-4">
                        {t('form.input.email')}
                      </label>
                      <Field
                        id="email"
                        name="email"
                        placeholder={t('form.input.placeholder')}
                        className="font-light w-full pl-4 pr-10 py-3 bg-white border border-slate-300 rounded-full focus:outline-none bg-transparent focus:ring-0 focus:border-slate-300 font-serif placeholder:text-sm placeholder:text-slate-800"
                        type="email"
                      />
                      <div className="text-sm pl-4 mt-1">
                        {errors.email && touched.email && <p className="text-red-600">{t(errors.email)}</p>}
                      </div>
                    </div>
                    <div>
                      <label htmlFor="password" className="font-bold text-xs capitalize font-serif ml-4">
                        {t('form.input.password')}
                      </label>
                      <Field
                        id="password"
                        name="password"
                        placeholder={t('form.input.password')}
                        className="font-light w-full pl-4 pr-10 py-3 bg-white border border-slate-300 rounded-full focus:outline-none bg-transparent focus:ring-0 focus:border-slate-300 font-serif placeholder:text-sm placeholder:text-slate-800"
                        type="password"
                      />
                      <div className="text-sm pl-4 mt-1">
                        {errors.password && touched.password && <p className="text-red-600">{t(errors.password)}</p>}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center gap-2 pb-1">
                      <Field type="checkbox" name="terms" id="terms" className="rounded cursor-pointer" />
                      <label htmlFor="terms" className="text-sm font-normal font-serif cursor-pointer">
                        {globals?.termsAndConditions?.[0] &&
                          t.rich('customer.registerForm.termsAndConditions', {
                            a: (chunks) =>
                              globals?.termsAndConditions?.map((cta, key) => (
                                <LinkRenderer
                                  cta={{
                                    ...cta,
                                    label: `${chunks}`,
                                    className: `underline`,
                                    linkStyle: LINK_STYLES.textBlack,
                                    size: undefined,
                                  }}
                                  key={key}
                                />
                              )),
                          })}
                      </label>
                    </div>
                    <div className="text-sm pl-4 mt-1">
                      {errors.terms && touched.terms && <p className="text-red-600 w-full">{t(errors.terms)}</p>}
                    </div>
                  </div>
                  <div className="text-sm pl-4 mt-1">{message && <p className="text-red-600">{message}</p>}</div>
                </div>
                <div>
                  <LinkRenderer
                    cta={{
                      label: t('customer.registerForm.submitButtonLabel'),
                      as: 'button',
                      linkStyle: LINK_STYLES.primary,
                      size: BUTTON_SIZE.base,
                      wrapperClassName: `w-full font-bold`,
                      type: 'submit',
                    }}
                  />
                </div>
                <div className="text-center text-sm flex justify-center gap-1">
                  <span>{t('customer.registerForm.loginText')}</span>
                  <button
                    type="button"
                    className="text-sm text-azure-500 hover:underline"
                    onClick={() => {
                      setShowRegister(false);
                      setShowLogin(true);
                    }}
                  >
                    {t('customer.registerForm.loginLink')}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
