import { ProductInterface } from '@qlevr/shared/interfaces';
import { reverseEscapeUrl } from '@qlevr/shared/utilities';

export interface OptionButtonProps {
  product: ProductInterface | null;
  value: string;
  isActive: boolean;
  isAvailableForSale: boolean;
  setSize: (size: string) => void;
  setColor: (color: string) => void;
}

export function BundleProductsOptionButton({
  product,
  value,
  isActive,
  isAvailableForSale,
  setSize,
  setColor,
}: OptionButtonProps) {
  // Helpers to determine if the option is a color or size
  const productColor = product?.colors?.find((color) => color.key === value);
  const productSize = product?.sizes?.find((size) => size.key === value);
  const slashCss =
    'flex items-center justify-center px-2 py-1 relative before:absolute before:inset-x-0 before:h-1 before:z-10 before:-rotate-45 before:bg-slate-500 before:transition-transform opacity-80 pointer-events-none overflow-hidden';
  let className =
    'group relative flex items-end justify-center min-w-10 min-h-10 lg:min-w-8 lg:min-h-8 transition duration-300 ease-in-out';
  let imageClassName = '';
  const getOption = () => productColor || productSize;

  // isActive class application logic
  if (isActive) {
    className += ' is-active';
  }

  // slashCss class for out-of-stock products, applied only when it's a color option
  if (!isAvailableForSale) {
    if (getOption()?.value) {
      className += ` ${slashCss}`;
    }
    imageClassName = 'opacity-50';
  }

  const handleClick = () => {
    if (productSize) {
      setSize(productSize.key);
    }
    if (productColor) {
      setColor(productColor.key);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`${className}`}
      aria-disabled={!isAvailableForSale}
      disabled={!isAvailableForSale}
      title={`${value}${!isAvailableForSale ? ' (Out of Stock)' : ''}`}
    >
      {getOption()?.value ? (
        <img
          className={`absolute top-0 mx-auto h-10 w-10 rounded-full lg:h-8 lg:w-8 ${imageClassName} ${isActive ? 'group-[.is-active]:border-2 group-[.is-active]:border-black group-[.is-active]:shadow-md' : ''}`}
          src={getOption()?.value ?? ''}
          alt={getOption()?.key}
        />
      ) : (
        <div
          className={`rounded-full border-2 border-slate-200 px-5 py-2 text-slate-600 ${imageClassName} ${isActive ? 'group-[.is-active]:border-blue group-[.is-active]:border-azure-500 group-[.is-active]:bg-azure-100 group-[.is-active]:text-night-800 group-[.is-active]:shadow-md' : ''}`}
        >
          {reverseEscapeUrl(getOption()?.key ?? null)}
        </div>
      )}
    </button>
  );
}

export default BundleProductsOptionButton;
