// Write a function to convert a when the locale-country is the same, to the locale only.
// examples nl-nl => nl, fr-fr => fr, de-de => de
// examples nl-be => NL/BE, fr-be => FR/BE
export function getLocaleLabel(locale: string): string {
  // Split the locale string into two parts: [locale, country]
  const parts = locale.split('-');

  if(parts.length === 1) {
    return parts[0].toUpperCase();
  }

  // Check if the locale and country are the same
  if (parts[0].toLowerCase() === parts[1].toLowerCase()) {
    return parts[0].toUpperCase(); // Return the locale only
  } else {
    // If different, return them in uppercase separated by a slash
    return parts[0].toUpperCase() + '/' + parts[1].toUpperCase();
  }
}
