import { ProductCombinationInterface } from '@qlevr/shared/interfaces';
import { useTranslations } from 'next-intl';

/* eslint-disable-next-line */
export interface ProductOutOfStockProps {
  combination: ProductCombinationInterface | null;
}

export function ProductOutOfStock({ combination }: ProductOutOfStockProps) {
  const t = useTranslations();

  return (
    <div className="btn-primary flex items-center justify-center rounded-full px-12 py-4 font-sans text-lg gap-3 w-full opacity-80 pointer-events-none">
      <div className="font-sans text-base">{t('pdp.outOfStock')}</div>
    </div>
  );
}

export default ProductOutOfStock;
