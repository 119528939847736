'use client';

import { CartQuantityType } from '@qlevr/shared/constants';
import {
  CartFreeShippingInterface,
  CartInterface,
  PayloadType,
  ProductVariantInterface,
  VariantWithQuantity,
  isCartInterface,
} from '@qlevr/shared/interfaces';
import { addToCartElevar, cartElevar, removeCartElevar, viewCartElevar } from '@qlevr/shared/mappers';
import { AttributeInputShopify } from '@qlevr/shared/schema';
import { ShopifyService, createCart, fetchFreeShipping, getCart, getCustomerMetafields } from '@qlevr/shared/services';
import { analyticsTrackEvent } from '@qlevr/shared/utilities';
import { useParams, usePathname } from 'next/navigation';
import React, { Suspense, createContext, useContext, useEffect, useOptimistic, useState } from 'react';
import { setAuthCookie } from './account/cookies/cookies';
import { addItem } from './actions/add-item';
import { addItems } from './actions/add-items';
import { addItemsBulk } from './actions/add-items-bulk';
import { removeItem } from './actions/remove-item';
import { setCartId } from './actions/set-cart-id';
import { setMetafieldCartId } from './actions/set-metafield-cart-id';
import { updateCartAttributes } from './actions/update-cart-attributes';
import { updateItem } from './actions/update-item';
import { getCartCookie, getLoggedInCookie, setCartCookie } from './cart-cookie';
import { createNewOptimisticCart } from './create-new-optimistic-cart';
import { removeOptimisticCartItem } from './remove-optimistic-cart-item';
import { updateOptimisticCart } from './update-optimistic-cart';

interface CartProviderProps {
  children: React.ReactNode;
  cart?: CartInterface | undefined;
}

interface CartContextType {
  isOpen: boolean;
  toggle: () => void;
  open: () => void;
  close: () => void;
  cart: CartInterface | undefined;
  add: (variant: ProductVariantInterface, productId: string, attributes: AttributeInputShopify[]) => void;
  bulkAdd: (variants: ProductVariantInterface[], variantWithQuantity: VariantWithQuantity[]) => void;
  update: (payload: PayloadType, type: CartQuantityType) => void;
  updateAttributes: (attributes: Array<AttributeInputShopify> | AttributeInputShopify) => Promise<unknown>;
  remove: (lineItemId: string) => void;
  login: (email: string, password: string) => Promise<boolean>;
  register: (email: string, password: string) => void;
  isLoggedIn?: boolean;
  cartIdCookie?: string | undefined | null;
}

export const CartContext = createContext<CartContextType>({
  isOpen: false,
  toggle: () => {},
  open: () => {},
  close: () => {},
  cart: undefined,
  add: (variant: ProductVariantInterface, productId: string, attributes: AttributeInputShopify[]) => {},
  bulkAdd: (variants: ProductVariantInterface[], variantWithQuantity: VariantWithQuantity[]) => {},
  update: (payload: PayloadType, type: CartQuantityType) => {},
  updateAttributes: (attributes: Array<AttributeInputShopify> | AttributeInputShopify) => Promise.resolve(),
  remove: (lineItemId: string) => {},
  login: async () => false,
  register: () => {},
  isLoggedIn: false,
  cartIdCookie: '',
});

const CartProviderComponent: React.FC<CartProviderProps> = ({ children, cart }) => {
  const { locale } = useParams<{ locale: string }>();
  // const params = useSearchParams();
  const [cartData, setCartData] = useState<CartInterface | undefined>(cart);
  const [freeShippingTreshold, setFreeShippingTreshold] = useState<CartFreeShippingInterface>();
  // const [, setCartIdState] = useQueryState('cartId');
  const [optimisticCart, addOptimisticCart] = useOptimistic(cartData, (state, cart: CartInterface | undefined) => {
    return cart;
  });

  const [isOpen, setIsOpen] = useState(false);
  const pathname = usePathname();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [referralCartIdString, setReferralCartIdString] = useState<string | null>(null);
  const [referralFetched, setReferralFetched] = useState(false); // State to track if referral has been fetched
  const [metafieldCartFetched, setMetafieldCartFetched] = useState(false); // State to track if metafield cart has been fetched
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cartIdCookie, setCartIdCookie] = useState<string | undefined | null>();

  const toggle = () => setIsOpen((prevState) => !prevState);
  const open = () => {
    setIsOpen(true);
    if (cartData) {
      analyticsTrackEvent(viewCartElevar(cartData));
    }
  };
  const close = () => setIsOpen(false);

  const add = async (variant: ProductVariantInterface, variantId: string, attributes: AttributeInputShopify[]) => {
    const newCartData = createNewOptimisticCart(
      cartData,
      variant,
      variantId,
      freeShippingTreshold as CartFreeShippingInterface,
    );
    addOptimisticCart(newCartData); // Update the UI optimistically

    const addElevarItem = newCartData?.items.find((item) => {
      return item.variantId === variantId;
    });

    analyticsTrackEvent(addToCartElevar(newCartData, addElevarItem));

    try {
      const result = await addItem(locale, variantId, attributes ?? []);

      if (typeof result === 'string') {
        // Handle error case
        console.error('Error adding item to cart:', result);
        return;
      }

      const { res, cartId } = result;
      setCartIdCookie(cartId);

      if (isCartInterface(res)) {
        setCartData({
          ...res,
          freeShipping: freeShippingTreshold as CartFreeShippingInterface | null,
          pending: false,
        });
      }
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  const createMetafieldCartId = async (locale: string) => {
    cart = await createCart(locale);
    const cartId = cart.id;
    setCartId(cartId);

    return cartId;
  };

  const register = async (email: string, password: string) => {
    const accessTokenResponse = await ShopifyService({}).getCustomerAccessToken({ email, password });
    const accessToken = accessTokenResponse.customerAccessTokenCreate?.customerAccessToken?.accessToken;

    setAccessToken(accessToken);

    const cartIdCookie = await getCartCookie();

    if (!cartIdCookie) {
      // if there is no cart id in the cookie, create a new cart
      const newCartId = await createMetafieldCartId(locale);

      await setCartCookie(newCartId);
      await setMetafieldCartId(newCartId, accessToken);

      setIsLoggedIn(true);
      return;
    }

    setIsLoggedIn(true);

    // Set the new cart id in the metafields
    await setMetafieldCartId(cartIdCookie, accessToken);
  };

  const update = async (payload: PayloadType, type: CartQuantityType) => {
    if (payload.quantity === 0) {
      remove(payload.lineItemId);
      return;
    }

    const newCartData = updateOptimisticCart(cartData, payload, type, freeShippingTreshold);
    addOptimisticCart(newCartData);

    const addElevarItem = newCartData?.items.find((item) => {
      return item.variantId === payload.variantId;
    });

    analyticsTrackEvent(addToCartElevar(newCartData, addElevarItem));

    try {
      const res = await updateItem(locale, payload);
      if (isCartInterface(res)) {
        setCartData({
          ...res,
          freeShipping: freeShippingTreshold as CartFreeShippingInterface | null,
        });
      }
    } catch (error) {
      console.error('Error updating item in cart:', error);
    }
  };

  const remove = async (lineItemId: string) => {
    const newCartData = removeOptimisticCartItem(
      cartData,
      lineItemId,
      freeShippingTreshold as CartFreeShippingInterface,
    );
    addOptimisticCart(newCartData);

    const removedItem = cartData?.items.find((item) => item.id === lineItemId);
    analyticsTrackEvent(removeCartElevar(newCartData, removedItem));

    try {
      const res = await removeItem(locale, lineItemId);
      if (isCartInterface(res)) {
        setCartData({
          ...res,
          freeShipping: freeShippingTreshold as CartFreeShippingInterface | null,
        });
      }
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  const updateAttributes = async (attributes: Array<AttributeInputShopify> | AttributeInputShopify) => {
    const payload = { attributes };
    try {
      const res = await updateCartAttributes(locale, payload);

      return res;
    } catch (error) {
      return 'Error updating cart attributes';
    }
  };

  const login = async (email: string, password: string): Promise<boolean> => {
    const success: boolean = false;

    try {
      // Fetch the customer metafields
      const response = await getCustomerMetafields(email, password);
      const metafieldCartId = response?.customer?.metafield?.value;

      if (!response) {
        return success;
      }

      // Fetch the customer access token
      const accessTokenResponse = await ShopifyService({}).getCustomerAccessToken({ email, password });

      // Set the access token state
      setAccessToken(accessTokenResponse.customerAccessTokenCreate?.customerAccessToken?.accessToken);

      setIsLoggedIn(true);

      if (!accessTokenResponse) {
        return success;
      }

      const cartId = await getCartCookie();
      await setAuthCookie(true);

      if (!metafieldCartId && !cartId) {
        // if there is no metafield cart id or already present in the cookie, create one
        const newCartId = await createMetafieldCartId(locale);

        // Set the new cart id in the metafields
        await setMetafieldCartId(newCartId, accessToken);
      }

      if (!metafieldCartId || !cartData || metafieldCartFetched) {
        return success;
      }

      const currentCartId = await getCartCookie();

      if (cartId) {
        await setMetafieldCartId(
          cartId,
          accessTokenResponse.customerAccessTokenCreate?.customerAccessToken?.accessToken,
        );
      }

      if (currentCartId === metafieldCartId) {
        return success;
      }

      const cartItems = cartData.items.map((item) => ({
        id: item.variantId,
        quantity: item.quantity,
      }));

      try {
        await addItemsBulk(locale, metafieldCartId, cartItems);
        await setCartCookie(metafieldCartId);

        const res = await getCart(locale, metafieldCartId);

        setCartData({
          ...res,
          freeShipping: freeShippingTreshold || null,
        });

        // setCartIdState(null);
        setMetafieldCartFetched(true);
      } catch (error) {
        console.error('Error adding items to cart:', error);
      }
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }

    return success;
  };

  const bulkAdd = async (variants: ProductVariantInterface[], variantWithQuantity: VariantWithQuantity[]) => {
    let newCartData = cartData;

    for (let i = 0; i < variantWithQuantity.length; i++) {
      const variant = variants[i];
      const { variantId, quantity = 1 } = variantWithQuantity[i];

      // Create an optimistic cart update for each variant
      newCartData = createNewOptimisticCart(
        newCartData,
        variant,
        variantId,
        freeShippingTreshold as CartFreeShippingInterface,
        quantity,
      );
    }

    // Update the UI optimistically with all variants added
    addOptimisticCart(newCartData);

    try {
      const lineItems = variantWithQuantity.map(({ variantId, quantity = 1 }) => ({
        merchandiseId: variantId,
        quantity,
      }));
      const responses = await addItems(locale, lineItems);

      if (isCartInterface(responses)) {
        setCartData({
          ...responses,
          freeShipping: freeShippingTreshold as CartFreeShippingInterface | null,
          pending: false,
        });
      }
    } catch (error) {
      console.error('Error adding items to cart:', error);
    }
  };

  // Fetch the cart data
  useEffect(() => {
    async function fetchData() {
      const freeShippingTreshold = await fetchFreeShipping();
      setFreeShippingTreshold(freeShippingTreshold);

      const cartId = await getCartCookie();

      if (cartId) {
        setCartIdCookie(cartId);
      }
      const res = await getCart(locale, cartId);
      setCartData({ ...res, freeShipping: freeShippingTreshold });

      // if cookie isLoggedIn is true, and there is no cartId, create a new cart and push it to the metafield of the user
      const isLoggedInCookie = await getLoggedInCookie();

      if (isLoggedInCookie) setIsLoggedIn(true);

      if (isLoggedInCookie && !cartId) {
        const newCartId = await createMetafieldCartId(locale);

        if (newCartId) {
          setCartIdCookie(newCartId);
        }
        await setMetafieldCartId(newCartId, accessToken);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    const fetchReferralCartData = async () => {
      // const referralCartId = params.get('cartId');
      const referralCartId = null;
      if (referralCartId) {
        setCartIdCookie(referralCartId);
      }

      if (referralCartId !== null) setReferralCartIdString(referralCartId);

      if (!referralCartIdString || referralFetched || !cartData) {
        // setCartIdState(null);
        return;
      }

      const currentCartId = await getCartCookie();
      if (currentCartId === referralCartIdString) {
        // setCartIdState(null);
        return;
      }

      const cartItems = cartData.items.map((item) => ({
        id: item.variantId,
        quantity: item.quantity,
      }));

      try {
        await addItemsBulk(locale, referralCartIdString, cartItems);
        await setCartCookie(referralCartIdString);

        const res = await getCart(locale, referralCartIdString);
        setCartData({
          ...res,
          freeShipping: freeShippingTreshold || null,
        });

        // setCartIdState(null);
        setReferralFetched(true);
      } catch (error) {
        console.error('Error adding items to cart:', error);
      }
    };

    fetchReferralCartData();
  }, [cartData, locale, referralFetched, freeShippingTreshold, referralCartIdString]);

  // Effect to close cart on pathname change
  useEffect(() => {
    analyticsTrackEvent(cartElevar(cartData));

    close(); // Close the cart whenever the pathname changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]); // Depend on pathname to trigger the effect

  return (
    <CartContext.Provider
      value={{
        isOpen,
        toggle,
        login,
        register,
        open,
        close,
        cart: optimisticCart,
        add,
        bulkAdd,
        update,
        remove,
        isLoggedIn,
        cartIdCookie,
        updateAttributes,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const CartProvider: React.FC<CartProviderProps> = ({ children, cart }) => {
  return (
    <Suspense>
      <CartProviderComponent cart={cart}>{children}</CartProviderComponent>
    </Suspense>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
