import { PremiumProductInfoInterface } from '@qlevr/shared/interfaces';
import { getPremiumProductInfoReferencesFields } from '../metafield/get-premium-product-info-references-field';
import { Maybe, MetafieldShopify } from '@qlevr/shared/schema';

export function mapPremiumProductInfo(
  metafields: Maybe<MetafieldShopify>[],
): PremiumProductInfoInterface[] | null {
  if (!metafields) {
    return null;
  }

  return getPremiumProductInfoReferencesFields('premium_product_information', metafields);
}
