import { GlobalInterface, ProductInterface } from '@qlevr/shared/interfaces';
import { mapTextImageCardFromProduct } from '../card/text-image-card-mapper';

/**
 * @description Check for each navigation item if the featured product has variants. When there is a featured product AND there are no products, the featured product variants will be added with data derived from Shopify.
 * @param globals
 * @param products
 */
export function mapEmptyCartProduct(globals: GlobalInterface | null, products: ProductInterface[] | null) {
  if (!globals?.emptyCart?.emptyCartProductId || !products) {
    return;
  }

  const emptyCartProduct = products.find((product) => product.id === globals?.emptyCart?.emptyCartProductId);
  if (!emptyCartProduct) {
    return;
  }

  globals.emptyCart.emptyCartProduct = mapTextImageCardFromProduct(emptyCartProduct, emptyCartProduct.title, globals.emptyCart.theme);
}
