import { FilterValueShopify } from '@qlevr/shared/schema';

interface DefaultFilterProps {
  checked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled: boolean;
  option: FilterValueShopify;
}

export default function DefaultFilter({ checked, handleChange, isDisabled, option }: DefaultFilterProps) {
  return (
    <div className="flex items-center gap-2">
      <input
        id={option.id}
        type="checkbox"
        className="peer h-5 w-5 cursor-pointer rounded-sm border border-slate-300 text-xl text-black checked:border-black focus:shadow-none disabled:cursor-not-allowed disabled:bg-gray-200"
        checked={checked}
        onChange={handleChange}
        value={option.input}
        disabled={isDisabled}
      />
      <label
        htmlFor={option.id}
        className="cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:text-gray-400"
      >
        {option.label}
      </label>
    </div>
  );
}
