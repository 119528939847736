'use client';

import { useRouter } from 'next/navigation';

export function DraftToggleSwitch(props: { isEnabled: boolean; disable: () => void }) {
  const router = useRouter();

  const disableDraftMode = () => {
    props.disable();
    router.refresh();
  };

  return (
    <div className="sticky bottom-8 cursor-pointer">
      {props.isEnabled && (
        <button className='bg-orange-400 p-2 ml-8 rounded-md text-white'
          onClick={() => {
            disableDraftMode();
          }}
        >
          Disable draft
        </button>
      )}
    </div>
  );
}

export default DraftToggleSwitch;
