import { ProductInterface } from '@qlevr/shared/interfaces';

export function mapProductSizeOptions(products: ProductInterface[]): string[] {
  const sizeOptions = new Set<string>();

  for (const product of products) {
    for (const option of product.options || []) {
      if (option.name.toLowerCase() === 'size') {
        for (const value of option.values || []) {
          sizeOptions.add(value);
        }
      }
    }
  }

  return Array.from(sizeOptions);
}
