import { COLUMNS, ColumnsType } from '@qlevr/shared/constants';

const columnMapping = new Map<string, ColumnsType>([
  ['grid-cols-1', COLUMNS['1/1']],
  ['grid-cols-2', COLUMNS['1/2']],
  ['grid-cols-3', COLUMNS['1/3']],
  ['grid-cols-4', COLUMNS['1/4']],
]);

export function columnMapper(block: string): ColumnsType {
  if (!block) return COLUMNS['1/1'];

  const column = columnMapping.get(block);
  if (!column) {
    return COLUMNS['1/1'];
  }

  return column;
}
