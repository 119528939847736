import { COUNTRIES_INTERNATIONAL_MARKET } from '@qlevr/shared/constants';

/**
 * Retrieves the country code from a given country locale.
 *
 * @param locale The country locale string.
 * @returns The country code extracted from the locale.
 * @example nl-BE => BE
 * @example nl-NL => NL
 */
export function getCountryFromCountryLocale(locale?: string): string {
  // Default to international market if no locale is provided or if the local
  // Also for when no country is provided eg. /en (instead of /en-US)
  // Then also go to the international market
  const countryDefault = COUNTRIES_INTERNATIONAL_MARKET.toUpperCase()

  if (!locale) {
    return countryDefault;
  };

  const splitCountryLocale = locale.split('-');
  if (splitCountryLocale.length < 2) return countryDefault;

  return splitCountryLocale[1];
}
