import { LinkedMetaDataInterface, MappingConnectedDataFunctionInterface } from '@qlevr/shared/interfaces';
import {
  HeroImageTextOverlayStoryblok,
  HeroTextImageCardStoryblok,
  HeroTextImageStoryblok,
  HeroTitleStoryblok,
  HeroVideoStoryblok,
  PageStoryblok,
} from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';

const componentMapping = new Map<string, MappingConnectedDataFunctionInterface<unknown, void>>([
  ['heroVideo', (block, meta) => mapComponentReviews(block as HeroVideoStoryblok, meta)],
  ['heroTitle', (block, meta) => mapComponentReviews(block as HeroTitleStoryblok, meta)],
  ['heroTextImage', (block, meta) => mapComponentReviews(block as HeroTextImageStoryblok, meta)],
  ['heroTextImageCard', (block, meta) => mapComponentReviews(block as HeroTextImageCardStoryblok, meta)],
  ['heroImageTextOverlay', (block, meta) => mapComponentReviews(block as HeroImageTextOverlayStoryblok, meta)],
]);

export function getHeroBlocksLinkedData(
  heroBlocks: PageStoryblok['hero'],
  meta: LinkedMetaDataInterface,
): LinkedMetaDataInterface {
  if (!heroBlocks || isEmpty(heroBlocks)) {
    return meta;
  }

  for (const block of heroBlocks) {
    if (!block.component) {
      throw new Error('Hero block cannot be mapped because component is undefined.');
    }

    const component = componentMapping.get(block.component);
    if (!component) {
      continue;
    }

    component(block, meta);
  }

  return meta;
}

function mapComponentReviews(
  block:
    | HeroVideoStoryblok
    | HeroTitleStoryblok
    | HeroTextImageStoryblok
    | HeroTextImageCardStoryblok
    | HeroImageTextOverlayStoryblok,
  meta: LinkedMetaDataInterface,
) {
  if (!block['product']) {
    return;
  }

  block['product'].items.forEach((item: { id: string }) => {
    meta.products.add(item.id);
    meta.productReviewIds.add(item.id);
  });
}
