import { PriceVariantInterface } from '@qlevr/shared/interfaces';
import { CurrencyCodeShopify, Maybe, MoneyV2Shopify } from '@qlevr/shared/schema';
import { getSymbolFromCurrencyCode } from '@qlevr/shared/utilities';

export function productVariantPriceMapper(priceRange: Maybe<MoneyV2Shopify>): PriceVariantInterface | null {
  if (!priceRange) {
    return null;
  }

  return {
    amount: parseFloat(priceRange.amount),
    currencyCode: priceRange.currencyCode,
    symbol: getSymbolFromCurrencyCode(priceRange.currencyCode as CurrencyCodeShopify),
  };
}
