import { RATIOS } from '@qlevr/shared/constants';
import { VariantImagesInterface } from '@qlevr/shared/interfaces';
import { Maybe, MetafieldShopify, MetaobjectShopify } from '@qlevr/shared/schema';
import { getLocaleFromCountryLocale } from '@qlevr/shared/utilities';
import { imageShopifyMapper } from '../../image/image-shopify';
import { getMetafieldReferenceImageField } from '../../metafield/get-metafield-reference-image-field';
import { getMetafieldsByKey } from '../../metafield/get-metafields-by-key';
import { getInfoImagesContentReference } from '../../metafield/variant-info-images/get-info-images-content-reference';

export function mapVariantInfoImages(metafields: Maybe<MetafieldShopify>[], locale: string): VariantImagesInterface[] {
  if (!metafields) {
    return [];
  }

  const key = 'info_images';
  const items: VariantImagesInterface[] = [];
  const reference = metafields.find((item) => item?.key === key);

  if (!reference || !reference?.references?.edges) {
    return items;
  }

  reference.references.edges.forEach(({ node }) => {
    const key = getMetafieldsByKey('title', (node as MetaobjectShopify).fields);
    const imageKey = getImageKeyByLocale(getLocaleFromCountryLocale(locale));
    let value = getMetafieldReferenceImageField(imageKey, (node as MetaobjectShopify).fields);

    // If locale-specific image is null, fallback to default 'image'
    if (!value) {
      value = getMetafieldReferenceImageField('image', (node as MetaobjectShopify).fields);
    }

    const content = getInfoImagesContentReference('content', (node as MetaobjectShopify).fields);

    if (!key) {
      return null;
    }

    if (!value) {
      return null;
    }

    const image = imageShopifyMapper(value, {
      ratio: RATIOS.DEFAULT,
      columnConfig: { xs: 1, lg: 3 },
    });

    const thumbnail = imageShopifyMapper(value, {
      ratio: RATIOS.DEFAULT,
      columnConfig: { xs: 18, lg: 18 },
      container: false,
    });

    return items.push({ key, image, thumbnail, content, value });
  });

  return items;
}

function getImageKeyByLocale(locale: string): string {
  switch (locale) {
    case 'de':
      return 'de_image';
    case 'nl':
      return 'nl_image';
    case 'fr':
      return 'fr_image';
    case 'es':
      return 'es_image';
    case 'it':
      return 'it_image';
    default:
      return 'image';
  }
}
