import { BADGE } from '@qlevr/shared/constants';

export function getVariantTagColor(color?: string | null): string {
  if (!color) {
    color = BADGE.teal;
  }

  const variantTagColorMapping = new Map<string, string>([
    ['Teal', BADGE.teal],
    ['Slate', BADGE.slate],
    ['Orange', BADGE.warm],
  ]);

  const variantTagColor = variantTagColorMapping.get(color);
  if (!variantTagColor) {
    return BADGE.teal;
  }

  return variantTagColor;
}
