'use client';

import { ElevarDataLayerEvent } from '@qlevr/shared/interfaces';
import dynamic from 'next/dynamic';
import { AnalyticsTrackEventProps } from './track-event';

// https://dev.to/vvo/how-to-solve-window-is-not-defined-errors-in-react-and-next-js-5f97
const TrackEventLoader = dynamic(
() => {
  return import("./track-event");
},
{ ssr: false }
);

/* eslint-disable-next-line */
export interface AnalyticsTrackEventHocProps extends ElevarDataLayerEvent {}

export function AnalyticsTrackEventHoc(props: AnalyticsTrackEventProps) {
  return <TrackEventLoader {...props} />;
}

export default AnalyticsTrackEventHoc;
