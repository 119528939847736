import { KeyValueInterface } from '@qlevr/shared/interfaces';
import { Maybe, MetafieldShopify, MetaobjectShopify } from '@qlevr/shared/schema';
import { escapeUrl } from '@qlevr/shared/utilities';
import { getMetafieldReferenceImageField } from './get-metafield-reference-image-field';
import { getMetafieldsByKey } from './get-metafields-by-key';

export function getProductMetafieldReferencesFields(
  key: string,
  references: Maybe<MetafieldShopify>[],
): KeyValueInterface[] {
  const items: KeyValueInterface[] = [];
  const reference = references.find((item) => item?.key === key);

  if (!reference || !reference?.references?.edges) {
    return items;
  }

  reference.references.edges.forEach(({ node }) => {
    const key = escapeUrl(getMetafieldsByKey('name', (node as MetaobjectShopify).fields));
    const value = getMetafieldReferenceImageField('image', (node as MetaobjectShopify).fields);

    if (!key) {
      return null;
    }

    return items.push({ key, value });
  });

  return items;
}
