import { HorizontalAlignmentConstant, TextAlignmentConstant, VerticalAlignmentConstant } from '@qlevr/shared/constants';
import { ContentAlignmentInterface } from '@qlevr/shared/interfaces';
import { ContentAlignmentStoryblok } from '@qlevr/shared/schema';
import { HorizontalAlignmentMapper, TextAlignmentMapper, VerticalAlignmentMapper } from './alignments';

export function contentAlignmentMapper(content: ContentAlignmentStoryblok | undefined): ContentAlignmentInterface {
  if (!content) {
    return {
      vertical: VerticalAlignmentConstant.start,
      horizontal: HorizontalAlignmentConstant.start,
      text: TextAlignmentConstant.left,
    };
  }

  return {
    vertical: VerticalAlignmentMapper(content.verticalAlignment ?? ''),
    horizontal: HorizontalAlignmentMapper(content.horizontalAlignment ?? ''),
    text: TextAlignmentMapper(content.textAlignment ?? ''),
  };
}
