import { ICONS } from '@qlevr/shared/constants';
import IconRenderer from '../icons/icon-renderer.component';

export function PaymentMethods() {
  return (
    <div className="flex items-center justify-center gap-2">
      <IconRenderer icon={ICONS.paypal} className="h-6 w-8 rounded-sm bg-white shadow-sm" />
      <IconRenderer icon={ICONS.klarna} className="h-6 w-8 rounded-sm bg-white shadow-sm" />
      <IconRenderer icon={ICONS.mastercard} className="h-6 w-8 rounded-sm bg-white shadow-sm" />
      <IconRenderer icon={ICONS.visa} className="h-6 w-8 rounded-sm bg-white shadow-sm" />
      <IconRenderer icon={ICONS.ideal} className="h-6 w-8 rounded-sm bg-white shadow-sm" />
      <IconRenderer icon={ICONS.bancontact} className="h-6 w-8 rounded-sm bg-white shadow-sm" />
      <IconRenderer icon={ICONS.applePay} className="h-6 w-8 rounded-sm bg-white shadow-sm" />
      <IconRenderer icon={ICONS.googlePay} className="h-6 w-8 rounded-sm bg-white shadow-sm" />
      <IconRenderer icon={ICONS.sepaBankTransfer} className="h-6 w-8 rounded-sm bg-white shadow-sm" />
    </div>
  );
}

export default PaymentMethods;
