import { ContentProductVariantsInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { ProductVariantsStoryblok, ShopifyProductStoryblok } from '@qlevr/shared/schema';
import { getMetaProductById } from '../products/products-shopify';
import { sectionMapper } from '../section/section';
import { mapTitle } from '../title/title';

export function mapProductVariantsContent(content: ProductVariantsStoryblok, meta: MetaInterface): ContentProductVariantsInterface {
  const product = content['product'].items[0] as ShopifyProductStoryblok;
  const productId = product.id;

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: mapTitle(content.title?.[0]),
    product: getMetaProductById(productId, meta),
    cta: content.cta ?? null,
    section: sectionMapper(content.section?.[0]),
  };
}
