import { ICONS } from '@qlevr/shared/constants';
import { useTranslations } from 'next-intl';
import { Dispatch, SetStateAction, useEffect } from 'react';
import IconRenderer from '../icons/icon-renderer.component';

interface VideoSoundProps {
  isMuted: boolean;
  isPlaying: boolean;
  setIsMuted: Dispatch<SetStateAction<boolean>>;
  isAutoplay: boolean;
}

export function VideoSound({ isMuted, isPlaying: playing, setIsMuted, isAutoplay }: VideoSoundProps) {
  const t = useTranslations();
  const ariaLabel = isMuted ? 'video.sound.off' : 'video.sound.on';
  const icon = isMuted ? ICONS.mute : ICONS.unmute;

  useEffect(() => {
    if (!isAutoplay) {
      setIsMuted(!playing);
    }

  }, [playing, setIsMuted, isAutoplay]);

  return (
    <button onClick={() => setIsMuted(!isMuted)} aria-label={t(ariaLabel)}>
      <IconRenderer id='muteUnmute' aria-label="muteUnmuteIcon" className="text-white w-12 h-12" icon={icon} />
    </button>
  );
}
export default VideoSound;
