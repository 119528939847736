'use client';

import { BUTTON_SIZE, ButtonSizeType, LINK_STYLES, LinkStyleType } from '@qlevr/shared/constants';
import {
  ContentGuidedBuyingFlowInterface,
  LinkInterface,
  ProductCombinationInterface,
  ProductInterface,
  ProductVariantInterface,
} from '@qlevr/shared/interfaces';
import { useBundleAndSave, useCart, useGuidedBuyingFlowBundler } from '@qlevr/shared/providers';
import { AttributeInputShopify } from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';
import { useTranslations } from 'next-intl';
import { startTransition, useEffect, useRef, useState } from 'react';
import ContentGuidedBuyingFlow from '../content-blocks/guided-buying-flow/guided-buying-flow';
import LinkRenderer from '../link/link-renderer';
import { useShopifyAnalytics } from '../shopify-analytics/use-shopify-analytics';
import ProductPreOrder from './product-pre-order';
import { StickyAddToCartButton } from './product-sticky-add-to-cart';

/* eslint-disable-next-line */
export interface ProductBuyProps {
  product?: ProductInterface;
  variantId?: ProductVariantInterface['id'];
  cta?: Partial<LinkInterface>;
  linkStyle?: LinkStyleType;
  size?: ButtonSizeType;
  label?: string;
  combination?: ProductCombinationInterface | null;
  showStickyAddToCart?: boolean;
  isBundleFlowProduct?: boolean;
  isProductPage?: boolean;
  guidedBuyingFlow?: ContentGuidedBuyingFlowInterface | null;
}

export function ProductBuy({
  product,
  variantId,
  label = 'cta.addToCart',
  cta,
  combination,
  showStickyAddToCart,
  isBundleFlowProduct = false,
  isProductPage = false,
  guidedBuyingFlow,
}: ProductBuyProps) {
  const t = useTranslations();
  // send cart event to Shopify an
  const { sendAddToCart } = useShopifyAnalytics();
  const bundleAndSaveContext = useBundleAndSave();
  const guidedBuyingFlowModal = useGuidedBuyingFlowBundler();
  const cartContext = useCart();
  const componentRef = useRef(null);
  const [isAdded, setIsAdded] = useState(false);
  const { add } = useCart();
  const ctaLabel = combination && combination.preOrder ? t('cta.preOrder') : t(label);
  const attributes: AttributeInputShopify[] = [];
  if (combination) {
    if (combination.preOrder) {
      attributes.push({ key: '_preOrder', value: 'true' });
    }
    if (combination.shippingDate) {
      attributes.push({ key: '_shippingDate', value: combination.shippingDate });
    }
  }

  const submitCta: LinkInterface = {
    as: 'button',
    type: 'submit',
    disabled: !variantId,
    ...(cta?.linkStyle ? { linkStyle: cta.linkStyle } : { linkStyle: LINK_STYLES.primary }),
    ...(cta?.wrapperClassName ? { wrapperClassName: cta.wrapperClassName } : { wrapperClassName: 'w-full' }),
    ...(cta?.className ? { className: cta.className } : { className: 'whitespace-nowrap' }),
    ...(cta?.size ? { size: cta.size } : { size: BUTTON_SIZE.base }),
    ...(cta?.leadingIcon ? { leadingIcon: cta.leadingIcon } : {}),
    ...(cta?.leadingClassName ? { leadingClassName: cta.leadingClassName } : {}),
  };

  const variant = product?.variants?.find((v) => v.id === variantId);

  async function formAction() {
    if (!variantId) return;

    if (isBundleFlowProduct) {
      setIsAdded(true);
    }

    if (!variant || !product) return;

    startTransition(() => {
      add(variant, variantId, attributes);
    });

    // Determine if we should start the bundle flow
    const shouldStartBundleFlow = !isEmpty(product?.productRecommendations);

    if (product?.hasGuidedBuyingFlow && guidedBuyingFlow) {
      guidedBuyingFlowModal.open();
    } else {
      if (shouldStartBundleFlow) {
        bundleAndSaveContext.open();
      }
    }

    // If the product is from the bundle flow, do not proceed to open the cart.
    // Otherwise, open the cart as normal.
    if (!isBundleFlowProduct && !shouldStartBundleFlow && !product?.hasGuidedBuyingFlow && !guidedBuyingFlow) {
      cartContext.open();
    }
  }

  useEffect(() => {
    if (cartContext.cart?.id && cartContext.cart.items.length > 0) {
      sendAddToCart({
        cartId: cartContext.cart?.id,
        products: cartContext.cart.items.map((item) => ({
          productGid: item.id,
          variantGid: item.variantId,
          name: item.title,
          brand: item.vendor || '',
          price: item.price?.amount?.toString() || '',
          quantity: item.quantity,
        })),
      });
    }
  }, [sendAddToCart, cartContext.cart?.id, cartContext.add, cartContext.cart?.items]);

  return (
    <>
      <form
        ref={componentRef}
        onClick={(e) => {
          e.preventDefault();
          formAction();
        }}
        className="w-full"
      >
        {isBundleFlowProduct ? (
          !isAdded ? (
            <LinkRenderer cta={submitCta} />
          ) : (
            <button className="w-fit rounded-full bg-green-500 px-4 py-2 text-sm text-white">
              {t('bundleAndSave.addedToCart')}
            </button>
          )
        ) : combination && combination.preOrder ? (
          <ProductPreOrder combination={combination} submitCta={submitCta} />
        ) : (
          <LinkRenderer cta={submitCta}>{t(label)}</LinkRenderer>
        )}

        <p aria-live="polite" className="sr-only" role="status">
          {/* {message} */}
        </p>
      </form>

      {product?.hasGuidedBuyingFlow && guidedBuyingFlow && <ContentGuidedBuyingFlow {...guidedBuyingFlow} />}
      {showStickyAddToCart && product && (
        <StickyAddToCartButton
          observedElementRef={componentRef}
          formAction={formAction}
          label={ctaLabel}
          submitCta={submitCta}
          product={product}
        />
      )}
    </>
  );
}

export default ProductBuy;
