import { ICONS } from '@qlevr/shared/constants';
import { Dispatch, SetStateAction } from 'react';
import IconRenderer from '../icons/icon-renderer.component';
import { useTranslations } from 'next-intl';

interface VideoPlayPauseProps {
  isPlaying: boolean;
  setPlaying: Dispatch<SetStateAction<boolean>>;
}

export function VideoPlayPause({ isPlaying: playing, setPlaying }: VideoPlayPauseProps) {
  const t = useTranslations();
  const accessibilityLabel = playing ? t('video.pause') : t('video.play');
  const icon = playing ? ICONS.pause : ICONS.play;
  return (
    <button className="relative text-white" onClick={() => setPlaying(!playing)}>
      <IconRenderer id="pausePlay" aria-label="PausePlayIcon" className="h-12 w-12 text-white" icon={icon} />
      <span className="sr-only">{accessibilityLabel}</span>
    </button>
  );
}

export default VideoPlayPause;
